import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import CreateTask from '../../partials/modals/Add Pages/CreateTask';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { right } from '@popperjs/core';
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
const API_URL = process.env.REACT_APP_API_URL;

function TaskList() {
  const [tasklist, setTasklist] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30; // Specify the number of rows per page
  const token = localStorage.getItem('token');
  const [TaskFilter, setTaskFilter] = useState('all');
  const [TaskStatusFilter, setTaskStatusFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const { eventId } = useParams();
  const [priorities, setPriorities] = useState([])
  const [TaskStatus, setTaskStatus] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");


  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = !checked;

    setLoading(true);
    axios
      .get(API_URL + `/api/Task/taskupdate/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTasklist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error updating Status. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + '/api/TaskPriority', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setPriorities(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error Fetching Task Priority. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [token]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + '/api/tastStatus', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setTaskStatus(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error Fetching Task Status. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [token]);


  const handleCreateTask = () => {
    axios
      .get(API_URL + `/api/Task`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setTasklist(response.data);
      })
      .catch(error => {
        toast.error('Error fetching tasks:', error);
      });
  }


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/Task`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setTasklist(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching tasks:', error);
        setLoading(false);
      });
  }, [token]);
  const handlePageChange = (selectedPage) => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const handleTaskFilterChange = (filter) => {

    if (filter === `all`) {
      setLoading(true);
      axios
        .get(API_URL + '/api/Task', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          setTasklist(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error fetching priorities:', error);
          setLoading(false);
        });

    } else {
      setLoading(true);
      axios
        .get(API_URL + `/api/Task/taskpriorityid=${filter}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          setTasklist(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error fetching Task Priorities:', error);
          setLoading(false);
        });
    }

  }
    ;

  const handleTaskStatusFilterChange = (status) => {
    setTaskStatusFilter(status);
    if (status === 'all') {
      setLoading(true);
      axios
        .get(API_URL + '/api/Task', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          setTasklist(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error fetching task statuses:', error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      axios
        .get(API_URL + `/api/Task/taskstatus=${status}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          setTasklist(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error fetching tasks by status:', error);
          setLoading(false);
        });
    }
  }


  const filteredData = tasklist.filter((tasks) =>
    tasks.taskname.toLowerCase().includes(searchTerm.toLowerCase()) ||
    tasks.taskassignedto.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );


  return (



    <div className='row'>
      {loading ? (
        <Loader />
      ) : (
        <div>

          <CreateTask onTaskCreated={handleCreateTask} eventId={eventId} />
          <span className='card-label fw-bold fs-1 mb-1'>Task List</span>
          <br></br>

          <div className='card-header border-0 pt-5'>

            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-450px ps-14'
                  placeholder='Search here..'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

            </div>

          </div>

          <div className='card-header border-0 pt-4'>

            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a task'
            >
              <a
                href='#'
                className='btn btn-sm btn-primary float-end mt-2'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_task'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>


            <div className='card-toolbar'>
              <div className='dropdown mr-2'>
                <button
                  className='btn btn-sm btn-light-primary dropdown-toggle float-end m-2'
                  type='button'
                  id='taskPriorityFilterDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Priority
                </button>

                <ul className='dropdown-menu' aria-labelledby='taskPriorityFilterDropdown'>
                  <li key='allpriority'>
                    <button className={`dropdown-item ${TaskFilter === 'all' ? 'active' : ''}`} onClick={() => handleTaskFilterChange('all')}>
                      All Tasks
                    </button>
                  </li>
                  {/* Iterate through the fetched priorities and populate the dropdown */}
                  {priorities.map(priority => (
                    <li key={`priority-${priority.id}`}>
                      <button className={`dropdown-item ${TaskFilter === priority.prioritytype ? 'active' : ''}`} onClick={() => handleTaskFilterChange(priority.prioritytype)}>
                        {priority.prioritytype}
                      </button>
                    </li>
                  ))}
                </ul>

              </div>


            </div>


            <div className='card-toolbar'>
              <div className='dropdown mr-2'>
                <button
                  className='btn btn-sm btn-light-primary dropdown-toggle float-end m-2'
                  type='button'
                  id='taskStatusFilterDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'

                >
                  Task Status
                </button>

                <ul className='dropdown-menu' aria-labelledby='taskStatusFilterDropdown'>
                  <li key='allstatus'>
                    <button className={`dropdown-item ${TaskStatusFilter === 'all' ? 'active' : ''}`} onClick={() => handleTaskStatusFilterChange('all')}>
                      All Tasks
                    </button>
                  </li>
                  {/* Iterate through the fetched priorities and populate the dropdown */}
                  {TaskStatus.map(taskstatus => (
                    <li key={`status-${taskstatus.id}`}>
                      <button className={`dropdown-item ${TaskStatusFilter === taskstatus.name ? 'active' : ''}`} onClick={() => handleTaskStatusFilterChange(taskstatus.name)}>
                        {taskstatus.name}
                      </button>
                    </li>
                  ))}
                </ul>

              </div>

            </div>


          </div>

          <br></br>
          <div className='card-header border-0 pt-8'>
            <div className='row g-5'>

              {paginatedData.slice().reverse().map((task) => (

                <div className='col-xl-6 col-md-6' key={`task-${task.id}`}>

                  <div className="card card-raised">
                    
                      <div className='border-0 pt-2'>
                        <div className='row'>


                          <div className='col-7'>


                            <h3 className='card-title align-items-start flex-column m-2'>
                              <span className='card-label fw-bold text-dark'>{task.taskname}</span>
                              <p className='text-muted mt-1 fw-semibold fs-7'>
                                {task.description.length > 160 ? `${task.description.slice(0, 160)}...` : task.description}
                              </p>
                            </h3>
                          </div>
                          <div className='col-2 d-flex justify-content-end m-2'>
                            <span className='text-muted mt-1 fw-semibold fs-7'>

                              {task.taskstatus === undefined || task.taskstatus === '' ?
                                <span className='badge badge-light-primary'>OPEN</span>
                                :
                                ''
                              }

                              {task.type === 'OPEN' ?
                                <span className='badge badge-light-primary'>${task.taskstatus}</span>
                                :
                                ''
                              }

                              {task.type === 'WIP' ?
                                <span className='badge badge-light-danger'>${task.taskstatus}</span>
                                :
                                ''
                              }

                              {task.type === 'DONE' ?
                                <span className='badge badge-light-success'>${task.taskstatus}</span>
                                :
                                ''
                              }

                              <span className='badge badge-light-success'>{task.taskstatus}</span>
                            </span>

                          </div>

                          <div className='col-1 justify-content-end'>


                            <button
                              type='button'
                              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='float-end'
                            >
                              <Link
                                to={`/edittask/${eventId}/${task.id}`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                                <KTIcon iconName='pencil' className='fs-2' />
                              </Link>
                            </button>
                          </div>

                        </div>
                      </div>
                      <hr />
                      <div>
                        <div className='align-items-start flex-column'>
                          <div className='row'>
                            <div className='col-6 m-2'>
                              <span className='text-muted mt-1 fw-semibold fs-7'>Assignee: {task.taskassignedto}</span>
                            </div>
                            <div className='col-5 text-align-right m-2'>
                              <span className='text-muted mt-1 fw-semibold fs-7' style={{ alignContent: right }}>Due Date: {task.duedate}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
              
                </div>

              ))}


            </div>
          </div>
        </div>
      )}
      <div>
        {tasklist.length > 0 && (
          <Pagination
            data={tasklist}
            itemsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            currentPage={page}
          />
        )}
      </div>
    </div>



  );
}

export default TaskList;
