import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import Home from '../../_metronic/organization/events/Home'
import PublicHome from '../../_metronic/Public/PublicHome'
import PublicEvent from '../modules/events/PublicEvent'
import PublicRegistration from '../modules/auth/components/PublicRegistration'
import Eventwise from '../modules/auth/components/EventWise'
import EventSponsors from '../../_metronic/Public/PublicHome/Event/EventSponsors'
import EventsVenue from '../../_metronic/Public/PublicHome/Event/EventsVenue';
import EventSpeaker from '../../_metronic/Public/PublicHome/Event/EventSpeakers'
import Invoice from '../../_metronic/users/Invoice'
import Subscription from '../../_metronic/users/Subscription'
import Payment from '../../_metronic/users/Payment'
import ExhibitorDetails from '../../_metronic/organization/events/Exhibitor/ExhibitorDetails'
import EventSignup from '../../_metronic/Public/PublicHome/Event/EventSignup'
import { ExhibitorRegister } from '../modules/auth/components/ExhibitorRegister'
import EventAgenda from '../../_metronic/Public/PublicHome/Event/EventAgenda'
import NavArticle from '../../_metronic/Public/PublicHome/Event/NavArticle'

// import YouTubeApiComponent from '../../_metronic/organization/events/Youtube/YoutubeAPI'


//import Authenticate from '../../app/modules/auth/components/Authenticate'
/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>

          {/* <Route path='u2B' element={<YouTubeApiComponent />} />
           */}
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='event/:eventId' element={<Home />} />
          <Route path='event/:eventId/publicregistration' element={<PublicRegistration />} />
          <Route path='event/:eventId/memberSignup' element={<ExhibitorRegister />} />
          <Route path='userEvents' element={<Eventwise />} />
          <Route path='/home' element={<Home />} />
          <Route path='event/:eventId/sponsors' element={<EventSponsors />} />
          <Route path='/venue' element={<EventsVenue />} />
          <Route path='event/:eventId/speakers' element={<EventSpeaker />} />
          <Route path='event/:eventId/agenda' element={<EventAgenda/>} />
          <Route path='event/:eventId/NavArticle' element={<NavArticle/>} />
          <Route path='/pubhome' element={<PublicHome />} />
          <Route path='event/:eventId/eventSignup' element={<EventSignup />} />
        
          <Route path='invoice/:featureId' element={<Invoice />} />
          <Route path='subscriptions' element={<Subscription />} />
          <Route path='paymentDetails/:featureId' element={<Payment />} />
          <Route path='connection/:connectionId' element={<ExhibitorDetails />} />
          <Route path='invoice' element={<Invoice />} />
           <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />

            </>
          )}

        </Route>
      </Routes>
    </BrowserRouter>

  )
}

export { AppRoutes }

