import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditAdminPlatform() {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { eventId } = useParams();
  console.log(eventId)
  const { auth } = useAuth();
  const [editplateForm, setEditplatform] = useState({
    id: "",
    platformFee: "",
    eventid:eventId

  });



  useEffect(() => {
    axios
      .get(API_URL + `/api/platformFee/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setEditplatform(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Platform Fee. Please try again.';
        toast.error(errorMessage);
      });
  }, [eventId, token]);


  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setEditplatform((prevFee) => ({ ...prevFee, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/platformFee/createUpdate", editplateForm, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Platform Fee Updated");
        navigate(-1);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update PlatForm Fee. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <h3>Edit Platform Fee</h3>


                    </div>
                  </div>

                  <br></br>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="platformFee">Platform Fee</label>
                      <input
                        className="form-control"
                        id="platformFee"
                        type="text"
                        placeholder="Platform Fee"
                        value={editplateForm.platformFee}
                        onChange={(e) => handleChange(e, "platformFee")}
                      />
                    </div>
                  </div>
                  <br></br>


                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditAdminPlatform;
