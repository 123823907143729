import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EventFooter from '../EventFooter';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import Navbar from '../Navbar';
import SpeakerDetail from './SpeakerDetails';
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;

function EventSpeaker() {
    const [event, seteventDetails] = useState({});
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        //setLoading(true);
        axios.get(API_URL + `/api/EventPlannings/${eventId}`)
            .then(response => {
                seteventDetails(response.data);
              //  setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching eventlist:', error);
              //  setLoading(false);
            });
    }, [eventId]);

    const [speakers, setSpeakers] = useState([]);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null);
    const [session, setSession] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/EventSpeakers/eventid=${eventId}`)
            .then(response => {
                setSpeakers(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching sponsors', error);
                setLoading(false);
            });

    }, [eventId]);

    // useEffect(() => {

    //     axios
    //         .get(API_URL + `/api/Sessioncalendar/eventid=${event?.id}/userid=4`)
    //         .then(response => {
    //             setSession(response.data);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching sessions:', error);
    //         });

    // }, [event]);


    const handleSponsorClick = (sponsor) => {
        setSelectedSpeaker(sponsor);
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <Navbar event={event} />
                    <div className='col-xl-12'>
                        <SpeakerDetail speaker={selectedSpeaker} session={session} />
                        <div className='card px-20 py-20'>
                            <div className='px-10'>
                                <div className='card'>
                                    <div style={{ backgroundColor: '#728df9', color: '#fbf9f9', fontSize: '3.5rem' }} className='card-label fw-bold text-center'>
                                        Our Speakers
                                    </div>
                                </div>
                                
                                <div className='row g-4 g-xl-10'>


                                    {speakers.map((speaker) => (

                                        <div key={speaker.id} className='col-lg-3 col-md-2'>
                                            <a
                                                href='#'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_speaker_details'
                                                onClick={() => handleSponsorClick(speaker)}
                                            >
                                                <div className='card text-center card-xl-stretch mb-xl-8' >
                                                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative pt-3'>

                                                        <img
                                                            src={`${speaker?.imageMeta},${speaker?.image}`}
                                                            style={{
                                                                width: '65%',
                                                                height: '160px',
                                                            
                                                            }}
                                                        />
                                                    </div>
                                                    <h3 className='pt-2'>{speaker?.name}</h3>
                                                    <span className='text-dark fw-semibold fs-5'>{speaker?.designation}</span>
                                                    <span className='text-dark fw-semibold fs-6 pb-5'>{speaker?.company}</span>
                                                </div>

                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <EventFooter />
                </div>
            )}
        </div>
    );
}
export default EventSpeaker;
