import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import { useAuth } from '../../app/modules/auth';
import './notification.css'; // Import the CSS file containing the styles
import Pagination from '../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

function UserNotification() {
  const [notifications, setNotifications] = useState([]);
  const { auth, notification, setNotification } = useAuth();
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;


  useEffect(() => {
    setNotifications(notification)
  
    }, []);
  
    function fetchData() {
      axios
        .get(`${API_URL}/api/user/notifications`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) => {
          toast.error('Error fetching Notifications:', error);
        });
    }
  
  useEffect(() => {
  setNotifications(notification)

  }, []);

  function fetchData() {
    // Check if auth is available and it has a username
    if (auth && auth.username) {
      axios
        .get(`${API_URL}/api/user/notifications/username=${auth.username}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((response) => {
          setNotifications(response.data);
        })
        .catch((error) => {
          toast.error('Error fetching Notifications:', error);
        });
    }
  }


  


  // function fetchData() {
  //   axios
  //     .post(API_URL + `/api/user/notifications/user${currentUser?.username}`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //       },
  //     })
  //     .then((response) => {
  //       setNotifications(response.data);
  //       setNotification(response.data);
        
  //     })
  //     .catch((error) => {
  //       toast.error('Error fetching Notifications:', error);
  //     });
  // }

  const markAsRead = async (agent) => {
    try {
      await axios.post(
        API_URL + `/api/user/notifications/update`, agent,
        {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        }

      );

      fetchData();
    } catch (error) {
      toast.error('Error updating notification:', error);
    }
  };

  const handleNotificationClick = (agent) => {
    const clickedNotification = notifications.find((notification) => notification.id === agent.id);

    if (clickedNotification && !clickedNotification.read) {
      markAsRead(agent);
    }
  };

  const handlePageChange = (selectedPage) => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const sortNotificationsByDate = (notifications) => {
    // Sort notifications by date in descending order
    return notifications.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  };

  const sortedNotifications = sortNotificationsByDate(notifications);
  const paginatedData = sortedNotifications.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  //const paginatedData = sortNotificationsByDate.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (

    <div className='container'>
      <div className='col-lg-12 mx-auto'>
        <div className='card'>
          <div className='box-title bg-primary border-bottom p-3'>
            <h2>Notifications</h2>
          </div>
          <div className='p-0 px-4 pb-4'>
            {paginatedData.slice().map((agent) => (
              <div

                className={`p-3 d-flex align-items-start border-bottom ${agent.read ? 'bg-light-basic' : 'bg-light-info'}`}
                key={agent?.id}
                onClick={() => handleNotificationClick(agent)}
              >
                <div className='col-lg-12 col-md-10 cursor-pointer'>
                  <div className='row'>
                    <div className='col-lg-10 col-md-8'>
                      <h3 className='font-weight-bold text-truncate'>{agent?.subject}</h3>

                    </div>

                    <div className='col-lg-2 col-md-3 flex-grow-1 text-md-right mt-2 mt-md-0'>
                      {agent?.timestamp}
                    </div>
                  </div>

                  <div className='row'>
                    <div>{agent?.message}</div>

                  </div>
                </div>

              </div>



            ))}

            <Pagination
              data={notification}
              itemsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              currentPage={page}
            />

          </div>
        </div>
      </div>
    </div>
  );

}

export default UserNotification;
