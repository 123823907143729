import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;

function EditAdvsettings() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { Advseid } = useParams();
    const { auth } = useAuth();
    
    const [advertisementTypes, setAdvertisementTypes] = useState([]);
    const [addsetting, setaddsetting] = useState({
        advertisementtypeid: '',
        timeallowed: '',
        cost: '',
        type: "",
        id: Advseid,
        disabled: false,
    });

    useEffect(() => {
        const fetchAdvertisementTypes = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/advertisementtype`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                });
                setAdvertisementTypes(response.data);
            } catch (error) {
                toast.error('Error fetching Advertisement Types. Please try again.');
            }
        };
        fetchAdvertisementTypes();
    }, [auth?.token]);

    useEffect(() => {
        setLoading(true);
        axios
          .get(API_URL + `/api/advertisementsettings/${Advseid}`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then((response) => {
            setaddsetting(response.data);
            setLoading(false);
          })
          .catch((error) => {
            const errorMessage = 'Error Fetching Advertisement setting. Please try again.';
            toast.error(errorMessage);
            setLoading(false);
          });
      }, [Advseid,auth?.token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setaddsetting(prevAdd => ({ ...prevAdd, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Prevent default form submission behavior
        setLoading(true);
    
        axios
          .post(API_URL + "/api/advertisementsettings/update", addsetting, {
            headers: { Authorization: `Bearer ${auth?.token}` },
          })
          .then(() => {
            toast.success("Advertisement setting Updated");
            navigate(-1);
            setLoading(false);  
          })
          .catch((error) => {
            const errorMessage = 'Failed to Update Advertisement setting. Please try again.';
            toast.error(errorMessage);
            setLoading(false);
          });
      };

    return (
        <div>
            {loading && <div className="loading"></div>}
            <main>
                <div className="col-8 mx-xl-auto ps-xl-8">
                    <div className="card card-raised">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <h3>Edit Advertisement Settings</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="advertisementtypeid">Advertisement Settings</label>
                                        <select
                                            name='advertisementtypeid'
                                            className='form-control'
                                            onChange={handleChange}
                                            value={addsetting.advertisementtypeid}
                                        >
                                            <option value="">Select...</option>
                                            {advertisementTypes.map((type, index) => (
                                                <option key={index} value={type.advertisementtypeid}>
                                                    {type.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="timeallowed">Time Allowed</label>
                                        <input
                                            className="form-control"
                                            name="timeallowed"
                                            type="text"
                                            placeholder="Time Allowed"
                                            value={addsetting.timeallowed}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="cost">Cost</label>
                                        <input
                                            className="form-control"
                                            name="cost"
                                            type="text"
                                            placeholder="Cost"
                                            value={addsetting.cost}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <br />

                                <div className="text-end">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default EditAdvsettings;
