import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import { MdCancel } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
const API_URL = process.env.REACT_APP_API_URL;

function ExibitorSeek() {
  const [uspseek, setUspseek] = useState([]);
  const [usp, setUsp] = useState([]);
  const [uspvalue] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const [loading, setLoading] = useState(false);
  const { currentUser, auth } = useAuth();
  const [selectedUspSeek] = useState(null);
  const [duplicateIntentionError, setDuplicateIntentionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  function onClickRemove(usp) {
    setLoading(true);
    axios
      .delete(API_URL + `/api/ExibitorUspSeek/delete`,usp, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        toast.success(response.data)
      })
      .catch((error) => {
        toast.error("Error removing USP Seek");
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/UspSeek`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setUspseek(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching USP Seeks");
        setLoading(false);
      });
  }, [auth?.token]);



  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/ExibitorUspSeek/userid=${currentUser?.email}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setUsp(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching Exhibitors USP's");
        setLoading(false);
      });
  }

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    const isDuplicate = usp.some((uspItem) => uspItem.uspseek === selectedValue);

    if (isDuplicate) {
      setErrorMessage('You have already added this USPSeek.');
      setDuplicateIntentionError(true);
      return;
    }

    setErrorMessage('');
    setDuplicateIntentionError(false);

    setLoading(true);

    const addusp = {
      uspseek: selectedValue,
      userid: currentUser?.email,
    };

    axios
      .post(API_URL + `/api/ExibitorUspSeek/create`, addusp, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        getData();
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error Creating USP");
        setLoading(false);
      });
  };



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>USP Seeks</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='to oltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='add new'
            >
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>
              <div>
                <label htmlFor='uspSeekSelect' className='form-label'>
                  Select USP Seeks:
                </label>
                <select
                  id='uspSeekSelect'
                  className='form-select'
                  onChange={handleSelectChange}
                  value={selectedUspSeek || ''}
                  style={{ width: '300px', padding: '10px' }}
                >
                  <option value='' disabled>
                    Select USP Seeks
                  </option>
                  {uspseek.map((uspseek) => (
                    <option key={uspseek.id} value={uspseek.uspseek}>
                      {uspseek.name}
                    </option>
                  ))}
                </select>
                {duplicateIntentionError && (
                  <div style={{ color: 'red', marginTop: '5px' }}>
                    {errorMessage}
                  </div>
                )}
              </div>
              <br></br>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-100px'>USP Seeks</th>
                      <th className='min-w-60px'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usp.slice().reverse().map((uspItem) => (
                      <tr key={uspItem.id}>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>{uspItem.uspseek}</div>

                        </td>
                        <td style={{ display: 'flex', alignItems: 'center', width: '150px' }}>
                            <button
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'style={{ width: '50px' }}
                              onClick={() => onClickRemove(uspItem)}
                             
                            >
                                 <MdDeleteForever style={{ fontSize: '20px' }} />
                            </button>
                          </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {uspvalue.length > 0 && (
                <Pagination
                  data={uspvalue}
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={page}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExibitorSeek;
