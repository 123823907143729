import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../app/modules/auth';
import Loader from '../../Loader';
import Pagination from '../../app/modules/auth/components/Pagination';
import { toast } from "react-toastify";




const API_URL = process.env.REACT_APP_API_URL;



function SuperDashboard() {
  const [eventlist, seteventlist] = useState([]);
  const [Users, setusers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const token = localStorage.getItem('token');


  useEffect(() => {
    fetchData(currentPage);
  }, []);


  function fetchData(page) {
    setLoading(true);
    axios
      .get(API_URL + `/api/organizationprofiles/getAll/page=${page}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        seteventlist(response.data.pageData);
    
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Orghanizations. Please try again.';
        console.error(errorMessage);
        setLoading(false);
      });
  }


  axios
    .get(API_URL + `/api/accounts/getAll/page=${page}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response) => {
      setusers(response.data.pageData);
      setLoading(false);
    })
    .catch((error) => {
      toast.error('Error fetching :', error);
      setLoading(false);
    });






  return (
    <div>
      <div>
        {loading ? (
          <Loader />
        ) : (

          <div className='row gy-5 g-xl-8'>
            <div className='col-xl-6 h-lg-100'>
              <div className='card card-xl-stretch mb-xl-8 bg-success' style={{ height: '235px' }}>
                <div className='card-title d-flex flex-column align-items-center justify-content-center ' style={{ height: '200px' }} >
                  <span className='fs-3hx fw-bold text-gray me-6 lh-1 ls-n2'>{eventlist.length}</span>
                  <h3 className='card-title text-gray-800 fw-bold text-center'>
                    Number of Organization
                  </h3>
                </div>
              </div>
            </div>
            {loading ? ( // Show loader if data is still loading
              <div className='text-center'>
                <div className='spinner-border' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </div>
            ) : (

              <div className='col-xl-6'>
                <div className='card card-xl-stretch mb-xl-8 bg-warning' style={{ height: '235px' }}>
                  <div className='card-title d-flex flex-column align-items-center justify-content-center ' style={{ height: '200px' }} >
                    <span className='fs-3hx fw-bold text-gray me-6 lh-1 ls-n2'>{Users.length}</span>
                    <h3 className='card-title text-gray-800 fw-bold text-center'>
                      Number of Users
                    </h3>
                  </div>
                </div>
              </div>

            )}


          </div>
        )}
      </div>
      <br />

    </div>



  );

            }


  export default SuperDashboard;