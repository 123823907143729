import React, { useContext, useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import axios from 'axios';
import WizardContext from '../WizardContext';
import Select from 'react-select';
import { CgOrganisation } from "react-icons/cg";
interface Country {
  value: string;
  label: string;
}
const Step2 = ({ updateOrganization }: any) => {
  const { organization, setOrganization } = useContext(WizardContext);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);


  const validatePhoneNumber = (contact: any) => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    return phoneNumberPattern.test(contact);
  };

  const validateAddress = (address: any) => {
    return address !== '';
  };

  const validateCountry = (country: any) => {
    return country !== null;
  };
  const validatePAN = (pan: any) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panPattern.test(pan);
  };

  const validateOrgType = (orgtype: any) => {
    return orgtype !== '';
  };


  const handleChange = (task: any, field: any) => {

    let actualValue = task.target.value;

    const updatedOrganization = { ...organization, [field]: actualValue };
    setOrganization(updatedOrganization);

  };
  updateOrganization(organization);

  useEffect(() => {
    if (organization?.country) {
      setSelectedCountry({
        value: organization.country,
        label: organization.country,
      });
    }

    async function fetchCountries() {
      try {
        const response = await axios.get('https://restcountries.com/v3.1/all');

        const countryOptions = response.data
          .map((country: any) => ({
            value: country.name.common,
            label: country.name.common,
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label)); // Sort countries alphabetically

        setCountries(countryOptions);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    }
    fetchCountries();
  }, [organization?.country]);


  const handleCountryChange = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
    setOrganization({ ...organization, country: selectedOption.value });

  };

  interface ImageData {
    metadata: string;
    content: string;
  }

  function getBase64FromFile(file: any): Promise<ImageData> {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {
        if (typeof reader.result === 'string') {
          const [metadata, content] = reader.result.split(",");
          resolve({ metadata, content });
        } else {
          reject(new Error('Unexpected result type'));
        }
      };

      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const handleImageChange = async (event: any) => {
    try {
      const convertedImage: ImageData = await getBase64FromFile(event.target.files[0]);
      const imageObject = {
        ...organization,
        imageMeta: convertedImage.metadata,
        image: convertedImage.content,
      };
      setOrganization(imageObject);
    } catch (error) {
      console.error('Error processing image:', error);
    }
  };


  //setSelectedCountry(organization.country);

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Business Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Organization Name</label>

        <Field
          name='businessName'
          value={organization.name}
          onChange={(e: any) => handleChange(e, "name")}
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Organization Code</span>
        </label>

        <Field
          name='businessDescriptor'
          value={organization.orgcode}
          className='form-control form-control-lg form-control-solid'

        />

        <div className='form-text'>
          Customers will see this shortened version of your statement descriptor
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Organization Type</label>

        <Field
          as='select'
          name='orgtype'
          className={`form-select form-select-lg form-select-solid ${validateOrgType(organization.orgtype) ? '' : 'is-invalid'
            }`}
          onChange={(e: any) => handleChange(e, 'orgtype')}
          style={{ cursor: 'pointer' }}
          value={organization.orgtype}

        >

          <option value={organization.orgtype}>{organization.orgtype}</option>
          <option value='Propritorship'>Propritorship</option>
          <option value='Partnership'>Partnership</option>
          <option value='limited liability partnership'>limited liability partnership</option>
          <option value='Non-profit'>Non-profit</option>
          <option value='Limited Liability'>Limited Liability</option>
          <option value='General Partnership'>General Partnership</option>
        </Field>
        <div className='invalid-feedback'>Please select an organization type.</div>
      </div>


      <div className='fv-row mb-10'>
        <label className='form-label'>Business Description</label>

        <Field
          as='textarea'
          name='businessDescription'
          value={organization.description}
          onChange={(e: any) => handleChange(e, "description")}
          className='form-control form-control-lg form-control-solid'
          rows={3}
        ></Field>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Country</label>

        <Select
          className={`form-control form-control-lg form-control-solid ${validateCountry(selectedCountry) ? '' : 'is-invalid'
            }`}
          options={countries}
          onChange={handleCountryChange}
          value={selectedCountry}

        />
        <div className='invalid-feedback'>Please select a country.</div>

      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Phone Number</label>
        {/* <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text">+{selectedCountryCode}</span>
    </div>
    </div> */}
        <Field
          name='Phone Number'
          value={organization.contact}
          onChange={(e: any) => handleChange(e, 'contact')}
          className={`form-control form-control-lg form-control-solid ${validatePhoneNumber(organization.contact) ? '' : 'is-invalid'
            }`}
          rows={1}
        ></Field>
        <div className='invalid-feedback'>Phone number should have exactly 10 digits.</div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Address</label>

        <Field
          name='address'
          value={organization.address}
          onChange={(e: any) => handleChange(e, 'address')}
          className={`form-control form-control-lg form-control-solid ${validateAddress(organization.address) ? '' : 'is-invalid'
            }`}
          rows={2}
        ></Field>
        <div className='invalid-feedback'>Please provide a valid address.</div>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>PAN</label>

        <Field
          name='pan'
          value={organization.pan}
          onChange={(e: any) => handleChange(e, "pan")}
          className={`form-control form-control-lg form-control-solid ${validatePAN(organization.pan) ? '' : 'is-invalid'
            }`}
          rows={1}
        ></Field>
        <div className='invalid-feedback'>PAN should be 10-character alphanumeric identifier, example: AAAAA1111A.</div>
      </div>

      {/* <div className='fv-row mb-10'>
        <label className='form-label'>TAN</label>

        <Field
          name='tan'
          value={organization.tan}
          onChange={(e:any) => handleChange(e, "tan")}
         
          className='form-control form-control-lg form-control-solid'
          rows={1}
        ></Field>
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label'>GST</label>

        <Field
         name='gstin'
          value={organization.gstin}
          onChange={(e:any) => handleChange(e, "gstin")}
         
          className='form-control form-control-lg form-control-solid'
          rows={1}
        ></Field>
      </div>

      */}

      <div className='col-lg-8'>


        <div className='me-7 mb-4'>
          <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>


            {organization?.image ? (
              <img id="image" src={`${organization?.imageMeta},${organization?.image}`} alt={organization?.email} style={{ maxWidth: "150px", maxHeight: "150px" }}

              />
            ) : (
              <CgOrganisation size={35} />
            )}

          </div></div>
        <input className="col-7" type="file" id="image" onChange={(event) => {
          handleImageChange(event)
        }} accept="image/*"></input>
      </div>
    </div>
  );
};

export default Step2;
