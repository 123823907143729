import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import BannerAdd from '../../org-management/Advertisement/BannerAdd';


const API_URL = process.env.REACT_APP_API_URL;

export default function EditAddsDetails() {
    const [addList, setAddlist] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const { eventId, addId } = useParams();
    const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [adds, setAdds] = useState([]);
    const [publishedAd, setPublishedAd] = useState({});
    const { auth } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        setLoading(true);
        if (eventId) {
            axios
                .get(API_URL + `/api/advertisement/eventid=${eventId}`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((response) => {
                    setAddlist(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Error fetching Adds:', error);
                    setLoading(false);
                });
        } else {
            axios
                .get(API_URL + `/api/advertisement`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((response) => {
                    setAddlist(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Error fetching Addvertisement:', error);
                    setLoading(false);
                });
        }

        axios
            .get(API_URL + `/api/addTransaction/addId=${addId}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setAdds(response.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching Transaction:', error);
                setLoading(false);
            });
    }


    const handleChange = (e, field) => {
        const actualValue = e.target.value;
        setAdds((prevAdds) => ({
            ...prevAdds,
            [field]: actualValue,
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true);

        try {
            await axios.post(
                API_URL + `/api/addTransaction/update/${addId}`, // Assuming addId is the identifier for the transaction
                adds,
                {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                }
            );
            toast.success("Updated Successfully");
            navigate(-1);
        } catch (error) {
            const errorMessage = 'Failed to Update Transaction details. Please try again.';
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };


    const paginatedData = adds.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const renderAdvertisement = (ad) => {
        setPublishedAd(ad);
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {publishedAd?.id && <BannerAdd ad={publishedAd} />}
                    <div className="row m-4">
                        <div className="col-md-12">
                            <h2>Add Details</h2>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="row g-5 g-xl-10">
                            {paginatedData.slice().reverse().map((adds, index) => (
                                <div
                                    className="col-xl-11"
                                    key={adds.id}
                                    onMouseEnter={() => setHoveredEventIndex(index)}
                                    onMouseLeave={() => setHoveredEventIndex(-1)}
                                >
                                    <div
                                        className="card card-raised"
                                        style={{
                                            transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                                            transition: 'transform 0.2s ease-in-out',
                                        }}
                                    >
                                        <div className="mx-4 mt-3">
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="border-0 pt-5">
                                                        <h5 className="card-title align-items-start flex-column">
                                                            <iframe
                                                                width="330"
                                                                height="283"
                                                                src={adds.videolink}
                                                                title="Video Player"
                                                                allowFullScreen
                                                            ></iframe>
                                                            <br />
                                                        </h5>
                                                    </div>
                                                    {/* end::Header */}
                                                </div>
                                                <div className="col-md-5 pt-9">
                                                    <div className="fs-4 pb-3">
                                                        <b>Name:</b> {adds.name}
                                                    </div>
                                                    <div className="fs-4 pb-3">
                                                        <b>Contact:</b> {adds.contactInformation}
                                                    </div>
                                                    <div className="fs-4 pb-3">
                                                        {' '}
                                                        <b>Description: </b> {adds?.description}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br></br>
                        <br></br>

                        <div className="card mb-xl-10">
                            <div className="card-toolbar d-flex justify-content-end pt-3 px-3">

                            </div>

                            <h1 className="pt-1 px-8">Transaction Details</h1>
                            <br></br>
                            {paginatedData.slice().reverse().map((add, index) => (
                                <div className="row px-8">
                                    <div className="col-3 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="username">Username</label>
                                            <input
                                                className="form-control"
                                                id="username"
                                                type="text"
                                                placeholder="Username"
                                                value={add?.transaction?.username}
                                                onChange={(e) => handleChange(e, "username")}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="startdate">Start Date</label>
                                            <input
                                                className="form-control"
                                                id="startdate"
                                                type="date"
                                                placeholder="Start Date"
                                                value={add?.startdate}
                                                onChange={(e) => handleChange(e, "startdate")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="enddate">End Date</label>
                                            <input
                                                className="form-control"
                                                id="enddate"
                                                type="date"
                                                placeholder="End Date"
                                                value={add?.enddate}
                                                onChange={(e) => handleChange(e, "enddate")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="cost">Cost</label>
                                            <input
                                                className="form-control"
                                                id="cost"
                                                type="text"
                                                placeholder="Cost"
                                                value={add?.cost}
                                                onChange={(e) => handleChange(e, "cost")}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="paymentLink">Payment Reference Code</label>
                                            <input
                                                className="form-control"
                                                id="paymentLink"
                                                type="text"
                                                placeholder="Payment Ref. Code"
                                                value={add?.transaction?.paymentLink}
                                                onChange={(e) => handleChange(e, "paymentLink")}
                                            />
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        <form onSubmit={handleSubmit}>
                                            <button type="submit" className="btn btn-primary">
                                                {'Save Changes'}

                                            </button>
                                        </form>
                                    </div>

                                </div>
                            ))}
                            <br></br>
                        </div>
                    </div>
                    {paginatedData && paginatedData.length > 0 && (
                        <Pagination
                            data={paginatedData}
                            itemsPerPage={rowsPerPage}
                            onPageChange={handlePageChange}
                            currentPage={page}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
