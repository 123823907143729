import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const initialValues = {
  username: '',
  token: '',
};

const API_URL = process.env.REACT_APP_API_URL;

export function OTPValidation() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = localStorage.getItem("username");

  const path = localStorage.getItem("currentPage");

  const resendOTP = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await axios.post(API_URL + `/api/resendOTP?username=${user}`);
      toast.success( `OTP sent to your email ${user}`);
      setLoading(false);
    } catch (error) {
      toast.error('Failed to resend OTP: ' + error.message);
      setLoading(false);
    }
  };

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setLoading(true);
    setSubmitting(true);

    try {
        axios
      .post(API_URL +`/api/validatePasswordReset?token=${values.token}&username=${user}`).then(response => {
        toast.success('The OTP validation is successful');
       
       if (path === "/auth/forgot-password") {
        localStorage.removeItem("currentPage");
        navigate('/auth/reset-password');
      } else {
        localStorage.removeItem("currentPage");
        localStorage.removeItem("username");
        navigate('/auth/login');
      }
      })
    } catch (error) {
      const errorMessage =  'OTP Validation Failed. Please try again.';
      toast.error(errorMessage);
      setStatus(errorMessage);

      setSubmitting(false);

      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: null, 
    onSubmit: handleSubmit,
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >

          {/* begin::Form group Email */}
    
          <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='text'
          value={user} 
          className='form-control bg-transparent'
          readOnly/>
          </div>


      {/* ... Rest of the JSX code ... */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>OTP</label>
        <input
          type='text'
          placeholder='Enter OTP'
          autoComplete='off'
          className='form-control bg-transparent'
          id='token'
          name='token' // Make sure to add the name attribute for formik to work correctly
          value={formik.values.token}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {/* Show validation errors if present */}
        {formik.touched.token && formik.errors.token && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.token}</div>
          </div>
        )}
      </div>
      {/* ... Rest of the JSX code ... */}

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/* Begin::Link */}
        <div>
          <Link to="#" onClick={resendOTP} className={`link-primary ${loading ? 'disabled-link' : ''}`}>
            Resend OTP?
          </Link>
        </div>
        {/* End::Link */}
      </div>

      <button
        type='submit'
        id='kt_password_reset_submit'
        className='btn btn-primary me-4'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        <span className='indicator-label'>Submit</span>
        {loading && (
          <span className='indicator-progress'>
            Please wait...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
      {/* ... Rest of the JSX code ... */}
    </form>
  );
}
