import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KTIcon } from '../../../helpers';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("kt-auth-react-v"); // No need to use JSON.parse

function CreateRoleUser({ onRoleUserCreated }) {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const [eventroleuser, setEventRoleUser] = useState({
    role: {
      id: ""
    },
    user: {
      id: ""
    },
    event: {
      id: "",
    }
  });


  useEffect(() => {
    axios
      .get(API_URL + `/api/accountRole`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching Task Priorities:", error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(API_URL + `/api/account-bio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching Task Priorities:", error);
      });
  }, [token]);

  const handleChange = (e, field) => {
    const actualValue = e.target.value;

    if (field === "userid") {
      setEventRoleUser({ ...eventroleuser, user: { id: actualValue } });
    } else if (field === "roleid") {
      setEventRoleUser({ ...eventroleuser, role: { id: actualValue } });
    } else if (field === "eventid") {
      setEventRoleUser({ ...eventroleuser, event: { id: actualValue } });
    } else {
      setEventRoleUser({ ...eventroleuser, [field]: actualValue });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + "/api/eventroleuser/create", eventroleuser, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Role User Created Successfully");
        setLoading(false);
        onRoleUserCreated();
        closeModal();
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className='modal fade' id='kt_modal_event_role_user' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Role Users</h1>


            </div>


            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-10 mx-xl-auto ps-xl-10">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>
                      <br></br>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label>Role ID</label>
                          <select id="roleid" type="text" placeholder="Select role" className="form-control"
                            onChange={(e) => handleChange(e, "roleid")} value={eventroleuser.role.id}>
                            <option>Select...</option>
                            {roles.map(ex => {
                              return (
                                <option key={ex.id} value={ex.id}>
                                  {ex.rolename}
                                </option>
                              );
                            })}

                          </select>{" "}
                        </div>
                      </div>
                      <br></br>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label>User ID</label>
                          <select id="userid" type="text" placeholder="Select event" className="form-control"
                            onChange={(e) => handleChange(e, "userid")} value={eventroleuser.user.id}>
                            <option>Select...</option>
                            {users.map(ex => {
                              return (
                                <option key={ex.id} value={ex.id}>
                                  {ex.email}
                                </option>
                              );
                            })}

                          </select>{" "}
                        </div>
                      </div>

                      <br></br>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label>Event ID</label>
                          <select id="eventid" type="text" placeholder="Select event" className="form-control"
                            onChange={(e) => handleChange(e, "eventid")} value={eventroleuser.event.id}>
                            <option>Select...</option>
                            {events.map(ex => {
                              return (
                                <option key={ex.id} value={ex.id}>
                                  {ex.title}
                                </option>
                              );
                            })}

                          </select>{" "}
                        </div>
                      </div>



                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                          data-bs-dismiss='modal'
                        />
                      </div>
                    </form>
                  </div>

                </main>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateRoleUser;
