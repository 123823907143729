import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../helpers';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import CreateEvent from '../../partials/modals/Add Pages/CreateEvent';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import { useAuth } from '../../../app/modules/auth';
import { FaRegCalendarAlt } from "react-icons/fa";



const API_URL = process.env.REACT_APP_API_URL;

function ListEvents() {
  const [eventlist, seteventlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 30;
  const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
  const { auth } = useAuth();
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const orgid = data?.orgid;

  useEffect(() => {
    fetchData(currentPage);
  }, []);


  function fetchData(page) {
    setLoading(true);

   
      axios
        .get(API_URL + `/api/EventPlannings/orgid=${orgid}/page=${page}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          seteventlist(response.data.pageData);
          setTotalPage(response.data.page);
          setCurrentPage(page);
          setLoading(false);
        })
        .catch(error => {
        // toast.error('Error fetching eventlist:', error);
          setLoading(false);
        });
    
  }

  const handleEventCreated = () => {
    setCurrentPage(1);
    setLoading(true);
    axios
      .get(API_URL + `/api/EventPlannings/orgid=${orgid}/page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        seteventlist(response.data.pageData);
        setTotalPage(response.data.page);
        setCurrentPage(currentPage);
        setLoading(false);
      })
      .catch((error) => {
       // toast.error('Error fetching eventlist:', error);
        setLoading(false);
      });
  };

  const handlePageChange = page => {
    fetchData(page);
  };

  const paginatedData = eventlist.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const navigate = useNavigate();
  const handleEventSelection = (eventId) => {
    navigate(`/eventDashboard/${eventId}`);
  };

  const [curtainraiserFilter, setCurtainraiserFilter] = useState('all');
  const [dateFilter, setDateFilter] = useState('all');


  const handleCurtainraiserFilterChange = (filter) => {
    setLoading(true);
    if (filter == 'all') {
      axios.get(API_URL + `/api/EventPlannings/orgid=${orgid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
        .then(response => {
          seteventlist(response.data)
          setLoading(false);
        })
        .catch(error => {
         // toast.error('Error fetching curtainraiser events:', error);
          setLoading(false);
        });

    } else {
      setLoading(true);
      axios
        .get(API_URL + `/api/EventPlannings/orgid=${orgid}/curtenraiser=${filter}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          seteventlist(response.data);
          setLoading(false);
        })
        .catch(error => {
         // toast.error('Error fetching curtainraiser events:', error);
          setLoading(false);
        });
    }

  };


  const handleDateFilterChange = filter => {
    setLoading(true);
  
    if (filter === 'all') {
      axios
      .get(API_URL + `/api/EventPlannings/orgid=${orgid}/page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        seteventlist(response.data.pageData);
        setTotalPage(response.data.page);
        setCurrentPage(currentPage);
        setLoading(false);
      })
      .catch(error => {
       // toast.error('Error fetching all events:', error);
        setLoading(false);
      });
    } else {
      const endpoint = filter === 'upcoming'
        ? `/api/EventPlannings/orgid=${orgid}/upCommingEvents/page=${currentPage}`
        : `/api/EventPlannings/orgid=${orgid}/activeEvents/page=${currentPage}`;
  
      axios.get(API_URL + endpoint, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      }).then(response => {
        seteventlist(response.data.pageData);
        setTotalPage(response.data.page);
        setCurrentPage(currentPage);
        setLoading(false);
      
      }).catch(error => {
       // toast.error(`Error fetching ${filter} events:`, error);
        setLoading(false);
      });
    }
  };
  
  

  const handleGetDirections = (eventlist) => {
    if (eventlist.latitude !== 0 && eventlist.longitude !== 0) {
      // Open Google Maps with directions
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${eventlist.latitude},${eventlist.longitude}`, '_blank');
    } else {
      toast.error('Coordinates not available');
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row m-4">
          {auth?.role === 'ROLE_ADMIN' && (
              <CreateEvent onEventCreated={handleEventCreated} />
            )}
            <div className="col-md-12">
              <h2>Event List</h2>
              <div className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add an Event'
              >
                  {auth?.role === 'ROLE_COMMITEE_MEMBER' ? null : (
                <div className='m-2'>
                  <a
                    href='#'
                    className='btn btn-sm btn-light-primary float-end mt-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_event'
                  >
                    <KTIcon iconName='plus' className='fs-3' />
                    Add
                  </a>
                </div>
                  )}  


                <div className='card-toolbar'>
                  <div className='dropdown mr-2'>
                    <button
                      className='btn btn-sm btn-light-primary dropdown-toggle float-end m-2'
                      type='button'
                      id='curtainraiserFilterDropdown'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Curtainraiser Filter
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='curtainraiserFilterDropdown'>
                      <li>
                        <button
                          className={`dropdown-item ${curtainraiserFilter === 'all' ? 'active' : ''}`}
                          onClick={() => handleCurtainraiserFilterChange('all')}
                        >
                          All Events
                        </button>
                      </li>
                      <li>
                        <button
                          className={`dropdown-item ${curtainraiserFilter === 'true' ? 'active' : ''}`}
                          onClick={() => handleCurtainraiserFilterChange('true')}
                        >
                          Curtainraisers
                        </button>
                      </li>
                      <li>
                        <button
                          className={`dropdown-item ${curtainraiserFilter === 'false' ? 'active' : ''}`}
                          onClick={() => handleCurtainraiserFilterChange('false')}
                        >
                          Non-Curtainraisers
                        </button>
                      </li>
                    </ul>
                  </div>

                  <div className='dropdown'>
                    <button
                      className='btn btn-sm btn-light-primary dropdown-toggle float-end m-2'
                      type='button'
                      id='dateFilterDropdown'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      Date Filter
                    </button>
                    <ul className='dropdown-menu' aria-labelledby='dateFilterDropdown'>
                      <li>
                        <button
                          className={`dropdown-item ${dateFilter === 'all' ? 'active' : ''}`}
                          onClick={() => handleDateFilterChange('all')}
                        >
                          All Events
                        </button>
                      </li>
                      <li>
                        <button
                          className={`dropdown-item ${dateFilter === 'upcoming' ? 'active' : ''}`}
                          onClick={() => handleDateFilterChange('upcoming')}
                        >
                          Upcoming Events
                        </button> 
                      </li>
                      <li>
                        <button
                          className={`dropdown-item ${dateFilter === 'past' ? 'active' : ''}`}
                          onClick={() => handleDateFilterChange('past')}
                        >
                          Past Events
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-12'>
            <div className='row g-5 g-xl-10'>
              {paginatedData.slice().reverse().map((eventlist, index) => (
                <div
                  className='col-xl-4'
                  key={eventlist.id}
                  onMouseEnter={() => setHoveredEventIndex(index)}
                  onMouseLeave={() => setHoveredEventIndex(-1)}
                >
                  <div
                    className='card card-raised'
                    
                    style={{
                      transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                      transition: 'transform 0.2s ease-in-out',
                      cursor: 'pointer'
                    }}
                  >
                    <div className='mx-4 mt-3'>
                      <div className='card-toolbar float-end'>
                        <button
                          type='button'
                          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='float-end'
                        >
                          <Link
                            to={`/editevent/${eventlist.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-2' />
                          </Link>
                        </button>
                      </div>
                      <div className='border-0 pt-5' onClick={() => handleEventSelection(eventlist.id)}>
                        <h3 className='card-title align-items-start flex-column'>

                          {eventlist.title}

                          <br />
                          <span className='text-muted mt-1 fw-semibold fs-7'>{eventlist.oneliner}</span>
                        </h3>
                      </div>
                      {/* end::Header */}

                      <div className='text-muted d-flex justify-content-end' onClick={() => handleEventSelection(eventlist.id)}>
                        <b><FaRegCalendarAlt /> {eventlist.startdate}</b>
                        {
                          eventlist.enddate === eventlist.startdate
                            ? ''
                            : <>
                              <span>&nbsp;to&nbsp;</span>
                              <b>{eventlist.enddate}</b>
                            </>
                        }
                      </div>



                      <div className='pt-5' onClick={() => handleEventSelection(eventlist.id)}>
                        {eventlist.description.length > 160 ? `${eventlist.description.slice(0, 160)}...` : eventlist.description}
                      </div>

                      <hr />
                      <div className='pt-5'>
                        <div className='d-flex align-items-center mb-7'>
                          <div className='symbol symbol-50px me-5'>
                            <span className='symbol-label bg-light-primary' onClick={() => handleGetDirections(eventlist)}>
                              <KTIcon iconName='geolocation' className='fs-2x text-primary' />
                            </span>
                          </div>
                          <div className='d-flex flex-column' onClick={() => handleEventSelection(eventlist.id)}>
                            <div className='text-dark fs-6 fw-bold'>
                              {eventlist.location}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='m-2 bg-light-success' onClick={() => handleEventSelection(eventlist.id)}>
                      <div className='d-flex align-items-center mb-7'>
                        <div className='p-2 d-flex flex-column'>
                          <span className='text-muted fw-semibold'>Theme</span>
                          <div className='text-dark fs-6 fw-bold'>
                            {eventlist.theme}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}

              

            </div>

            {eventlist.length > 0 && (
                <Pagination
                  data={eventlist} // Pass the full data to the Pagination component
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />)}
                
          </div>
        </div>
      )}
    </div>


  )
}

export default ListEvents;
