import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { useAuth } from "../../../app/modules/auth";
import { useNavigate, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function EditSubFeature() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { featureId } = useParams();
    const [feature, setFeature] = useState({
        id:featureId,
        feature: "",
        description: ""
    });

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/subscriptionFeatures/${featureId}`)
            .then((response) => {
                setFeature(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error Fetching Subscription Feature:', error);
                setLoading(false);
            });
    }, [featureId, auth?.token]); 


    const handleChange = (e, field) => {
        const actualValue = e.target.value;
        setFeature((f) => ({ ...f, [field]: actualValue }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        setLoading(true);

        axios
            .post(API_URL + "/api/subscriptionFeatures/update", feature, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then(() => {
                toast.success("Subscription Feature Updated");
                navigate("/subscriptionFeatures");
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error updating Subscription Type:', error);
                setLoading(false);
            });
           
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div id="layoutDrawer_content">
                        {loading && <div className="loading"></div>}

                        <main>
                            <div className="col-lg-8 mx-xl-auto col-md-12">
                                <div className="card card-raised">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <h3 className='card-title align-items-start flex-column'>
                                                        <span className='card-label fw-bold fs-3 mb-1'> Edit Subscription</span>
                                                    </h3>

                                                </div>
                                            </div>
                                            <br></br>

                                            <div className="form-floating-sm mb-2"></div>


                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="type">Subscription Feature</label>
                                                    <input
                                                        className="form-control"
                                                        id="type"
                                                        type="text"
                                                        placeholder="Subscription Feature"
                                                        value={feature.feature}
                                                        onChange={(e) => handleChange(e, "feature")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="type">Description</label>
                                                    <input
                                                        className="form-control"
                                                        id="cost"
                                                        type="text"
                                                        placeholder="Description"
                                                        value={feature.description}
                                                        onChange={(e) => handleChange(e, "description")}
                                                    />
                                                </div>
                                            </div>
                                            <br></br>



                                            <div>
                                                <button
                                                
                                                className='btn btn-lg btn-light-primary float-end mt-8'
                                                    type="submit"
                                                    disabled={loading}
                                                    value="Submit"
                                                >Submit</button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </main>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EditSubFeature;
