import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../../helpers';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;

function ExhiobitorEvents() {
    const [eventlists, seteventlist] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const rowsPerPage = 30;
    const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
    const { currentUser, auth } = useAuth();
   
    useEffect(() => {
        fetchData(currentPage);
    }, []);


    function fetchData(currentPage) {
        setLoading(true);
        axios
            .get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/events/page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                seteventlist(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching eventlist:', error);
                setLoading(false);
            });
    }

    const handlePageChange = page => {
        fetchData(page);
    };

    const navigate = useNavigate();

    const handleEventSelection = (eventId) => {
        navigate(`/ExhibitorEventdashboard/${eventId}`);
    };

    const [dateFilter, setDateFilter] = useState('all');

    const handleDateFilterChange = filter => {
        if (filter === `all`) {
          setLoading(true);
          axios.get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/events/page=${currentPage}`).then(response => {
            seteventlist(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
          }).catch(error => {
            toast.error('Error fetching filtered events:', error);
            setLoading(false);
          })
    
        } else if(filter===`past`) {
          setLoading(true);
          axios
            .get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/archiveEvent/page=${currentPage}`, {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            })
            .then(response => {
                seteventlist(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
            })
            .catch(error => {
              toast.error('Error fetching filtered events:', error);
              setLoading(false);
            });
        }
        else if(filter===`upcoming`){
            setLoading(true);
            axios
              .get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/activeEvent/page=${currentPage}`, {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                },
              })
              .then(response => {
                seteventlist(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
              })
              .catch(error => {
                toast.error('Error fetching filtered events:', error);
                setLoading(false);
              });
        }
      };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className="row m-4">

                        <div className="col-md-12">
                            <h2>Event List</h2>
                            <div className='card-toolbar'>



                                <div className='card-toolbar'>
                                   

                                    <div className='dropdown'>
                                        <button
                                            className='btn btn-sm btn-light-primary dropdown-toggle float-end m-2'
                                            type='button'
                                            id='dateFilterDropdown'
                                            data-bs-toggle='dropdown'
                                            aria-expanded='false'
                                        >
                                            Filter
                                        </button>
                                        <ul className='dropdown-menu' aria-labelledby='dateFilterDropdown'>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${dateFilter === 'all' ? 'active' : ''}`}
                                                    onClick={() => handleDateFilterChange('all')}
                                                >
                                                    All Events
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${dateFilter === 'upcoming' ? 'active' : ''}`}
                                                    onClick={() => handleDateFilterChange('upcoming')}
                                                >
                                                    Upcoming Events
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className={`dropdown-item ${dateFilter === 'past' ? 'active' : ''}`}
                                                    onClick={() => handleDateFilterChange('past')}
                                                >
                                                    Past Events
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-xl-12'>
                        <div className='row g-5 g-xl-10'>
                       
                            { eventlists && eventlists.slice().reverse().map((eventlist, index) => (
                                <div
                                    className='col-xl-4'
                                    key={eventlist?.id}
                                    onMouseEnter={() => setHoveredEventIndex(index)}
                                    onMouseLeave={() => setHoveredEventIndex(-1)}
                                >
                                    <div
                                        className='card card-raised'
                                        onClick={() => handleEventSelection(eventlist?.id)}
                                        style={{
                                            transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                                            transition: 'transform 0.2s ease-in-out',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div className='mx-4 mt-3'>
                                            
                                            <div className='border-0 pt-5'>
                                                <h3 className='card-title align-items-start flex-column'>

                                                    {eventlist?.title}

                                                    <br />
                                                    <span className='text-muted mt-1 fw-semibold fs-7'>{eventlist?.oneliner}</span>
                                                </h3>
                                            </div>
                                            {/* end::Header */}

                                            <div className='text-muted d-flex justify-content-end'>
                                                <b>{eventlist?.startdate}</b>
                                                {
                                                    eventlist?.enddate === eventlist?.startdate
                                                        ? ''
                                                        : <>
                                                            <span>&nbsp;to&nbsp;</span>
                                                            <b>{eventlist?.enddate}</b>
                                                        </>
                                                }
                                            </div>



                                            <div className='pt-5'>
                                                {eventlist?.description.length > 160 ? `${eventlist?.description.slice(0, 160)}...` : eventlist?.description}
                                            </div>

                                            <hr />
                                            <div className='pt-5'>
                                                <div className='d-flex align-items-center mb-7'>
                                                    <div className='symbol symbol-50px me-5'>
                                                        <span className='symbol-label bg-light-primary'>
                                                            <KTIcon iconName='geolocation' className='fs-2x text-primary' />
                                                        </span>
                                                    </div>
                                                    <div className='d-flex flex-column'>
                                                        <div className='text-dark fs-6 fw-bold'>
                                                            {eventlist?.location}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='m-2 bg-light-success'>
                                            <div className='d-flex align-items-center mb-7'>
                                                <div className='p-2 d-flex flex-column'>
                                                    <span className='text-muted fw-semibold'>Theme</span>
                                                    <div className='text-dark fs-6 fw-bold'>
                                                        {eventlist?.theme}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                            

                            {eventlists && eventlists.length > 0 && (
                                <Pagination
                                    data={eventlists} // Pass the full data to the Pagination component
                                    itemsPerPage={rowsPerPage}
                                    onPageChange={handlePageChange}
                                    currentPage={currentPage}
                                />)}

                        </div>
                    </div>
                </div>
            )}
        </div>


    )
}

export default ExhiobitorEvents;
