import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import { SettingHeader } from './SettingHeader'
import TaskStatus from '../../../../../../_metronic/organization/Tasks/TaskStatus'
import Taskpriority from '../../../../../../_metronic/organization/Task Priority/TaskPriority'


const accountBreadCrumbs = [
  {
    title: 'SystemSettings',
    path: '/event/taskstatus',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const SystemSettingPage = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <SettingHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
              <TaskStatus />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle>
              <Taskpriority />
            </>
          }
        />
        <Route index element={<Navigate to='/systemSettings' />} />
      </Route>
    </Routes>
  )
}

export default SystemSettingPage
