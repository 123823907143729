import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {publicRegister} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../core/Auth'
import { KTIcon } from '../../../../_metronic/helpers'
import { toast } from 'react-toastify';

const initialValues = {
  email: '',
  firstname: '',
  contact: '',
  evetid:'',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please Enter valid email'),
  contact: Yup.number()
    .min(10, 'Should be 10 numbers')
    //.max(50, 'Maximum 50 symbols')
    .required('Mobile number required'),
    evetid: Yup.string()
    .required('Please select event'),

    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})


export function GeneralUserRegistration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await publicRegister(
          values.email,
          values.firstname,
          values.contact,
          values.evetid
        )
        saveAuth(auth)
        navigate('/auth/generallogin');
        toast.success('Registration Completed')
      } catch (error) {
         saveAuth(undefined)
        const errorMessage =  'Registration failed. Please try again.';
        toast.error(errorMessage);
        setStatus(errorMessage);
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  return (

    <div className='modal fade' id='kt_modal_invite_userregister' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}
</div>
      
      {/* end::Heading */}

      


      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Firstname</label>
          <div className='position-relative mb-3'>
            <input
              type='text'
              placeholder='Firstname'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
         </div>
</div>
      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Contact</label>
        <input
          type='number'
          placeholder='Contact Number'
          autoComplete='off'
          {...formik.getFieldProps('contact')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.contact && formik.errors.contact,
            },
            {
              'is-valid': formik.touched.contact && !formik.errors.contact,
            }
          )}
        />
        {formik.touched.contact && formik.errors.contact && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contact}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Event Name</label>
        <input
          type='text'
          placeholder='Evetid'
          autoComplete='off'
          {...formik.getFieldProps('evetid')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.evetid && formik.errors.evetid,
            },
            {
              'is-valid': formik.touched.evetid && !formik.errors.evetid,
            }
          )}
        />
        {formik.touched.evetid && formik.errors.evetid && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.evetid}</span>
            </div>
          </div>
        )}
      </div>


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-md btn-primary mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
       
          <button
            type='button'
            data-bs-dismiss='modal'
            className='btn btn-md btn-light-primary mb-5'
          >
            Cancel
          </button>
       
      </div>
      {/* end::Form group */}

      
      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}


    </form>
    </div>
    </div>
    </div>
    </div>
  )
}
