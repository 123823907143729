import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../helpers';

export default function BannerAdd({ ad }) {
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  useEffect(() => {
    if (ad) {
      setIsClosed(false);
    }
  }, [ad]);

  return (
    <div>
      {!isClosed && ad && (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              width: '100%',
              height: '150px',
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <h5 style={{ marginBottom: '8px', color: '#343a40', textAlign: 'center' }}>
              Registration Link: <a href={ad?.registrationLink}>{ad?.registrationLink}</a>
            </h5>
            <iframe
              src={ad?.videolink}
              title="Video Player"
              allowFullScreen
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            ></iframe>
          </div>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'pointer',
              padding: '8px',
              backgroundColor: '#fff',
              borderRadius: '0 8px 0 0',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            onClick={handleClose}
          >
            <KTIcon iconName='cross' className='fs-1' style={{ color: '#000' }} />
          </div>
        </div>
      )}
    </div>
  );
}
