import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';
import Navbar from "../../../../_metronic/Public/PublicHome/Navbar";
import { useReactToPrint } from "react-to-print";
import { MdLocalPrintshop } from "react-icons/md";
import "../../events/print.css";


function PublicRegistration() {
  const [event, seteventDetails] = useState({});
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profile, setProfile] = useState({
    firstname: "",
    company: "",
    designation: "",
    description: "",
    country: "",
    phone: "",
    email: "",
    image: null,
    imageMeta: "",
  });

  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [showDetailedInfo, setShowDetailedInfo] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState({});


  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();


  useEffect(() => {
    setLoading(true);
    axios.get(API_URL + `/api/EventPlannings/${eventId}`)
      .then(response => {
        seteventDetails(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching eventlist', error);
        setLoading(false);
      });
  }, [eventId]);



  const handleClick = () => {
    navigate(-1);
  };

  const getBase64FromFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  const handleQrCodeClick = (user) => {
    setQrCodeValue({
      Name: user.firstname,
      Email: user.email,
      ContactNumber: user.phone,
      Company: user?.company,
      Designation: user?.designation,
    });
    openModal(); // Make sure this is calling openModal to set isModalOpen to true
  };


  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    setProfile({
      ...profile,
      imageMeta: convertedImage.metadata,
      image: convertedImage.content,
    });
  };

  const handleChange = (event, field) => {
    const actualValue = event.target.value;

    // Validate email format
    if (field === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!emailPattern.test(actualValue)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError(""); // Clear the error if the email is valid
      }
    }

    // Validate phone number: 10 digits and no alphabets
    if (field === "phone") {
      if (!/^[0-9]{10}$/.test(actualValue)) {
        setPhoneError("Phone number should be 10 digits and contain only numbers.");
      } else {
        setPhoneError("");
      }
    }

    // Validate name is not empty
    if (field === "firstname") {
      if (actualValue.trim() === "") {
        setNameError("Name is required");
      } else {
        setNameError("");
      }
    }

    setProfile({ ...profile, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!profile.firstname || !profile.email || !profile.phone) {
      toast.warning("Please fill in all mandatory fields before submitting");
      return;
    }

    if (phoneError || emailError || nameError) {
      toast.error("Please fix the form errors before submitting.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(API_URL + `/publicaccounts/register/${eventId}`, profile);
      toast.success("Registration successful");
      handleQrCodeClick(response.data);
      setProfile({
        firstname: "",
        lastname: "",
        company: "",
        designation: "",
        description: "",
        country: "",
        phone: "",
        email: "",
        image: null,
        imageMeta: "",
      });

      setEmailError("");
      setPhoneError("");
      setNameError("");


    } catch (error) {
      toast.error("Registration failed");
    } finally {
      setLoading(false);
    }
  };


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(`/event/${eventId}/eventSignup`);
  };

  const toggleDetailedInfo = () => {
    setShowDetailedInfo(!showDetailedInfo);
  };

  return (
    <div>
      <Navbar event={event} />
      <div className="form w-100 mt-10" noValidate id="kt_login_signin_form">
        {isModalOpen && (
          <div className="modal fade show" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="cursor-pointer text-end px-10 pt-4">
                  <MdLocalPrintshop size={25} onClick={handlePrint} />
                </div>
                <div className="modal-header">
                  <h2>Your QR Code</h2>
                </div>
                <div className='card card-raised' ref={componentRef}>

                  <div className="modal-body">
                    <div className="col-md-12" >
                      <div className="form-group">
                        <div className="mb-2 mt-2 fs-4"> Name: {qrCodeValue?.Name} </div>
                        <div className="mb-2 fs-4"> Email: {qrCodeValue?.Email} </div>
                        <div className="mb-2 fs-4"> ContactNumber : {qrCodeValue?.ContactNumber} </div>
                        <div className="card-body">
                          <QRCode value={JSON.stringify(qrCodeValue, null, 2)} size={250} />
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="container">
          <div className="main-body">
            <div className="row gutters-sm">
              <div className="col-md-4 mb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-column align-items-center text-center">
                      {profile?.image ? (
                        <img
                          id="image"
                          src={`${profile?.imageMeta},${profile?.image}`}
                          alt={profile?.email}
                          className="rounded-circle"
                          width="220"
                        />
                      ) : (
                        <img alt={profile?.firstname} />
                      )}
                      <div className="mt-3">
                        <h4>{profile.firstname}</h4>
                        <p className="mb-1">{profile.designation}</p>
                        <p className="font-size-sm">{profile.company}</p>
                        <label htmlFor="image" className="col-12 btn btn-md btn-primary">
                          Upload Image
                        </label>
                        <input
                          type="file"
                          id="image"
                          onChange={(event) => {
                            handleImageChange(event);
                          }}
                          accept="image/*"
                          style={{ display: "none" }}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Name</h6>
                      </div>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name"
                          onChange={(e) => handleChange(e, "firstname")}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0 required">Email</h6>
                      </div>
                      <div className="col-sm-9 text-secondary ">
                        <input
                          type="text"
                          className="form-control "
                          placeholder="Email"
                          onChange={(e) => handleChange(e, "email")}
                        />
                        {emailError && <div className="text-danger">{emailError}</div>}
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0 required">Phone Number</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Phone Number"
                          onChange={(e) => handleChange(e, "phone")}
                        />
                        {phoneError && <div className="text-danger">{phoneError}</div>}
                      </div>
                    </div>
                    <hr />

                    {showDetailedInfo && (
                      // Detailed Information Section
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Country</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Country"
                              onChange={(e) => handleChange(e, "country")}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Company</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company"
                              onChange={(e) => handleChange(e, "company")}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Designation</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Designation"
                              onChange={(e) => handleChange(e, "designation")}
                            />
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Description</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder="Description"
                              onChange={(e) => handleChange(e, "description")}
                            />
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    <div className="row">
                      <div className="col-sm-13 mb-1">
                        <div className="d-flex justify-content-end">
                          <button className="btn btn-primary btn-sm rounded-pill" onClick={toggleDetailedInfo}>
                            {showDetailedInfo ? "Less Details" : "More Details"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-9 mb-1">

                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn btn-primary btn-sm rounded-pill'
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          {!loading && <span className="indicator-label">Submit</span>}
                          {loading && (
                            <span className="indicator-label">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                        <button className="btn btn-primary btn-sm rounded-pill" onClick={handleClick}>
                          Back
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>  </div>
      </div>
    </div>
  );


}

export default PublicRegistration;
