import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { useAuth } from "../../../app/modules/auth";



function StallExhibitor() {
  const API_URL = process.env.REACT_APP_API_URL;
  const [stall, setStall] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exhibitor, setExhibitor] = useState([]);
  const token = localStorage.getItem('token');
  const {auth} = useAuth();
  const [exhibitorStall, setExhibitorStall] = useState({
    stallId: "",
    stalltype: "",
    exhibitorId: "",

  })

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/StallType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setStall(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Stall Exhibitor. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [token]);


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/eventroleuser/role=2`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setExhibitor(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching StallExhibitor. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [token]);


  const navigate = useNavigate();

  const handleChange = (stall, field) => {
    let actualValue = stall.target.value;

    setExhibitorStall({ ...exhibitorStall, [field]: actualValue });
  };
  const handleSubmit = async (task) => {
    task.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + "/api/stallexhibitormapping/create", exhibitorStall, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Updated");

        navigate("/event/stallexhibitor");
        setLoading(false);

      }).catch((error) => {
        const errorMessage = 'Error while creating. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };



  return (
    <div>
    {loading ? (
      <Loader />
    ) : (
    <div>
      <div id="layoutDrawer_content">

        {loading && <div className="loading"></div>}

        <main>

          <div className="col-12 mx-xl-auto ps-xl-12" style={{
            fontSize: '16px'
          }}>

            <div className="card card-raised ">
              <div className="card-body">
              <span className='card-label fw-bold fs-2 mb-1'>Stall Exhibitor</span>

                <br>
                </br>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label>Stall Type</label>
                      <select id="stalltype" type="text" placeholder="select..." className="form-control"
                        onChange={(e) => handleChange(e, "stalltype")} >
                        <option> Select..... </option>
                        {stall.map(etask => {
                          return (
                            <option value={etask.id}>
                              {etask.stallType}
                            </option>

                          );
                        })}

                      </select>{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="stallId">Stall Id</label>
                      <input
                        className="form-control"
                        id="stallId"
                        type="text"
                        placeholder="Stall Id "
                        onChange={(e) => handleChange(e, "stallId")}
                      />
                    </div>
                  </div>


                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label>Exhibitor Name</label>
                      <select id="exhibitorId" type="text" placeholder="Select event" className="form-control"
                        onChange={(e) => handleChange(e, "exhibitorId")}>
                        <option>Select...</option>
                        {exhibitor.map(ex => {
                          return (
                            <option value={ex.id}>
                              {ex.user.email}
                            </option>
                          );
                        })}

                      </select>{" "}
                    </div>
                  </div>

                  <div className="text-end">
                    <input
                      className="btn btn-secondary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    )}
    </div>
  );
}

export default StallExhibitor;