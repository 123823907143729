/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import { Step1 } from '../create-app-stepper/steps/Step1';


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const Neworganization = ({ onOrgCreated }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const { auth } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);

  // Initialize organization input fields
  const initialOrganization = {
    name: "",
  };

  const [Step1, SetStep1] = useState({ ...initialOrganization });

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    SetStep1({ ...Step1, [field]: actualValue });
  };

  const handleSubmit = async (task) => {
    task.preventDefault();
    try {
      const response = await axios.post(API_URL + "/api/organizationprofiles/create", Step1, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })  
      const result = response.data;
      toast.success("Organization created successfully");
      setLoading(false);
      onOrgCreated();
      closeModal();
    } catch (error) {
      toast.error("Error creating organization");
    }
  };
  

  const closeModal = () => {
    SetStep1({ ...initialOrganization });
    setModalOpen(false);
  };

  return (
    <div className='modal fade' id='kt_modal_invite_organization_new' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add New Organization</h1>
            </div>

            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-12 mx-xl-auto ps-xl-12">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>
                      <br></br>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="name">Name </label>
                          <input
                            className="form-control"
                            id="name"
                            type="text"
                            placeholder="Name"
                            value={Step1.name} // Bind value to state
                            onChange={(e) => handleChange(e, "name")}
                          />
                        </div>
                      </div>
                      <br></br>

                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                          data-bs-dismiss='modal'
                        />
                      </div>
                    </form>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Neworganization;
