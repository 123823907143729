/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import { useAuth } from '../../../../app/modules/auth';
import { KTIcon } from '../../../helpers';

const API_URL = process.env.REACT_APP_API_URL;

function CreateTaskStatus({ onStatusCreated }) {
    const[loading ,setLoading]=useState(false);
    const {auth} = useAuth();
    const [modalOpen, setModalOpen] = useState(false);
  
    const [taskstatus, setTaskStatus] = useState({
      name: "",
      
    })
    
    const handleChange = (task, field) => {
      let actualValue = task.target.value;
  
      setTaskStatus({ ...taskstatus, [field]: actualValue });
    };
    const handleSubmit = async(task) => {
      task.preventDefault();
      setLoading(true); 
      axios
        .post(API_URL+"/api/tastStatus/create", taskstatus, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        })
        .then((response) => response.data)
        .then((result) => {
          toast.success("Created Successfully");
          setLoading(false);
          onStatusCreated(); 
           closeModal();
        });
    };

    const closeModal = () => {
      setModalOpen(false);
    };

  return (
    <div className='modal fade' id='kt_modal_add_taskStatus' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Status Name</h1>

             
            </div>


            <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-10 mx-xl-auto ps-xl-10">
             <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row"></div>
                  <br></br>

                  

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="rolename">Status Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Status Name"
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

                  
                
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                      data-bs-dismiss='modal'
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTaskStatus;
