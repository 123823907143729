import React from "react";
import { Container, Row, Col } from "reactstrap";

function CarouselSection({ event }) {
  return (
    <div className="section" id="carousel">
      <Container>
        <Row className="justify-content-center">
          <Col lg="12" md="12">
            {event?.image && (
              <div>
                <img
                  src={`${event?.imageMeta},${event?.image}`}
                  alt={event.theme}
                  className="w-100 h-100"
                  style={{ objectFit: "cover" }}
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>{event.theme}</h5>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CarouselSection;
