import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Description({ event }) {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate(`/event/${event?.id}/eventSignup`);
    };

    return (
        <div className='card-header pt-10'>
            <div className='col-12'>
                <div className='card card-xl-stretch mb-xl-8' 
                    style={{ 
                        backgroundColor: '#728df9', 
                        color: '#fbf9f9', 
                        fontSize: '1.5rem',
                        padding: '30px',
                    }}
                >
                    {/* Main Flex Container */}
                    <div 
                        className='d-flex' 
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'row', 
                            alignItems: 'center', 
                            justifyContent: 'space-between', 
                        }}
                    >
                        {/* Left Section - Description */}
                        <div 
                            className='card-title' 
                            style={{ 
                                flexBasis: '60%', // Occupies 60% of the width
                                maxWidth: '60%',  // Prevents it from growing too wide
                                wordWrap: 'break-word',
                            }}
                        >
                            <div>{event?.description}</div>
                        </div>

                        {/* Right Section - Register Button */}
                        <div 
                            className='d-flex' 
                            style={{ 
                                flexBasis: '40%', // Occupies 40% of the width
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%', // Ensures the button container is full height
                            }}
                        >
                            <button
                                onClick={handleSignUp}
                                style={{
                                    padding: '10px 20px',
                                    backgroundColor: '#fbf9f9',
                                    color: '#728df9',
                                    fontSize: '1.5rem',
                                    borderRadius: '5px',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Register Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
