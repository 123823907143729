import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { KTIcon } from '../../../../helpers';
import { useAuth } from '../../../../../app/modules/auth';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;



const AddEXEvent = ({ onexCreated }) => {
    const { eventId } = useParams()
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const storedData = localStorage.getItem('kt-auth-react-v');
    const data = storedData ? JSON.parse(storedData) : null;
    const orgid = data?.orgid;
    const [EventEX, setEventEx] = useState({
        email: '',
        eventrole: "ROLE_EXHIBITOR",
        eventid: eventId,
        orgid: orgid,
        sector: "",

    });
    const [sector, setsector] = useState([]);

    useEffect(() => {
        axios
            .get(API_URL + `/api/sectors`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setsector(response.data);
           
            })
            .catch((error) => {
                toast.error("Error fetching Event Sector:", error);
              
            });
    }, [auth?.token]);


    const handleChange = (event, field) => {
        let actualValue = event.target.value;
        setEventEx(prevState => ({ ...prevState, [field]: actualValue }));

    };



    const handleSubmit = (e) => {
        e.preventDefault();     

        axios
            .post(API_URL + '/api/exhibitorProfile/add', EventEX, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then((response) => {
                toast.success('Created Successfully');
                onexCreated();

            })
            .catch((error) => {
                toast.error('Error Event Exhibitor:', error);
            });
    };

    return (
        <div className='modal fade' id='kt_modal_Event_Exhibitor' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Create Event Exhibitor</h1>
                        </div>

                        {/* Form */}
                        <form onSubmit={handleSubmit}>




                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label htmlFor='email'>User Name</label>
                                    <input
                                        className='form-control'
                                        id='email'
                                        type='text'
                                        placeholder='User Name'
                                        autoComplete='off'
                                        onChange={(e) => handleChange(e, "email")}
                                    />
                                </div>
                            </div>
                            <br></br>


                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label htmlFor='sector'>Select Sector</label>
                                    <select
                                        id='sector'
                                        className='form-control'
                                        onChange={(e) => handleChange(e, "sector")}
                                    >
                                        <option>Select...</option>
                                        {sector.map((ex) => (
                                            <option key={ex.id} value={ex.name}>
                                                {ex.name}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>


                            <br></br>



                            <div className='text-end'>
                                <button className='btn btn-primary' type='submit'  data-bs-dismiss='modal'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEXEvent;
