import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function AdminPlatformfee() {
  const [platformFeeDetails, setPlatformFeeDetails] = useState({
    platformFee: 0,
    additionalFee: 0,
    totalFee: 0
  });
  const [additionalFee, setAdditionalFee] = useState(0); 
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [adminamount,setAdminamount]=useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/platformFee`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        const { platformFee, additionalFee } = response.data;
        const totalFee = (platformFee || 0) + (additionalFee || 0); 
        setPlatformFeeDetails({ platformFee, additionalFee, totalFee });
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching Platform Fee:', error);
        setLoading(false);
      });
  }, [auth?.token]);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const payload = {
      platformFee: additionalFee
    };
  
    try {
      const response = await axios.post(API_URL + "/api/platformFee/createUpdate", payload, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
  
      toast.success("Platform Fee Updated");
      // Update state with response data
      setPlatformFeeDetails(response.data);
      setLoading(false);
    } catch (error) {
      const errorMessage = 'Failed to Update Platform Fee. Please try again.';
      toast.error(errorMessage);
      setLoading(false);
    }
  };
  

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Platform Fees</span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Platform Fee</th>
                    <th className='min-w-120px'>Additional Fee</th>
                    <th className='min-w-120px'>Action</th>
                    <th className='min-w-120px'>Total Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{platformFeeDetails.platformFee}</td>
                    
                    <td>
                      <input
                        className="form-control"
                        type="number"
                        placeholder="Additional Fee"
                        value={additionalFee}
                        onChange={(e) => setAdditionalFee(parseFloat(e.target.value))}
                      />
                    </td>
                    <td>
                      <button className='btn btn-primary' type='button' onClick={handleSave}>Save</button>
                    </td>
                    <td>{platformFeeDetails.platformFee}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminPlatformfee;
