import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, Navigate, useParams } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import CreateStallType from '../../partials/modals/Add Pages/CreateStallType';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import { useAuth } from '../../../app/modules/auth';


const API_URL = process.env.REACT_APP_API_URL;

function StallType() {
  const [stallType, SetStallType] = useState([]);
  const token = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const { eventId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);

  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = !checked;
    setLoadingUserId(id) 

   
    axios
      .get(API_URL + `/api/StallType/id=${id}/value=${disabled}  `, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Stall Type Updated Succesfully")
        setUpdateFlag((prevFlag) => !prevFlag);
        
      })
      .catch((error) => {
        toast.error('Error updating Stall type:', error);
        setLoadingUserId(null);
      });
  }

  const handleStallTypeCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/StallType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetStallType(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching StallType. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });

  }

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true)
    axios
      .get(API_URL + `/api/StallType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetStallType(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching StallType. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const paginatedData = stallType.slice((page - 1) * rowsPerPage, page * rowsPerPage);


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>

          <CreateStallType event={eventId} onStallTypeCreated={handleStallTypeCreated} />
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>

              <span className='card-label fw-bold fs-2 mb-1'>Stall Type</span>

            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='add new Stalltype'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_stall_type'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'>
          </div>
          <div className='card-toolbar'>



            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>


                      <th className='min-w-100px'>Stall Type</th>
                      <th className='min-w-60px'>Cost</th>
                      <th className='min-w-60px'>Actions</th>


                    </tr>
                  </thead>

                  <tbody>
                    {paginatedData.slice().reverse().map((stallType) => (
                      <tr key={stallType.id}>





                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {stallType.stallType}
                          </a>
                        </td>

                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {stallType.cost}
                          </a>
                        </td>



                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                          {loading && loadingUserId === stallType.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}

                            <input
                              className="form-check-input"
                              id="flexSwitchCheckDefault"
                              type="checkbox"
                              defaultChecked={!stallType.disabled}
                              onClick={(e) => onClickhandler(e, stallType.id)}
                              value={stallType.id}
                            />
                             {loadingUserId === stallType.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                          </div>
                          <Link
                            to={`/editstalltype/${eventId}/${stallType.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                        </td>
                      </tr>

                    ))}

                  </tbody>
                </table>
              </div>
              {stallType.length > 0 && (
                <Pagination
                  data={stallType} // Pass the full data to the Pagination component
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={page}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StallType;
