import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import BannerAdd from '../../org-management/Advertisement/BannerAdd';
import { GrView } from "react-icons/gr";
import { useLocation } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

//const GAPI_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export default function AddList() {
    const [addList, setAddlist] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const { eventId } = useParams();
    const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [publishedAd, setPublishedAd] = useState({});
    const { auth } = useAuth();
    useEffect(() => {
        fetchData();

    }, []);


    function fetchData() {
        setLoading(true);
        const url = eventId ? `/api/advertisement/eventid=${eventId}` : '/api/advertisement';
        axios
            .get(API_URL + url, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setAddlist(response.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching advertisements:', error);
                setLoading(false);
            });
    }

    const handleLinkClick = (e, adddetail) => {
        navigate(`/addsDetails`, { state: { adddetail } });
    };

    const paginatedData = addList.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = page => {
        setPage(page);
    };

    function getYouTubeVideoId(url) {
        // Regular expression to extract the YouTube video ID from the URL
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    const renderMedia = (ad) => {
        if (ad.videolink) {
            // If there's a video link, render the video
            return (
                <iframe
                    width="330"
                    height="283"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(ad.videolink)}`}
                    title="Video Player"
                    allowFullScreen
                ></iframe>
            );
        } else if (ad.image) {
            // If there's an image, render the image
            return <img src={`${ad.imageMeta},${ad.image}`} alt="Advertisement Image" width="330" height="283" />;
        } else if (ad.text) {
            // If there's text content, render the text
            return <div>{ad.text}</div>;
        } else {
            // If neither video nor image nor text is provided, render a placeholder or any default content
            return <div>No media available</div>;
        }
    };



    return (
        <div>

            {loading ? (
                <Loader />
            ) : (
                <div>
                    {publishedAd?.id && (
                        <BannerAdd ad={publishedAd} />
                    )}
                    <div className="row m-4">
                        <div className="col-md-12">
                            <h2>Advertisements</h2>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Add New'
                            >
                                <Link
                                    to={`/createAdds`}
                                    className='btn btn-sm btn-light-primary float-end'>
                                    <KTIcon iconName='plus' className='fs-3' />
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>


                    <div className='col-xl-12'>
                        <div className='row g-5 g-xl-10'>

                            {paginatedData.slice().reverse().map((adds, index) => (
                                <div
                                    className='col-xl-11'
                                    key={adds.id}
                                    onMouseEnter={() => setHoveredEventIndex(index)}
                                    onMouseLeave={() => setHoveredEventIndex(-1)}
                                >
                                    <div
                                        className='card card-raised'
                                        style={{
                                            transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                                            transition: 'transform 0.2s ease-in-out',
                                        }}
                                    >
                                        <div className='mx-4 mt-3'>
                                            <div className='card-toolbar float-end'>
                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                                    data-kt-menu-trigger='click'
                                                    data-kt-menu-placement='bottom-end'
                                                    data-kt-menu-flip='float-end'
                                                >
                                                    <Link
                                                        to={`/editAdds/${adds.id}`}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-2' />
                                                    </Link>
                                                </button>
                                                <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={(e) => { handleLinkClick(e, adds) }}>
                                                    <GrView size={20} />
                                                </button>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-7">
                                                    <div className='border-0 pt-5'>
                                                        <h5 className='card-title align-items-start flex-column'>
                                                            {renderMedia(adds)}
                                                            <br />
                                                        </h5>
                                                    </div>
                                                    {/* end::Header */}
                                                </div>
                                                <div className='col-md-5 pt-9'>
                                                    <div className='fs-4 pb-3'><b>Name:</b> {adds.name}</div>
                                                    <div className='fs-4 pb-3'> <b>Description: </b> {adds.description}</div>
                                                    {adds.eventid && adds.events && (
                                                        <div className='fs-4 pb-3'>
                                                            <b>Event Name:</b> {adds.events.title} ({adds.eventid})
                                                        </div>
                                                    )}

                                                    {adds.username && <div className='fs-4 pb-3'> <b>Username: </b> {adds.username}</div>}

                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {paginatedData && paginatedData.length > 0 && (
                            <Pagination
                                data={paginatedData}
                                itemsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                currentPage={page}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
