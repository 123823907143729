/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;

function CreateSponsors({ onSponsorCreated, eventId }) {
  const [loading, setLoading] = useState(false);
  const {auth} = useAuth();
  const [type, SetType] = useState([]);
  const [activities, setActivities]= useState([]);
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen]= useState(false);
  const [sponser, setsponser] = useState({
    name: "",
    email: "",
    contact: "",
    eventid: eventId,
    logo: "",
    imageMeta: "",
    website: "",
    description: "",
    sponsertype:"",
    sponserActivity:""
  })

  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }


  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...sponser,

      imageMeta: convertedImage.metadata,
      logo: convertedImage.content,

    };
    setsponser(imageObject);
  }

  const handleChange = (inten, field) => {
    let actualValue = inten.target.value;

    setsponser({ ...sponser, [field]: actualValue });
  };

  const handleSubmit = async (task) => {
    task.preventDefault();
    setLoading(true);
    axios
      .post(API_URL + "/api/sponsers/create", sponser, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)

      .then((result) => {
        toast.success("Sponsors created successfully");
        setData(result)
        setLoading(false);
        onSponsorCreated();
        closeModal();
      });

  };


  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    axios
      .get(API_URL + '/api/sponsertype', {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {

        const dataArray = Array.isArray(response.data) ? response.data : [];
        SetType(dataArray);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error Fetching Sponsors Type:', error);
        setLoading(false);
      });
  }, [auth?.token]);

  useEffect(() => {
    axios
      .get(API_URL + '/api/sponsorActivities', {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {

        setActivities(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error Fetching Sponsors Activities', error);
        setLoading(false);
      });
  }, [auth?.token]);


  return (
    <div className='modal fade' id='kt_modal_sponsors' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Sponsors</h1>



            </div>


            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-10 mx-xl-auto ps-xl-10">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>


                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="name"> Name</label>
                          <input
                            className="form-control"
                            id="name"
                            type="text"
                            placeholder="Name"
                            onChange={(e) => handleChange(e, "name")}
                          />
                        </div>
                      </div>
                     
                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="email"> Email</label>
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            placeholder="Email"
                            onChange={(e) => handleChange(e, "email")}
                          />
                        </div>
                      </div>
                   
                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="contact">Contact Number</label>
                          <input
                            className="form-control"
                            id="contact"
                            type="text"
                            placeholder="Contact"
                            onChange={(e) => handleChange(e, "contact")}
                          />
                        </div>
                      </div>
               

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <div className='me-7 mb-4'>
                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                              {sponser?.logo ? (
                                <img
                                  id="logo"
                                  src={`${sponser?.imageMeta},${sponser?.logo}`}
                                  alt={sponser?.email}
                                  style={{ maxWidth: "150px", maxHeight: "150px" }}
                                />
                              ) : (
                                <img alt={sponser?.firstname} />
                              )}
                            </div>
                          </div>
                        </div>
                        <input
                          className="col-7"
                          type="file"
                          id="image"
                          onChange={(event) => {
                            handleImageChange(event)
                          }}
                          accept="image/*"
                        />
                      </div>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="website"> Website</label>
                          <input
                            className="form-control"
                            id="website"
                            type="text"
                            placeholder="Website "
                            onChange={(e) => handleChange(e, "website")}
                          />
                        </div>
                      </div>
                     

                      <div className="form-floating-sm mb-2">
                        <label htmlFor="sponsertype">Sponsors Type</label>
                        <select
                          className="form-control"
                          id="sponsertype"
                          onChange={(e) => handleChange(e, "sponsertype")}
                        >
                          <option value="">Select.. </option>
                          {type.map((sponsorType) => (
                            <option key={sponsorType.id} value={sponsorType.sponsertype}>
                              {sponsorType?.sponsertype}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-floating-sm mb-2">
                        <label htmlFor="sponserActivity">Sponsors Activity</label>
                        <select
                          className="form-control"
                          id="sponserActivity"
                          onChange={(e) => handleChange(e, "sponserActivity")}
                        >
                          <option value="">Select..</option>
                          {activities.map((activity) => (
                            <option key={activity.id} value={activity.type}>
                              {activity?.type}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="description"> Description </label>
                          <textarea
                            className="form-control"
                            id="description"
                            type="text"
                            placeholder="Description"
                            onChange={(e) => handleChange(e, "description")}
                          />
                        </div>
                      </div>

<br></br>
                      
                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                          data-bs-dismiss='modal'
                        />
                      </div>
                    </form>
                  </div>

                </main>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateSponsors;
