import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { KTIcon } from "../../helpers";
import { WindowSharp } from "@mui/icons-material";


function SponsorDetails({ sponsor }) {

    const websiteClick = (sponsor) => {
        if (sponsor) {
            window.open(sponsor, '_blank');
          }
    };


    return (
        <div className='modal fade' id='kt_modal_sponsor_details' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center'>
                        {/* <span className='text-muted mt-1 fw-semibold fs-2'>
                    PARTNERS IN PROGRESS
                </span> */}
                <img
                                    src={`${sponsor?.imageMeta},${sponsor?.logo}`}
                                    style={{
                                        width: '40%',
                                        height: '150px'
                                    }}
                                />
                                <h3 className='px-12 mt-3'>{sponsor?.name}</h3>
                            
                                <h4 onClick={()=>websiteClick(sponsor?.website)} className='text-primary mt-4' style={{ cursor: 'pointer' }}>
                                {sponsor?.website}
                            </h4>
                            
                        </div>
            </div>
        </div>
   </div >
   </div >
  );
}





export default SponsorDetails;



