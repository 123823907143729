import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../app/modules/auth";
import { AiFillCheckCircle } from "react-icons/ai";
import Loader from "../../../Loader";
import { IoArrowBackOutline } from "react-icons/io5";
import { CgOrganisation } from "react-icons/cg";

function OrganizationDetails() {
  const [events, SetEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orgid, SetOrgID] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const API_URL = process.env.REACT_APP_API_URL;
  const { auth } = useAuth();
  const { id, orgName } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    id: "",
    name: "",
    contact: "",
    description: "",
    pan: "",
    gstin: "",
    tan: "",
    orgcode: "",
    orgtype: "",
    address: "",
    status: "",
    admin: "",
    image: "",
    imageMeta: ""
  });

  useEffect(() => {
    setLoading(true);
    if (orgName) {
      axios
        .get(API_URL + `/api/organizationprofiles/name=${orgName}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setProfile(response.data);
          setLoading(false);
        })
        .catch(error => {
          const errorMessage = 'Error fetching Organizations Details. Please try again.';
          toast.error(errorMessage);
          setLoading(false);
        });
    }
    else {
      axios
        .get(API_URL + `/api/organizationprofiles/${id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setProfile(response.data);
          setLoading(false);
        })
        .catch(error => {
          const errorMessage = 'Error fetching Organizations Details. Please try again.';
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  }, [auth?.token]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/adminorganizationmapping/orgid=${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetOrgID(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = error.response ? error.response.data.message : 'Error fetching Sponsors. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [auth?.token]);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = (page) => {
    setLoading(true);

    axios
      .get(API_URL + `/api/EventPlannings/orgid=${id}/page=${page}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetEvents(response.data.pageData);
        setTotalPages(response.data.pages
        );
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Events. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const goBack = () => {
    navigate(-1); // This will take you back to the previous page
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>

          <div onClick={goBack} className='mb-10 cursor-pointer'> <IoArrowBackOutline size={30} /></div>

          <h2 className="mb-5"> Organization Details</h2>
          <div
            className="form w-100" noValidate id="kt_login_signin_form">

            <div className="container">
              <div className="main-body">
                <div className="row gutters-sm">
                  <div className="col-md-4 mb-4">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex flex-column align-items-center text-center">
                          {profile?.image ? (
                            <img id="image" src={`${profile?.imageMeta},${profile?.image}`} alt={profile?.email} style={{ maxWidth: "250px", maxHeight: "270px" }}
                            />
                          ) : (
                            <CgOrganisation size={75} />
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card mb-3">
                      <div className="card-body">


                        <div className="row">

                          <div className="col-sm-4">
                            <h6 className="mb-2">Oragnization Name</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.name}
                          </div>
                        </div>
                        <hr />
                        <br></br>
                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="mb-2">Org Type</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.orgtype}
                          </div>
                        </div>
                        <hr />

                        <br></br>



                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="mb-2">Org Code</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.orgcode}
                          </div>

                        </div>
                        <hr />
                        <br></br>



                        <div className="row">

                          <div className="col-sm-4">

                            <h6 className="mb-2">Phone Number</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.contact}
                          </div>

                        </div>
                        <hr />

                        <br></br>



                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="mb-2">Address</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.address}
                          </div>
                        </div>
                        <hr />
                        <br></br>



                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="mb-2">Description</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.description}
                          </div>
                        </div>
                        <hr />
                        <br></br>

                        <div className="row">
                          <div className="col-sm-4">
                            <h6 className="mb-2">Pan</h6>
                          </div>
                          <div className="col-sm-8">
                            {profile.pan}
                          </div>

                        </div>
                        <hr />

                        <br></br>

                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-2">Tan</h6>
                          </div>
                          <div className="col-sm-3">
                            {profile.tan}
                          </div>




                          <div className="col-sm-3">
                            <h6 className="mb-2">GSTIN</h6>
                          </div>
                          <div className="col-sm-3">
                            {profile.gstin}
                          </div>

                        </div>
                        <hr />

                        <br></br>

                      </div>
                    </div>
                  </div>
                </div>
              </div>  </div>
          </div>
          <br></br>


          <div className="d-flex">
            <div className="card col-sm-6 text-center" style={{ marginRight: '20px', border: '1px solid #000000', borderRadius: '10px' }}>
              <h2 className="mt-3" style={{ color: '#000000', fontSize: '24px', fontWeight: 'bold', paddingBottom: '5px' }}>Organization Admins</h2>
              <div className="list-group">
                {orgid.map(org => (
                  <div className="list-group-item" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", marginBottom: "5px" }}>
                    <div className="cursor-pointer" onClick={() => navigate(`/AccSetting/${org.adminid}`)}>{org.adminid}</div>
                    {org.primaryadmin ? <AiFillCheckCircle style={{ fontSize: '20px', color: 'darkgreen' }} /> : ""}
                  </div>
                ))}
              </div>
            </div>
            <div className="card col-sm-6 text-center" style={{ marginRight: '20px', border: '1px solid #000000', borderRadius: '10px' }}>
              <h2 className="mt-3" style={{ color: '#000000', fontSize: '24px', fontWeight: 'bold', paddingBottom: '5px' }}>Events</h2>
              <div className="list-group">
                {events && events.map(event => (
                  <div key={event.id} className="list-group-item" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", marginBottom: "5px" }}>
                    <div className="cursor-pointer text-hover-primary" onClick={() => navigate(`/eventDetails/${event?.id}`)}>
                      {event.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>

      )}
    </div>
  );
}
export default OrganizationDetails;