import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useAuth } from '../../../app/modules/auth';
import Loader from '../../../Loader';
import { end, left, right } from '@popperjs/core';

const API_URL = process.env.REACT_APP_API_URL;

const UI_URL = process.env.REACT_APP_INTERFACE_URL;

function EventDetails() {
  const [eventlist, seteventlist] = useState([]);
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
  const auth = useAuth();
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const orgcode = data?.orgcode;
  const eventid = eventlist?.id;
  const [events, setEvents] = useState();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const url = UI_URL + `/event/${eventid}`


  const { eventId } = useParams();
  const token = localStorage.getItem('token');
  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/EventPlannings/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        seteventlist(response.data);
        setEvents(eventlist.id);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching eventlist:', error);
        setLoading(false);
      });
  }, [token]);

  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }

  const handleSessionCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/EventPlannings/${eventId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        seteventlist(response.data);
        setEvents(eventlist.id);
        setLoading(false);

      })
      .catch(error => {
        console.error('Error fetching eventlist:', error);
        setLoading(false);
      });

  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...eventlist,

      imageMeta: convertedImage.metadata,
      image: convertedImage.content,

    };
    seteventlist(imageObject);
  }

  const handleLogoChange = async (event) => {
    const convertedLogo = await getBase64FromFile(event.target.files[0]);
    const logoObject = {
      ...eventlist,
      logoMeta: convertedLogo.metadata,
      logo: convertedLogo.content,
    };
    seteventlist(logoObject);
  };

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    seteventlist({ ...eventlist, [field]: actualValue });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(API_URL + "/api/EventPlannings/update", eventlist, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Event Details Updated");
        seteventlist(eventlist);
        navigate(`/eventDetails/${eventId}`);
        setLoading(false);

      });
  };


  const handleButtonClick = () => {
    // Trigger the file input's click event when the button is clicked
    fileInputRef.current.click();
  };

  const handleLogoButtonClick = () => {
    logoInputRef.current.click();
  };


  return (

    <div style={{ paddingLeft: '15px', paddingRight: '15px', marginLeft: 'auto', marginRight: 'auto' }}>

      {loading ? (
        <Loader />
      ) : (
        <div>


          <h1 className='text-center'>{eventlist.title}</h1>
          <div className="row">
            {eventlist?.image ? (
              <img id="image" src={`${eventlist?.imageMeta},${eventlist?.image}`} style={{ maxWidth: "100%", maxHeight: "50%" }}

              />
            ) : (
              <img alt={eventlist?.title} />
            )}
            <div>
              <input
                type="file"
                id="image"
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <button
                className="btn btn-primary mt-3 col-3 float-end"
                type="button"
                onClick={handleButtonClick}
              >
                Upload
              </button>
            </div>
          </div>
          <br></br><br></br>
          <form onSubmit={handleSubmit} noValidate className='form'>
            <div className='card mb-5 mb-xl-10'>
              <div className='card-body border-top p-9'>

                <div>
                  <h4 style={{ cursor: 'pointer' }}>
                    Event URL:<a href={url} target='_blank'> {url} </a>
                  </h4>
                  <br />
                </div>

                <br />

                <div className="row">

                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span>Title</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      value={eventlist?.title}
                      onChange={(e) => handleChange(e, "title")}

                    />
                  </div>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>Start Date</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='date'
                      id="startdate"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      value={eventlist?.startdate}
                      onChange={(e) => handleChange(e, "startdate")}
                    />

                  </div>

                </div>
                <br></br>

                <div className="row">

                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span>Theme</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      value={eventlist?.theme}
                      onChange={(e) => handleChange(e, "theme")}

                    />
                  </div>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>EndDate</label>

                  <div className='col-lg-3 fv-row'>
                    <input
                      type='date'
                      id="enddate"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      value={eventlist?.enddate}
                      onChange={(e) => handleChange(e, "enddate")}
                    />

                  </div>

                </div>
                <br></br>


                <div className="row">

                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span>OneLiner</span>
                  </label>

                  <div className='col-lg-5 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      value={eventlist?.oneliner}
                      onChange={(e) => handleChange(e, "oneliner")}

                    />
                  </div>



                </div>

                <br></br>
                {/* Logo Upload Section */}


                <div className="row">
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>Location</label>

                  <div className='col-lg-7 fv-row'>
                    <textarea
                      type='text'
                      id="location"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      value={eventlist?.location}
                      onChange={(e) => handleChange(e, "location")}
                    />

                  </div>
                </div>
                <br></br>
                <div className='row'>
                  <label className='col-lg-2 col-form-label fw-bold fs-6'>
                    <span>Description</span>
                  </label>

                  <div className='col-lg-7 fv-row'>
                    <textarea
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      value={eventlist?.description}
                      onChange={(e) => handleChange(e, "description")}

                    />
                  </div>

                </div>

                <div className="row">
                  <label className="col-lg-2 col-form-label fw-bold fs-6">Logo</label>
                  <div className="col-lg-10 d-flex align-items-center">  {/* Add flexbox wrapper */}
                    {eventlist?.logo ? (
                      <img
                        id="logo"
                        src={`${eventlist?.logoMeta},${eventlist?.logo}`}
                        style={{
                          width: '100px',   // Set smaller width
                          height: 'auto',   // Maintain aspect ratio
                          marginRight: '10px'  // Add space between image and button
                        }}
                        alt="Event Logo"
                      />
                    ) : (
                      <img alt="Event Logo Placeholder" />
                    )}
                    <div>
                      <input
                        type="file"
                        id="logo"
                        ref={logoInputRef}
                        onChange={handleLogoChange}
                        accept="image/*"
                        style={{ display: 'none' }}
                      />
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleLogoButtonClick}
                        style={{
                          width: '150px',  // Make the button smaller
                          marginLeft: '10px'  // Add some space between the button and the image
                        }}
                      >
                        Upload Logo
                      </button>
                    </div>
                  </div>
                </div>
                <br />

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>


              </div>
            </div>


          </form>
        </div>
      )}

    </div>


  );
}

export default EventDetails;