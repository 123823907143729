import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { useAuth } from "../../../app/modules/auth";
import { useNavigate, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditTaskStatus() {
  const [events, setEvents] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {auth} = useAuth();
  const {taskstatusid} = useParams();
  const [taskstatus, setTaskStatus] = useState({
  
    name:""
   
  });


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/tastStatus/${taskstatusid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setTaskStatus(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Task Status. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [taskstatusid, token]); // Include taskid as a dependency


  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setTaskStatus((prevTaskStatus) => ({ ...prevTaskStatus, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/tastStatus/update", taskstatus, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Task Status Updated");
        navigate("/taskstatus");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update Task Status. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
    {loading ? (
      <Loader />
    ) : (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Edit Task Status</span>
           </h3>
                      
                    </div>
                  </div>
                  <br></br>

                  <div className="form-floating-sm mb-2"></div>
 

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="name">Task Name</label>
              <input
                className="form-control"
                id="name"
                type="text"
                placeholder="Task Name "
                value={taskstatus.name}
                 onChange={(e) => handleChange(e, "name")}
              />
            </div>
          </div>
          <br></br>


          
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                  
                </form>
                </div>
                </div>
                </div>
            
        </main>
        </div>
    </div>
    )}
    </div>
  );
}

export default EditTaskStatus;
