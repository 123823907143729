/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate, Link } from 'react-router-dom';
import {useFormik} from 'formik'
import {publiclogin} from '../core/_requests'
import {useAuth} from '../core/Auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const initialValues = {
  email: ''
};

const GenrateuserQRcode = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await publiclogin(values.email);
        saveAuth(auth);
        navigate('/pubhome');
      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus('The login details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  });


  return (
    <form
    className="form w-100"
    onSubmit={formik.handleSubmit}
    noValidate
    id="kt_login_signin_form"
  >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'> Genrate User QR code</h1>
       
      </div>
      {/* begin::Heading */}

      
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>User Name</label>
        <input
          placeholder='username'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Contact</label>
        <input
          type='number'
          placeholder='Contact Number'
          autoComplete='off'
          {...formik.getFieldProps('contact')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.contact && formik.errors.contact,
            },
            {
              'is-valid': formik.touched.contact && !formik.errors.contact,
            }
          )}
        />
        {formik.touched.contact && formik.errors.contact && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contact}</span>
            </div>
          </div>
        )}
      </div>


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Company</label>
        <input
          placeholder='Select company'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      
      {/* begin::Wrapper */}
      


          <div className="row">
            <div className="form-floating-sm mb-2">
              <label className='form-label fs-6 fw-bolder text-dark '>Description</label>
              <textarea
              className="form-control"
                id="description"
                type="text"
                placeholder="Description"
              
              />
              
            </div>
          </div>
     
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Role</label>
        <input
          placeholder='Select role'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>


      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Image</label>
        <input className= 'form-control bg-transparent' type="file" id="image"  accept="image/*"></input>
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>



      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Genrate user QR code</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... 
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      
      

     



    </form>
  )
}
export default GenrateuserQRcode;
