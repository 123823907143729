/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate, Link } from 'react-router-dom';
import {useFormik} from 'formik'
import {publiclogin, getUserNotification} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { toast } from 'react-toastify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

const initialValues = {
  email: ''
};

const GeneralusersLogin = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, setNotification } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await publiclogin(values.email);
        saveAuth(auth);
        const { data: noteDate } = await getUserNotification(auth?.token);
        setNotification(noteDate);
        toast.success(`Welcome! ${values.email}`);
        navigate('/pubhome');
      } catch (error) {
         saveAuth(undefined);
        const errorMessage =  'The login details are incorrect. Please try again.';
        toast.error(errorMessage);
        setStatus(errorMessage);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });


  return (
    <form
    className="form w-100"
    onSubmit={formik.handleSubmit}
    noValidate
    id="kt_login_signin_form"
  >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
       
      </div>
      {/* begin::Heading */}

      
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      
      {/* end::Form group */}

      {/* begin::Wrapper */}
     



      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... 
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/generalRegistration' className='link-primary'>
          Sign up
        </Link>
      </div>

     



    </form>
  )
}
export default GeneralusersLogin;
