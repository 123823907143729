import React, {useState, useEffect} from 'react';
import Map from '../location'; // Import the Map component
import {toast} from 'react-toastify';
import Navbar from '../Navbar';
import EventFooter from '../EventFooter';


//import Directions from './DirectionService';

export default function EventVenue(event) {

const [userLocation, setUserLocation] = useState(null);
  
const address= event?.location;

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          toast.error(`Error getting user's location: ${error.message}`);
        }
      );
    } else {
        toast.error(`Geolocation is not available`);
    }
  }, []);

  return (
    <div>

        <Navbar event={event} />
        <div className='pt-20'>
                        <div className='card'>
                            <div style={{ backgroundColor: '#728df9', color: '#fbf9f9', fontSize: '2rem' }} className='card-label fw-bold px-10 pt-5 pb-5'>
                                Directions to the event venue
                            </div>
                        </div>
                        </div>

        <Map eventLocation={address} />
 
       
        <EventFooter />
         </div>
  );
};

