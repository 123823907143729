import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;


const Map = ({ eventLocation }) => {
  const containerStyle = {
    width: '100%',
    height: '400px', 
  };

  return (
    <LoadScript googleMapsApiKey={MapsApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={eventLocation}
        zoom={15} // Adjust the zoom level as needed
      >
        <Marker position={eventLocation} />
      </GoogleMap>
    </LoadScript>
  );
};

export default Map;
