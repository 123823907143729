/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom';
import {useFormik} from 'formik'
import {getUserByToken, login, getUserNotification} from '../core/_requests'
import {useAuth} from '../core/Auth'
import { toast } from "react-toastify";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email address'
    )
    .required('Email is required'),
//   password: Yup.string()
//   .matches(
//     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
//     'Password must have at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character'
//   )
//     .required('Password is required'),

}
);

const initialValues = {
  email: '',
  password: '',
 
};

const ExhibitorLogin = () => {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, setNotification } = useAuth();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await login(values.email, values.password, values.orgid);
        saveAuth( );
        const { data: user } = await getUserByToken(auth.token);
        setCurrentUser(user);
        const { data: noteDate } = await getUserNotification(auth?.token);
      setNotification(noteDate);
        navigate('/dashboard');
        toast.success(`Welcome! ${user.email}`);

      } catch (error) {
        const errorMessage =  'The login details are incorrect. Please try again.';
        toast.error(errorMessage);
        console.error(error);
        saveAuth(undefined);
        setStatus(errorMessage);
        setSubmitting(false);
        setLoading(false);
        
      }
    },
  });


  return (
    <form
    className="form w-100"
    onSubmit={formik.handleSubmit}
    noValidate
    id="kt_login_signin_form"
  >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
           </div>
      {/* begin::Heading */}

      
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
        placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
       
   <br></br>

     


      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}



      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait... 
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>

     

    </form>
  )
}
export default ExhibitorLogin;
