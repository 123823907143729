import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;



export default function ViewArticle({ selectedArticle }) {
   
    return (
        <div>

            <div className='modal fade' id='kt_modal_view_article' aria-hidden='true'>
                <div className='modal-dialog mw-650px'>
                    <div className='modal-content'>
                        <div className='modal-header pb-0 border-0 justify-content-end'>
                            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                        </div>

                        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>


                            <div className='col-xl-12'>
                                <div className='row g-5 g-xl-10'>

                                    <div
                                        className='col-xl-11'
                                    >

                                        <div className='mx-4 mt-3'>

                                            <div className='border-0 pt-5'>
                                                <h5 className='card-title align-items-start flex-column'>

                                                    {selectedArticle?.subject}

                                                    <br />
                                                </h5>
                                            </div>
                                            {/* end::Header */}


                                            <div className='text-muted d-flex justify-content'>
                                                <b>{selectedArticle?.userid}</b>

                                            </div>

                                            <div className='text-muted d-flex justify-content-end'>
                                                <b>{selectedArticle?.dateTime}</b>

                                            </div>



                                            <div className='pt-5'>

                                                <div
                                                    dangerouslySetInnerHTML={{ __html: selectedArticle?.description }}
                                                />
                                            </div>

                                          

                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
