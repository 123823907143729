import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditStallType() {
  const [events, setEvents] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { stalltypeid } = useParams();
  const {auth} = useAuth();
  const [stalltype, setstalltype] = useState({
   
    stallType: "",
    cost:""
   
  });
  
  

  useEffect(() => {
    axios
      .get(API_URL + `/api/StallType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPriority(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error fetching Stall Type. Please try again.';
        toast.error(errorMessage);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(API_URL + `/api/StallType/${stalltypeid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setstalltype(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching StallType. Please try again.';
        toast.error(errorMessage);
      });
  }, [stalltypeid, token]); 

  
  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setstalltype((prevStalltype) => ({ ...prevStalltype, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/StallType/update", stalltype, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Stall Type Updated");
        navigate("/stalltype/:eventId");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update Stall Type. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3>Edit Stall Type</h3>

                      
</div>
</div>



          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="stallType">Stall Type</label>
              <input
                className="form-control"
                id="stallType"
                type="text"
                placeholder="Stall Type "
                value={stalltype.stallType}
                 onChange={(e) => handleChange(e, "stallType")}
              />
            </div>
          </div>
          <br></br>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="cost">cost</label>
              <input
                className="form-control"
                id="cost"
                type="text"
                placeholder="cost "
                value={stalltype.cost}
                 onChange={(e) => handleChange(e, "cost")}
              />
            </div>
          </div>
          <br></br>

        

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditStallType;
