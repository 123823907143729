import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toAbsoluteUrl } from '../helpers';
import { useAuth } from '../../app/modules/auth';
import { MdLocalPrintshop } from "react-icons/md";
const API_URL = process.env.REACT_APP_API_URL;

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let params = useParams();

        return <Component {...props} params={params} />;
    }
    return ComponentWithRouterProp;
}


function Invoice(props) {
    const { currentUser } = useAuth()
    const { featureId } = useParams();
    const username = currentUser !== undefined ? currentUser?.email : localStorage.getItem('user');
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState([]);
    const [costs, setCost] = useState({});
    const [invoice, setInvoice] = useState("");
    const [appcode, setAppcode] = useState({});

    const date = new Date();

    let day = date.getDate();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let year = date.getFullYear();
    let nextyear = year + 1;
    let currentDate = `${day}-${month}-${year}`;

    const navigate = useNavigate();

    const getData = async () => {
        setLoading(true);

        await axios.get(API_URL + `/api/paymentgatewaytax`).then((response) => {
            setAmount(response.data);
        });

        if (featureId) {
            await axios.get(API_URL + `/api/subscriptiontype/type=${featureId}`).then((response) => {
                setCost(response.data);
                setLoading(false);

            });
        }
        if (!featureId) {
            setCost(location.state && location?.state.sub);

        }
        await axios.get(API_URL + "/api/version/appcode").then((response) => {
            setAppcode(response.data);
            setInvoice(
                `IN - ${year} - ${nextyear} - ${response.data.appCode} - ${props.params.pid}`
            );
        });
    };
    useEffect(() => {
        getData();
    }, []);

    function printbill(e) {
        window.print();

        let appcd = `${appcode.appCode}`;
    }
    return (
        <div className="mx-20 mt-8 my-10">
            <div className="cursor-pointer float-end" onClick={(e) => printbill()}>
                  <MdLocalPrintshop size={25}/>
            </div>
            
            <div className="mt-10">
            <div className='card'>
               
                    {loading && <div className="loading"></div>}

                        <div className="a4-sheet px-3 mt-3">
                            <header className="bg-primary">
                                <div className="px-10">
                                    <h2 className="text-white mt-1 py-3 mb-0 display-6 mb-10"> Invoice Details</h2>
                                </div>
                            </header>

                                {amount.map((amount) => {

                                    const totalcost = +`${costs.cost}`;
                                    const gsttax = +`${amount.gsttax}`;
                                    const gatewaytax = +`${amount.gatewaytax}`;
                                    const Taxamount1 = (gsttax / 100) * totalcost + totalcost;
                                    const Taxamount3 = (gsttax / 100) * totalcost;
                                    const Taxamount2 = (gatewaytax / 100) * Taxamount1;
                                    const Tax1 = (gsttax / 100) * Taxamount2;
                                    const TotalTax = Taxamount2 + Tax1;
                                    const cgst = (gsttax / 2 / 100) * totalcost;
                                    const sgst = (gsttax / 2 / 100) * totalcost;

                                    const totalamount = Taxamount1 + TotalTax;
                                    const totaltransactioncost = Tax1 + Taxamount2;
                                    return (

                                        <div className="px-10 mb-10" style={{ margin: '0' }}>

                                            <div className='col-12' style={{ margin: '0' }}>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <h4>
                                                            <b>Cynefian</b>{" "}
                                                        </h4>{" "}
                                                        <h6> 1010/27 Mysore Road</h6>{" "}
                                                        <h6>Gundlupet , Karnataka</h6>
                                                        <h6>In 571111</h6>
                                                    </div>
                                                    <div className='col-4'>

                                                        <img
                                                            src={toAbsoluteUrl('/media/cynefian.logo.png')} width="125px" />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <div>
                                                {" "}
                                                <h4>
                                                    <b>To</b>
                                                </h4>
                                                <h6>{username}</h6>
                                            </div>
                                            <hr style={{ height: "8px", color: "black" }} />

                                            <div className='col-12'>
                                                <div className='row'>

                                                    <div className='col-8'>
                                                        <h2>Total Amount</h2></div>
                                                    <div className="col-4 d-flex">
                                                        {" "}
                                                        <h2>{totalamount.toFixed(2)}</h2>
                                                    </div>
                                                </div>

                                                <hr style={{ height: "8px", color: "black" }} />
                                                <div className='row mt-8'>

                                                    <div className='col-6'></div>


                                                    <div className="col-6 px-5">
                                                        <div>
                                                            <div>
                                                                <h6>Amount: {totalcost}</h6>
                                                                <h6>CGST: {cgst.toFixed(2)}</h6>
                                                                <h6>SGST: {sgst.toFixed(2)}</h6>
                                                                <h6>Transaction Cost: {totaltransactioncost.toFixed(2)}</h6>
                                                            </div>
                                                            <br></br>
                                                            <h2 style={{ color: "black" }}>
                                                                Total Amount: {totalamount.toFixed(2)}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    );

                                })}
                           
                        </div>
                   
                </div>
            </div>
        </div>
    );
}

export default withRouter(Invoice);