// import React, { useEffect, useState } from 'react';
// import axios from 'axios';


// const YouTubeLiveStream = () => {
//   const [liveStreamId, setLiveStreamId] = useState('');
//   const [isLive, setIsLive] = useState(false);

//   const createLiveStream = async () => {
//     // Replace with the actual URL for inserting a live broadcast
//     const response = await axios.post('https://example.com/api/liveBroadcast', {
//       snippet: {
//         title: 'My Live Broadcast Title',
//         scheduledStartTime: '2023-11-25T12:00:00Z', // Set the start time in ISO 8601 format
//       },
//       status: {
//         privacyStatus: 'public', // Set privacy status (public, private, unlisted)
//         selfDeclaredMadeForKids: false,
//       },
//     });
//     const liveStreamId = response.data.id;
//     console.log('Live stream created with ID:', liveStreamId);
//     return liveStreamId;
//   };
  
//   const startLiveStream = async (liveStreamId) => {
//     // Replace with the actual URL for setting the live stream status
//     const response = await axios.put(`https://example.com/api/liveStream/${liveStreamId}`, {
//       status: {
//         streamingStatus: 'live',
//       },
//     });
//     console.log('Live stream started');
//   };
  

//   useEffect(() => {
//     youtube.init({
//       key: 'AIzaSyAzqE86K1zD_Wq9zMxuXsG1P5JYqb5uDuY',
//     });
//   }, []);

//   const createAndStartLiveStream = async () => {
//     const liveStreamId = await createLiveStream();
//     setLiveStreamId(liveStreamId);
//     await startLiveStream(liveStreamId);
//     setIsLive(true);
//   };

//   const endLiveStream = async () => {
//     await endLiveStream(liveStreamId);
//     setIsLive(false);
//   };

//   return (
//     <div>
//       {isLive ? (
//         <iframe
//           src={`https://www.youtube.com/embed/${liveStreamId}`}
//           title="My Live Stream"
//           width="640"
//           height="360"
//           frameborder="0"
//           allowfullscreen
//         ></iframe>
//       ) : (
//         <button onClick={createAndStartLiveStream}>Start Live Stream</button>
//       )}

//       {isLive && (
//         <button onClick={endLiveStream}>End Live Stream</button>
//       )}
//     </div>
//   );
// };
// export default YouTubeLiveStream;


// import React, { useEffect, useRef } from 'react';

// const TwitchPlayer = () => {
//   const twitchPlayerRef = useRef();

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://player.twitch.tv/js/embed/v1.js';
//     script.async = true;
//     document.body.appendChild(script);

//     script.onload = () => {
//       new window.Twitch.Player('twitch-player', {
//         width: '100%',
//         height: '100%',
//         channel: 'UC26q2TzaQfNvoHmkUQLRgVw', 
//         parent: ['www.cynefian.com'],
//       });
//     };

//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return (
//     <div>
//       <div id="twitch-player" ref={twitchPlayerRef}></div>
//     </div>
//   );
// };

// export default TwitchPlayer;



// React component for video upload
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [codes, setCode] = useState('');

  useEffect(() => {
    // Call the backend to get the authorization URL
    const fetchAuthorizationUrl = async () => {
      try {
        const response = await axios.get('http://localhost:8080/getAuthorizationUrl');
        window.location.href = response.data; // Redirect to the authorization URL
      } catch (error) {
        console.error('Error fetching authorization URL:', error.message);
      }
    };

    fetchAuthorizationUrl();
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const code="https://accounts.google.com/o/oauth2/auth?access_type=offline&approval_prompt=force&client_id=868087995809-i5tp3oavmmfvoq1bf29j65sjdecb7jht.apps.googleusercontent.com&redirect_uri=https://uthsava.in&response_type=code&scope=https://www.googleapis.com/auth/youtube.upload"
  const handleUpload = async () => {
    if (!file || !code) {
      setUploadStatus('Please select a file and provide the authorization code.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      // Use the obtained authorization code to upload the video
      const response = await axios.post('http://localhost:8080/uploadVideo', formData, {
        params: { code },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUploadStatus(response.data);
    } catch (error) {
      setUploadStatus('Error uploading video. Please try again.');
      console.error('Error uploading video:', error.message);
    }
  };

  return (
    <div>
      <h1>Video Upload</h1>
      <input type="file" accept="video/*" onChange={handleFileChange} />
      <input type="text" placeholder="Authorization Code" onChange={handleCodeChange} />
      <button onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
    </div>
  );
};

export default VideoUpload;
