import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditSponsors() {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [type, SetType] = useState([]);
    const { sponsersid } = useParams();
    const { auth } = useAuth()
    const [activities, setActivities] = useState([]);
    const [sponser, setsponser] = useState({
        name: "",
        email: "",
        contact: "",
        eventid: "",
        logo: "",
        imageMeta: "",
        website: "",
        description: "",
        sponsertype: "",
        sponserActivity: ""
    });

    function getBase64FromFile(file) {

        return new Promise((resolve, reject) => {

            var reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = function () {

                const [metadata, content] = reader.result.split(",");

                resolve({ metadata, content });

            };

            reader.onerror = function (error) {

                reject(error);

            };

        });

    }


    const handleImageChange = async (event) => {
        const convertedImage = await getBase64FromFile(event.target.files[0]);
        const imageObject = {

            ...sponser,

            imageMeta: convertedImage.metadata,
            logo: convertedImage.content,

        };
        setsponser(imageObject);
    }







    useEffect(() => {
        axios
            .get(API_URL + `/api/sponsers/${sponsersid}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setsponser(response.data);
            })
            .catch((error) => {
                const errorMessage = 'Error Fetching sponsers. Please try again.';
                toast.error(errorMessage);
            });
    }, [sponsersid, token]);


    const handleChange = (e, field) => {
        const actualValue = e.target.value;
        setsponser((sponser) => ({ ...sponser, [field]: actualValue }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setLoading(true);

        axios
            .post(API_URL + "/api/sponsers/update", sponser, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then(() => {
                toast.success("Updated Succesfully");
                navigate(-1);
                setLoading(false);
            })
            .catch((error) => {
                const errorMessage = 'Failed to Update sponsors. Please try again.';
                toast.error(errorMessage);
                setLoading(false);

            });
    };


    useEffect(() => {
        axios
            .get(API_URL + '/api/sponsertype', {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {

                const dataArray = Array.isArray(response.data) ? response.data : [];
                SetType(dataArray);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error Fetching Sponsors Type', error);
                setLoading(false);
            });
    }, [auth?.token]);


    useEffect(() => {
        axios
            .get(API_URL + '/api/sponsorActivities', {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {

                setActivities(response.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error Fetching Sponsors Activities', error);
                setLoading(false);
            });
    }, [auth?.token]);

    return (
        <div>
            <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                    <div className="col-8 mx-xl-auto ps-xl-8">
                        <div className="card card-raised">
                            <div className="card-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <h3>Edit Sponsors</h3>


                                        </div>
                                    </div>

                                    <br>
                                    </br>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <label htmlFor="name"> Name</label>
                                            <input
                                                className="form-control"
                                                id="name"
                                                type="text"
                                                placeholder="Name"
                                                value={sponser.name}
                                                onChange={(e) => handleChange(e, "name")}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <label htmlFor="email"> Email</label>
                                            <input
                                                className="form-control"
                                                id="email"
                                                type="text"
                                                placeholder="Email"
                                                value={sponser.email}
                                                onChange={(e) => handleChange(e, "email")}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <label htmlFor="contact">Contact Number</label>
                                            <input
                                                className="form-control"
                                                id="contact"
                                                type="text"
                                                placeholder="Contact"
                                                value={sponser.contact}
                                                onChange={(e) => handleChange(e, "contact")}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <div className='me-7 mb-4'>
                                                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                                    {sponser?.logo ? (
                                                        <img
                                                            id="logo"
                                                            src={`${sponser?.imageMeta},${sponser?.logo}`}
                                                            alt={sponser?.email}
                                                            style={{ maxWidth: "150px", maxHeight: "150px" }}
                                                        />
                                                    ) : (
                                                        <img alt={sponser?.firstname} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <input
                                            className="col-7"
                                            type="file"
                                            id="image"
                                            onChange={(event) => {
                                                handleImageChange(event)
                                            }}
                                            accept="image/*"
                                        />
                                    </div>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <label htmlFor="website"> Website</label>
                                            <input
                                                className="form-control"
                                                id="website"
                                                type="text"
                                                placeholder="Website "
                                                value={sponser.website}
                                                onChange={(e) => handleChange(e, "website")}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="sponsertype">Sponsors Type</label>
                                        <select
                                            className="form-control"
                                            id="sponsertype"
                                            onChange={(e) => handleChange(e, "sponsertype")}
                                        >
                                            <option>{sponser?.sponsertype}</option>
                                            {type.map((sponsorType) => (
                                                <option key={sponsorType.id} value={sponsorType.sponsertype}>
                                                    {sponsorType?.sponsertype}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="sponserActivity">Sponsors Activity</label>
                                        <select
                                            className="form-control"
                                            id="sponserActivity"
                                            onChange={(e) => handleChange(e, "sponserActivity")}
                                        >
                                            <option>{sponser?.sponserActivity}</option>
                                            {activities.map((activity) => (
                                                <option key={activity.id} value={activity.type}>
                                                    {activity?.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="row">
                                        <div className="form-floating-sm mb-2">
                                            <label htmlFor="description"> Description </label>
                                            <textarea
                                                className="form-control"
                                                id="description"
                                                type="text"
                                                placeholder="Description"
                                                value={sponser.description}
                                                onChange={(e) => handleChange(e, "description")}
                                            />
                                        </div>
                                    </div>

                                    <div className="text-end">
                                        <input
                                            className="btn btn-primary"
                                            type="submit"
                                            disabled={loading}
                                            value="Submit"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default EditSponsors;
