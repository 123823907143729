import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditSpeaker() {
 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id,eventId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [speaker, setspeaker] = useState({
    id:"",
    name: "",
    eventid:"",
    company:"",
    designation: "",
    linkedinId:"",
    image:"",
    imageMeta:""
});

  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...speaker,

      imageMeta: convertedImage.metadata,
      image: convertedImage.content,

    };
    setspeaker(imageObject);
  }
  

  useEffect(() => {
    axios
      .get(API_URL + `/api/EventSpeakers/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setspeaker(response.data);
        
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching speaker. Please try again.';
        toast.error(errorMessage);
      });
  }, [id, token]);


  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setspeaker((speaker) => ({ ...speaker, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/EventSpeakers/update", { ...speaker, eventid: eventId }, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        toast.success("Updated Succesfully");
        navigate(`/Speakers/${eventId}`);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update speaker. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
        closeModal();

        const closeModal = () => {
          setModalOpen(false);
        };

      }); 
  };


  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <h3>Edit Speaker</h3>


                    </div>
                  </div>

                  <br>
                  </br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name"> Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={speaker.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                          {speaker?.image ? (
                            <img
                              id="image"
                              src={`${speaker?.imageMeta},${speaker?.image}`}
                              alt={speaker?.name}
                              style={{ maxWidth: "150px", maxHeight: "150px" }}
                            />
                          ) : (
                            <img alt={speaker?.firstname} />
                          )}
                        </div>
                      </div>
                    </div>
                    <input
                      className="col-7"
                      type="file"
                      id="image"
                      onChange={(event) => {
                        handleImageChange(event)
                      }}
                      accept="image/*"
                    />
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="company"> Company Name</label>
                      <input
                        className="form-control"
                        id="company"
                        type="text"
                        placeholder="Company Name"
                        value={speaker.company}
                        onChange={(e) => handleChange(e, "company")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="designation"> Designation </label>
                      <input
                        className="form-control"
                        id="designation"
                        type="text"
                        placeholder="Designation"
                        value={speaker.designation}
                        onChange={(e) => handleChange(e, "designation")}
                      />
                    </div>
                  </div><br></br>



                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="linkedinId"> LinkedIn ID</label>
                      <input
                        className="form-control"
                        id="linkedinId"
                        type="text"
                        placeholder="LinkedIn ID"
                        value={speaker.linkedinId}
                        onChange={(e) => handleChange(e, "linkedinId")}
                      />
                    </div>
                  </div>
                  <br></br>


                  <br></br>
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditSpeaker;
