import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import BannerAdd from '../../org-management/Advertisement/BannerAdd';
import CreateTransactionDetails from './CreateTransactionDetails';


const API_URL = process.env.REACT_APP_API_URL;

export default function AddsDetails() {
    const [addList, setAddlist] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const feature = location.state?.adddetail;
    const addId = feature?.id;
    const [adds, setAdds] = useState([{
        // eventid:eventId,
        addId: addId,
        startdate: "",
        enddate: "",
        paymentType: "",
        cost: "",
        username: "",
        paymentLink: ""
    }]);
    const [publishedAd, setPublishedAd] = useState({});
    const { auth } = useAuth();

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        setLoading(true);
        if (eventId) {
            axios
                .get(API_URL + `/api/advertisement/eventid=${eventId}`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((response) => {
                    setAddlist(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Error fetching Adds:', error);
                    setLoading(false);
                });
        } else {
            axios
                .get(API_URL + `/api/advertisement`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                })
                .then((response) => {
                    setAddlist(response.data);

                    setLoading(false);
                })
                .catch((error) => {
                    toast.error('Error fetching Addvertisement:', error);
                    setLoading(false);
                });
        }

        axios
            .get(API_URL + `/api/addTransaction/addId=${addId}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setAdds(response.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching Transaction:', error);
                setLoading(false);
            });
    }

    const paginatedData = adds.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const handlePageChange = (page) => {
        setPage(page);
    };

    const [createadds, setcreateadds] = useState({
        addId: addId,
        startdate: "",
        enddate: "",
        paymentType: "",
        cost: "",
        username: "",
        paymentLink: ""
    });
    const [createdTransactions, setCreatedTransactions] = useState([{
        addId: addId,
        startdate: "",
        enddate: "",
        paymentType: "",
        cost: "",
        username: "",
        paymentLink: ""
    }]);
    const handleChange = (inten, field) => {
        let actualValue = inten.target.value;

        setcreateadds({ ...createadds, [field]: actualValue });
    };


    const handleTransactionDetails = () => {
        setLoading(true);
        axios
            .get(API_URL + `/api/addTransaction`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setCreatedTransactions(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error Fetching addTransaction:', error);
                setLoading(false);
            });

    }
    useEffect(() => {
        fetchData();
    }, []);


    function fetchData() {
        setLoading(true);
        axios
            .get(API_URL + `/api/addTransaction`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setCreatedTransactions(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error Fetching addTransaction:', error);
                setLoading(false);
            });
    };

    function getYouTubeVideoId(url) {
        if (url) {
            // Regular expression to extract the YouTube video ID from the URL
            const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const match = url.match(regExp);
            if (match && match[1]) {
                return match[1];
            }
        }
        return null;
    }

    const renderMedia = (ad) => {

        if (ad.videolink) {
            // If there's a video link, render the video
            return (
                <iframe
                    width="330"
                    height="283"
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(ad.videolink)}`}
                    title="Video Player"
                    allowFullScreen
                ></iframe>
            );
        } else if (ad.image) {
            // If there's an image link and imageMeta is present, render the image
            console.log(ad.image);
            console.log(ad.imageMeta);
            return <img src={`${ad.imageMeta},${ad.image}`} alt="Advertisement Image" width="330" height="283" />;

        } else if (ad.text) {
            // If there's text content, render the text
            return <div>{ad.text}</div>;
        } else {
            // If neither video nor image nor text is provided, render a placeholder or any default content
            return <div>No media available</div>;
        }
    };






    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {publishedAd?.id && <BannerAdd ad={publishedAd} />}

                    <div className="row m-4">
                        <div className="col-md-12">
                            <h2>Advertisement Details</h2>
                        </div>
                    </div>

                    <div className="col-xl-12 col-md-12 col-lg-12">
                        <div className="row g-5 g-xl-10">
                            <div
                                className="col-xl-11 col-md-11 col-lg-11"
                                key={feature.id}
                            >
                                <div
                                    className="card card-raised"

                                >
                                    <div className="mx-4 mt-3">
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="border-0 pt-5">
                                                    <h5 className="card-title align-items-start flex-column">
                                                        {renderMedia(feature)}
                                                    </h5>
                                                </div>
                                                {/* end::Header */}
                                            </div>
                                            <div className="col-md-5 pt-9">
                                                <div className="fs-4 pb-3">
                                                    <b>Name:</b> {feature.name}
                                                </div>
                                                {/* <div className="fs-4 pb-3">
                                                    <b>Contact:</b> {feature.contactInformation}
                                                </div> */}
                                                <div className="fs-4 pb-3">
                                                    {' '}
                                                    <b>Description: </b> {feature?.description}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>

                            </div>

                            <CreateTransactionDetails onTransactionCreated={handleTransactionDetails} addId={addId} />

                            <div
                                className="card-toolbar"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-trigger="hover"
                                title="Click to add a user"
                            >
                                <div
                                    className="btn btn-sm btn-light-primary float-end ms-"
                                    // onClick={handleAddClick}
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_transactiondetails'
                                >
                                    <KTIcon iconName="plus" className="fs-3" />
                                    Add
                                </div>
                            </div>

                        </div>
                        <br></br>

                        <div className="card mb-xl-12 col-lg-12 col-md-12">
                            <h1 className="pt-1 px-8">Transaction Details</h1>

                            <br></br>
                            {paginatedData.slice().reverse().map((add, index) => (
                                <div className="col-lg-12 col-md-12 px-8">
                                    <div className='row'>
                                        <div className="col-md-6 col-form-label fw-bold mb-2">
                                            <div className="form-floating-sm">
                                                <input
                                                    className="form-control"
                                                    id="username"
                                                    type="text"
                                                    placeholder="Username"
                                                    value={add?.transaction?.username}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-form-label fw-bold mb-2">
                                            <div className="form-floating-sm">
                                                <input
                                                    className="form-control"
                                                    id="startdate"
                                                    type="date"
                                                    placeholder="Start Date"
                                                    value={add?.startdate}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6 col-form-label fw-bold mb-2">
                                            <div className="form-floating-sm">
                                                <input
                                                    className="form-control"
                                                    id="enddate"
                                                    type="date"
                                                    placeholder="End Date"
                                                    value={add?.enddate}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-form-label fw-bold mb-2">
                                            <div className="form-floating-sm">
                                                <input
                                                    className="form-control"
                                                    id="cost"
                                                    type="text"
                                                    placeholder="Cost"
                                                    value={add?.cost}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-6 col-form-label fw-bold mb-2">
                                            <div className="form-floating-sm">
                                                <input
                                                    className="form-control"
                                                    id="paymentLink"
                                                    type="text"
                                                    placeholder="Payment Ref. Code"
                                                    value={add?.transaction?.paymentLink}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {createdTransactions.slice().reverse().map((createdTransaction, index) => (
                                <div className="row px-8" key={index}>
                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="username">Username</label>
                                            <input
                                                className="form-control"
                                                id="username"
                                                type="text"
                                                placeholder="Username"
                                                value={createdTransaction.username}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="startdate">Start Date</label>
                                            <input
                                                className="form-control"
                                                id="startdate"
                                                type="date"
                                                placeholder="Start Date"
                                                value={createdTransaction.startdate}
                                                readOnly

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="enddate">End Date</label>
                                            <input
                                                className="form-control"
                                                id="enddate"
                                                type="date"
                                                placeholder="End Date"
                                                value={createdTransaction.enddate}
                                                readOnly


                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="cost">Cost</label>
                                            <input
                                                className="form-control"
                                                id="cost"
                                                type="text"
                                                placeholder="Cost"
                                                value={createdTransaction.cost}
                                                readOnly

                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-form-label fw-bold mx-0 mb-2">
                                        <div className="form-floating-sm">
                                            <label htmlFor="paymentLink">Payment Reference Code</label>
                                            <input
                                                className="form-control"
                                                id="paymentLink"
                                                type="text"
                                                placeholder="Payment Ref. Code"
                                                value={createdTransaction.paymentLink}
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                </div>
                            ))}

                        </div>

                        {/* {paginatedData && paginatedData.length > 0 && (
                        <Pagination
                            data={paginatedData}
                            itemsPerPage={rowsPerPage}
                            onPageChange={handlePageChange}
                            currentPage={page}
                        />
                    )} */}
                    </div>


                </div>
            )}
        </div>
    );
}
