import clsx from 'clsx';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../helpers';
import { Search } from '../../../partials';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { HeaderUserMenu } from '../../../partials';
import { BiChevronRight } from 'react-icons/bi';
import './notification.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaRegUser } from "react-icons/fa";
import { on } from '../header/Eventbus';


const itemClass = 'ms-1 ms-lg-3';
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px';
const userAvatarClass = 'symbol-35px symbol-md-40px';
const btnIconClass = 'fs-1';

const Navbar = () => {
  const [count, setCount] = useState(0);
  const {Auth}= useAuth();
   const [notifications, setNotifications] = useState([]);
  const { currentUser, notification } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const getData = async () => {
    try {
      const date = new Date();
      let day = date.getDate();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear();
      let currentDate = `${day}-${month}-${year}`;

      // const response = await axios.get(API_URL + `/api/user/notifications/user/${currentUser?.username}/read=false`, {
      //   headers: { Authorization: `Bearer ${Auth?.token}` },
      // });

      setNotifications(notification); 

      const countStatus = notification.reduce((count, notification) => {
        return notification?.read === false ? count + 1 : count;
      }, 0);

      setCount(countStatus);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setLoading(false);
    }
  };


  useEffect(() => {
    getData();
  }, [notification]);

  return (
    <div className='app-navbar flex-shrink-0'>


      
        <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
          <Search />
        </div>
     

      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip id="auditlog-tooltip">Audit Log</Tooltip>}
      >
        <div className={clsx('app-navbar-item', itemClass)}>
          <div className={btnClass}>
            <Link to="/auditlog">
              <KTIcon iconName='chart-simple' className={btnIconClass} />
            </Link>
          </div>
        </div>
      </OverlayTrigger>



      <div className={clsx('app-navbar-item', itemClass)}>
        <div className="dropdown">

<OverlayTrigger
  placement="bottom"
  overlay={<Tooltip id="notification-tooltip">Notifications</Tooltip>}
>
  <button
    className={btnClass}
    id='dropdownMenuNotifications'
    type='button'
    data-bs-toggle='dropdown'
    aria-expanded='false'
    // onClick={(e) => notificationstates(e)}
  >
    <KTIcon iconName='message-text-2' className={btnIconClass} />
    <sup>
      <div style={{ borderRadius: '50%', width: '17px', fontSize: '10px' }}>
        {count > 0 && <Badge bg='danger'>{count}</Badge>}
      </div>
    </sup>
  </button>
</OverlayTrigger>


          <ul
            className='dropdown-menu dropdown-menu-end me-3 mt-3 py-0 overflow-show'
            aria-labelledby='dropdownMenuNotifications'
            style={{ minWidth: '200px' }} // Set a minimum width for better display
          >
            <h6 className='dropdown-header bg-primary text-white fw-500 py-3'>Notifications</h6>


            {loading ? (
              <li>
                <div className='dropdown-item text-center'>Loading...</div>
              </li>
            ) : error ? (
              <li>
                <div className='dropdown-item text-danger'>{error}</div>
              </li>
            ) : (
              notifications.slice(-3).map((notification) => (
                <li key={notification.id}>
                  <div className="dropdown-item unread">
                    <BiChevronRight className="leading-icon float-end" />
                    <div className="dropdown-item-content me-2">
                      <div className="notification-subject">{notification.subject}</div>
                      {/* <div className="dropdown-item-content-text">{notification.message}</div> */}
                      <div className="dropdown-item-content-subtext">
                        {notification.timestamp}
                      </div>
                    </div>
                  </div>
                </li>
              ))
            )}

            <li>
              <hr className='dropdown-divider my-0' />
            </li>
            <li>
              <Link className='dropdown-item py-3' to='/usernotifications'> 
                <div className='d-flex align-items-center w-100 justify-content-end text-primary'>
                  <div className='fst-button small'>View all</div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>


<OverlayTrigger
  placement="bottom"
  overlay={<Tooltip id="profile-tooltip">Profile</Tooltip>}
>
  <div
    className={clsx('cursor-pointer symbol', userAvatarClass)}
    data-kt-menu-trigger="{default: 'click'}"
    data-kt-menu-attach='parent'
    data-kt-menu-placement='bottom-end'
  >
    {currentUser?.image ? (
      <img src={`${currentUser.imageMeta},${currentUser.image}`} alt={currentUser.email} />
    ) : (
      <FaRegUser size={25} />
    )}
  </div>
</OverlayTrigger>


        <HeaderUserMenu />
      </div>


    </div>
  );
};

export { Navbar };
