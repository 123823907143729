import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from "react-to-print";
import axios from 'axios';
import { KTIcon } from '../../../_metronic/helpers';
import Pagination from '../auth/components/Pagination';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';
import Loader from '../../../Loader';
import "./print.css";
import { MdLocalPrintshop } from "react-icons/md";
import AddEventAttendee from './AddEventAttendee';
import { useAuth } from '../auth';

const API_URL = process.env.REACT_APP_API_URL;


function EventAttendee() {
  const [eventuser, seteventUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState({});
  const [loading, setLoading] = useState(false);
  const rowsPerPage = 30;
  const [searchTerm, setSearchTerm] = useState("");
  const {auth}= useAuth();

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const event = useParams();
  const eventid = event.eventId;


  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function fetchData(page) {
    setLoading(true);
    axios
      .get(API_URL + `/publicaccounts/eventid=${eventid}/page=${page}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        seteventUser(response.data.pageData);
     
      })
      .catch((error) => {
        toast.error('Error fetching:', error);
        setLoading(false);
      });
  }

  const handleAttendeeCreated = () => {
    setLoading(true);
    axios
    .get(API_URL + `/publicaccounts/eventid=${eventid}/page=${currentPage}`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then((response) => {
      setLoading(false);
      seteventUser(response.data.pageData);
      
    })
    .catch((error) => {
      toast.error('Error fetching:', error);
      setLoading(false);
    });
  };

  const handleQrCodeClick = (user) => {
    setQrCodeValue({
      Name: user.name,
      Email: user.email,
      ContactNumber: user.phone,
      Company: user?.company,
      Designation: user?.designation,
    });
    openModal();
  };

  const handlePageChange = (currentPages) => {
    setCurrentPage(currentPages);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const filteredData = eventuser.filter((eventuser) =>
  eventuser.name.toLowerCase().includes((searchTerm || '').toLowerCase())
);


  const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (

    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <AddEventAttendee onAttendeeCreated={handleAttendeeCreated} />
          {isModalOpen && (
            <div className="modal fade show" style={{ display: 'block' }}>

              <div className="modal-dialog">
                <div className="modal-content">

                  <div className="cursor-pointer text-end px-10 pt-10">
                    <MdLocalPrintshop size={25} onClick={handlePrint} />
                  </div>

                  <div className="modal-header">
                    <h2>Your QR Code</h2>
                  </div>
                  <div className='card card-raised' ref={componentRef}>

                    <div className="modal-body">

                      <div className="col-md-12" >
                        <div className="form-group">

                          <div className="card-body">
                            <QRCode value={JSON.stringify(qrCodeValue, null, 2)} size={250} />
                          </div>

                          <div className="mb-2 mt-2 fs-4"> Name: {qrCodeValue?.Name} </div>
                          <div className="mb-2 fs-4"> Email: {qrCodeValue?.Email} </div>
                          <div className="mb-2 fs-4"> ContactNumber : {qrCodeValue?.ContactNumber} </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>

            </div>
          )}

          <div className='card-header border-0 pt-5'>
            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-450px ps-14'
                  placeholder='Search Users'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Attendees List</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a Attendee'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_addAttendee'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-40px'>Name</th>
                      <th className='min-w-80px'>Email</th>
                      <th className='min-w-80px'>Create Date&Time</th>
                      <th className='min-w-70px'>Contact Number</th>
                      <th className='min-w-60px'>QRCode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.slice().reverse().map((accountusers) => (
                      <tr key={accountusers.id}>
                        <td>
                          <div className='text-dark fw-bold fs-6'>{accountusers.name}</div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold fs-6'>{accountusers.email}</div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {accountusers.createdTime}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {accountusers.phone}
                          </div>
                        </td>
                        <td>
                          <button
                            className='col-9 btn btn-sm btn-primary'
                            onClick={() => handleQrCodeClick(accountusers)}
                          >
                            QRCode
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {eventuser.length > 0 &&(
                <Pagination
                  data={eventuser}
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventAttendee;
