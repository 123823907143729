/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { KTIcon } from '../../../helpers';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

function CreateTaskPriority({ onPriorityCreated }) {
    const[loading ,setLoading]=useState(false);
    const {auth} =useAuth();
    const [modalOpen, setModalOpen] = useState(false);

    const [taskPriorities, setTaskPriorities] = useState({
      prioritytype: "",
      description:"",
    })

  const handleChange = (task, field) => {
      let actualValue = task.target.value;
  
      setTaskPriorities({ ...taskPriorities, [field]: actualValue });
    };
    
    const handleSubmit = async(task) => {
      task.preventDefault();
      setLoading(true); 
      axios
        .post(API_URL+"/api/TaskPriority/create", taskPriorities, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        })
        .then((response) => response.data)
        .then((result) => {
          toast.success("Craeted Successfully");
           setLoading(false);
           setTaskPriorities({
            prioritytype: "",
            description:""
          })
           onPriorityCreated(); 
           closeModal();
        });
    };

    const closeModal = () => {
     
      setModalOpen(false);
    };

  return (
    <div>
    {loading ? (
      <Loader />
    ) : (
    <div className='modal fade' id='kt_modal_task_priority' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div onClick={closeModal}>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          </div>
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Task Priority</h1>

             
            </div>


            <div>
      <div id="layoutDrawer_content">
      

        <main>
          <div className="col-10 mx-xl-auto ps-xl-10">
             <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row"></div>
                  <br></br>

                  

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="prioritytype">Priority Name</label>
                      <input
                        className="form-control"
                        id="prioritytype"
                        type="text"
                        placeholder="Priority Name"
                        onChange={(e) => handleChange(e, "prioritytype")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </div>
                  </div>
                  <br></br>
                
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                      data-bs-dismiss='modal'
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      </div>
    </div>
    )}
    </div>
  )
}

export default CreateTaskPriority;
