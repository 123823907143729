import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import {toast} from 'react-toastify'
const API_URL = process.env.REACT_APP_API_URL;

function Whitelabeling() {
  const { eventId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
    const [Whitelabeling, setWhitelabeling] = useState({
    id:0,
    name:'',
    eventid:eventId,
    eventurl:'',
    dnsname:''
  });
  const [dnsurl, setDnsurl] = useState('');

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setWhitelabeling({ ...Whitelabeling, [field]: actualValue, eventid:eventId });
  };

  function onSubmit() {
    // Calculate the DNS URL
    const calculatedDnsurl = `https://${Whitelabeling.eventurl}.uthsava.in`;

    // Update the state with the calculated DNS URL
    setDnsurl(calculatedDnsurl);
    setWhitelabeling({ ...Whitelabeling, dnsname:calculatedDnsurl, eventid:eventId});

    if (Whitelabeling.id) {
      setLoading(true);
      axios
        .post(API_URL + `/api/domineLists/update`, Whitelabeling, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setWhitelabeling(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error(error);
          setLoading(false);
        });
    } else {
      setLoading(true);
      axios
        .post(API_URL + `/api/domineLists/create`, Whitelabeling, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setWhitelabeling(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error(error);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/domineLists/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setWhitelabeling(response.data);
        setDnsurl(response.data.eventurl);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error);
        setLoading(false);
      });
  }, [eventId]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'> White Labeling </span>
            </h3>
          </div>
          <div className='card-body py-3'>
            <div className="row mb-6">
              <label className="col-lg-2 col-form-label fw-bold fs-6">Name</label>
              <div className="col-lg-5 fv-row">
                <input
                  className="form-control"
                  placeholder="Name"
                  id="name"
                  onChange={(e) => handleChange(e, "name")}
                  value={Whitelabeling?.name}
                />
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-2 col-form-label fw-bold fs-6">URL</label>
              <div className="col-lg-5 fv-row">
                <input
                  className="form-control"
                  placeholder="URL"
                  id="eventurl"
                  onChange={(e) => handleChange(e, "eventurl")}
                  value={Whitelabeling?.eventurl}
                />
              </div>
            </div>

            <div className='fs-5 mt-2'><b>DNS URL: {dnsurl}</b></div>
          </div>

          <div className="text-end py-2 mb-4 mt-2">
            <input
              className="btn btn-primary"
              type="submit"
              disabled={loading}
              value="Submit"
              onClick={onSubmit}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Whitelabeling;