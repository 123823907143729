import React, { useState } from 'react';
import { ProfileDetails } from '../../app/modules/accounts/components/settings/cards/ProfileDetails';
import ProfileDocumentation from '../../app/modules/accounts/components/settings/cards/ProfileDocumentation';


export default function ProfileTabnav() {
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>


                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <div
                                className={`nav-link text-active-primary cursor-pointer me-6 ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => handleTabClick('tab1')}
                            >
                                Profile
                            </div>
                        </li>
                        <li className='nav-item'>
                            <div
                                className={`nav-link text-active-primary cursor-pointer me-6 ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => handleTabClick('tab2')}>

                                Documentation
                            </div>
                        </li>
                    </ul>
                </div>

                <hr />
                <div>
                    {activeTab === 'tab1' && <ProfileDetails />}
                    {activeTab === 'tab2' && <ProfileDocumentation />}
                </div>
            </div>
        </div>


    );
};
