import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth, currentUser } from '../../app/modules/auth';
import Loader from '../../Loader';
import './sub.css';
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";


const SubscriptionPermissions = ({ feature }) => {
    const [types, setType] = useState([]);
    const [loading, setLoading] = useState(false);
    const { currentUser } = useAuth();
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        if (`${feature.id}` == 1) {
            const user = currentUser !== undefined ? currentUser?.email : localStorage.getItem('user');
            setUsername(user);

            axios
                .post(API_URL + `/api/user/subscription/freetrial?username=${user}`)
                .then((res) => {
                    toast.success("Free Trial account create succesfully!");
                    setLoading(false);
                    navigate(-1);
                });
        } else {

            navigate(`/paymentDetails/${feature.type}`, { state: { feature } });
            setLoading(false);

        }
    }



    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/subscriptionpermissions/type=${feature?.type}`)
            .then(response => {
                setType(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching subscription Type:', error);
                setLoading(false);
            });
    }, [feature]);


    return (
        <div className='col-lg-12 px-10'>
            {loading ? (
                <Loader />
            ) : (


                <div className='card'>
                    <div className="hero-section">

                        <h2 className='mt-5'>Details About The Plan</h2>

                    </div>
                    <div className="plans-section px-10">
                        <div className="plan">
                            <h2>{feature?.type} - ₹ {feature?.cost}</h2>
                            <div className='col-lg-12 col-md-12'>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6'></div>
                                    <div className='col-lg-6 col-md-6'>

                                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                            <thead>
                                                <tr className='fw-bold text-muted'>

                                                    <th className='min-w-80px'>Features</th>
                                                    <th className='min-w-40px'>Permission</th>
                                                    <th className='min-w-60px'>Size</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {types.slice().map((type, index) => (
                                                    <tr key={index}>
                                                        <td>{type.feature}</td>

                                                        <td>{type.permission ? <FaCheck /> : <RxCross2 style={{ fontWeight: 'bold', fontSize: '15px' }} />}</td>
                                                        <td>{type.size}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <button className="btn btn-primary mt-4 float-end col-lg-3 col-md-4" onClick={handleSubmit}>
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>

    )
}



export default SubscriptionPermissions;
