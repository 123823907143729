import React from 'react'
import {ProfileDetails} from './cards/ProfileDetails'
import {SignInMethod} from './cards/SignInMethod'
import {DeactivateAccount} from './cards/DeactivateAccount'
import { useParams } from 'react-router-dom'
import { UserProfileDetails } from './cards/UserProfileDetails'
import { UpdateSubscription } from '../../../../../_metronic/Super/Subscriptions/SubscriptionUpdate'

export function Settings() {

  const {userid} = useParams();

  return (
    <>
      <UserProfileDetails userId={userid}/>
      <SignInMethod userId={userid}/>
      <UpdateSubscription userId={userid} />
      {/* <DeactivateAccount userId={userid} /> */}
    </>
  )
}
