import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios';
import SponsorDetails from '../SponsorDetail';
import EventFooter from '../EventFooter';
import Navbar from '../Navbar';
import Loader from '../../../../Loader';


const API_URL = process.env.REACT_APP_API_URL;

function EventSponsors() {
    const [event, seteventDetails] = useState({});
    const { eventId } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(API_URL + `/api/EventPlannings/${eventId}`)
            .then(response => {
                seteventDetails(response.data);
                //setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching eventlist', error);
                // setLoading(false);
            });
    }, [eventId]);


    const [sponsors, setSponsors] = useState([]);
    const [selectedSponsor, setSelectedSponsor] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/sponsers/eventid=${eventId}`)
            .then(response => {
                setSponsors(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching sponsors', error);
                setLoading(false);
            });

    }, [eventId]);

    const handleSponsorClick = (sponsor) => {
        setSelectedSponsor(sponsor);
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    
                    <Navbar event={event} />
                    <div className='col-xl-12 col-lg-12'>
                        <SponsorDetails sponsor={selectedSponsor} />
                        <div className='card px-20 py-20'>
                            <div className='px-10'>
                                <div className='card'>
                                    <div style={{ backgroundColor: '#728df9', color: '#fbf9f9', fontSize: '3.5rem' }} className='card-label fw-bold text-center'>
                                        Our Sponsors
                                    </div>
                                </div>
                                {/* <div style={{ fontSize: '2rem' }} className='card-label text-dark fw-bold mb-6 mt-10'>
                                    PARTNERS IN PROGRESS
                                </div> */}
                                <div className='row g-5 g-xl-10'>


                                    {sponsors.map((sponsor) => (

                                        <div key={sponsor.id} className='col-lg-3 col-md-2'>
                                            <a
                                                href='#'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_sponsor_details'
                                                onClick={() => handleSponsorClick(sponsor)}
                                            >
                                                <br />
                                               <div className='card card-xl-stretch mb-xl-8' style={{ textAlign: 'center' }}>
    <img
        src={`${sponsor?.imageMeta},${sponsor?.logo}`}
        style={{
            width: '60%',
            height: '140px',
            display: 'block',
            margin: '0 auto'
        }}
    />
    <h3
        style={{
            marginTop: '10px',  // Adds space between image and text
            fontSize: '1.5rem',
            fontWeight: 'bold'
        }}
    >
        {sponsor.name}
    </h3>
</div>



                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <EventFooter />
                </div>
            )}
        </div>
    );
}
export default EventSponsors;
