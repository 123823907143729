import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

function EditSocialMedia() {
  const [socialmedia, setSocialmedia] = useState([]);
  const [type, setType] = useState([]);
  const [Socialmedias, setSocialmedias] = useState([]);
  const [loading,setLoading]= useState(false);
  const {auth}= useAuth();

  const navigate = useNavigate();
  const { eventId } = useParams();
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const userid = data?.userid;

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(API_URL + `/api/socialmedias/eventid=${eventId}/userid=${userid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setSocialmedias(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching SocialMedias:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axios
        .get(API_URL + `/api/SocialMediaType`, {
            headers: {
                Authorization: `Bearer ${auth?.token}`,
            },
        })
        .then(response => {
          setType(response.data);
            setLoading(false);
        })
        .catch(error => {
            toast.error('Error Fetching SocialMedias:', error);
            setLoading(false);
        });
}, []);

 

  const [media, setMedia] = useState({
    socialmedia: "",
    socialmedialink: "",
  });

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setMedia((prevTask) => ({ ...prevTask, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

   
      axios
        .post(API_URL + `/api/socialmedias/update`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setSocialmedia(response.data);
         
        })
        .catch((error) => {
          console.error("Error fetching Social Media details:", error);
        });
    

    setLoading(false); 
  };

  return (
    <div>
      {loading && <div className="loading"></div>}
      <main>
        <div className="col-8 mx-xl-auto ps-xl-8">
          <div className="card card-raised">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-floating-sm mb-2">
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Edit Social Media</span>
                  </h3>
                  <br></br>
                  <select
                    id="roleid"
                    type="text"
                    placeholder="Select Social Media"
                    className="form-control"
                    onChange={(e) => handleChange(e, "socialmedia")}
                    value={type.type}
                  >
                 <div>
                  {Socialmedias.map((media)=>
                    <option>{media.socialmedia}</option>
                  )}
                    </div>
                    {type.map((ex) => (
                      <option key={ex.id} value={ex.media}>
                        {ex.media}
                      </option>
                    ))}
                  </select>
                </div>
                <br></br>
                <div className="form-floating-sm mb-2">
                  <label>Social Media Link</label>
                  <input
                    id="socialmedialink"
                    type="text"
                    className="form-control"
                    value={media.socialmedialink}
                    onChange={(e) => handleChange(e, "socialmedialink")}
                  />
                </div>
                <br></br>
                <div className="text-end">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default EditSocialMedia;
