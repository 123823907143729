import React, { useContext, useState, useEffect, useRef } from 'react';
import { Field, ErrorMessage } from 'formik';
import { KTIcon } from '../../../../../_metronic/helpers';
import WizardContext from '../WizardContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import Newuser from '../../../../../_metronic/partials/modals/Add Pages/Newuser';
import { useAuth } from '../../../auth';
import { ICreateAccount } from '../CreateAccountWizardHelper';

const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const API_URL = process.env.REACT_APP_API_URL;

interface AdminUser {
  adminid: string; // Assuming adminid is of string type
  orgid: string;
  primaryadmin: boolean;
  id: number
}

interface Step3Props {
  organizationAdmin: Array<AdminUser>; // Replace AdminUserType with the actual type
  updateOrganization: (updatedOrg: ICreateAccount) => void;

}

const Step3: React.FC<Step3Props> = ({ organizationAdmin }, { updateOrganization }: any) => {
  const { organization, setOrganization } = useContext(WizardContext);
  const [matchingNames, setMatchingNames] = useState<string[]>([]);
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>('');


  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [loadingUserId, setLoadingUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { auth } = useAuth();

  const handleNameSelect = (user: string) => {
    if (selectedUsers.includes(user)) {
      toast.info('User Already Exists');
      return;
    }
  
    setSelectedUser(user);
  
    const adminUser: AdminUser = {
      adminid: user,
      orgid: organization.id,
      primaryadmin: false,
      id: 0
    };
  
    axios
      .post(API_URL + `/api/adminorganizationmapping/create`, adminUser, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        if (response.data !== 'Admin already mapped for this organization') {
          toast.success('Admin Added');
          setOrganization((prevOrganization: any) => {
            return { ...prevOrganization, admin: response.data.adminid };
          });
           setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, user]);
        } else {
          
          toast.warning('Admin already mapped for this organization');
         // updateOrganization(organization);
        }
      })
      .catch((error) => {
        console.error('Error Adding Admin:', error); // Log the actual error for debugging
          })
      .finally(() => {
        setLoading(false);
        setSelectedUser('');
        setLoadingUserId(null);
      });
  
    setMatchingNames([]);
    setShowList(false);
  };
  
  

  const onClickhandle = (e: React.ChangeEvent<HTMLInputElement>, userid: any) => {
    const checked = e.target.checked;
    const disabled = !checked;

    setLoading(true);
    setLoadingUserId(userid);

    axios
      .get(API_URL + `/api/adminorganizationmapping/adminEnable/id=${userid}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setLoadingUserId(null);
        toast.success('Primary Admin updated successfully');
      })
      .catch((error) => {
        toast.error('Error updating Primary Admin:', error);
      });
  };



  const handleOutsideClick = (e: MouseEvent) => {
    if (searchInputRef.current && !searchInputRef.current.contains(e.target as Node)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSelectedUser(value);
    try {
      const response = await axios.get(API_URL + `/api/registrationSearch`, {
        params: {
          key: value,
        },
      });

      if (Array.isArray(response.data)) {
        const usernames = response.data.map((item: any) => item.username);
        setMatchingNames(usernames);
        setShowList(response.data.length !== 0);
      } else {
        console.error('Invalid response data format:', response.data);
      }
    } catch (error) {
      toast.error('Error fetching names: ' + error);
    }
  };

  return (
    <div className='w-100'>
      <Newuser />
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Admin Details</h2>
        {/* <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div> */}
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Organization Name</label>
        <Field
          type='text'
          name='businessName' // Use the correct Formik field name
          value={organization.name}
          onChange={(e: any) =>
            setOrganization({ ...organization, name: e.target.value })
          }
          className='form-control form-control-lg form-control-solid'
          readOnly
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='businessName' />
        </div>
      </div>

      <label className='d-flex justify-content-between align-items-center form-label mb-5'>
        Select User
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a new User'
          >
            <a
              href='#'
              className='btn btn-sm btn-light-primary float-end'
              data-bs-toggle='modal'
              data-bs-target='#kt_new_user'
            >
              <KTIcon iconName='plus' className='fs-3' />
              New User
            </a>
          </div>
        </div>
      </label>

      <div className='mb-10 fv-row' ref={searchInputRef}>
        <Field
          type='text'
          className='form-control form-control-lg'
          name='username'
          value={selectedUser}
          onChange={handleInputChange}
        />

        {showList && (
          <div className='card-toolbar'>
            {matchingNames.map((user) => (
              <div
                key={user} // Add a unique key
                className='form-control form-control-lg cursor-pointer'
                onClick={() => handleNameSelect(user)}
              >
                {user}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className='card-toolbar'>
        <label className='form-label'>Organization Admins:</label>
        <ol style={{ fontSize: '14px' }}>
          {organizationAdmin.map((user, index) => (
            <li key={index}>
              <div className='d-flex justify-content-between align-items-center'>
                <span>{user.adminid}</span>
                <div>
                  <div className='form-check form-switch form-check-inline' style={{ fontSize: '12px' }}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      defaultChecked={user.primaryadmin}
                      onClick={(e: any) => onClickhandle(e, user.id)}
                    //value={user.id}
                    />
                    <label className='form-check-label'>Toggle</label>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>

      <div className='card-toolbar'>
        <label className='form-label'>New Admins:</label>
        <ol style={{ fontSize: '14px' }}>
          {selectedUsers.map((admin, index) => (
            <div key={index}>
              <li> {admin}</li>
            </div>
          ))}

        </ol>
      </div>
    </div>
  );
};

export { Step3 };
