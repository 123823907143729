import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function ExhibitorDashboard() {
  const [active, setactive] = useState([]);
  const [Archived, setarchived] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalexhibitor, settotalexhibitor] = useState([]);
  const {currentUser, auth} = useAuth();
  const [loading, setLoading] = useState(false);
const token = localStorage.getItem('token');


  useEffect(() => {
    // setLoading(true);
    axios
      .get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/activeEvent/page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setactive(response.data.pageData);
        setCurrentPage(response.data.pages);
        // setLoading(false);
   
      })
      .catch(error => {
        console.error('Error fetching eventlist:', error);
      });
      // setLoading(false);
  }, [auth?.token]);


  useEffect(() => {

    // setLoading(true);

    axios
      .get(API_URL + `/api/exhibitorProfile/${currentUser?.email}/archiveEvent/page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setarchived(response.data.pageData); // Check if response.data is defined
        setCurrentPage(response.data.pages);
        // setLoading(false);
    
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
      // setLoading(false);
  }, [auth?.token]);

  

  useEffect(() => {
    const role = 'ROLE_EXHIBITOR';

    axios
      .get(`${API_URL}/api/authority/username=${currentUser?.email}/role=${role}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        settotalexhibitor(response.data);
    
      })
      .catch((error) => {
        console.error('Error fetching EventRole:', error);
      });
  }, [token]);


  return (
    <div >

      {/* <h1 className="text-center">{eventlist.title}</h1> */}
      <br></br>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-6' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8  bg-success'>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{active.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center' style={{ fontSize:'23px' }}>
                Number of Active Events
              </h3>
            </div>
          </div>
        </div>
        <div className='col-xl-6' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8 bg-danger '>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{Archived.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center 'style={{ fontSize: '23px'}}>
                Number of Archived Events
              </h3>
            </div>
          </div>
        </div>
        {/* <div className='col-xl-4' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8 bg-warning '>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{totalexhibitor.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center'>
                Total Number of Events
              </h3>
            </div>
          </div>
        </div> */}
      </div>
      <br />

    </div>


  );
}



export default ExhibitorDashboard;