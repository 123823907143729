/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { KTIcon } from '../../../helpers';
import { Step1 } from '../create-app-stepper/steps/Step1';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const Newuser = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [emailError, setEmailError] = useState('');

  const [Step1, SetStep1] = useState({
    email: '',
    firstname: '',
    lastname: '',
    contact: '',
  });

  // Contact validation states
  const [contact, setContact] = useState('');
  const [contactError, setContactError] = useState('');

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    SetStep1({ ...Step1, [field]: actualValue });

    // Email validation
    if (field === 'email') {
      if (!isValidEmail(actualValue)) {
        setEmailError('Invalid email address');
      } else {
        setEmailError('');
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (!isValidEmail(Step1.email)) {
    //   setEmailError('Invalid email address');
    //   return;
    // }

    if (!/^\d{10}$/.test(contact)) {
      setContactError('Contact must be 10 digits');
      return;
    }

    axios
      .post(API_URL + '/api/createNewUser/createUser', { ...Step1, contact }, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success('User created successfully');
         SetStep1({
          email: '',
          firstname: '',
          lastname: '',
          contact: '',
        });
        setContact('');
        setContactError('');

        setLoading(false);
      });
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/;
    return emailPattern.test(email);
  };

  return (
    <div className="modal fade" id="kt_new_user" aria-hidden="true">
      <div className="modal-dialog mw-650px">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Add New User</h1>
            </div>

            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-12 mx-xl-auto ps-xl-12">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>
                      <br></br>

                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="email">Email </label>
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            placeholder="Email"
                            onChange={(e) => handleChange(e, "email")}
                            required
                          />
                          {emailError && (
                            <span className="text-danger">{emailError}</span>
                          )}
                        </div>

                      </div>
                
                  <br></br>

                 

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="firstname">FirstName</label>
                      <input
                        className="form-control"
                        id="firstname"
                        type="text"
                        placeholder="FirstName"
                        onChange={(e) => handleChange(e, "firstname")}
                      />
                    </div>
                  </div>
                  <br></br>


                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="lastname">LastName</label>
                      <input
                        className="form-control"
                        id="lastname"
                        type="text"
                        placeholder="LastName"
                        onChange={(e) => handleChange(e, "lastname")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contact">Contact Number</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        placeholder="Contact Number"
                        value={contact}
                        onChange={(e) => {
                          const value = e.target.value;
                          setContact(value);
                          // Add validation for 10 digits
                          if (!/^\d{10}$/.test(value)) {
                            setContactError('Contact must be 10 digits');
                          } else {
                            setContactError('');
                          }
                        }}
                      />
                      {contactError && (
                        <span className="text-danger">{contactError}</span>
                      )}
                    </div>
                  </div>
                  <br></br>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading || contactError}
                      value="Submit"
                      data-bs-dismiss="modal"
                    />
                  </div>
                </form>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
      </div >
    </div >
  );
};

export default Newuser;
