import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel, NotificationModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken , getUserNotification} from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import {toast} from "react-toastify";

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  notification: NotificationModel[]; // Correct the type here
  setNotification: Dispatch<SetStateAction<NotificationModel[]>>;
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  notification: [],
  setNotification:()=>{},
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const path = localStorage.getItem("loginpath");
  const [notification, setNotification] = useState<NotificationModel[]>([]); // Correct the type here

  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {

    if (path === "/userEvents") {
      saveAuth(undefined)
      setCurrentUser(undefined)
      setNotification([])
      localStorage.removeItem("loginpath");
    } else {
      saveAuth(undefined)
      setCurrentUser(undefined)
      setNotification([])
      handleLogoutNavigation(path)
      localStorage.removeItem("loginpath");
    }
  };

  const handleLogoutNavigation = (path: string | null) => {
    if (path) {
      window.location.href = path;

    } else {
      console.error("Invalid path during logout");
    }
  };

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, notification, setNotification, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser, notification, setNotification } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
            const response = await getUserNotification(apiToken);
             setNotification(response.data);
        }
          }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.token) {
      requestUser(auth.token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
