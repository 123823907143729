import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditTask() {
  const [events, setEvents] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { taskid,eventId } = useParams();
  const {auth} = useParams();
  const [tasklist, setTasklist] = useState({
    eventid: eventId,
    taskname: "",
    taskpriorityid: "",
    duedate: "",
    taskmaneger: "",
    taskassignedto: "",
    taskstatus: "",
    description: "",
  });

  useEffect(() => {
    axios
      .get(API_URL + `/api/TaskPriority`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPriority(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Task Priorities. Please try again.';
        toast.error(errorMessage);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(API_URL + `/api/Task/${taskid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setTasklist(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Task. Please try again.';
        toast.error(errorMessage);
      });
  }, [taskid, token]); // Include taskid as a dependency

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setTasklist((prevTasklist) => ({ ...prevTasklist, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + `/api/Task/update`, tasklist, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Task Updated");
        navigate(`/tasklist/${eventId}`);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to update Task. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="taskname">Task Id</label>
                      <input
                        className="form-control"
                        id="id"
                        type="text"
                        value={tasklist.id}
                        placeholder="Task name"
                        readOnly
                       // onChange={(e) => handleChange(e, "id")}
                      />
                    </div>
                  </div>
                  <br></br>


                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="taskname">Task Name</label>
                      <input
                        className="form-control"
                        id="taskname"
                        type="text"
                        value={tasklist.taskname}
                        placeholder="Task name"
                        onChange={(e) => handleChange(e, "taskname")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label>Task Priority</label>
                      <select
                        id="taskpriorityid"
                        type="text"
                        placeholder="Select event"
                        value={tasklist.taskpriorityid}
                        className="form-control"
                        onChange={(e) => handleChange(e, "taskpriorityid")}
                      >
                        <option value="">Select</option>
                        {priority.map((pr) => (
                          <option key={pr.id} value={pr.prioritytype}>
                            {pr.prioritytype}
                          </option>
                        ))}
                      </select>{" "}
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Description"
                        value={tasklist.description}
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="taskassignedto">Task Assigned To</label>
                      <input
                        className="form-control"
                        id="taskassignedto"
                        type="text"
                        value={tasklist.taskassignedto}
                        placeholder="Task assigned to"
                        onChange={(e) => handleChange(e, "taskassignedto")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="duedate">Due Date</label>
                      <input
                        className="form-control"
                        id="duedate"
                        type="date"
                        placeholder="Due Date"
                        value={tasklist.duedate}
                        onChange={(e) => handleChange(e, "duedate")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <br></br>

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditTask;
