import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "../../../Loader";
import { useAuth } from "../../../app/modules/auth";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");


function EditActivity() {
  const[loading ,setLoading]=useState(false);
  const {activityid}= useParams();
  const {auth} =useAuth();
  const [activity, setActivity] = useState({
    type: "",
   
  })


  
  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/ActivityType/${activityid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setActivity(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error fetching Activity Types. Please try again.';
       toast.error(errorMessage);
        setLoading(false);
      });
  }, [activityid, token]); // Include taskid as a dependency


  const navigate = useNavigate();

  const handleChange = (task, field) => {
    let actualValue = task.target.value;

    setActivity({ ...activity, [field]: actualValue });
  };
  const handleSubmit = async(task) => {
    task.preventDefault();
    setLoading(true); 

    axios
      .post(API_URL + "/api/ActivityType/update", activity, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Activity updated successfully");
  
                navigate("/activitytype");
                setLoading(false);

      })
      .catch((error) => {
        const errorMessage = 'Error Updating Activity Types. Please try again.';
       toast.error(errorMessage);
        setLoading(false);
      });
  };
      
      

 

  return (
    <div>
    {loading ? (
      <Loader />
    ) : (
    <div>
   
    <div id="layoutDrawer_content">

{loading && <div className="loading"></div>}

<main>

<div className="col-8 mx-xl-auto ps-xl-8">

<div className="card card-raised ">
<div className="card-body ">
   
  
        <h1 className="card-title">Activity Type</h1>
        <br></br>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="type">Activity Type</label>
              <input
                className="form-control "
                id="type"
                type="text"
                placeholder="Type"
                value={activity.type}
                onChange={(e) => handleChange(e, "type")}
                
              />
            </div>
          </div>

        <br></br>
       
       
              
          <div className="text-end">
            <input
              className="btn btn-primary"
              type="submit"
              disabled={loading}

              value="Submit"
            />
          </div>
        </form>
      </div>
    </div>
  </div> 
   
  
   </main>
   </div>
   </div>
    )}
    </div>
  );
}


  
  

export default EditActivity;



      