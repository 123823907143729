
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';

const CreateStallExhibitor = ({ onExhibitorCreated, eventId }) => {
  const [stalltype, setStallatype] = useState([]);
  const [stall, setStall] = useState([]);
  const { auth } = useAuth();
  const [exibitors, setExibitors] = useState([]);
  const [filteredExhibitors, setFilteredExhibitors] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [exhibitorStall, setExhibitorStall] = useState({
    eventid: eventId,
    stallId: '',
    stalltype: '',
    exhibitor: '',
    paymentStatus: '',
    cost: '',
    referenceNo: ''
  });
  const API_URL = process.env.REACT_APP_API_URL;

  const closeModal = () => {
    setModalOpen(false);
  };
  
  useEffect(() => {
    axios
      .get(API_URL + '/api/StallType', {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setStallatype(response.data);
      })
      .catch((error) => {
        toast.error(error.errorMessage);
      });

    axios
      .get(API_URL + `/api/authority/eventid=${eventId}/role=ROLE_EXHIBITOR`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setExibitors(response.data);
      })
      .catch((error) => {
        toast.error(error.errorMessage);
      });

  }, [auth?.token]);

  const handleNameSelect = (user) => {
    setSelectedUser(user);
    setFilteredExhibitors([]);
    setExhibitorStall((prev) => ({ ...prev, exhibitor: user }));
  };

  const handleChange = async (field, value) => {
    setExhibitorStall({ ...exhibitorStall, [field]: value });
    try {
      if (field === 'stalltype' && value) {
        const response = await axios.get(
          `${API_URL}/api/StallChart/${eventId}/${value}/false`,
          {
            headers: { Authorization: `Bearer ${auth?.token}` },
          }
        );
        setStall(response.data);
      }
      // Fetch cost based on stallid and update the cost field
      if (field === 'stallId' && value) {
        const selectedStall = stall.find((s) => s.stallid === value);
        if (selectedStall) {
          setExhibitorStall((prev) => ({ ...prev, cost: selectedStall.cost }));
        }
      }
    } catch (error) {
      toast.error(error.errorMessage);
    }
  };

  const handleExhibitorFilter = (inputValue) => {
    setSelectedUser(inputValue);
    const filtered = exibitors.filter((exhibitor) =>
      exhibitor.username.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredExhibitors(filtered);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Submitting exhibitorStall:', exhibitorStall); // Log the data being sent

    axios
      .post(API_URL + '/api/stallexhibitormapping/addExibitor', exhibitorStall, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => {
        console.log('Server response:', response.data); // Log the response from the server
        toast.success('Stall Exhibitor Created Successfully');
        onExhibitorCreated();
        closeModal();
        // Implement your modal close logic here
      })
      .catch((error) => {
        console.error('Error adding exhibitor:', error); // Log any errors
        toast.error(error.errorMessage);
      });
  };

  return (
    <div className='modal fade' id='kt_modal_stall_exhibitor' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          {/* Modal body */}
          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Create Stall Exhibitor</h1>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit}>
              {/* Stall Type */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label>Stall Type</label>
                  <select
                    id='stalltype'
                    className='form-control'
                    onChange={(e) => handleChange('stalltype', e.target.value)}
                  >
                    <option>Select...</option>
                    {stalltype.map((ex) => (
                      <option key={ex.id} value={ex.stallType}>
                        {ex.stallType}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Stall Id */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label htmlFor='stallId'>Stall Number</label>
                  <select
                    id='stallId'
                    className='form-control'
                    onChange={(e) => handleChange('stallId', e.target.value)}
                  >
                    <option>Select...</option>
                    {stall.map((ex) => (
                      <option key={ex.id} value={ex.stallid}>
                        {ex.stallid}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Cost */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label htmlFor='cost'>Cost</label>
                  <input
                    className='form-control'
                    id='cost'
                    type='text'
                    placeholder='Cost'
                    value={exhibitorStall?.cost}
                    onChange={(e) => handleChange('cost', e.target.value)}
                  />
                </div>
              </div>

              {/* Exhibitor Name */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label>Exhibitor Name</label>
                  <input
                    className='form-control'
                    id='exhibitorId'
                    type='text'
                    placeholder='Exhibitor Name'
                    value={selectedUser}
                    onChange={(e) => {
                      //   handleChange('exhibitorId', e.target.value);
                      handleExhibitorFilter(e.target.value);
                    }}
                  />
                  {/* Display filtered exhibitors */}
                  {filteredExhibitors.map((exhibitor) => (
                    <div
                      className='form-control cursor-pointer text-hover-primary'
                      onClick={() => handleNameSelect(exhibitor.username)}
                      key={exhibitor.id}
                    >
                      {exhibitor.username}
                    </div>
                  ))}
                </div>
              </div>

              {/* Payment Status */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label>Payment Status</label>
                  <select
                    id='paymentStatus'
                    className='form-control'
                    value={exhibitorStall.paymentStatus}
                    onChange={(e) => handleChange('paymentStatus', e.target.value)}
                  >
                    <option value='SUCCESS'>Success</option>
                    <option value='FAILED'>Failed</option>
                  </select>
                </div>
              </div>

              {/* Reference Code */}
              <div className='row'>
                <div className='form-floating-sm mb-2'>
                  <label>Reference Code</label>
                  <input
                    className='form-control'
                    id='referenceNo'
                    type='text'
                    placeholder='Reference Code'
                    onChange={(e) => handleChange('referenceNo', e.target.value)}
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className='text-end'>
                <button className='btn btn-primary' type='submit' data-bs-dismiss='modal' >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateStallExhibitor;
