import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { toast } from 'react-toastify';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import CreateSpeaker from '../../../partials/modals/Add Pages/CreateSpeaker';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";


const API_URL = process.env.REACT_APP_API_URL;
function SpeakerMngnt() {
  const [speaker, setspeaker] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 16;
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { eventId } = useParams();
  const { auth } = useAuth();

  const websiteClick = (url) => {
    if (url) {
      window.open(`https://${url}`, '_blank');
    }
  };

  const removeClick = (speakerId) => {
    setLoading(true);

    // Check if the speaker is associated with any sessions
    axios
      .get(API_URL + `/api/Sessioncalendar/eventid=${eventId}/userid=${speakerId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        const sessions = response.data;

        if (sessions.length > 0) {
          // Speaker is associated with sessions, show confirmation dialog
          const confirmation = window.confirm(
            `This speaker is associated with another sessions. Are you sure you want to delete?`
          );

          if (!confirmation) {
            // User cancelled the deletion
            setLoading(false);
            return;
          }
        }

        // If user confirmed or the speaker is not associated with any sessions, proceed with deletion
        axios
          .post(API_URL + `/api/EventSpeakers/delete/${speakerId}`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then((response) => {
            toast.success("Successfully Removed");
            handleSpeakerCreated();
            setLoading(false);
          })
          .catch((error) => {
            toast.error('Error deleting Speakers', error);
            setLoading(false);
          });
      })
      .catch((error) => {
        toast.error('Error checking speaker sessions', error);
        setLoading(false);
      });
  };


  // const removeClick = (speakerid) => {
  //   setLoading(true);
  //   axios
  //     .post(API_URL + `/api/EventSpeakers/delete/${speakerid}`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //       },
  //     })
  //     .then(response => {
  //       toast.success("Successfully Removed");
  //       handleSpeakerCreated();
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       toast.error('Error deleting Speakers', error);
  //       setLoading(false);
  //     });
  // }

  const handleSpeakerCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/EventSpeakers/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setspeaker(response.data);

        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching Speaker:', error);
        setLoading(false);
      });
  }


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/EventSpeakers/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setspeaker(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching Speaker', error);
        setLoading(false);
      });
  }, []);


  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const filteredData = speaker.filter((speaker) =>
    speaker.name.toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='row'>
          <CreateSpeaker onSpeakerCreated={handleSpeakerCreated} eventId={eventId} />
          <h1>Speakers</h1>
          <div className='card-header border-0 pt-5'>

            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-450px ps-14'
                  placeholder='Search Users'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

            </div>

          </div>


          <div className='m-2'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary float-end mt-2'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_speaker'
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add
            </a>
          </div>


          {paginatedData.map((speaker) => (
            <div className='col-lg-4 col-md-2'>
              <div className='card card-xl-stretch mb-xl-8'>

                <div className='mx-4 mt-3'>
                  <div className='card-toolbar float-end'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='float-end'
                    >
                      <Link
                        to={`/editspeaker/${eventId}/${speaker.id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-2' />
                      </Link>
                    </button>

                    <button
                      type='button'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={() => removeClick(speaker?.id)}
                    >
                      <RiDeleteBin6Line className='fs-3' />
                    </button>

                  </div>
                </div>

                <div key={speaker.id} className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '250px' }}>


                  <div className='image-container d-flex align-items-center justify-content-center'>

                    <img
                      src={`${speaker.imageMeta},${speaker.image}`}
                      style={{
                        width: '100%',
                        height: '100px',
                        borderRadius: '20%',
                      }}
                    />
                  </div>


                  <div className='text-dark fw-bold d-block mt-2 mb-1 fs-4'>
                    {speaker.name}
                  </div>
                  <div className='mb-1'>
                    {speaker.company}
                  </div >
                  <div className='mb-1'>
                    {speaker.designation}
                  </div>
                  <div onClick={() => websiteClick(speaker?.linkedinId)} className='text-primary mt-1 text-center' style={{ cursor: 'pointer' }}>
                    {speaker?.linkedinId}
                  </div>


                </div>
              </div>
            </div>
          ))}

          {speaker.length > 0 && (
            <Pagination
              data={speaker}
              itemsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              currentPage={page}
            />
          )}

        </div>

      )}
    </div>
  );
}

export default SpeakerMngnt;
