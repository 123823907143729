import React from 'react';

function Aprovalrequest({ user, closeModal }) {
  return (
    <div className="modal fade show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">User Details</h3>
            <button type="button" className="btn-close" onClick={closeModal}></button>
          </div>
          <div className="modal-body">
        
          <div className='fs-4 pb-3'><b>Username:</b> {user.username}</div>
          <div className='fs-4 pb-3'><b>PAN:</b> {user.pan}</div>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aprovalrequest;
