import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

export default function RTE({ eventId, article }) {
  const [loading, setLoading] = useState(false);
  const { currentUser, auth } = useAuth();
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [articles, setArticle] = useState(
    {
      id: 0,
      eventid: eventId,
      description: "",
      dateTime: "",
      subject: "",
      userid: currentUser?.email
    }
  );

  const handleChange = (e) => {
    const actualValue = e.target.value;
    setArticle((articles) => ({ ...articles, subject: actualValue }));
  };

  useEffect(() => {
    if (article) {
      setLoading(true);
      axios
        .get(API_URL + `/api/eventarticles/${article}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setArticle(response.data);
          setLoading(false);
        })
        .catch(error => {
          toast.error('Error fetching article', error);
          setLoading(false);
        });
    }
  },
    [article, auth?.token]);


  const handleSubmit = () => {
    const content = editorRef.current?.getContent();
    if (content) {
      if (article) {
        handleUpdateArticle(content);
      } else {
        handleMediaCreated(content);
      }
    }
  };

  const handleMediaCreated = (content) => {
    setLoading(true);
    const newArticle = {
      ...articles,
      description: content,
    };

    console.log(newArticle);
    axios
      .post(API_URL + `/api/eventarticles/create`, newArticle, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setArticle(response.data);
        setLoading(false);
        toast.success('Article created successfully');
        navigate(`/eventArticles/${articles.eventid}`);
      })
      .catch(error => {
        toast.error('Error creating article', error);
        setLoading(false);
      });
  };

  const handleUpdateArticle = (content) => {
    setLoading(true);
    const updatedArticle = {
      ...articles,
      description: content,
    };

    axios
      .post(API_URL + `/api/eventarticles/update`, updatedArticle, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setArticle(response.data);
        setLoading(false);
        toast.success('Article updated successfully');
        navigate(`/eventArticles/${articles.eventid}`);
      })
      .catch(error => {
        toast.error('Error creating article', error);
        setLoading(false);
      });
  };



  return (
    <div className="container">

      {loading ? (
        <Loader />
      ) : (
        <>

          <div className="col-lg-6 col-md-12">

            <input
              className="form-control"
              type="text"
              value={articles?.subject}
              id="sessionname"
              onChange={(e) => handleChange(e, "subject")}
              required
              placeholder="Enter Subject"
            />
          </div>
          <br />
          <Editor
            initialValue={articles.description}
            apiKey='csgeev86vab9rod8ht46ztlg3onestfi2jrm828yt1etqtcf'
            init={{

              plugins: 'ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
              toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
              ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
              onSave: (editor) => {
                const content = editor.getContent();
                handleMediaCreated(content);
              },
            }}
            onInit={(evt, editor) => {
              editorRef.current = editor; // Set the editor instance in the ref
            }}
          />
          <button className='btn btn-lg btn-light-primary float-end mt-8' onClick={handleSubmit}>Submit</button>

        </>


      )
      }
    </div>

  );
}

