import * as React from 'react';
import ProductCategories from '../Public/ProductCategories';
import ProductSmokingHero from '../Public/ProductSmokingHero';
import ProductHero from '../Public/ProductHero';
import ProductValues from '../Public/ProductValues';
import ProductHowItWorks from '../Public/ProductHowItWorks';
import ProductCTA from '../Public/ProductCTA';
import withRoot from '../Public/withRoot';

function PublicHome() {
  return (
    <React.Fragment>
      
      <ProductHero />
      <ProductValues />
      {/* <ProductCategories /> */}
      <ProductHowItWorks />
      <ProductCTA />
      <ProductSmokingHero />
      
    </React.Fragment>
  );
}

export default withRoot(PublicHome);