import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Loader';
import { toast } from "react-toastify";
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import { Link } from 'react-router-dom';
import AddSponsorType from '../../../partials/modals/Add Pages/AddSponsorType';

const API_URL = process.env.REACT_APP_API_URL;

function SponsorTypeList() {
    const [Sponsor, setSponsor] = useState([]);
    console.log(Sponsor);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const { eventId } = useParams();
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [loadingUserId, setLoadingUserId] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    function onClickhandler(e, typeid) {
        const checked = e.target.checked;
        const status = checked;
        setLoadingUserId(typeid);

        axios
            .get(API_URL + `/api/sponsertype/id=${typeid}/value=${status}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setLoadingUserId(null);
                toast.success("Updated Successfully");
                setUpdateFlag((prevFlag) => !prevFlag);
            })
            .catch((error) => {
                const errorMessage = 'Failed to update Sponsor Type Actions. Please try again.';
                toast.error(errorMessage);
                setLoadingUserId(null);
            });
    }

    const handleExCreated = () => {
        setLoading(true);
        axios
            .get(API_URL + `/api/sponsertype`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setSponsor(response.data);
                console.log(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching Sponsor Type', error);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    function fetchData() {
        setLoading(true);
        axios
            .get(API_URL + `/api/sponsertype`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setSponsor(response.data);
                
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching Sponsor Types', error);
                setLoading(false);
            });
    }

    const handlePageChange = (selectedPage) => {
        setPage(selectedPage);
    };

    const filteredData = Sponsor.filter((sponsor) =>
        sponsor.sponsertype.toLowerCase().includes((searchTerm || '').toLowerCase())
    );

    const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className='card card-raised'>
                    <AddSponsorType event={eventId} onSponsorCreated={handleExCreated} />
                    <div className='card-header border-0 pt-5'>
                        <div className='card-title'>
                            <div className='d-flex align-items-center position-relative my-1'>
                                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                                <input
                                    type='text'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control w-450px ps-14'
                                    placeholder='Search Users'
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bold fs-3 mb-1'> Sponsors Type </span>
                        </h3>
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a user'
                        >
                            <a
                                href='#'
                                className='btn btn-sm btn-light-primary float-end'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_sponsorstype'
                            >
                                <KTIcon iconName='plus' className='fs-3' />
                                Add
                            </a>
                        </div>
                    </div>
                    <div className='card-toolbar'></div>
                    <div className='card-toolbar'>
                        <div className='card-body py-3'>
                            <div className='table-responsive'>
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='w-25px'>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'></div>
                                            </th>
                                            <th className='min-w-200px'>Sponsors Type</th>
                                            <th className='min-w-80px '>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.slice().reverse().map((sponsor) => (
                                            <tr key={sponsor.id}>
                                                <td></td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {sponsor?.sponsertype}
                                                    </div>
                                                </td>
                                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                                                        <input
                                                            className="form-check-input"
                                                            id={`flexSwitchCheck-${sponsor.id}`}
                                                            type="checkbox"
                                                            checked={sponsor.status}
                                                            onChange={(e) => onClickhandler(e, sponsor.id)}
                                                            value={sponsor.id}
                                                        />
                                                        {loadingUserId === sponsor.id && (
                                                            <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                                                        )}
                                                    </div>

                                                   
                            <Link
                            to={`/EditSponsorType/${eventId}/${sponsor.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                                {Sponsor.length > 0 && (
                                    <Pagination
                                        data={Sponsor}
                                        itemsPerPage={rowsPerPage}
                                        onPageChange={handlePageChange}
                                        currentPage={page}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SponsorTypeList;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Pagination from '../../../../app/modules/auth/components/Pagination';
// import { useParams, Link } from 'react-router-dom';
// import Loader from '../../../../Loader';
// import { toast } from "react-toastify";
// import { KTIcon } from '../../../helpers';
// import { useAuth } from '../../../../app/modules/auth';
// import AddSponsorType from '../../../partials/modals/Add Pages/AddSponsorType';
// import EditSponsorType from '../../../organization/events/Sponsors/EditSponsorType'; // Import EditSponsorType component

// const API_URL = process.env.REACT_APP_API_URL;

// function SponsorTypeList() {
//     const [sponsorTypes, setSponsorTypes] = useState([]);
//     const [page, setPage] = useState(1);
//     const rowsPerPage = 30;
//     const { eventId } = useParams();
//     const { auth } = useAuth();
//     const [loading, setLoading] = useState(false);
//     const [searchTerm, setSearchTerm] = useState("");

//     useEffect(() => {
//         fetchData();
//     }, []);

//     const fetchData = () => {
//         setLoading(true);
//         axios
//             .get(API_URL + `/api/sponsortypes`, {
//                 headers: {
//                     Authorization: `Bearer ${auth?.token}`,
//                 },
//             })
//             .then(response => {
//                 setSponsorTypes(response.data);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 toast.error('Error fetching Sponsor Types. Please try again.');
//                 setLoading(false);
//             });
//     };

//     const handlePageChange = (selectedPage) => {
//         setPage(selectedPage);
//     };

//     const filteredData = sponsorTypes.filter((sponsorType) =>
//         sponsorType.sponsorType.toLowerCase().includes((searchTerm || '').toLowerCase())
//     );

//     const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

//     return (
//         <div>
//             {loading ? (
//                 <Loader />
//             ) : (
//                 <div className='card card-raised'>
//                     <AddSponsorType event={eventId} onSponsorCreated={fetchData} />
//                     <div className='card-header border-0 pt-5'>
//                         <div className='card-title'>
//                             <div className='d-flex align-items-center position-relative my-1'>
//                                 <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
//                                 <input
//                                     type='text'
//                                     className='form-control form-control w-450px ps-14'
//                                     placeholder='Search Sponsor Types'
//                                     value={searchTerm}
//                                     onChange={(e) => setSearchTerm(e.target.value)}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                     <div className='card-header border-0 pt-5'>
//                         <h3 className='card-title align-items-start flex-column'>
//                             <span className='card-label fw-bold fs-3 mb-1'>Sponsors Type</span>
//                         </h3>
//                     </div>
//                     <div className='card-toolbar'></div>
//                     <div className='card-toolbar'>
//                         <div className='card-body py-3'>
//                             <div className='table-responsive'>
//                                 <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
//                                     <thead>
//                                         <tr className='fw-bold text-muted'>
//                                             <th className='min-w-200px'>Sponsor Type</th>
//                                             <th className='min-w-80px'>Actions</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {paginatedData.map((sponsorType) => (
//                                             <tr key={sponsorType.id}>
//                                                 <td>
//                                                     <div className='text-dark fw-bold d-block mb-1 fs-6'>
//                                                         {sponsorType.sponsorType}
//                                                     </div>
//                                                 </td>
//                                                 <td style={{ display: 'flex', alignItems: 'center' }}>
//                                                     <Link
//                                                         to={`/editsponsortype/${eventId}/${sponsorType.id}`}
//                                                         className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
//                                                     >
//                                                         <KTIcon iconName='pencil' className='fs-3' />
//                                                     </Link>
//                                                 </td>
//                                             </tr>
//                                         ))}
//                                     </tbody>
//                                 </table>
//                                 {sponsorTypes.length > 0 && (
//                                     <Pagination
//                                         data={sponsorTypes}
//                                         itemsPerPage={rowsPerPage}
//                                         onPageChange={handlePageChange}
//                                         currentPage={page}
//                                     />
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// }

// export default SponsorTypeList;
