/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;

function AddSponsorType({ onSponsorCreated, eventId }) {
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [data, setData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [sponser, setsponser] = useState({
    id: "",
    sponsertype: "",
    disabled: "",
    eventid: eventId,

  })    

 


  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    if (field === "isTrue") {
      actualValue = event.target.value === "true";
    }
    setsponser({ ...sponser, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + "/api/sponsertype/create", sponser, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Created Succesfully");
        setLoading(false);
        onSponsorCreated();
        closeModal();
      });
  };


  const closeModal = () => {
    setModalOpen(false);
  };

  


  return (
    <div className='modal fade' id='kt_modal_sponsorstype' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Create Sponsors Type</h1>



            </div>


            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-10 mx-xl-auto ps-xl-10">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>


                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="sponsertype"> Sponsor Type</label>
                          <input
                            className="form-control"
                            id="sponsertype"
                            type="text"
                            placeholder="Sponser Type"
                            onChange={(e) => handleChange(e, "sponsertype")}
                          />
                        </div>
                      </div>

                      <br></br>

                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                          data-bs-dismiss='modal'
                        />
                      </div>
                    </form>
                  </div>

                </main>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default AddSponsorType;
