/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import { KTIcon } from '../../../helpers';



const CreateUspSeek = ({onUSPSeekCreated}) => {
   
    const [loading, setLoading] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
  
    const token = localStorage.getItem('token');
  
    const API_URL = process.env.REACT_APP_API_URL;
    const [orgAdmin, setOrgAdmin] = useState({
        id:"",
        name:"",
       
    });
  
    const handleChange = (event, field) => {
      let actualValue = event.target.value;
      if (field === "isTrue") {
        actualValue = event.target.value === "true";
      }
      setOrgAdmin({ ...orgAdmin, [field]: actualValue });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      axios
        .post(API_URL + "/api/UspSeek/create", orgAdmin, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data)
        .then((result) => {
            toast.success("Usp seek Created Successfully");
  
          setLoading(false);
          onUSPSeekCreated(); 
           closeModal();
        });
    };

    const closeModal = () => {
      setModalOpen(false);
    };
  return (
    <div className='modal fade' id='kt_modal_ActivityType' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add USPSeek</h1>
              <span className='card-label fs-5 mb-1'style={{color:'orange'}}>( What Are You Looking For )</span>

            </div>


      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-10 mx-xl-auto ps-xl-10">
             
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

               
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      
    </div>
  )
}

export default CreateUspSeek;
