import React, { useEffect, useState } from 'react';
import EventVenue from './EventVenue';
import { SlLocationPin, SlEnvelopeOpen } from 'react-icons/sl';
import { RiPhoneLine } from 'react-icons/ri';
import { BsCalendarDate } from 'react-icons/bs';
import { FaDirections } from "react-icons/fa";
import PublicAdver from './Event/PublicAdver';

const MapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export default function Venue({ event }) {
  const [coordinate, setCoordinate] = useState({
    lat: 0,
    lng: 0,
  });

  const address = event?.location;

  useEffect(() => {
    if (event) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${MapsApiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'OK') {
            const { lat, lng } = data.results[0].geometry.location;
            setCoordinate({ lat, lng });
          } else {
          console.error('Geocoding failed:', data.status);
          }
        });
    }
  }, [event]);

  const handleGetDirections = () => {
    if (coordinate.lat !== 0 && coordinate.lng !== 0) {
      // Open Google Maps with directions
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${coordinate.lat},${coordinate.lng}`, '_blank');
    } else {
      console.error('Coordinates not available');
    }
  };

  return (
    <div>
      <div className="col-12">
        <div
          className="card"
          style={{
            backgroundColor: '#728df9',
            color: '#fbf9f9',
            fontSize: '1.3rem',
          }}
        >
          <div
            className="card-title d-flex flex-column"
            style={{
              height: '100%',
              marginTop: '40px',
              marginLeft: '90px', // Decreased left margin for mobile
              marginRight: '50px', // Decreased right margin for mobile
              marginBottom: '30px',
            }}
          >
            <h2 style={{ color: '#fbf9f9', fontSize: '2rem' }}>Our Location</h2>
            <span>Our location and how you can get here</span>

            <div className="pt-15">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-md-8">
                    <EventVenue event={coordinate} />
                  </div>
                  <div className="card col-12 col-md-4" style={{ color: '#140404' }}>
                    <div className="pt-10 px-4">
                   <div onClick={handleGetDirections} style={{ cursor: 'pointer' }} className='text-end'> Get Direction    <FaDirections className='text-primary fs-1' /></div>
                      <SlLocationPin className="text-primary fs-1" />
                      <p>{event?.location}</p>
                     
                    </div>
                    <div className="pt-4 px-4">
                      <RiPhoneLine className="text-primary fs-1" />
                      <p>{event?.phone}</p>
                    </div>
                    <div className="pt-4 px-4">
                      <SlEnvelopeOpen className="text-primary fs-1" />
                       <p>{event?.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <PublicAdver/>
    </div>
   
  );
}
