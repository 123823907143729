import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  useParams } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;
  

function ExhibitorEventDashboard(){
  const [eventlist, seteventlist] = useState([]);
  const [RoleUsers, setroleusers] = useState([]);
  const [commitee,SetCommitee] = useState([]);
  const [volunteer,SetVolunteer] = useState([]);
  const {eventId,auth}= useParams();
  const token = localStorage.getItem('token');


        useEffect(() => {
          axios
            .get(API_URL + `/api/EventPlannings/${eventId}`, {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            })
            .then(response => {
              seteventlist(response.data);
             
            })
            .catch(error => {
              console.error('Error fetching eventlist:', error);
            });
        }, [token]);


        useEffect(() => {

        const role="ROLE_EXHIBITOR";

          axios
            .get(API_URL +`/api/authority/eventid=${eventId}/role=${role}` , {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            })
            .then(response => {
              setroleusers(response.data);
            })
            .catch(error => {
              console.error('Error fetching EventRole:', error);
            });
        }, [token]);

        useEffect(() => {

          const role="ROLE_COMMITEE_MEMBER";
  
            axios
              .get(API_URL +`/api/authority/eventid=${eventId}/role=${role}` , {
                headers: {
                  Authorization: `Bearer ${auth?.token}`,
                },
              })
              .then(response => {
                SetCommitee(response.data);
              })
              .catch(error => {
                console.error('Error fetching EventRole:', error);
              });
          }, [token]);

          useEffect(() => {

            const role="ROLE_VOLUNTER";
    
              axios
                .get(API_URL +`/api/authority/eventid=${eventId}/role=${role}` , {
                  headers: {
                    Authorization: `Bearer ${auth?.token}`,
                  },
                })
                .then(response => {
                  SetVolunteer(response.data);
                 })
                .catch(error => {
                  console.error('Error fetching EventRole:', error);
                });
            }, [token]);
      
        
  return (
    <div>
    
      <h1 className="text-center">{eventlist.title}</h1>
      <br></br>
      <div className='row gy-5 g-xl-8'>
        {/* <div className='col-xl-6'>
          <div className='card card-xl-stretch mb-xl-8 bg-info'>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '200px' }}>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{RoleUsers.length}</span>
              <h3 className='card-title text-light fw-bold text-center ' style={{ fontSize: '28px' }}>
                Number of Visitors 
              </h3>
            </div>
          </div>
        </div> */}
        <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-xl-8 bg-success '>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '200px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{commitee.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center'>
                Number of Leeds
              </h3>
            </div>
          </div>
        </div>
        {/* <div className='col-xl-12'>
          <div className='card card-xl-stretch mb-xl-8 bg-success'>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '200px' }}>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{volunteer.length}</span>
              <h3 className='card-title text-light fw-bold text-center' style={{ fontSize:'28px' }}>
                Number of Leeds
              </h3>
            </div>
          </div>
        </div> */}
      </div>
      <br />
     
          </div>

  
);
}
    


export default ExhibitorEventDashboard ;