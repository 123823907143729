import React from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import {
  ListsWidget2,
  ListsWidget4,
  ListsWidget6,
} from '../../../_metronic/partials/widgets';

const DashboardPage = () => (
  <>

<div className='row gy-5 g-xl-8'>
      <div className='col-xl-4 h-lg-100 '>
        <ListsWidget2 className='card-xl-stretch mb-xl-8 bg-success' />
      </div>
      <div className='col-xl-4 h-lg-100'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8 bg-warning ' />
      </div>
      <div className='col-xl-4 h-lg-100'>
        <ListsWidget4 className='card-xl-stretch mb-xl-8 bg-primary'/>
      </div>
    </div>
   
      <div className='col-xl-12 '>
        {/* <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' /> */}
       
      </div>
    
     
    <div className='row gx-5 gx-xl-10'>
      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-xxl-6 mb-5 mb-xl-10'>
        {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
     
    </div>
   
  </>
);

const DashboardWrapper = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
