import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../helpers';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../../Loader';
import { useAuth } from '../../../app/modules/auth';
import { toast } from "react-toastify";
import Aprovalrequest from './Aprovalrequest';

const API_URL = process.env.REACT_APP_API_URL;

function AdminListAdver() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { auth } = useAuth();
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const rowsPerPage = 30;
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function onClickhandler(e, id) {
    const checked = e.target.checked;
    const value = !checked; 
  
    setLoadingUserId(id);
  
    axios
      .post(API_URL + '/api/advertisement/approvals', {
        id: id,
        disabled: value,
       
      }, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Successfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error('Error updating account: ' + error);
        setLoadingUserId(null);
      });
  }
  
  useEffect(() => {
    handleEventSector();
  }, [updateFlag]);

  const handleEventSector = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/advertisementapproval`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setUsers(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Approval request . Please try again';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
  };

  const paginatedData = users.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleRequestClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setShowModal(false);
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Advertisement request list</span>
            </h3>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-120px'>Requested Name</th>
                      <th className='min-w-120px'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.slice().reverse().map((user) => (
                      <tr key={user.id}>
                        <td>
                          {/* <span
                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            onClick={() => handleRequestClick(user)}
                            style={{ cursor: 'pointer' }}
                          >
                            {user.username}
                          </span> */}
                              
                          <Link to={`/GetAdmin/${user.username}`} className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {user.username}
                           
                          </Link>
                          
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                            <input
                              className="form-check-input"
                              id={`flexSwitchCheckDefault-${user.id}`}
                              type="checkbox"
                              defaultChecked={!user.disabled}  
                              onClick={(e) => onClickhandler(e, user.id)}
                              value={user.id}
                            />
                            {loadingUserId === user.id && (
                              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {users.length > 0 && (
                  <div className='pagination-container' style={{ marginRight: '120px' }}>
                    <Pagination
                      data={users}
                      itemsPerPage={rowsPerPage}
                      onPageChange={handlePageChange}
                      currentPage={page}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal && <Aprovalrequest user={selectedUser} closeModal={closeModal} />}
    </div>
  );
}

export default AdminListAdver;
