import React, { useState, useEffect } from 'react'
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../app/modules/auth/components/Pagination';
import Loader from '../../../Loader';
import { MdDeleteForever } from "react-icons/md";

const API_URL = process.env.REACT_APP_API_URL;

export default function TransactionHistory() {
    const [stalls, setStalls] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const [loading, setLoading] = useState(false);
    const { eventId } = useParams();
    const { auth } = useAuth();


    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/user/transactions`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.errorMessage);
                setLoading(false);
            });
    }, [auth?.token, eventId]);

    const handlePageChange = selectedPage => {
        setPage(selectedPage);
    };

    const paginatedData = stalls.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className='card card-raised'>

                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>

                            <span className='card-label fw-bold fs-3 mb-1'>Transaction History</span>

                        </h3>
                    </div>
                    <div className='card-toolbar'>



                        <div className='card-body py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>


                                            <th className='min-w-120px'>Transaction Type</th>
                                            <th className='min-w-120px'>Payment Link</th>
                                            <th className='min-w-120px'>Transaction Status</th>
                         


                                        </tr>
                                    </thead>

                                    <tbody>

                                        {paginatedData.slice().reverse().map((stalluser) => (
                                            <tr key={stalluser.id}>





                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {stalluser.transactionType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {stalluser.paymentLink}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold fs-6'>
                                                        {stalluser.transactionStatus}
                                                    </div>
                                                </td>
                                                <td>
                                                   
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>


                        </div>

                        {stalls.length > 0 && (
                            <Pagination
                                data={stalls}
                                itemsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                currentPage={page}
                            />
                        )}
                    </div>
                </div>
            )}


        </div>
    )
}
