import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import CreateTaskPriority from '../../partials/modals/Add Pages/CreateTaskPriority';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import { useAuth } from '../../../app/modules/auth';


  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

function Taskpriority() {
  const [taskpriority, SetTaskpriority] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30; // Specify the number of rows per page
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);

  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = !checked;
    setLoadingUserId(id) 

    axios
      .get(API_URL + `/api/TaskPriority/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error('Error Updating', error);
        setUpdateFlag((prevFlag) => !prevFlag);
        setLoadingUserId(null);
      });
  }

  const handlePriorityCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/TaskPriority`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetTaskpriority(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Task Priority. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true)
    axios
      .get(API_URL + `/api/TaskPriority`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetTaskpriority(response.data);
          setLoading(false);

      })
      .catch(error => {
        const errorMessage = 'Error Fetching Task Priority. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const handlePageChange = (selectedPage) => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };
  const paginatedData = taskpriority.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateTaskPriority onPriorityCreated={handlePriorityCreated} />

          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>

              <span className='card-label fw-bold fs-3 mb-1'>Task Priority</span>

            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_task_priority'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>





          <div className='card-body py-3'>

            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>

                    <th className='min-w-120px'>Priority Type</th>
                    <th className='min-w-120px'>Description</th>
                    <th className='min-w-120px'>Action</th>



                  </tr>
                </thead>

                <tbody>
                  {paginatedData.slice().reverse().map((taskpriority) => (
                    <tr key={taskpriority.id}>


                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {taskpriority.prioritytype}
                        </a>
                      </td>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                          {taskpriority.description}
                        </a>
                      </td>


                      <td style={{ display: 'flex', alignItems: 'center' }}>

                        <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                          <input
                            className="form-check-input"
                            id="flexSwitchCheckDefault"
                            type="checkbox"
                            defaultChecked={!taskpriority.disabled}
                            onClick={(e) => onClickhandler(e, taskpriority.id)}
                            value={taskpriority.id}
                          />
                           {loadingUserId === taskpriority.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                        </div>

                        <Link
                          to={`/edittaskpriority/${taskpriority.id}`}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <KTIcon iconName='pencil' className='fs-3' />
                        </Link>


                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>

            </div>

            {taskpriority.length > 0 && (
               <div className='pagination-container' style={{marginRight:'120px'}}>
              <Pagination
                data={taskpriority} // Pass the full data to the Pagination component
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                currentPage={page}
              />
         </div>
            )}


          </div>
        </div>
      )}
    </div>


  );
}


export default Taskpriority;
