import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../../app/modules/auth";
import { IoArrowBackOutline } from "react-icons/io5";

const API_URL = process.env.REACT_APP_API_URL;
const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const orgid = data?.orgid;

function EditEvent() {
  const [loading, setLoading] = useState(false);
  const { eventid } = useParams();
  const { auth } = useAuth();
  const [profile, setProfile] = useState({
    id: 0,
    title: "",
    theme: "",
    oneliner: "",
    startdate: "",
    location: "",
    description: "",
    enddate: "",
    latitude: "",
    longitude: "",
    phone:"",
    email:""
  })

  useEffect(() => {
    axios
      .get(API_URL + `/api/EventPlannings/${eventid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error('Error fetching Events:', error);
      });
  }, [auth?.token]);


  const navigate = useNavigate();

  const handleChange = (task, field) => {
    let actualValue = task.target.value;

    setProfile({ ...profile, [field]: actualValue });
  };
  const handleSubmit = async (task) => {
    task.preventDefault();
    setLoading(true);
    axios
      .post(API_URL + "/api/EventPlannings/update", profile, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Event Updated Succesfully");

        navigate(`/listEvents/${orgid}`);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Updating Event. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const goBack = () => {
    navigate(-1); // This will take you back to the previous page
  };

  return (
    <div>

      <div onClick={goBack} className='mb-10 cursor-pointer'> <IoArrowBackOutline size={30} /></div>

      <div id="layoutDrawer_content">

        {loading && <div className="loading"></div>}

        <main>

          <div className="col-8 mx-xl-auto ps-xl-8">

            <div className="card card-raised ">
              <div className="card-body ">


                <h1 className="card-title">Edit Event</h1>
                <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="title">Title <span className="required"></span></label>
                      <input
                        className="form-control "
                        id="title"
                        type="text"
                        placeholder="Title"
                        onChange={(e) => handleChange(e, "title")}
                        value={profile.title}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="theme">Theme <span className="required"></span></label>
                      <input
                        className="form-control"
                        id="theme"
                        type="text"
                        placeholder="Theme"
                        onChange={(e) => handleChange(e, "theme")}
                        value={profile.theme}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="oneliner">Oneliner <span className="required"></span></label>
                      <input
                        className="form-control"
                        id="oneliner"
                        type="text"
                        placeholder="oneliner"
                        onChange={(e) => handleChange(e, "oneliner")}
                        value={profile.oneliner}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="startdate">Start Date <span className="required"></span></label>
                      <input
                        className="form-control"
                        id="startdate"
                        type="date"
                        placeholder="Start Date"
                        onChange={(e) => handleChange(e, "startdate")}
                        value={profile.startdate}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="enddate">End Date <span className="required"></span></label>
                      <input
                        className="form-control"
                        id="enddate"
                        type="date"
                        placeholder="End Date"
                        onChange={(e) => handleChange(e, "enddate")}
                        value={profile.enddate}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="location">Location <span className="required"></span></label>
                      <input
                        className="form-control"
                        id="location"
                        type="text"
                        placeholder="Location"
                        onChange={(e) => handleChange(e, "location")}
                        value={profile?.location}
                      />
                    </div>
                  </div>

                  <div className="row">
                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="email">Event Mail </label>
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              placeholder="Event Email Address"
                              onChange={(e) => handleChange(e, "email")}
                              value={profile?.email}
                            />
                          </div>
                          

                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="phone">Contact</label>
                            <input
                              className="form-control"
                              id="phone"
                              type="tel"
                              placeholder="Contact"
                              onChange={(e) => handleChange(e, "phone")}
                              value={profile?.phone}
                            />
                          </div>
                        </div>

                  <div className="row">
                    <div className="col-6 form-floating-sm mb-2">
                      <label htmlFor="latitude">Latitude</label>
                      <input
                        className="form-control"
                        id="latitude"
                        type="text"
                        placeholder="Latitude"
                        onChange={(e) => handleChange(e, "latitude")}
                        value={profile.latitude}
                       
                      />
                    </div>
                    <div className="col-6 form-floating-sm mb-2">
                      <label htmlFor="longitude">Longitude</label>
                      <input
                        className="form-control"
                        id="longitude"
                        type="text"
                        placeholder="Longitude"
                        onChange={(e) => handleChange(e, "longitude")}
                        value={profile.longitude}
                      
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="description"
                        onChange={(e) => handleChange(e, "description")}
                        value={profile.description}
                        
                      />
                    </div>
                  </div>
                  <br>
                  </br>
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}

                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>


        </main>
      </div>
    </div>
  );
}





export default EditEvent;



