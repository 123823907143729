import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Field } from 'formik';
import CreateOrg from '../../../../../_metronic/partials/modals/Add Pages/CreateOrg';
import WizardContext from '../WizardContext'
import { KTIcon } from '../../../../../_metronic/helpers';
import Neworganization from '../../../../../_metronic/partials/modals/Add Pages/Neworganization';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../auth';


const token = localStorage.getItem('token');
const API_URL = process.env.REACT_APP_API_URL;

const Step1 = () => {
  const { organization, setOrganization } = useContext(WizardContext);
  const [matchingNames, setMatchingNames] = useState([]);
  const [showList, setShowList] = useState(false);
  const {auth} =useAuth();
  const [isOrganizationSelected, setIsOrganizationSelected] = useState(false);
  const searchInputRef = useRef(null);

  const {orgid}= useParams();


// const initialOrganizationName = orgid ? orgid : '';
useEffect(() => {
  axios.get(API_URL+`/api/organizationprofiles/${orgid}`, {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  })
  .then(response => {
    setOrganization(response.data);
  })
  .catch(error => {
    console.error('Error fetching curtainraiser events:', error);
  });

}, [orgid, setOrganization]);

  const handleNameSelect = (org) => {
    setOrganization(org);
    setMatchingNames([]);
    setShowList(false);
    setIsOrganizationSelected(true);
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    setOrganization(value);
    setIsOrganizationSelected(false);

    try {
      const response = await axios.get(API_URL + `/api/orgSearch`, {
        params: {
          key: value,
        },
      });

      if (Array.isArray(response.data)) {
        setMatchingNames(response.data);
        setShowList(response.data.length !== 0);
      } else {
        console.error('Invalid response data format:', response.data);
      }
    } catch (error) {
      toast.error('Error fetching names:', error);
    }
  };

  const handleOutsideClick = (e) => {
    if (searchInputRef.current && !searchInputRef.current.contains(e.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);


  const handleOrgcreated= ()=>{
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }
  return (
    <div className='w-100'>
      
      <Neworganization/>

      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Choose Organization
        </h2>
      </div>

      <div className='row mb-2'>

      

        <label className='d-flex justify-content-between align-items-center form-label mb-5'>
          Select Organization
          <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          
        >
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a new Organization'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_organization_new'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Organization
          </a>
        </div>
       
        </div>
        </label>

        <div className='mb-10 fv-row' ref={searchInputRef}>
          <Field
            type='text'
            className='form-control form-control-lg'
            name='orgname'
            value={organization.name}
            onChange={handleInputChange}
          />

          {showList && (
            <div className='card-toolbar'>
              {matchingNames.map((org) => (
                <Field
                  key={org.id}
                  className='form-control form-control-lg'
                  onClick={() => handleNameSelect(org)}
                  value={org.name}
                  style={{ cursor: 'pointer'}}
                />
              ))}
            </div>
          )}
          {isOrganizationSelected || (
            <div className='text-danger mt-2'>Select an organization</div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export { Step1 };
