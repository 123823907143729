import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, Navigate, useParams } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import Pagination from '../../../app/modules/auth/components/Pagination';
import CreateStallExhibitor from '../../partials/modals/Add Pages/CreateStallExhibitor';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import { MdDeleteForever } from "react-icons/md";
import { useAuth } from '../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;


function StallExhibitorList() {
  const [stallusers, setStallusers] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const { eventId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");


  function removeStall(stall) {
    setLoading(true);
    axios
      .delete(API_URL + `/api/stallexhibitormapping/delete/${stall}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        toast.success(response.data);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        toast.error(error.errorMessage);
        setLoading(false);
      });
  }

  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = !checked;
    setLoadingUserId(id)

    setLoading(true);
    axios
      .get(API_URL + `/api/stallexhibitormapping/stallExhibitorStatus/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error(error.errorMessage);
        setLoadingUserId(null);
      });
  }
  const handleExhibitorCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/stallexhibitormapping/eventid=${eventId}`, {

        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setStallusers(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.errorMessage);
        setLoading(false);

      });
  }


  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/stallexhibitormapping/eventid=${eventId}`, {

        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setStallusers(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    setPage(selectedPage);
  };

  const filteredData = stallusers.filter((stalluser) =>
  stalluser.stalltype.toLowerCase().includes(searchTerm.toLowerCase()) ||
  stalluser.exhibitor.toLowerCase().includes(searchTerm.toLowerCase())
);


  const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateStallExhibitor onExhibitorCreated={handleExhibitorCreated} eventId={eventId} />

          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>

              <span className='card-label fw-bold fs-3 mb-1'>Stall Exhibitor</span>

            </h3>

            <div className='card-header border-0 pt-5'>

              <div className='card-title'>
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control form-control w-450px ps-14'
                    placeholder='Search Users'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

              </div>

            </div>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click here to add new'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_stall_exhibitor'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'>



            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>


                      <th className='min-w-120px'>Exhibitor</th>
                      <th className='min-w-120px'>Stall Type</th>
                      <th className='min-w-120px'>Stall Id</th>
                      <th className='min-w-120px'>Cost</th>
                      <th className='min-w-120px'>Payment Status</th>
                      <th className='min-w-120px'>Reference Code</th>
                      <th className='min-w-120px'>Actions</th>


                    </tr>
                  </thead>

                  <tbody>

                    {paginatedData.slice().reverse().map((stalluser) => (
                      <tr key={stalluser.id}>

                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {stalluser.exhibitor}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {stalluser.stalltype}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold fs-6'>
                            {stalluser.stallId}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {stalluser.cost}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {stalluser.payementStatus}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {stalluser.referenceNo}
                          </div>
                        </td>

                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <div onClick={(e) => removeStall(stalluser.id)} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' style={{ fontSize: '12px', marginRight: '10px' }}>

                            <MdDeleteForever style={{ fontSize: '30px' }} />

                          </div>

                          <Link
                            to={`/editstallexhibitor/${eventId}/${stalluser.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>


                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>


              </div>


            </div>

            {stallusers.length > 0 && (
              <Pagination
                data={stallusers}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                currentPage={page}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default StallExhibitorList;
