import React, { useState, useEffect } from 'react'
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../app/modules/auth/components/Pagination';
import Loader from '../../../Loader';
// import AddAdvsettings from '../partials/modals/Add Pages/AddAdvsettings';


const API_URL = process.env.REACT_APP_API_URL;

export default function AdminAddsettings() {
    const [stalls, setStalls] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const [loading, setLoading] = useState(false);
    const { eventId } = useParams();
    const { auth } = useAuth();



    const onstallCreated = () => {
        setLoading(true)
        axios
            .get(API_URL + `/api/advertisementsettings`, {

                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/advertisementsettings`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error);
                setLoading(false);
            });

    }, [auth?.token, eventId]);

    const handlePageChange = selectedPage => {
        setPage(selectedPage);
    };

    const paginatedData = stalls.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>

                    <br />
                    <div className='card card-raised'>
                        {/* <AddAdvsettings onstallCreated={onstallCreated} /> */}

                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>

                                <span className='card-label fw-bold fs-3 mb-1'>Advertisement Type</span>

                            </h3>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add a stall'
                            >
                                <a
                                    href='#'
                                    className='btn btn-sm btn-light-primary float-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_stall_chart'
                                >
                                    <KTIcon iconName='plus' className='fs-3' />
                                    Add
                                </a>
                            </div>
                        </div>
                        <div className='card-toolbar'>



                            <div className='card-body py-3'>
                                {/* begin::Table container */}
                                <div className='table-responsive'>
                                    {/* begin::Table */}
                                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                        {/* begin::Table head */}
                                        <thead>
                                            <tr className='fw-bold text-muted'>


                                                <th className='min-w-120px'>Type Name</th>
                                                <th className='min-w-120px'>Time Allowed</th>
                                                <th className='min-w-120px'>Cost</th>
                                                <th className='min-w-120px'>Action</th>


                                            </tr>
                                        </thead>

                                        <tbody>

                                            {paginatedData.slice().reverse().map((stalluser) => (
                                                <tr key={stalluser.id}>





                                                    <td>
                                                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                            {stalluser.advertisementtypeid}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                            {stalluser.timeallowed}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='text-dark fw-bold fs-6'>
                                                            {stalluser.cost}
                                                        </div>
                                                    </td>

                                                    <td style={{ display: 'flex', alignItems: 'center' }}>

                                                        <Link
                                                            to={`/editadv/${stalluser.id}`}
                                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                        >
                                                            <KTIcon iconName='pencil' className='fs-1' />
                                                        </Link>


                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>


                                </div>


                            </div>

                            {stalls.length > 0 && (
                                <Pagination
                                    data={stalls}
                                    itemsPerPage={rowsPerPage}
                                    onPageChange={handlePageChange}
                                    currentPage={page}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}


        </div>
    )
}
