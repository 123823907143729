import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { RxLinkedinLogo } from 'react-icons/rx';
import { toast } from "react-toastify";
import { KTIcon } from "../../../helpers";

function SpeakerDetail({ speaker, session }) {

    const websiteClick = (url) => {
        if (url) {
          window.open(`https://${url}`, '_blank');
        }
      };
      

    return (
        <div className='modal fade' id='kt_modal_speaker_details' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-2 mx-xl-18 pt-0 pb-15'>

                        <div className="col-12">
                            <div className="row">
                                <div className="col-4 col-md-3">

                                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>

                                        <img
                                            src={`${speaker?.imageMeta},${speaker?.image}`}
                                            style={{
                                                width: '120%',
                                                height: '130px',
                                                borderRadius: '50%'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-7 col-md-8 px-8">
                                    <h2> {speaker?.name}</h2>
                                    <span className='text-dark fw-semibold fs-5'>{speaker?.designation}</span>
                                    <span className='text-dark fw-semibold fs-6 pb-5'>{speaker?.company}</span>
                                    <div onClick={() => websiteClick(speaker?.linkedinId)}>
                                        <RxLinkedinLogo className='fs-1 pt-3' style={{ color: '#1da1f2' }} size={40} /></div>

                                </div>
                            </div>
                            <h5 className='pt-8 pb-4'> Session By {speaker?.name} </h5>
                            <div className='card px-6 mb-xl-8 pt-4 mt-2 pb-4' style={{ border: '1px solid #728df9' }}>
                                <h4> {session?.sessionname}</h4>
                                <div className="pb-2">
                                Topic: {session?.topic}
                                </div>
                                <div className='flex'>

                                    <div className="pb-3">
                                        <KTIcon iconName='time' className='fs-3'/> {session?.startTime} - <KTIcon iconName='time' className='fs-3'/> {session?.endTime}
                                    </div>
                                    <div>
                                        <KTIcon iconName='geolocation' className='fs-2 ' /> {session?.location}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </div >
    );
}





export default SpeakerDetail;



