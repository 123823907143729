import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import Pagination from '../../app/modules/auth/components/Pagination';
import CreateUSPIntentions from '../partials/modals/Add Pages/CreateUSPIntentions';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;

function USPIntentions() {
  const [intenstions, setIntentions] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const { auth } = useAuth();
  const rowsPerPage = 30;


  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = !checked; // Reverse the checked value to set the 'disabled' property
    setLoadingUserId(id)

    axios
      .get(API_URL + `/api/UspIntentions/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error('Error updating account:', error);
        setLoadingUserId(null);
      });
  }


  const handleUSPIntension = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/UspIntentions`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setIntentions(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching USPIntentions:', error);
        setLoading(false);
      });

  }

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/UspIntentions`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setIntentions(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching USPIntentions:', error);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const paginatedData = intenstions.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateUSPIntentions onUSPIntensCreated={handleUSPIntension} />
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-3'>USP Intentions</span>
              <span className='card-label fs-7 mb-2' style={{ color: 'orange' }}>(Unique Selling Proposition)</span>

            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a intention'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_uspIntenstions'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>

              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>

                      <th className='min-w-120px'>USP Intentions Name</th>
                      <th className='min-w-120px'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.slice().reverse().map((intens) => (
                      <tr key={intens.id}>



                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {intens.name}
                          </a>
                        </td>

                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                            <input
                              className="form-check-input"
                              id="flexSwitchCheckDefault"
                              type="checkbox"
                              defaultChecked={!intens.disable}
                              onClick={(e) => onClickhandler(e, intens.id)}
                              value={intens.id}
                            />
                             {loadingUserId === intens.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                          </div>
                          <Link
                            to={`/EditUSPIntentions/${intens.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >  <KTIcon iconName='pencil' className='fs-3' />
                          </Link>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {intenstions.length > 0 && (
                <div className='pagination-container' style={{ marginRight: '120px' }}>
                  <Pagination
                    data={intenstions} // Pass the full data to the Pagination component
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={page}
                  />
                </div>
              )}


            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default USPIntentions;
