import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function CommitteeDashboard() {
  const [eventlist, seteventlist] = useState([]);
  const [currentpage, setCurrentPage]= useState(1);
  const [RoleUsers,setRoleUsers] = useState([]);
  const [Widget4,SetWidget4] = useState([]);
const {auth} = useParams(); 
const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const orgid = data?.orgid;

  

  useEffect(() => {
    axios
      .get(API_URL +`/api/EventPlannings/activeEvents/page=${currentpage}` , {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        seteventlist(response.data.pageData);
       
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
  }, [auth?.token]);
 


  useEffect(() => {
    axios
      .get(API_URL +`/api/EventPlannings/archiveEvents/page=${currentpage}` , {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setRoleUsers(response.data.pageData);
       
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
  }, [auth?.token]);

  

  useEffect(() => {

    const role= "ROLE_EXHIBITOR";
    axios
      .get(API_URL +`/api/authority/orgid=${orgid}/role=${role}` , {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        SetWidget4(response.data);
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
  }, [auth?.token]);


  return (
    <div >

      {/* <h1 className="text-center">{eventlist.title}</h1> */}
      <br></br>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4 h-lg-100' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8  bg-success'>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{eventlist.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center' style={{ fontSize:'23px' }}>
                Number of Active Events
              </h3>
            </div>
          </div>
        </div>
        <div className='col-xl-4 h-lg-100' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8 bg-warning '>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{RoleUsers.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center 'style={{ fontSize: '22px'}}>
                Number of Archived Events
              </h3>
            </div>
          </div>
        </div>
        <div className='col-xl-4 h-lg-100' style={{ marginTop: '3px' }}>
          <div className='card card-xl-stretch mb-xl-8 bg-primary'>
            <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '230px' }}>
              <span className='fs-2hx fw-bold text-gray me-2 lh-1 ls-n2'>{Widget4.length}</span>
              <h3 className='card-title text-gray-800 fw-bold text-center'style={{ fontSize:'21px'}}>
              Number of Unique Exhibitor
              </h3>
            </div>
          </div>
        </div>
      </div>
      <br />

    </div>


  );
}



export default CommitteeDashboard;