import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import { toast } from 'react-toastify';
import { getUserByToken, login, getUserNotification } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { KTIcon } from '../../../../_metronic/helpers';
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;

const  Eventwise=()=> {

  const location = useLocation();
  const [user, setUser] = useState(location.state && location.state.user);
  const [curtainraiserFilter, setCurtainraiserFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, setNotification } = useAuth();
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;

  const navigate = useNavigate();

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };



  const handleLogin = async (us) => {
    setLoading(true);
    try {

      const { data: auth } = await login(us.username, us.role, us.eventid, us.orgid);
      saveAuth(auth);
      const { data: userdata } = await getUserByToken(auth.token);
      setCurrentUser(userdata);
      const { data: noteDate } = await getUserNotification(auth.token);
      setNotification(noteDate);

      localStorage.setItem("loginpath", location.pathname);
      if (us.role === 'ROLE_ADMIN') {
        navigate('/dashboard');
      } else if (us.role === 'ROLE_EXHIBITOR') {
        navigate('/Exhibitordashboard');
      } else if (us.role === 'ROLE_COMMITEE_MEMBER') {
        navigate('/committeedash');
      }

      toast.success(`Logged in As a ${us.role}`);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        const errorMessage = 'The login details are incorrect. Please try again.';
        toast.error(errorMessage);
      }
      saveAuth(undefined);
      setLoading(false);
    }
  }

  const handleFilter = (filter) => {
    setLoading(true);

    let apiUrl;

    if (user && user.length > 0) {
      if (filter === 'All Roles') {
        apiUrl = API_URL + `/api/authority/username=${user[0].username}`;
      } else {
        apiUrl = API_URL + `/api/authority/username=${user[0].username}/role=${filter}`;
      }

    axios
      .get(apiUrl)
      .then(response => {
        setUser(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching User By Role:', error);
        setLoading(false);
      });
};
  }


  


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='container'>

          <h3>
            Please select Event Or organization

          </h3>

          <div className='row m-2 p-3'>
            <div className='col-6'>

            </div>
            <div className='col-6 d-flex align-items-center justify-content-end'>
              <div>
                <button
                  className='btn btn-sm btn-light-primary dropdown-toggle m-2'
                  type='button'
                  id='curtainraiserFilterDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Role
                </button>
                <ul className='dropdown-menu' aria-labelledby='curtainraiserFilterDropdown'>
                <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'All' ? 'active' : ''}`}
                      onClick={() => handleFilter('All')}
                    >
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'ROLE_EXHIBITOR' ? 'active' : ''}`}
                      onClick={() => handleFilter('ROLE_EXHIBITOR')}
                    >
                      ROLE_EXHIBITOR
                    </button>
                  </li>

                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'ROLE_ADMIN' ? 'active' : ''}`}
                      onClick={() => handleFilter('ROLE_ADMIN')}
                    >
                      ROLE_ADMIN
                    </button>
                  </li>
                  
                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'ROLE_COMMITEE_MEMBER' ? 'active' : ''}`}
                      onClick={() => handleFilter('ROLE_COMMITEE_MEMBER')}
                    >
                      ROLE_COMMITEE_MEMBER
                    </button>
                  </li>
                </ul>
              </div>

            </div>
          </div>


          <div className='row g-5 g-xl-8'>
            {user.map((us) => (

              <div className='col-xl-4' onClick={() => handleLogin(us)}
                style={{ cursor: 'pointer' }}>
                <div className="card card-raised">
                  <div>


                    {/* begin::Header */}
                    <div className='card-header border-0 pt-5'>
                      <h3 className='card-title align-items-start flex-column'>

                        <span className='card-label fw-bold text-dark'>{us.role}</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'><b>Username:</b>{us.username}</span>
                      </h3>


                    </div>
                    {/* end::Header */}


                    <div className='row'>
                      <div>

                      </div>
                    </div>

                    {/* begin::Body */}
                    <div className='card-body pt-5' >
                      {/* begin::Item */}

                      {/* end::Item */}
                      {/* begin::Item */}
                      {us.orgid ? (
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Symbol */}
                          <div className='symbol symbol-50px me-5'>
                            <span className='symbol-label bg-light-primary'>
                              <KTIcon iconName='home-1' className='fs-2x text-primary' />
                            </span>
                          </div>

                          <div className='d-flex flex-column'>
                            <span className='text-muted fw-semibold'>Oganization Id</span>
                            {us.orgid}


                          </div>

                        </div>

                      ) : (


                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Symbol */}
                          <div className='symbol symbol-50px me-5'>
                            <span className='symbol-label bg-light-primary'>
                              <KTIcon iconName='some-files' className='fs-2x text-primary' />
                            </span>
                          </div>
                          {/* end::Symbol */}
                          {/* begin::Text */}
                          <div className='d-flex flex-column'>
                            <span className='text-muted fw-semibold'>Event Id</span>
                            {us.eventid}


                          </div>
                          {/* end::Text */}
                        </div>
                      )}
                      {/* end::Item */}
                    </div>
                    {/* end::Body */}
                  </div>
                </div>


              </div>

            ))}

            {user.length > 0 && (
              <Pagination
                data={user}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                currentPage={page}
              />)}

            {/* Conditionally render subscription link for admin and exhibitor */}
            {user.some((us) => us.role === 'ROLE_ADMIN' || us.role === 'ROLE_EXHIBITOR') && (
              <Link to='/subscriptions'>
                <button className='btn btn-primary float-end' style={{ marginBottom: '0', color: 'white' }}>
                  Click here to Subscribe
                </button>
              </Link>
            )}
          </div>

        </div>
      )}
    </div>

  );
}

export default Eventwise;
