import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

function FetchData() {
  const [error, setError] = useState(null);
  const [agents, setAgents] = useState([]);
  const [response, setResponse] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(API_URL + '/api/permissions', { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setAgents(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const selectPageHandle = (selectedPage) => {
    // Pagination Logic
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(agents.length / rowsPerPage) &&
      selectedPage !== page
    ) {
      setPage(selectedPage);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='card card-raised'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Permissions</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
         
        </div>
      </div>
      <div className='card-toolbar'></div>
      <div className='card-toolbar'>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-40px'>ID</th>
                  <th className='min-w-80px'>Permission</th>
                  <th className='min-w-80px'>Description</th>
                  <th className='min-w-80px'>Permission Code</th>
                </tr>
              </thead>
              <tbody>
                {agents &&
                  agents
                    .slice(
                      (page - 1) * rowsPerPage,
                      page * rowsPerPage
                    )
                    .map((agent) => (
                      <tr key={agent.id}>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {agent.id}
                          </a>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {agent.permission}
                          </a>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {agent.description}
                          </a>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {agent.permissionCode}
                          </a>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {agents.length > 0 && (
        <div className='pagination' style={{ marginRight: '120px' }}>
          <div
            className='arrows'
            onClick={() => selectPageHandle(page - 1)}
          >
            <MdKeyboardArrowLeft size={35} />
          </div>
          <div className='pageNumbers'>
            {[(agents.length / rowsPerPage)].map((n, i) => (
              <div className={`num ${`numActive`}`}>{page}</div>
            ))}
          </div>
          <div
            className='arrows'
            onClick={() => selectPageHandle(page + 1)}
          >
            <MdKeyboardArrowRight size={35} />
          </div>
        </div>
      )}
    </div>
  );
}

export default FetchData;
