/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { KTIcon } from '../../../helpers';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

function CreateSocialMedia({ onMediaCreated, eventId, userId }) {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [sociamedias, setSocialmedias] = useState([]);
    const [media, setMedia] = useState({
        socialmedia: "",
        appId: "",
        secretId:"",
        userid: userId,
        eventid: eventId
    })

    const handleChange = (task, field) => {
        let actualValue = task.target.value;

        setMedia({ ...media, [field]: actualValue });
    };

    const handleSubmit = async (task) => {
        task.preventDefault();
        setLoading(true);
        axios
            .post(API_URL + "/api/socialmedias/create", media, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => response.data)
        {
            toast.success("Social Media Added Successfully");
            setLoading(false);
            onMediaCreated();
            closeModal();
        };
    };

    useEffect(() => {
        axios
            .get(API_URL + `/api/SocialMediaType`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                setSocialmedias(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error Fetching SocialMedias:', error);
                setLoading(false);
            });
    }, []);


    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className='modal fade' id='kt_modal_socialMedia' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Add SocialMedia</h1>


                        </div>


                        <div>
                            <div id="layoutDrawer_content">
                                {loading && <div className="loading"></div>}

                                <main>
                                    <div className="col-10 mx-xl-auto ps-xl-10">
                                        <br></br>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row"></div>
                                            <br></br>



                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="socialmedia">SocialMedia</label>
                                                    <select id="roleid" type="text" placeholder="Select Social Media" className="form-control"
                                                        onChange={(e) => handleChange(e, "socialmedia")} value={sociamedias.type}>
                                                        <option>Select...</option>
                                                        {sociamedias.map(ex => {
                                                            return (
                                                                <option key={ex.id} value={ex.media}>
                                                                    {ex.media}
                                                                </option>
                                                            );
                                                        })}

                                                    </select>{" "}

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="appId">App Id</label>
                                                    <input
                                                        className="form-control"
                                                        id="appId"
                                                        type="text"
                                                        placeholder="type"
                                                        onChange={(e) => handleChange(e, "appId")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="secretId">Secret Id</label>
                                                    <input
                                                        className="form-control"
                                                        id="secretId"
                                                        type="text"
                                                        placeholder="type"
                                                        onChange={(e) => handleChange(e, "secretId")}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row" hidden>
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="userid">UserId</label>
                                                    <input
                                                        className="form-control"
                                                        id="userid"
                                                        type="text"
                                                        value={userId}
                                                    />
                                                </div>
                                            </div>


                                            <div className="row" hidden>
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="eventid">EventId</label>
                                                    <input
                                                        className="form-control"
                                                        id="eventid"
                                                        type="text"
                                                        value={eventId}
                                                    />
                                                </div>
                                            </div>

                                            <div className="text-end">
                                                <input
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    value="Submit"
                                                    data-bs-dismiss='modal'
                                                />
                                            </div>
                                        </form>
                                    </div>

                                </main>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSocialMedia;
