import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import Navbar from '../Navbar';
import { useAuth } from '../../../../app/modules/auth';
import NavViewArticle from './NavViewArticle';

const API_URL = process.env.REACT_APP_API_URL;

export default function Articles() {
    const [articleList, setarticleList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPage] = useState(0);
    const rowsPerPage = 5;
    const [selectedArticle, setSelectedArticle] = useState(null);
    const { eventId } = useParams();
    const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [eventDetails, setEventDetails] = useState({});

    useEffect(() => {
        setLoading(true);
        axios
            .get(`${API_URL}/api/EventPlannings/${eventId}`)
            .then((response) => {
                setEventDetails(response.data); 
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching event:', error);
                setLoading(false);
            });
    }, [eventId]);

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    function fetchData(currentPage) {
        setLoading(true);
        axios
            .get(`${API_URL}/api/eventarticles/eventid=${eventId}/page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setarticleList(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching Articles:', error);
                setLoading(false);
            });
    }

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    };

    const handleViewArticle = (article) => {
        setSelectedArticle(article);
    };

    const handlePageChange = page => {
        fetchData(page);
    };

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <Navbar event={eventDetails} />
                    <div className='schedule-header mt-20'>
                        <h1 style={{ fontSize: '35px', textAlign: 'center' }}>EVENT ARTICLES</h1>
                        <br />
                    </div>

                    <div className='row justify-content-end'> {/* Center the column */}
                        <div className='col-xl-11'>
                            <div className='row g-5 g-xl-10'>
                                {articleList.slice().reverse().map((article, index) => (
                                    <div
                                        className='col-xl-11'
                                        key={article.id}
                                        onMouseEnter={() => setHoveredEventIndex(index)}
                                        onMouseLeave={() => setHoveredEventIndex(-1)}
                                    >
                                         <NavViewArticle selectedArticle={selectedArticle} />
                                        <div
                                            className='card card-raised'
                                            style={{
                                                transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                                                transition: 'transform 0.2s ease-in-out',
                                            }}
                                        >
                                            <div className='mx-4 mt-3'>
                                                <div className='card-toolbar float-end'>
                                                    <a
                                                        href='#'
                                                        className='btn btn-sm btn-light-primary'
                                                        data-bs-toggle='modal'
                                                        data-bs-target='#kt_modal_view_article'
                                                        onClick={() => handleViewArticle(article)}
                                                    >
                                                        <i className="fa-solid fa-eye"></i>
                                                    </a>

                                                  
                                                </div>
                                                <div className='border-0 pt-5'>
                                                    <h5 className='card-title align-items-start flex-column'>
                                                        {article.subject}
                                                        <br />
                                                    </h5>
                                                </div>

                                                <div className='text-muted d-flex justify-content'>
                                                    <b>{article.userid}</b>
                                                </div>

                                                <div className='text-muted d-flex justify-content-end'>
                                                    <b>{article.dateTime}</b>
                                                </div>

                                                <div className='pt-5'>
                                                    {stripHtmlTags(article.description).length > 500
                                                        ? `${stripHtmlTags(article.description).slice(0, 500)}...`
                                                        : stripHtmlTags(article.description)}
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {articleList && articleList.length > 0 && (
                                <Pagination
                                    data={articleList}
                                    itemsPerPage={rowsPerPage}
                                    onPageChange={handlePageChange}
                                    currentPage={currentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
