import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { MdSubscriptions } from "react-icons/md";
import SubscriptionPermissions from './SubscriptionPermission';
import { IoArrowBackOutline } from "react-icons/io5";


const Subscription = () => {
    const [types, setType] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState();
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/subscriptiontype`)
            .then(response => {
                setType(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching subscription Type:', error);
                setLoading(false);
            });
    }, []);

    const handleFeatureSelection = (type) => {
        setSelectedFeature(type);
    };
    
    const goBack = () => {
        navigate(-1); // This will take you back to the previous page
    };

    return (
        <div>
            <div className='px-10'>

                <div onClick={goBack} className='mb-10 mt-10 cursor-pointer'> <IoArrowBackOutline size={30} /></div>

                {loading ? (
                    <Loader />
                ) : (
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body border-top p-9 text-center'>

                            <div className="hero-section">
                                <h2>Ready to get started?</h2>
                                <h3 className='mt-4'>Choose one of our subscription plans and start creating today!</h3>

                            </div>
                            <div className='col-lg-12 mt-12'>
                                <div className='row'>
                                    {types.slice().map((type) => (
                                        <div className='col-lg-3' >
                                            <div style={{
                                                borderLeft: '1px solid black',
                                                height: '100px',
                                            }}>
                                                <div className='fs-6 mb-4'>{type.type}</div>
                                                <div> <MdSubscriptions bounce style={{ color: "#2f75ee", }} size={40} /></div>
                                                <div className='fs-6 mt-4 text-hover-primary' style={{
                                                    cursor: 'pointer'
                                                }} onClick={() => handleFeatureSelection(type)}> Click to learn more </div>

                                            </div>
                                        </div>

                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>



            {selectedFeature && <SubscriptionPermissions feature={selectedFeature} />}
        </div>
    )
}

export default Subscription
