import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../helpers';
import axios from 'axios';
import { Link, useNavigate} from 'react-router-dom';
import { useAuth } from '../../../app/modules/auth';
import CreateEvent from '../../partials/modals/Add Pages/CreateEvent'; 
import Pagination from '../../../app/modules/auth/components/Pagination';

const API_URL = process.env.REACT_APP_API_URL;

function ORG() {
    const [eventlist, seteventlist] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const {auth} =useAuth();
  const token = localStorage.getItem('token');
        useEffect(() => {
          axios
            .get(API_URL + `/api/organizationprofiles`, {
              headers: {
                Authorization: `Bearer ${auth?.token}`,
              },
            })
            .then(response => {
              seteventlist(response.data);
            })
            .catch(error => {
              console.error('Error fetching eventlist:', error);
            });
        }, [token]);
      
        const handlePageChange = selectedPage => {
          // Update the current page when the pagination component triggers a page change
          setPage(selectedPage);
        };
      
        const paginatedData = eventlist.slice((page - 1) * rowsPerPage, page * rowsPerPage);
       // const history= useHistory();

       const navigate= useNavigate();
        const handleEventSelection = (eventId) => {
           navigate(`/listEvents/${eventId}`);
           // window.location.reload();
        };


  return (
    <div>
    <CreateEvent/>

    


    <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_event'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </a>
        </div>


        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Filter'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_event'
          >
            <KTIcon iconName='filter' className='fs-3' />
            Filters
          </a>
        </div>


    <div className='row g-5 g-xl-8'>
        {paginatedData.slice().reverse().map((eventlist) => (
                 

        <div className='col-xl-4'>
        <div className="card card-raised">
<div className="card-body ">
<div className='card-toolbar float-end'>
          {/* begin::Menu */}
          { <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='float-end'
          >
             <Link
                 to={`/editevent/${eventlist.id}`}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              >
            <KTIcon iconName='pencil' className='fs-2' />
            </Link>
          </button> }
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
        
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'><div onClick={() => handleEventSelection(eventlist.id)}>{eventlist.title}</div></span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{eventlist.oneliner}</span>
        </h3>

        
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-success'>
              <KTIcon iconName='subtitle' className='fs-2x text-success' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {eventlist.theme}
            </a>
            <span className='text-muted fw-semibold'>Theme</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-warning'>
              <KTIcon iconName='calendar' className='fs-2x text-warning' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
             {eventlist.startdate}
            </a>
            <span className='text-muted fw-semibold'>Start Date</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-primary'>
              <KTIcon iconName='geolocation' className='fs-2x text-primary' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {eventlist.location}
            </a>
            <span className='text-muted fw-semibold'>Location</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-danger'>
              <KTIcon iconName='cheque' className='fs-2x text-danger' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
            {eventlist.description}
            </a>
            <span className='text-muted fw-semibold'>Description</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label bg-light-info'>
              <KTIcon iconName='calendar' className='fs-2x text-info' />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Text */}
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
              {eventlist.enddate}
            </a>
            <span className='text-muted fw-semibold'>End Date</span>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
    </div>
    </div>
 
    ))}
   
   {eventlist.length > 0 && (
          <Pagination
            data={eventlist} // Pass the full data to the Pagination component
            itemsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            currentPage={page}
          />)}
  
    </div>
    
    </div>
    
   
  )
}

export default ORG;
