import React, {useState, useEffect} from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../auth';
import { toast } from "react-toastify";


const API_URL = process.env.REACT_APP_API_URL;

const token = localStorage.getItem('token');


const EventmembersProfile = () => {

  const {setCurrentUser, setNotification } = useAuth()
  const [profile, setProfile] = useState({
    id:"",
    email: "",
    name:"",
    role: "",
    phone: "",
    image: "",
    imageMeta: ""
    

});
 // const {currentUser}= useAuth();
  const [loading, setLoading] = useState(false)
 
  const navigate= useNavigate();

 
  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

       reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }


  const validatePhoneNumber = (phone) => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    return phoneNumberPattern.test(phone);
  };


  const handleImageChange=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...profile,

      imageMeta: convertedImage.metadata   ,
         image:convertedImage.content,

    };
    setProfile(imageObject);
  }

  const getData = async () => {

    const { data } = await axios.get(API_URL+ "/eventMembers", {
      headers: { Authorization: `Bearer ${token}` },
    });
    setProfile(data);
  };
  useEffect(() => {
    getData();
  }, []);

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
  };
  const handleSubmit = async(event) => {
    event.preventDefault();
    setLoading(true);
 axios
      .post(API_URL+ "/eventMembers/update", profile, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Event Member Profile Updated");
        setProfile(profile);
        setCurrentUser(profile);
          navigate("/eventmemberprofile");
          setLoading(false);

      });
  };

 
  

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile </h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='col-lg-8'>
             
  
              <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
           
                      
{profile?.image ? (
<img id="image" src={`${profile?.imageMeta},${profile?.image}`} alt={profile?.email} style={{ maxWidth: "150px", maxHeight: "150px" }}

/>
) : (
<img alt={profile?.firstname} />
)}

</div></div>
<input className="col-7" type="file" id="image" onChange={(event) => {
                          handleImageChange(event)}} accept="image/*"></input>
</div>
                      
                </div>
             

            <div className='row mb-6' hidden>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>User Id</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  value={profile?.userid}
                  onChange={(e) => handleChange(e, "userid")}
                  readOnly 
                />
              </div>
            </div>

            <div className='row mb-12'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'> Name</label>

                
                  <div className='col-lg-8 fv-row'>
                    
                    <input
                      type='text'
                      id="name"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Name'
                      value={profile?.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                   
                  </div>
                 
                </div> 

                <div className='row mb-12'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'> Role</label>

                
                  <div className='col-lg-8 fv-row'>
                    
                    <input
                      type='text'
                      id="role"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Role'
                      value={profile?.role}
                      onChange={(e) => handleChange(e, "role")}
                    />
                   
                  </div>
                 
                </div> 
             


                <div className='row mb-12'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'>Mail</label> 
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      id="email"
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Mail'
                      value={profile?.email}
                      onChange={(e) => handleChange(e, "email")}
                    />
                   
                  </div>
                
                </div>


          
                <div className='row mb-12'>
              <label className='col-lg-2 col-form-label required fw-bold fs-6'>Contact Phone</label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      id='phone'
                      placeholder='Phone number'
                      value={profile?.phone}
                      onChange={(e) => handleChange(e, "phone")}
                      className={`form-control form-control-lg form-control-solid ${
                        validatePhoneNumber(profile.phone) ? '' : 'is-invalid'
                      }`}
                      rows={1}
                    />
                     <div className='invalid-feedback'>Phone number should have exactly 10 digits.</div>
                  
                  </div>
                  </div>
                  
           
          
           
         
              
              

                

               

          

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          </div>
        </form>
      </div>
      
    </div>
  )
}

export {EventmembersProfile}
