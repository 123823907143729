/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import { useParams } from 'react-router-dom';
import { CiCircleRemove } from "react-icons/ci";



const API_URL = process.env.REACT_APP_API_URL;
function CreateEventRole({ onEventRoleCreated }) {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const role = data?.role;
  const { eventId } = useParams();
  const [eventrole, setEventrole] = useState({
    role: "",
    eventid: eventId,
    permissions: [],
    userid: "",

  });

  const [modalOpen, setModalOpen] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]); // Options for the role dropdown
  const [selectedFeature, setSelectedFeatures] = useState([]);

  useEffect(() => {
    axios
      .get(API_URL + `/api/rolepermission/role=${role}`, role,{
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setRoleOptions(response.data);
     
  
      })
      .catch((error) => {
        toast.error('Error Fetching Permission type:', error);
      });
  }, [role]);

  

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setEventrole({ ...eventrole, [field]: actualValue });
  };


  const handleFeatureChange = (e) => {
    const selectedFeature = e.target.value;

    // Add the selected feature to the array if it doesn't exist
    if (!eventrole.permissions.includes(selectedFeature)) {
      setEventrole({ ...eventrole, permissions: [...eventrole.permissions, selectedFeature] });
    }
  };

  const handleRemove = (permissions) => {
    // Remove the selected feature from the array
    const updatedpermission = eventrole.permissions.filter((f) => f !== permissions);
    setEventrole({ ...eventrole, permissions: updatedpermission });
  };

  const handleSubmit = async (task) => {
    task.preventDefault();
    setLoading(true);
    axios
      .post(API_URL + "/api/eventrolepermission/create", eventrole, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success(" created successfully");
        setLoading(false);
        onEventRoleCreated();
        closeModal();
      })
      .catch((error) => {
        // Handle error
        setLoading(false);
        toast.error("Error creating event role: " + error.message);
      });
  };



  const closeModal = () => {
    setModalOpen(false);

  };



  return (
    <div className='modal fade' id='kt_modal_event_role' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Event Role</h1>


            </div>


            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-10 mx-xl-auto ps-xl-10">
                    <br></br>
                    <form onSubmit={handleSubmit}>
                      <div className="row"></div>
                      <br></br>



                      <div className="form-floating-sm mb-2">
                        <label htmlFor="role">Role Name</label>
                        <input
                          className="form-control"
                          id="role"
                          placeholder='Role Name'
                          onChange={(e) => handleChange(e, "role")}
                        />


                      </div>

                      <br></br>

                      <div className="row" >
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="userid">User Name</label>
                          <input
                            className="form-control"
                            id="userid"
                            type="text"
                            placeholder='User Name'
                            onChange={(e) => handleChange(e, "userid")}

                          />
                        </div>
                      </div>
                      <br></br>



                      <div>
                        <div className='row'>
                          <div className='form-floating-sm mb-2'>
                            <label>Permissions</label>
                            <select
                              id='permissions'
                              className='form-control'
                              onChange={(e) => handleFeatureChange(e)}
                            >
                              <option>Select...</option>
                              {roleOptions.map((ex) => (
                                <option key={ex.id} value={ex.permission}>
                                  {ex.permission}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className='selected-options'>
                          <p>Selected Options:</p>
                          <ul>
                            {eventrole.permissions.map((permission) => (
                              <li key={permission}>
                                {permission}{' '}
                                <CiCircleRemove  onClick={() => handleRemove(permission)}>Remove</CiCircleRemove >
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>


                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                          data-bs-dismiss='modal'
                        />
                      </div>
                    </form>
                  </div>

                </main>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateEventRole;
