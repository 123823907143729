import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;

function EditStallChart() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id,eventId } = useParams();
    const { auth } = useAuth();
    const [type, setType] = useState([]);
    const [stall, setStall] = useState({
        id:"",
        stallid: '',
        stalltype: '',
        dimension: '',
        eventid: eventId,
        disabled: false,
    });

    useEffect(() => {
        const fetchStallTypes = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/StallType`, {
                    headers: {
                        Authorization: `Bearer ${auth?.token}`,
                    },
                });
                setType(response.data);
            } catch (error) {
                toast.error('Error fetching Stall Type. Please try again.');
            }
        };
        fetchStallTypes();
    }, [auth?.token]);

    useEffect(() => {
        axios
          .get(API_URL + `/api/StallChart/${id}`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then((response) => {
            setStall(response.data);
          })
          .catch((error) => {
            const errorMessage = 'Error Fetching Stall. Please try again.';
            toast.error(errorMessage);
          });
    }, [id, auth?.token]);
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStall(prevStall => ({ ...prevStall, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.post(`${API_URL}/api/StallChart/update`, stall, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            });
            toast.success("Stall Chart Updated");
            navigate(-1);
        } catch (error) {
            toast.error('Failed to Update Stall Chart. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {loading && <div className="loading"></div>}
            <main>
                <div className="col-8 mx-xl-auto ps-xl-8">
                    <div className="card card-raised">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <h3>Edit Stall Chart</h3>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="stalltype">Stall Type</label>
                                        <select
                                            name='stalltype'
                                            className='form-control'
                                            onChange={handleChange}
                                            value={stall.stalltype}
                                        >
                                            <option>Select...</option>
                                            {type.map((ex, index) => (
                                                <option key={index} value={ex.stallType}>
                                                    {ex.stallType}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <br/>

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="stallid">Stall Id</label>
                                        <input
                                            className="form-control"
                                            name="stallid"
                                            type="text"
                                            placeholder="Stall Id"
                                            value={stall.stallid}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <br/>

                                <div className="row">
                                    <div className="form-floating-sm mb-2">
                                        <label htmlFor="dimension">Dimension</label>
                                        <input
                                            className="form-control"
                                            name="dimension"
                                            type="text"
                                            placeholder="Dimension"
                                            value={stall.dimension}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <br/>

                                <div className="text-end">
                                    <button
                                        className="btn btn-primary"
                                        type="submit"
                                        disabled={loading}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default EditStallChart;
