/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KTIcon } from '../../../helpers';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';

function CreateActivityType({ onActivityCreated }) {

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const token = localStorage.getItem('token');
  const { auth } = useAuth();

  const API_URL = process.env.REACT_APP_API_URL;
  const [orgAdmin, setOrgAdmin] = useState({
    type: "",
  });


  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    if (field === "isTrue") {
      actualValue = event.target.value === "true";
    }
    setOrgAdmin({ ...orgAdmin, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + "/api/ActivityType/create", orgAdmin, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        setLoading(false);
        toast.success("Created Successfully");
        onActivityCreated(); // Call the onEventCreated function to refresh the events list
        closeModal();
      });
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='modal fade' id='kt_modal_ActivityType' aria-hidden='true'>
          <div className='modal-dialog mw-650px'>
            <div className='modal-content'>
              <div className='modal-header pb-0 border-0 justify-content-end'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                  <KTIcon iconName='cross' className='fs-1' />
                </div>
              </div>

              <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                <div className='text-center mb-13'>
                  <h1 className='mb-3'>Add Activity Type</h1>


                </div>


                <div>
                  <div id="layoutDrawer_content">
                    {loading && <div className="loading"></div>}

                    <main>
                      <div className="col-10 mx-xl-auto ps-xl-10">
                        <br></br>
                        <form onSubmit={handleSubmit}>
                          <div className="row"></div>
                          <br></br>



                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label htmlFor="type">Activity Type</label>
                              <input
                                className="form-control"
                                id="type"
                                type="text"
                                placeholder="Activity type"
                                onChange={(e) => handleChange(e, "type")}
                              />
                            </div>
                          </div>
                          <br></br>


                          <div className="text-end">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              disabled={loading}
                              value="Submit"
                              data-bs-dismiss='modal'
                            />
                          </div>
                        </form>
                      </div>

                    </main>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateActivityType;
