import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chart from 'chart.js/auto';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const ListsWidget26 = ({ className }) => {
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [chartRendered, setChartRendered] = useState(false);
  const { eventId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + '/api/sectors', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setSectors(response.data);
        setLoading(false); // Set loading to false after data fetch
      } catch (error) {
        toast.error('Error Fetching Sectors:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!loading && sectors.length > 0 && !chartRendered) {
      renderChart();
      setChartRendered(true);
    }
  }, [loading, sectors, chartRendered]);

  const fetchDataForSector = async (sectorName) => {
    try {
      const response = await axios.get(
        `${API_URL}/api/EventSectors/eventid=${eventId}/sector=${sectorName}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
     
      return response.data;
    } catch (error) {
      toast.error(`Error Fetching ${sectorName} Data:`, error);
      throw error;
    }
  };

  const renderChart = async () => {
    const ctx = document.getElementById('barChart').getContext('2d');
    const colors = generateColors(sectors.length);
  
    const sectorsData = [];
    let maxNumberOfIDs = 0; // Initialize max number of IDs to 0
  
    for (const sector of sectors) {
      const sectorData = await fetchDataForSector(sector.name);
      sectorsData.push(sectorData.length); // Push the number of IDs for the sector
      if (sectorData.length > maxNumberOfIDs) {
        maxNumberOfIDs = sectorData.length; // Update max number of IDs if needed
      }
    }
  
    new Chart(ctx, {
      type: 'bar',
      data: {
        labels: sectors.map((sector) => sector.name),
        datasets: [
          {
            label: 'Number of IDs',
            data: sectorsData, // Use the number of IDs as data for the bars
            backgroundColor: colors,
            borderColor: colors.map((color) => color.replace('0.5', '1')),
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            onClick: () => {} // Disable legend click behavior
          },    
          title: {
            display: true,
            text: 'Event Sector',
          },
        },
        scales: {
          y: {
            min: 0,
            max: maxNumberOfIDs > 0 ? maxNumberOfIDs : 1, // Ensure the maximum value of Y-axis is at least 1
            suggestedMax: maxNumberOfIDs + 1, // Suggest a maximum value for Y-axis
            stepSize: 1,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)', // Color of grid lines
              borderWidth: 0.5, // Width of grid lines
              borderDash: [3, 3], // Dotted lines style
            },
          },
        },
      },
    });
  };
  


  const generateColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const color = `rgba(${Math.floor(Math.random() * 256)}, ${Math.floor(
        Math.random() * 256
      )}, ${Math.floor(Math.random() * 256)}, 0.5)`;
      colors.push(color);
    }
    return colors;
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <canvas id="barChart" style={{ backgroundColor: 'white' }}></canvas>
        </div>
      )}
    </div>
  );
};

export default ListsWidget26;
