import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import Pagination from '../../app/modules/auth/components/Pagination';
import { Link } from 'react-router-dom';
import CreateOrgType from '../partials/modals/Add Pages/CreateOrgType';
import { toast } from 'react-toastify';
import { useAuth } from '../../app/modules/auth';
import Loader from '../../Loader';

const API_URL = process.env.REACT_APP_API_URL;

function OrganizationType() {
  const [organizationType, setOrganizationType] = useState([]);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const [page, setPage] = useState(1);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const rowsPerPage = 30;

  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disabled = checked;
    setLoadingUserId(id); 

    
    axios
      .get(API_URL + `/api/OrganizationType/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
     
      })
      .catch((error) => {
        toast.error('Error updating Organization Type', error);
        setLoadingUserId(null);
       
      });
  }

  const handleOrgCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/OrganizationType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setOrganizationType(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching OrganizationType:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/OrganizationType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setOrganizationType(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching OrganizationType:', error);
        setLoading(false);
      });
    };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const paginatedData = organizationType.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateOrgType onOrgCreated={handleOrgCreated} />
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Organization Type</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_organizationtype'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>
              {loading ? (
                <div className='text-center'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>

                        <th className='min-w-120px'>Organization Type</th>
                        <th className='min-w-120px'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.slice().reverse().map((organizationType) => (
                        <tr key={organizationType.id}>



                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {organizationType.type}
                            </a>
                          </td>
                          <td style={{ display: 'flex', alignItems: 'center' }}>

                            <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                              <input
                                className="form-check-input"
                                id={`flexSwitchCheckDefault-${organizationType.id}`} 
                                type="checkbox"
                                defaultChecked={organizationType.disabled}
                                onClick={(e) => onClickhandler(e, organizationType.id)}
                                value={organizationType.id}
                              />
                              {loadingUserId === organizationType.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                            </div>
                            <Link
                              to={`/editorgtype/${organizationType.id}`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <KTIcon iconName='pencil' className='fs-3' />
                            </Link>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {organizationType.length > 0 && (
                    <div className='pagination-container' style={{ marginRight: '120px' }}>
                      <Pagination
                        data={organizationType} // Pass the full data to the Pagination component
                        itemsPerPage={rowsPerPage}
                        onPageChange={handlePageChange}
                        currentPage={page}
                      />
                    </div>
                  )}
                </div>

              )}
            </div>
          </div>
        </div>
      )}
    </div>

  );
}

export default OrganizationType;
