import React from 'react';
import axios from 'axios';
import './pagination.css';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

class FetchData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      agents: [],
      response: {},
      isLoading: true,
      currentPage: 1,
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.fetchData(1);
  }

  fetchData(page) {
    axios
      .get(API_URL + `/api/AuditLog/getAll/page=${page}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({
            agents: result.pageData,
            currentPage: page,
            totalPages: result.pages,
            isLoading: false,
          });


        },
        (error) => {
          this.setState({ error, isLoading: false }); // Set isLoading to false in case of an error too
        }
      );
  }

  selectPageHandle = (page) => {
    this.fetchData(page);
  };

  render() {
    const { error, agents, isLoading, currentPage } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className='card card-raised'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Audit Log</span>
                </h3>
              </div>
              <div className='card-toolbar'>
                <div className='card-body py-3'>

                  <React.Fragment>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>DateTime</th>
                            <th className='min-w-120px'>username</th>
                            <th className='min-w-120px'>Action</th>
                            <th className='min-w-150px'>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            agents
                              .reverse().slice()
                              .map((agent) => (
                                <tr key={agent.id}>

                                  <td>
                                    <div
                                      className='text-dark fw-bold fs-6'
                                    >
                                      {agent?.datetime}
                                    </div>
                                  </td>
                                  <td>
                                    <Link to={`/AccSetting/${agent?.user?.id}`}
                                      className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                    >
                                      {agent?.user?.email}
                                    </Link>
                                  </td>
                                  <td>
                                    <div
                                      className='text-dark fw-bold d-block mb-1 fs-6'
                                    >
                                      {agent?.action}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className='text-dark fw-bold d-block mb-1 fs-6'
                                    >
                                      {agent?.description}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>

                </div>
              </div>
            </div>
          )}
          {!isLoading && agents.length > 0 && (
            <div className='pagination' style={{ marginRight: '150px' }}>
              <div
                className='arrows'
                onClick={() => this.selectPageHandle(currentPage - 1)}
              >
                <MdKeyboardArrowLeft size={35} />
              </div>
              <div className='pageNumbers'>
                {Array.from({ length: this.state.totalPages }, (_, i) => i + 1)
                  .slice(Math.max(currentPage - 3, 0), currentPage + 2)
                  .map((pageNumber) => (
                    <div
                      className={`num ${currentPage === pageNumber ? 'numActive' : ''}`}
                      key={pageNumber}
                      onClick={() => this.selectPageHandle(pageNumber)}
                    >
                      {pageNumber}
                    </div>
                  ))}
              </div>
              <div
                className='arrows'
                onClick={() => this.selectPageHandle(currentPage + 1)}
              >
                <MdKeyboardArrowRight size={35} />
              </div>
            </div>
          )}
        </div>
      );
    }

  }
}

export default FetchData;

