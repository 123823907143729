import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SponsorDetails from './SponsorDetail';
const API_URL = process.env.REACT_APP_API_URL;

function Sponsors({ event }) {
    const [sponsors, setSponsors] = useState([]);
    const [selectedSponsor, setSelectedSponsor] = useState(null);

    useEffect(() => {
        if (event) {
            axios
                .get(API_URL + `/api/sponsers/eventid=${event.id}`)
                .then(response => {
                    setSponsors(response.data);
                })
                .catch(error => {
                    console.error('Error fetching sponsors:', error);
                });
        }
    }, [event]);

    const handleSponsorClick = (sponsor) => {
        setSelectedSponsor(sponsor);
    };

    return (
        <div className='col-xl-12'>
            <SponsorDetails sponsor={selectedSponsor} />
            <div className='card px-20 py-20'>
                <div className='px-10'>
                    {/* <BannerAdd /> */}
                    <div style={{ color: '#728df9', fontSize: '3.5rem' }} className='card-label fw-bold'>
                        Our Sponsors
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-2'>
                        A special thanks to the individuals who made this event possible
                    </span>
                    {/* <div style={{ fontSize: '2rem' }} className='card-label text-dark fw-bold mb-6 mt-10'>
                        PARTNERS IN PROGRESS
                    </div> */}
                    <div className='row g-5 g-xl-10'>


                        {sponsors.map((sponsor) => (

                            <div key={sponsor.id} className='col-xl-2 col-md-4 col-6'>
                                <a
                                    href='#'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_sponsor_details'
                                    onClick={() => handleSponsorClick(sponsor)}
                                >
                                    <div className='card card-xl-stretch mb-xl-8' >
                                        <img
                                            src={`${sponsor?.imageMeta},${sponsor?.logo}`}
                                            style={{
                                                width: '100%',
                                                height: '140px'
                                            }}
                                        />
                                    </div>
                                    {/* <h3 className='px-10'>{sponsor.name}</h3> */}

                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Sponsors;
