/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../app/modules/auth';




const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const orgid = data?.orgid;

type Props = {
  className: string
}


const ListsWidget2: React.FC<Props> = ({ className }) => {
  const [eventlist, seteventlist] = useState([]);
  const [currentpage, setCurrentPage]= useState(1);
  const { auth } = useAuth();

  

  useEffect(() => {
    axios
      .get(API_URL +`/api/EventPlannings/orgid=${orgid}/upCommingEvents/page=${currentpage}` , {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        seteventlist(response.data.pageData);
       
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
  }, [auth?.token]);
 

 

 

  return (
    <div className={`card ${className}`}>
   
      <div className='card card-xl-stretch mb-xl-8  bg-success '>
        <div className='card-title d-flex flex-column align-items-center justify-content-center ' style={{ height: '200px' }} >
       
            <div  className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>{eventlist.length}</div>
       
          <h3 className='card-title text-gray-800 fw-bold  text-center'>Number of Active Events</h3>
        </div>
      </div>

     
    </div>
  );
};

export { ListsWidget2 }
