import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";


const API_URL = process.env.REACT_APP_API_URL;

function EditStallEXhibitor() {
  const [stalltype, setStallatype] = useState([]);
  const [stall, setStall] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [matchingNames, setMatchingNames] = useState([]);
  const [showList, setShowList] = useState(false);

  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { stallexhibitorid, eventId } = useParams();
  const [stallexhibitorList, setstallexhibitorlist] = useState({
    id: "",
    eventid: eventId,
    cost: "",
    exhibitor: "",
    stalltype: "",
    payementStatus: "",
    referenceNo: "",
    stallId: ""
  });

  useEffect(() => {
    axios
      .get(API_URL + '/api/StallType', {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setStallatype(response.data);
      })
      .catch((error) => {
        toast.error(error.errorMessage);
      });
  }, [auth?.token]);




  const handleChange = async (field, value) => {
    setstallexhibitorlist({ ...stallexhibitorList, [field]: value });
    try {
      if (field === 'stalltype' && value) {
        const response = await axios.get(
          `${API_URL}/api/StallChart/${eventId}/${value}/false`,
          {
            headers: { Authorization: `Bearer ${auth?.token}` },
          }
        );
        setStall(response.data);
      }
      // Fetch cost based on stallid and update the cost field
      if (field === 'stalltype' && value) {
        const selectedStall = stall.find((s) => s.stalltype === value);
        if (selectedStall) {
          setstallexhibitorlist((prev) => ({ ...prev, cost: selectedStall.cost }));
        }
      }
    } catch (error) {
      toast.error(error.errorMessage);
    }
  };

  const handleInputChange = async (e) => {
    const { value } = e.target;
    setSelectedUser(value);
    const role = "ROLE_EXHIBITOR"
    try {
      const response = await axios.get(`${API_URL}/api/authority/${eventId}/${role}/committememberSearch`, {
        params: {
          key: value,
        },
      });

      if (Array.isArray(response.data)) {
        const usernames = response.data.map((item) => item.username);
        setMatchingNames(usernames);
        setShowList(response.data.length !== 0);
      } else {
        toast.error('Invalid response data format:', response.data);
      }
    } catch (error) {
      toast.error('Error fetching names: ' + error);
    }
  };

  const handleNameSelection = (selectedName) => {
    // Update the taskassignedto property when a name is selected
    setstallexhibitorlist((prevTask) => ({ ...prevTask, exhibitorId: selectedName }));
    setSelectedUser(selectedName);
    // Clear the suggestions and hide the list
    setMatchingNames([]);
    setShowList(false);
  };


  useEffect(() => {
    axios
      .get(API_URL + `/api/stallexhibitormapping/${stallexhibitorid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setstallexhibitorlist(response.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  }, [stallexhibitorid]); // Include taskid as a dependency

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    axios
      .post(API_URL + "/api/stallexhibitormapping/update", stallexhibitorList, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Stall Exhibitor Updated");
        navigate(`/stallexhibitorList/${eventId}`);
        setLoading(false);
      })

      .catch((error) => {
        toast.error(error);
        setLoading(false);

      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'> Edit Stall Exhibitor </span>
                      </h3>

                    </div>
                  </div>
                  <br></br>

                  {/* <div className="row" hidden>
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="eventid">Event ID</label>
                      <input
                        className="form-control"
                        id="eventid"
                        type="text"
                        placeholder="eventId "
                        value={stallexhibitorList.eventid}
                        readOnly

                      />
                    </div>
                  </div> */}


                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label>Stall Type</label>
                      <select
                        id='stalltype'
                        className='form-control'
                        value={stallexhibitorList?.stalltype}
                        onChange={(e) => handleChange('stalltype', e.target.value)}
                      >
                        <option>Select...</option>
                        {stalltype.map((ex) => (
                          <option key={ex.id} value={ex.stallType}>
                            {ex.stallType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {/* Stall Id */}
                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label htmlFor='stallId'>Stall Number</label>
                      <select
                        id='stallId'
                        className='form-control'
                        value={stallexhibitorList?.stallId}
                        onChange={(e) => handleChange('stallId', e.target.value)}
                      >
                        <option>Select...</option>
                        {stall.map((ex) => (
                          <option key={ex.id} value={ex.stallid}>
                            {ex.stallid}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>


                  {/* Cost */}
                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label htmlFor='cost'>Cost</label>
                      <input
                        className='form-control'
                        id='cost'
                        type='text'
                        placeholder='Cost'
                        value={stallexhibitorList?.cost}
                        onChange={(e) => handleChange('cost', e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Exhibitor Name */}
                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label>Exhibitor Name</label>

                      <input
                        className="form-control"
                        id="exhibitorId"
                        type="text"
                        placeholder="Exhibitor Name"
                        onChange={handleInputChange}
                        value={selectedUser}
                      />
                      {showList && (
                        <div>
                          {matchingNames.map((name) => (
                            <div className="form-control cursor-pointer" key={name} onClick={() => handleNameSelection(name)}>
                              {name}
                            </div>
                          ))}
                        </div>
                      )}

                    </div>
                  </div>

                  {/* Payment Status */}
                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label>Payment Status</label>
                      <select
                        id='payementStatus'
                        className='form-control'
                        value={stallexhibitorList?.payementStatus}
                        onChange={(e) => handleChange('payementStatus', e.target.value)}
                      >
                        <option value='SUCCESS'>Success</option>
                        <option value='PENDING'>Pending</option>
                      </select>
                    </div>
                  </div>


                  {/* Reference Code */}
                  <div className='row'>
                    <div className='form-floating-sm mb-2'>
                      <label>Reference Code</label>

                      <input
                        className='form-control'
                        id='referenceNo'
                        type='text'
                        placeholder='Reference Code'
                        onChange={(e) => handleChange('referenceNo', e.target.value)}
                        value={stallexhibitorList?.referenceNo}
                      />

                    </div>
                  </div>

                  <br>
                  </br>
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>

                </form>
              </div>
            </div>
          </div>

        </main>
      </div>
    </div>
  );
}

export default EditStallEXhibitor;
