import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import { FaRegUser } from "react-icons/fa";

const UI_URL = process.env.REACT_APP_INTERFACE_URL;

const connectionId = uuidv4();
const connectionUrl = UI_URL + `/connection/${connectionId}`;


const API_URL = process.env.REACT_APP_API_URL;

function ExhibitorDetails() {
    const { eventId} = useParams();
    const { currentUser, auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({
        id:currentUser.id,
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        imageMeta: "",
        country: "",
        description: "",
        image: null,
    });
    const [connection, setConnection] = useState({
        user: user,
        exibitor: { id: currentUser.id },
        eventid: eventId
    });
    const [showQRCode, setShowQRCode] = useState(false);

    useEffect(() => {
        setConnection((prevConnection) => ({
            ...prevConnection,
            user: user,
        }));
    }, [user]);

    const handleChange = (event, field) => {
        let actualValue = event.target.value;

        setUser((prevUser) => ({
            ...prevUser,
            [field]: actualValue,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(
                `${API_URL}/api/exibitorConnections/create`, connection,
                {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                }
            );
            toast.success('Connected Successfully');
            setLoading(false);
        } catch (error) {
            console.error('Error:', error); 
            toast.error(error.message);
            setLoading(false);
        }
    };

    const generateQRCode = () => {
        setShowQRCode(!showQRCode);
    };

    function getBase64FromFile(file) {

        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                const [metadata, content] = reader.result.split(",");
                resolve({ metadata, content });
            };
            reader.onerror = function (error) {
                reject(error);
            };
        });
    }
    const handleImageChange = async (event) => {
        const convertedImage = await getBase64FromFile(event.target.files[0]);
        const imageObject = {

            ...user,

            imageMeta: convertedImage.metadata,
            image: convertedImage.content,

        };
        setUser(imageObject);
    }

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className='card card-raised'>
                    <div className='card-header border-0 pt-5'>
                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h3 className='card-title align-items-start flex-column'>

                                        <span className='card-label fw-bold fs-2 mb-1'>Exhibitor Details</span>

                                    </h3>
                                </div>
                                <div className='col-lg-6'>
                                    <button onClick={generateQRCode} className='btn btn-sm btn-light-primary float-end my-4'>
                                        {showQRCode ? 'Hide' : 'QR Code'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-toolbar">

                        <div className="col-lg-12">
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='card-body py-3'>

                                        <label className='col-lg-10 col-form-label fw-bold fs-6'>Full Name : {currentUser.firstname}      {currentUser.lastname}</label>

                                        <br></br>
                                        <label className='col-lg-10 col-form-label  fw-bold fs-6'>Organization Type : {currentUser.orgtype}</label>

                                        <br></br>
                                        <label className='col-lg-10 col-form-label  fw-bold fs-6'>Organization : {currentUser.company}</label>

                                        <br></br>
                                        <label className='col-lg-10 col-form-label fw-bold fs-6'> Contact Number : {currentUser.phone}    </label>

                                        <br></br>

                                        <label className='col-lg-10 col-form-label fw-bold fs-6'> E-Mail : {currentUser?.email} </label>
                                        <br></br>

                                        <label className='col-lg-10 col-form-label fw-bold fs-6'> Description : {currentUser?.description} </label>
                                        <br></br>
                                    </div>
                                </div>

                                <div className='col-lg-6'>


                                    {showQRCode && <QRCode value={connectionUrl} size={250} />}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            )}

            <br></br>

            <div></div>

            <div className='card card-raised'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>

                        <span className='card-label fw-bold fs-2 mb-1'>Provide your Information</span>

                    </h3>

                </div>
                <div className='card-toolbar'>
                </div>
                <div className='card-toolbar'>



                    <div className='card-body py-3'>
                        {/* begin::Table container */}
                        <div className='table-responsive'>

                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label  fw-bold fs-6'>Full Name</label>

                                        <div className='col-lg-10'>
                                            <div className='row'>
                                                <div className='col-lg-5 fv-row'>
                                                    <input

                                                        id="firstname"
                                                        className='form-control '
                                                        placeholder='First name'
                                                        onChange={(e) => handleChange(e, "firstname")}

                                                    />

                                                </div>

                                                <div className='col-lg-4 fv-row'>
                                                    <input

                                                        id='lastname'
                                                        className='form-control'
                                                        placeholder='Last name'
                                                        onChange={(e) => handleChange(e, "lastname")}

                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    {/* Company */}
                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label  fw-bold fs-6 '>Company</label>

                                        <div className='col-lg-8 fv-row '>
                                            <input

                                                className='form-control '
                                                placeholder='Company name'
                                                id='company'
                                                onChange={(e) => handleChange(e, "company")}

                                            />

                                        </div>
                                    </div>


                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label  fw-bold fs-6 '>Designation</label>

                                        <div className='col-lg-8 fv-row '>
                                            <input

                                                className='form-control '
                                                placeholder='Designation'
                                                id='designation'
                                                onChange={(e) => handleChange(e, "designation")}

                                            />

                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label required fw-bold fs-6'>
                                            <span className=''>Contact Phone</span>

                                        </label>

                                        <div className='col-lg-8 fv-row '>
                                            <input
                                                type='tel'
                                                className='form-control '
                                                // className={`form-control ${validatePhoneNumber(profile.phone) ? '' : 'is-invalid'
                                                //     }`}
                                                rows={1}
                                                placeholder='Phone number'
                                                onChange={(e) => handleChange(e, "phone")}

                                            />

                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                            <span className='required'>Email</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='email'
                                                className='form-control '
                                                placeholder='Email'
                                                onChange={(e) => handleChange(e, "email")}
                                                required
                                            />

                                        </div>
                                    </div>



                                    <div className='row mb-6'>
                                        <label className='col-lg-2 col-form-label  fw-bold fs-6 '>Description</label>

                                        <div className='col-lg-8 fv-row '>
                                            <textarea

                                                className='form-control '
                                                placeholder='Description'
                                                id='description'
                                                onChange={(e) => handleChange(e, "description")}

                                            />

                                        </div>


                                    </div>

                                    <br></br>

                                    <div className='col-lg-8'>


                                        <div className='me-7 mb-4'>
                                            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>

                                                {user?.image ? (
                                                    <img id="image" src={`${user?.imageMeta},${user?.image}`} style={{ maxWidth: "150px", maxHeight: "150px" }}

                                                    />
                                                ) : (
                                                    <FaRegUser size={45} />
                                                )}

                                            </div></div>

                                        <input className="col-7" type="file" id="image" onChange={(event) => {
                                            handleImageChange(event)
                                        }} accept="image/*"></input>
                                    </div>

                                    <div className="text-end">
                                        <input
                                            className="btn btn-primary " style={{ width: '100px' }}
                                            type="submit"
                                            disabled={loading}
                                            value="Submit"
                                            data-bs-dismiss='modal'
                                        />
                                    </div>
                                    <br></br>
                                </form>
                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </div>
    );
}

export default ExhibitorDetails;
