import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { KTIcon } from "../../helpers";
import { useParams } from "react-router-dom";
import { Form, Formik, FormikValues } from "formik";
import { toast } from "react-toastify";
import WizardProvider from "../../../app/modules/wizards/components/WizardProvider";
import WizardContext from "../../../app/modules/wizards/components/WizardContext";
import { StepperComponent } from "../../assets/ts/components";

import { Step1 } from "../../../app/modules/wizards/components/steps/Step1";
import Step2 from "../../../app/modules/wizards/components/steps/Step2";
import { Step3 } from "../../../app/modules/wizards/components/steps/Step3";
import { Step5 } from "../../../app/modules/wizards/components/steps/Step5";
import { ICreateAccount } from "../../../app/modules/wizards/components/CreateAccountWizardHelper";
import Neworganization from "../../partials/modals/Add Pages/Neworganization";
import Newuser from "../../partials/modals/Add Pages/Newuser";
import Organization from "../org-management/organization";
import { useAuth } from "../../../app/modules/auth";
import { Step4 } from "../../../app/modules/wizards/components/steps/Step4";

const initialOrganization: ICreateAccount = {
  id: 0,
  name: "",
  description: "",
  address: "",
  contact: "",
  pan: "",
  tan: "",
  gstin: "",
  orgcode: "",
  primaryadmin: false,
  admin: "",
  orgid: 0,
  country:"",
  orgtype: "",
};


const AddAdmin = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [organization, setOrganization] = useState<ICreateAccount>(initialOrganization);
  const [adminUser, setAdminUser] = useState([]);
  const { auth } = useAuth();

  const navigate = useNavigate();
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;

  const updateOrganization = (updatedOrg: ICreateAccount) => {
      setOrganization(updatedOrg);
  };

  function OrganizationAdmin(orgid: any) {
    axios
      .get(API_URL + `/api/adminorganizationmapping/orgid=${orgid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setAdminUser(response.data);
      })
      .catch((error) => {
        toast.error('Error fetching :', error);
      });
  };


  function handleOrgUpdate(formValues: any) {
    setLoading(true);
    axios
      .post(API_URL + "/api/organizationprofiles/update", formValues, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Organization Updated Successfully");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Updating Organization. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }

  const handleNavigation = () => {
   navigate('/organizationlist');
  };

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev();
    setCurrentStep(prev => Math.max(prev - 1, 1));
  }


  const submitStep = (values: ICreateAccount, actions: FormikValues) => {

    if (currentStep === 2) {
      handleOrgUpdate(organization);
      const orgid = organization.id;
      OrganizationAdmin(orgid);

      // If we're on step 2, call the API to save the data
    }

    if (currentStep === 5) {
      handleNavigation();
    }


    if (currentStep === 3) {
      handleOrgUpdate(organization);
    }
   
      

    if (!stepper.current) {
      return;
    }

    if (currentStep !== stepper.current.totalStepsNumber) {
      stepper.current.goNext();

      setCurrentStep(prev => prev + 1);
    } else {
      stepper.current.goto(1);
      actions.resetForm();
      setCurrentStep(1);
    }
  }

  const handleOrgCreated = () => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }


  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])


  return (
    <WizardProvider>
      <Neworganization onOrgCreated={handleOrgCreated} />
      <Newuser />
      <div>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_create_account_stepper'
        >
          {/* begin::Aside*/}
          <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
            {/* begin::Wrapper*/}
            <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
              {/* begin::Nav*/}
              <div className='stepper-nav'>
                {/* begin::Step 1*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Organization Setting</h3>

                      <div className='stepper-desc fw-semibold'>Setup Your Organization Details</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 1*/}

                {/* begin::Step 2*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Business Details</h3>
                      <div className='stepper-desc fw-semibold'>Setup Your Business Details </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Authorised User</h3>
                      <div className='stepper-desc fw-semibold'>Your Business Related Info</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 4*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Subscription</h3>
                      <div className='stepper-desc fw-semibold'>Account Subscription</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  <div className='stepper-line h-40px'></div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 4*/}

                {/* begin::Step 5*/}
                <div className='stepper-item' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>5</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title'>Completed</h3>
                      <div className='stepper-desc fw-semibold'>yay, we are here</div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 5*/}
              </div>
              {/* end::Nav*/}
            </div>
            {/* end::Wrapper*/}
          </div>
          {/* begin::Aside*/}

          <div className='d-flex flex-row-fluid bg-body rounded'>
            <Formik initialValues={organization} onSubmit={submitStep}>
              {() => (
                <Form className='py-10 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <Step1 />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step2 organization={organization} updateOrganization={updateOrganization} />
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step3 organizationAdmin={adminUser} updateOrganization={updateOrganization}/>
                  </div>

                  <div data-kt-stepper-element='content'>
                    <Step4 />
                  </div>


                  <div data-kt-stepper-element='content'>
                    <Step5 />
                  </div>

                  <div className='d-flex flex-stack pt-10'>
                    <div className='mr-2'>
                      <button
                        onClick={prevStep}
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                      >
                        <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                        Back
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {currentStep !== stepper.current?.totalStepsNumber! - 1 ? 'Submit' : 'Complete'}
                          <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

      </div>
    </WizardProvider>
  );
}
export { AddAdmin };
