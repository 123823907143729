import React, { useState } from "react";
import WizardContext from "./WizardContext";

const WizardProvider = ({ children }) => {
    const [organization, setOrganization] = useState('');

    return (
        <WizardContext.Provider value={{ organization, setOrganization }}>
            {children}
        </WizardContext.Provider>
    );
};

export default WizardProvider;

