/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { ILayout, useLayout } from '../../core'
import axios from 'axios';


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])


  const [version, setVersion] = useState({ buildVersion: '', gitHash: '', buildTimestamp: '' });


  useEffect(() => {
    axios
      .get(API_URL + `/api/version`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setVersion(response.data);
      })
      .catch(error => {
        console.error('Error fetching EventRole:', error);
      });
  }, [token]);




  return (
    <>
      <div className='d-flex flex-wrap justify-content-between align-items-center'>
        

        <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1' style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', margin: '0', padding: '0' }}>
          <li className='menu-item'>
            <a href='https://cynefian.com/' target='_blank' className='menu-link px-2'>
              About
            </a>
          </li>

        
          <li className='menu-item'>
            <a href='https://cynefian.com/' target='_blank' className='menu-link px-2'>
              Privacy Policy
            </a>
          </li>
          
          <li className='menu-item'>
            <a href='https://cynefian.com/' target='_blank' className='menu-link px-2'>
              Support
            </a>
          </li>
          {/* <div style={{borderLeft: '1px solid black', height: '30px'}}/>
   */}
          <div className='text-dark order-2 order-md-1' style={{ display: 'flex', alignItems: 'center' }}>
          <span className='text-muted fw-semibold me-1'>
            {new Date().getFullYear().toString()}&copy;
          </span>
          <a
            href='https://cynefian.com/'
            target='_blank'
            className='text-gray-800 text-hover-primary'
            style={{ marginLeft: '5px' }} // Adjust margin as needed
          >
            Cynefian
          </a>
        </div>


          <li className='menu-item' style={{ fontSize: '12px', marginLeft: '5px' }}>
          {/* ver.: {version.buildVersion}#{version.gitHash.substring(0, 8)}-{version.buildTimestamp.substring(0, 10)}  */}
          </li>
        </ul>
      </div>

    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
