import * as React from 'react';
import { ThemeProvider, CssBaseline, Link, Container, Typography, TextField, Button } from '@mui/material';
import theme from '../Public/theme';

export default function withRoot(Component) {
  function WithRoot(props) {
    return (
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithRoot;
}