import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../helpers';
import { toast } from "react-toastify";
import { useAuth } from '../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;


function Popuprequest({ onpopup, username }) {
    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();
    const [intentions, setIntentions] = useState({
        username: "",
        requesteddate: "",
        approveddate: "",
        approvalstatus: "",
        pan: "",
        disabled:""
    });
    const [modalOpen, setModalOpen] = useState(false);

    const token = localStorage.getItem('token');

    useEffect(() => {
       
        if (auth) {
            setIntentions(prevState => ({
                ...prevState,
                username: auth.username 
            }));
        }
    }, [auth]);

    const handleChange = (inten, field) => {
        let actualValue = inten.target.value;
        setIntentions({ ...intentions, [field]: actualValue });
    };

    const handleSubmit = async (task) => {
        task.preventDefault();
        setLoading(true);
        axios
            .post(API_URL + "/api/advertisementapproval/create", intentions, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => response.data)
            .then((result) => {
                toast.success("send a request successfully");
                setLoading(false);
                onpopup();
                closeModal();
            });
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className='modal fade' id='kt_modal_popupresquest' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Request </h1>
                        </div>

                        <div>
                            <div id="layoutDrawer_content">
                                {loading && <div className="loading"></div>}

                                <main>
                                    <div className="col-10 mx-xl-auto ps-xl-10">
                                        <br></br>
                                        <form onSubmit={handleSubmit}>
                                            <div className="row"></div>

                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="username"> UserName</label>
                                                    <input
                                                        className="form-control"
                                                        id="username"
                                                        type="text"
                                                        placeholder="Username"
                                                        value={intentions.username}
                                                        onChange={(e) => handleChange(e, "username")}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="form-floating-sm mb-2">
                                                    <label htmlFor="pan"> PAN</label>
                                                    <input
                                                        className="form-control"
                                                        id="pan"
                                                        type="text"
                                                        placeholder="PAN"
                                                        value={intentions.pan}
                                                        onChange={(e) => handleChange(e, "pan")}
                                                    />
                                                </div>
                                            </div>

                                            {/* Other input fields... */}

                                            <div className="text-end">
                                                <input
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    disabled={loading}
                                                    value="Submit"
                                                    data-bs-dismiss='modal'
                                                />
                                            </div>
                                        </form>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Popuprequest;
