import React, { useState, useEffect } from 'react'
import AddStall from './AddStalls';
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../app/modules/auth/components/Pagination';
import Loader from '../../../Loader';
import { MdDeleteForever } from "react-icons/md";

const API_URL = process.env.REACT_APP_API_URL;

export default function StallChart() {
    const [stalls, setStalls] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const [loading, setLoading] = useState(false);
    const { eventId } = useParams();
    const { auth } = useAuth();

    function removeStall(stall) {
          setLoading(true);
        axios
            .get(API_URL + `/api/StallChart/delete`, stall, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                toast.success(response.data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error(error);
                setLoading(false);
            });
    }

    const onstallCreated = () => {
         setLoading(true);
        axios
            .get(API_URL + `/api/StallChart/eventid=${eventId}`, {

                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/StallChart/eventid=${eventId}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error);
                setLoading(false);
            });
    }, [auth?.token, eventId]);

    const handlePageChange = selectedPage => {
        setPage(selectedPage);
    };

    const paginatedData = stalls.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className='card card-raised'>
                    <AddStall onstallCreated={onstallCreated} />

                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>

                            <span className='card-label fw-bold fs-3 mb-1'>Stalls Chart</span>

                        </h3>
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add a stall'
                        >
                            <a
                                href='#'
                                className='btn btn-sm btn-light-primary float-end'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_stall_chart'
                            >
                                <KTIcon iconName='plus' className='fs-3' />
                                Create Stall
                            </a>
                        </div>
                    </div>
                    <div className='card-toolbar'>



                        <div className='card-body py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>


                                            <th className='min-w-120px'>Stall Type</th>
                                            <th className='min-w-120px'>Stall Id</th>
                                            <th className='min-w-120px'>Dimension</th>
                                            <th className='min-w-120px'>Actions</th>


                                        </tr>
                                    </thead>

                                    <tbody>

                                        {paginatedData.slice().reverse().map((stalluser) => (
                                            <tr key={stalluser.id}>





                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {stalluser.stalltype}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                                        {stalluser.stallid}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold fs-6'>
                                                        {stalluser.dimension}
                                                    </div>
                                                </td>
                                                
                                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div onClick={(e) => removeStall(stalluser)}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <MdDeleteForever style={{ fontSize: '30px' }} />

                                                    </div>
                                                    <Link
                                                        to={`/EditStallChart/${eventId}/${stalluser.id}`}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-1' />
                                                    </Link>


                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>


                        </div>

                        {stalls.length > 0 && (
                            <Pagination
                                data={stalls}
                                itemsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                currentPage={page}
                            />
                        )}
                    </div>
                </div>
            )}


        </div>
    )
}
