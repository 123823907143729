import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditUSPIntention() {
  //const [USPIntens, setUSPIntens] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {auth} = useAuth();
  const { intentionid } = useParams();
  const [intens, setIntens] = useState({
    name: ""
  });
  
 
  useEffect(() => {
    axios
      .get(API_URL + `/api/UspIntentions/${intentionid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setIntens(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching USPIntentions. Please try again.';
        toast.error(errorMessage);
      });
  }, [intentionid, token]); 

  
  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setIntens((intens) => ({ ...intens, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/UspIntentions/update", intens, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Updated Succesfully");
        navigate("/USPIntentions");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update USPIntentions. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3>USP Intention</h3>

                      
</div>
</div>

<br>
</br>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="name">Intention Name</label>
              <input
                className="form-control"
                id="name"
                type="text"
                placeholder="Intention Name "
                value={intens.name}
                 onChange={(e) => handleChange(e, "name")}
              />
            </div>
          </div>
          <br></br>

        
<br></br>
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUSPIntention;
