/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { toast } from 'react-toastify';
import { toAbsoluteUrl } from '../../../helpers'
import { useNavigate } from "react-router-dom"
import { Logout } from '../../../../app/modules/auth'
import { FaRegUser } from "react-icons/fa";


const HeaderUserMenu: FC = () => {
  const { currentUser, logout, auth } = useAuth()
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const role = data?.role;
  const navigate = useNavigate();
  const [user, setUser] = useState([]);

  const API_URL = process.env.REACT_APP_API_URL;

  function handleSwtich() {
    axios
      .get(API_URL + `/switchAccount`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`
        },
      })
      .then((response) => {
        setUser(response.data);
        navigate('/userEvents', { state: { user: response.data } });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser?.image ? (
              <img src={`${currentUser.imageMeta},${currentUser.image}`} alt={currentUser.email} />
            ) : (
              <FaRegUser size={25} />
            )}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.firstname}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}

      {/* <div className='menu-item px-5'>
      <Link to='/systemSettings' className='menu-link px-5'>
        
          <span className='menu-text'>System Settings</span>
          <span className='menu-badge'>
            
          </span>
       </Link>
      </div> */}
      {`${role}` !== "ROLE_SUPER" && `${role}` !== "ROLE_EXHIBITOR" ?

        <div className='menu-item px-5 my-1'>
          <Link to='/subscription' className='menu-link px-5'>
            Account Subscription
          </Link>
        </div>
        : ""}
      {`${role}` !== "ROLE_SUPER" ?
        <div className='menu-item px-5 my-1'>
          <div onClick={handleSwtich} className='menu-link px-5'>
            Switch Account
          </div>
        </div>
        : ""}
       {`${role}` === "ROLE_COMMITEE_MEMBER" ?
  (
    <div className='menu-item px-5 my-1'>
      <Link to='/profilecommittee' className='menu-link px-5'>
        Account Settings
      </Link>
    </div>
  ) : (
    
    `${role}`!== "ROLE_EXHIBITOR" ?
    
        <div className='menu-item px-5 my-1'>
          <Link to='/Profile' className='menu-link px-5'>
          Account Settings
          </Link>
        </div>
       : (
        <div className='menu-item px-5 my-1'>
          <Link to='/profiletabs' className='menu-link px-5'>
            Account Settings
          </Link>
        </div>
      )
  )
}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
