import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import { useParams } from 'react-router-dom';

const AddAdvsettings = ({ onstallCreated }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { auth } = useAuth();
    const userid= auth.userid
    
    const [stall, setStall] = useState({
        advertisementtypeid: '',
        timeallowed: '',
        cost: '',
        type: "",
        id: "",
        userid: userid,
        total:"",
        action: false,
    });
    const [Advetype, setAdvetype] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        axios
            .get(API_URL + '/api/advertisementtype', {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setAdvetype(response.data);
            })
            .catch((error) => {
                toast.error('Error Fetching :', error);
            });
    }, [auth?.token]);

    const handleChange = (field, value) => {
        setStall({ ...stall, [field]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post(API_URL + '/api/advertisementsettings/create', stall, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then((response) => {
                toast.success('Created Successfully');
                onstallCreated();
                closeModal();
            })
            .catch((error) => {
                toast.error('Error creating Advertisement Type', error);
            });
    };
    

    const closeModal = () => {
        setModalOpen(true);
    };

    return (
        <div className='modal fade' id='kt_modal_stall_chart' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Create Advertisement Type</h1>
                        </div>

                        {/* Form */}
                        <form onSubmit={handleSubmit}>

                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label>Advertisement Settings</label>
                                    <select
                                        id='advertisementtypeid'
                                        className='form-control'
                                        onChange={(e) => handleChange('advertisementtypeid', e.target.value)}
                                        value={stall.advertisementtypeid}
                                    >
                                        <option value="">Select...</option>
                                        {Advetype.map((type) => (
                                            <option key={type.id} value={type.advertisementtypeid}>
                                                {type.type}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label htmlFor='timeallowed'>Time Allowed</label>
                                    <input
                                        className='form-control'
                                        id='timeallowed'
                                        type='text'
                                        placeholder='Time Allowed'
                                        autoComplete='off'
                                        onChange={(e) => handleChange('timeallowed', e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label>Cost</label>
                                    <input
                                        className='form-control'
                                        id='cost'
                                        type='text'
                                        placeholder='Cost'
                                        autoComplete='off'
                                        onChange={(e) => handleChange('cost', e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className='text-end'>
                                <button className='btn btn-primary' type='submit' data-bs-dismiss='modal'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdvsettings;
