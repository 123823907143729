import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateEventRole from '../../../partials/modals/Add Pages/CreateEventRole';
import { KTIcon } from '../../../helpers';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function EventRoles() {
  const [Eventrole, setEventrole] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const [updateFlag, setUpdateFlag] = useState(false);
  const rowsPerPage = 30;
  const {auth} = useAuth;
  const [loadingUserId, setLoadingUserId] = useState(null);
  const { eventId } = useParams()

  function onClickhandler(e, id) {
    const checked = e.target.checked;
    const disabled = !checked;
    setLoadingUserId(id) 



    axios
      .get(API_URL + `/api/eventrolepermission/disabled/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Event Role Updated Succesfully")
         setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error('Error updating account:', error);
        setLoadingUserId(null);
      });
  }

  
  

  const handleEventRoleCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/eventrolepermission/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setEventrole(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching EventRole:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [updateFlag]);

  function fetchData() {
    setLoading(true)
    axios
      .get(API_URL + `/api/eventrolepermission/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setEventrole(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Event Roles. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
    };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const paginatedData = Eventrole.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div className='card card-raised'>
      <CreateEventRole event={eventId} onEventRoleCreated={handleEventRoleCreated}/>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1'>Event Role Users Permissions </span>


        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_event_role'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </a>
        </div>
      </div>
      <div className='card-toolbar'></div>
      <div className='card-toolbar'>
        <div className='card-body py-3'>
          {loading ? (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          ) : (
            <div className='table-responsive'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>

                    
                    <th className='min-w-120px'>Role Name </th>
                    <th className='min-w-120px'>User Name</th>
                    <th className='min-w-120px'>Permissions</th>
                    <th className='min-w-120px'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedData.slice().reverse().map((event) => (
                    <tr key={event.id}>

                      <td>
                        <div className='text-dark fw-bold fs-6'>
                          {event.role}
                        </div>
                        </td>

                        <td>
                        <div className='text-dark fw-bold fs-6'>
                          {event.userid}
                        </div>
                        </td>
                        
                        <td>
                        <div className='text-dark fw-bold fs-6'>
                          {event.permissions}
                        </div>
                      </td>

                    

                      <td style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                          {loading && loadingUserId === event.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}

                            <input
                              className="form-check-input"
                              id="flexSwitchCheckDefault"
                              type="checkbox"
                              defaultChecked={!event.disabled}
                              onClick={(e) => onClickhandler(e, event.id)}
                              value={event.id}
                            />
                             {loadingUserId === event.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                          </div>
                          <Link
                            to={`/editeventrole/${eventId}/${event.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                        </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {Eventrole.length > 0 && (
                <Pagination
                  data={Eventrole} // Pass the full data to the Pagination component
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={page}
                />
              )}
            </div>

          )}
        </div>
      </div>
    </div>
  );
}

export default EventRoles;
