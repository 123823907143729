import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../app/modules/auth';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const API_URL = process.env.REACT_APP_API_URL;

const UpdateSubscription = ({ userId }) => {
    const username = userId;
    const auth = useAuth();
    const [subscriptions, setSubscriptions] = useState([]);
    const [showSubForm, setSubForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [expandedSubIndex, setExpandedSubIndex] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const [subscribe, setSubscribe] = useState({
        id: 0,
        subcode: "",
        expires: "",
        subdate: "",
        transactionLink: "",
        username: "",
    });

    useEffect(() => {
        getData();
    }, [auth?.token, username]);

    async function getData() {
        try {
            const response = await axios.get(`${API_URL}/api/user/subscription/username=${username}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            });
            setSubscriptions(response.data);
        } catch (error) {
            toast.error(`Error Fetching Subscription Details: ${error.message}`);
        }
    }

   

    const handleChange = (event) => {
      
        setSubscribe((prevSubscribe) => ({
            ...prevSubscribe,
            expires: event.toISOString().split('T')[0],
        }));
    };

    const handleChangeDate = (date) => {
        setSelectedDate(date);
        setSubscribe((prevSubscribe) => ({
            ...prevSubscribe,
            expires: date.toISOString().split('T')[0],
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        axios.post(`${API_URL}/api/user/subscription/update`, subscribe,
            {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then(response => {
                setSubscribe(response.data);
                setLoading(false);
                toast.success("updated Successfully")
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };


    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_subscription_method'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Subscriptions</h3>
                </div>
            </div>

            <div id='kt_account_subscription_method' className='collapse show'>
                <div className='card-body border-top p-9'>
                    <div className='d-flex flex-wrap align-items-center'>
                        <div id='kt_signin_email'>
                            <div className='fs-6 fw-bolder mb-1'>Username</div>
                            <div className='fw-bold text-gray-600'>{username}</div>
                        </div>
                    </div>

                    <div className='separator separator-dashed my-6'></div>

                    {subscriptions.slice().reverse().map((sub, index) => (
                        <div key={sub.id} className='d-flex flex-wrap align-items-center mb-10'>
                            <div id='kt_signin_subcode' className={' ' + ((showSubForm && expandedSubIndex === index) && 'd-none')}>
                                <div className='fs-6 fw-bolder mb-1'>Subscription {index} </div>
                            </div>

                            <div id='kt_signin_subcode_edit' className={'flex-row-fluid ' + (!(showSubForm && expandedSubIndex === index) && 'd-none')}>
                                <form onSubmit={(e) => handleSubmit(e, subscribe)} className='form' noValidate>
                                    <div className='row mb-1'>
                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-3'>
                                                <label htmlFor='subcode' className='form-label fs-6 fw-bolder mb-3'>
                                                    Subcode Code
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid fs-6'
                                                    id='subcode'
                                                    onChange={(e) => handleChange(e, 'subcode')}
                                                    value={subscribe.subcode || sub.subcode}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='subdate' className='form-label fs-6 fw-bolder mb-3'>
                                                    Subscribed Date
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid fs-6'
                                                    id='subdate'
                                                    onClick={(e) => handleChange(e, 'subdate')}
                                                    value={subscribe.subdate || sub.subdate}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-4'>
                                            <div className='fv-row mb-0'>
                                                <label htmlFor='expires' className='form-label fs-6 fw-bolder mb-3'>
                                                    Expired Date
                                                </label>
                                                <DatePicker
                                                    id='expires'
                                                    selected={selectedDate || (subscribe.expires ? new Date(subscribe.expires) : null)}
                                                    onChange={handleChangeDate}
                                                    dateFormat='dd/MMM/yyyy'
                                                    className='form-control form-control-lg form-control-solid fs-6'
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex'>
                                        <button type='submit' className='btn btn-primary me-2 px-6' disabled={loading}>
                                            {!loading ? 'Update' : 'Updating...'}
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-color-gray-400 btn-active-light-primary px-6'
                                            onClick={() => {
                                                setSubForm(false);
                                                setExpandedSubIndex(null);

                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div id='kt_signin_subcode_button' className={'ms-auto ' + (showSubForm && 'd-none')}>
                                <button
                                    onClick={() => {
                                        setSubForm(true);
                                        setExpandedSubIndex(index);
                                        setSubscribe(sub);
                                    }}
                                    className='btn btn-light btn-active-light-primary'
                                >
                                    Reset Subscription Date
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export { UpdateSubscription };
