import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import Pagination from '../../app/modules/auth/components/Pagination';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';


const API_URL = process.env.REACT_APP_API_URL;

function AccountUser() {
  const [accountuser, setAccountUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const auth = useAuth();
  const rowsPerPage = 30;
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetchData(currentPage);
  }, [updateFlag]);


  function fetchData(page) {
    setLoading(true)
    axios
      .get(API_URL + `/api/accounts/getAll/page=${page}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setAccountUser(response.data.pageData);
        setTotalPage(response);
        setCurrentPage(page);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error fetching :', error);
        setLoading(false);
      });
  }

  const handlePageChange = page => {
    fetchData(page);
  };

  function onClickhandler(e, userid) {
    const checked = e.target.checked;

    const disabled = checked;
    setLoadingUserId(userid);
   
    axios
      .get(API_URL + `/api/accounts/accountUpdate/id=${userid}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("User updated successfully");
        setUpdateFlag((prevFlag) => !prevFlag);
          })
      .catch((error) => {
        toast.error('Error updating account:', error);
        setLoadingUserId(null);
      });
  }

  const filteredData = accountuser.filter((accountusers) =>
    accountusers.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>

          <div className='card-header border-0 pt-5'>
            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control'
                  placeholder='Search Users'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: '100%', // Full width by default
                    marginBottom: '10px', // Optional: Add margin to the bottom for better spacing
                  }}
                />
              </div>
            </div>
          </div>

          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>User List</span>
            </h3>
          </div>

          
            <div className='card-toolbar'>
              <div className='card-body py-3'>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>

                        <th className='min-w-40px'>Email</th>
                        <th className='min-w-80px'>Create Date&Time</th>
                        <th className='min-w-80px'>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.slice().reverse().map((accountusers) => (
                        <tr key={accountusers.id}>

                          <td>
                            <div
                              onClick={() => navigate(`/AccSetting/${accountusers.username}`)}
                              className='text-dark fw-bold text-hover-primary fs-6'
                              style={{ cursor: 'pointer' }}
                            >
                              {accountusers.username}
                            </div>
                          </td>

                          <td>
                            <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {accountusers.createdTime}
                            </div>
                          </td>

                          <td>
                            <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px' }}>
                              
                              <input
                                className="form-check-input"
                                id={`flexSwitchCheckDefault-${accountusers.id}`}  // Unique id for each checkbox
                                type="checkbox"
                                defaultChecked={accountusers.disabled}
                                onClick={(e) => onClickhandler(e, accountusers.id)}
                                value={accountusers.id}
                              />
                              {loadingUserId === accountusers.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                              <label className="form-check-label" htmlFor={`flexSwitchCheckDefault-${accountusers.id}`}></label>
                            </div>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className='pagination-container' style={{ marginRight: '140px' }}>
                    <Pagination
                      data={accountuser} // Pass the full data to the Pagination component
                      itemsPerPage={rowsPerPage}
                      onPageChange={handlePageChange}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
      
        </div>
      )}
    </div>
  );
}

export default AccountUser;
