import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditTaskPriority() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {taskpriorityid} = useParams();
  const {auth} =useAuth();
  const [taskpriority, setTaskpriority] = useState({

   prioritytype:"",
    description:""
   
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/TaskPriority/${taskpriorityid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setTaskpriority(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Task Priorities. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [taskpriorityid, token]); // Include taskid as a dependency



  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setTaskpriority((prevTaskpriority) => ({ ...prevTaskpriority, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/TaskPriority/update", taskpriority, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Task Priority Updated");
        navigate("/taskpriorities");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update Taak Priority. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
        <div>
    {loading ? (
      <Loader />
    ) : (
      <div id="layoutDrawer_content">
       
        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Edit Task Priority </span>
           </h3>
                      
                    </div>
                  </div>
                  <br></br>

                  <div className="form-floating-sm mb-2"></div>
 

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="prioritytype">Priority Type </label>
              <input
                className="form-control"
                id="prioritytype"
                type="text"
                placeholder="Priority Type "
                value={taskpriority.prioritytype}
                 onChange={(e) => handleChange(e, "prioritytype")}
              />
            </div>
          </div>
          <br></br>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="description">Description</label>
              <textarea
                className="form-control"
                id="description"
                type="text"
                placeholder="Description"
                value={taskpriority.description}
                 onChange={(e) => handleChange(e, "description")}
              />
            </div>
          </div>
          <br></br>

          
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                  
                </form>
                </div>
                </div>
                </div>
            
        </main>
        </div>
    )}
    </div>
    </div>
  );
}

export default EditTaskPriority;
