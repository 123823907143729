import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { KTIcon } from "../../../helpers";
import { useAuth } from "../../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;


function CreateEvent({ onEventCreated }) {

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const {auth}= useAuth();
  const {navigate} = useNavigate();
  const token = localStorage.getItem('token');
  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const org = data?.orgid;

  const [events, setEvents] = useState({
    orgid: org,
    title: "",
    startdate: "",
    location: "",
    description: "",
    enddate: "",
    mascotMeta: "",
    theme: "",
    oneliner: "",
    snippets: "",
    curtenraiser: "",
    latitude: "",
    longitude: "",
    email:"",
    phone:""
  }
  )

  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    setEvents({ ...events, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
    
      !events.title ||
      !events.startdate ||
      !events.enddate ||
      !events.theme ||
      !events.oneliner

    ) {
      toast.error("Please fill the required fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(API_URL + "/api/EventPlannings/create", events, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      });
      const result = response.data;
      setLoading(false);
      onEventCreated();
      toast.success("Created Successfully");
      resetForm();
      closeModal();
      navigate(-1);
    } catch (error) {
      setLoading(false);
      toast.error("Error creating event. Please try again.");
    }
  };


  const closeModal = () => {
    resetForm();
    setModalOpen(false);
  };

  const resetForm = () => {
    setEvents({
      orgid: "",
      title: "",
      startdate: "",
      location: "",
      description: "",
      enddate: "",
      mascotMeta: "",
      theme: "",
      oneliner: "",
      snippets: "",
      curtenraiser: "",
      latitude: "",
      longitude: ""
    });
  };

  return (
    <div className='modal fade' id='kt_modal_invite_event' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center'>
              <h1>Create New Event</h1>
            </div>

            <div>
              <div id="layoutDrawer_content">

                {loading && <div className="loading"></div>}

                <main>

                  <div className="col-12 mx-xl-auto ps-xl-12">

                    <div className="card card-raised ">
                      <form onSubmit={handleSubmit}>



                        <div className="row">
                          <div className="form-floating-sm mb-1">
                            <input
                              className="form-control"
                              id="orgid"
                              type="text"
                              placeholder="Org Id"
                              value={org}
                              readOnly
                              style={{ visibility: 'hidden' }}
                            />
                          </div>
                        </div>
                        <br></br>

                        <div className="row ">
                          <div className="form-floating-sm mb-2 ">
                            <label htmlFor="title">Tittle  <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="title"
                              type="text"
                              placeholder="Tittle"
                              onChange={(e) => handleChange(e, "title")}
                              required
                            />
                          </div>
                        </div>
                    


                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="phone">Theme  <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="theme"
                              type="text"
                              placeholder="Theme"
                              onChange={(e) => handleChange(e, "theme")}
                              required
                            />
                          </div>
                        </div>
                     

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="oneliner">One Liner  <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="oneliner"
                              type="text"
                              placeholder="One Liner"
                              onChange={(e) => handleChange(e, "oneliner")}
                              required
                            />
                          </div>
                        </div>
                       


                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="startdate">Start date  <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="startdate"
                              type="date"
                              placeholder="Start date"
                              onChange={(e) => handleChange(e, "startdate")}
                              required
                            />
                          </div>
                        </div>
                       

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="enddate">End date  <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="enddate"
                              type="date"
                              placeholder="End date"
                              onChange={(e) => handleChange(e, "enddate")}
                              required
                            />
                          </div>
                        </div>
                       

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="location">Location <span className="required"></span></label>
                            <input
                              className="form-control"
                              id="location"
                              type="text"
                              placeholder="Location"
                              onChange={(e) => handleChange(e, "location")}
                            
                            />
                          </div>
                        </div>
                      

                        <div className="row">
                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="email">Event Mail </label>
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              placeholder="Event Email Address"
                              onChange={(e) => handleChange(e, "email")}
                             
                            />
                          </div>
                          

                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="phone">Contact</label>
                            <input
                              className="form-control"
                              id="phone"
                              type="tel"
                              placeholder="Contact"
                              onChange={(e) => handleChange(e, "phone")}
                             
                            />
                          </div>
                        </div>
                        

                       
                        <div className="row">
                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="latitude">Latitude</label>
                            <input
                              className="form-control"
                              id="latitude"
                              type="text"
                              placeholder="Latitude"
                              onChange={(e) => handleChange(e, "latitude")}
                              
                            />
                          </div>
                          
                          <div className="col-6 form-floating-sm mb-2">
                            <label htmlFor="longitude">Longitude</label>
                            <input
                              className="form-control"
                              id="longitude"
                              type="text"
                              placeholder="Longitude"
                              onChange={(e) => handleChange(e, "longitude")}
                              
                            />
                          </div>
                        </div>
                     

                        <div className="row">
                          <div className="form-floating-sm mb-2">
                            <label htmlFor="description">Description</label>
                            <textarea
                              className="form-control"
                              id="description"
                              type="text"
                              placeholder="Description"
                              onChange={(e) => handleChange(e, "description")}

                            />
                          </div>
                        </div>



                        <div className="text-end">
                          <input
                            className="btn btn-primary"
                            type="submit"
                            disabled={loading}
                            value="Submit"
                            data-bs-dismiss='modal'
                            
                          />
                        </div>
                      </form>

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}





export default CreateEvent;



