import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

function EditRoleUsers() {
  const [events, setEvents] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const {auth} = useAuth();
  const navigate = useNavigate();

  const [task, setTask] = useState({
    eventid: "",
    roleid:"",
    userid:""
   
  });

  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(API_URL + `/api/eventrole`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setEvents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Events:", error);
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(API_URL + `/api/eventroleuser`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPriority(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Task Priorities:", error);
      });
  }, [auth?.token]);

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setTask((prevTask) => ({ ...prevTask, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/Task/create", task, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("roleusers Updated");
        navigate("/events/roleusers");
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Edit Role Users</span>
           </h3>
                      <select
                        id="eventid"
                        type="text"
                        placeholder="select event"
                        className="form-control"
                        onChange={(e) => handleChange(e, "eventid")}
                      >
                        <option value="">Select event</option>
                        {events.map((event) => (
                          <option key={event.id} value={event.title}>
                            {event.title}
                          </option>
                        ))}
                      </select>{" "}
                    </div>
                  </div>
                  <br></br>

                  <div className="form-floating-sm mb-2"></div>
      

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label>Role</label>
                      <select
                        id="roleid"
                        type="text"
                        placeholder="Select event"
                        className="form-control"
                        onChange={(e) => handleChange(e, "roleid")}
                      >
                        <option value="">Select</option>
                        {priority.map((pr) => (
                          <option key={pr.id} value={pr.id}>
                            {pr.prioritytype}
                          </option>
                        ))}
                      </select>{" "}
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label>User ID</label>
                      <select
                        id="userid"
                        type="text"
                        placeholder="Select event"
                        className="form-control"
                        onChange={(e) => handleChange(e, "userid")}
                      >
                        <option value="">Select</option>
                        {priority.map((pr) => (
                          <option key={pr.id} value={pr.id}>
                            {pr.prioritytype}
                          </option>
                        ))}
                      </select>{" "}
                    </div>
                  </div>
                  <br></br>

                 


                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                  
                </form>
                </div>
                </div>
                </div>
            
        </main>
        </div>
    </div>
  );
}

export default EditRoleUsers;
