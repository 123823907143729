import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import Navbar from '../../../../_metronic/Public/PublicHome/Navbar'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Please Enter valid email'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must have at least 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character'
    )
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  passwordConfirm: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  eventrole: Yup.string().required('Please select a valid Role'),


})

const API_URL = process.env.REACT_APP_API_URL;

export function ExhibitorRegister() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const [eventRoles, setEventRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [eventSectors, setEventSectors] = useState([]);

  const [event, seteventDetails] = useState({});
  const { eventId } = useParams();

  useEffect(() => {
    setLoading(true);
    axios.get(API_URL + `/api/EventPlannings/${eventId}`)
      .then(response => {
        seteventDetails(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching eventlist', error);
        setLoading(false);
      });
  }, [eventId]);


  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/sectors`)
      .then((response) => {
        setEventSectors(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error fetching sector:', error);
        setLoading(false);
      });
  }, []);


  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/accountRole`)
      .then((response) => {
        // setEventRoles(response.data);
        const rl = response.data;
        const rolesToDisplay = rl.slice(2);
        setEventRoles(rolesToDisplay);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error fetching EventRole:', error);
        setLoading(false);
      });
  }, []);

  const handleChange = (event) => {
    const actualValue = event.target.value;
    setSelectedRole(actualValue); // Update the selected role
    formik.setFieldValue('eventrole', actualValue); // Update formik field value
    formik.setFieldValue('eventid', event); // Update formik field value
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirm: '',
      eventrole: '',
      sector: '',
    },
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const { data: auth } = await register(
          values.email,
          values.password,
          values.passwordConfirm,
          values.eventrole,
          1,
          values.sector
        );
        navigate(`/event/${event?.id}/eventSignup`);
        toast.success('Registration Completed');
      } catch (error) {
        toast.error(error);
        setSubmitting(false);
        setLoading(false);
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (

    <div>
      <Navbar event={event} />
      <div className="container mt-10">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6">
            <form
              className='form'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formik.handleSubmit}
            >
              <div className='card p-4'>
                <div className='text-center mb-4'>
                  <h1 className='text-dark fw-bolder mb-3'>Event Member Registration</h1>
                </div>

                <div className='mb-3'>
                  <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      { 'is-valid': formik.touched.email && !formik.errors.email }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='invalid-feedback'>
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                <div className='mb-3'>
                  <div className='row'>
                    <div className='col'>
                      <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid': formik.touched.password && formik.errors.password,
                          },
                          {
                            'is-valid': formik.touched.password && !formik.errors.password,
                          }
                        )}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className='invalid-feedback'>
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                    <div className='col'>
                      <input
                        type='password'
                        placeholder='Confirm Password'
                        autoComplete='off'
                        {...formik.getFieldProps('passwordConfirm')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid': formik.touched.passwordConfirm && formik.errors.passwordConfirm,
                          },
                          {
                            'is-valid': formik.touched.passwordConfirm && !formik.errors.passwordConfirm,
                          }
                        )}
                      />
                      {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
                        <div className='invalid-feedback'>
                          {formik.errors.passwordConfirm}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='mb-3'>
                  <div className='position-relative mb-3'>
                    <select
                      id='eventrole'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('eventrole')}
                      className={clsx(
                        'form-control',
                        {
                          'is-invalid': formik.touched.eventrole && formik.errors.eventrole,
                        },
                        {
                          'is-valid': formik.touched.eventrole && !formik.errors.eventrole,
                        }
                      )}
                    >
                      <option>Select Role</option>
                      {eventRoles.map((role) => (
                        <option key={role.role} value={role.role}>
                          {role.role}
                        </option>
                      ))}
                    </select>
                    {formik.touched.eventrole && formik.errors.eventrole && (
                      <div className='invalid-feedback'>
                        {formik.errors.eventrole}
                      </div>
                    )}
                  </div>
                </div>

                {formik.values.eventrole === 'ROLE_EXHIBITOR' && (
                  <div className='mb-3'>
                    <div className='position-relative mb-3'>
                      <select
                        id='sector'
                        type='text'
                        autoComplete='off'
                        {...formik.getFieldProps('sector')}
                        className={clsx(
                          'form-control',
                          {
                            'is-invalid': formik.touched.sector && formik.errors.sector,
                          },
                          {
                            'is-valid': formik.touched.sector && !formik.errors.sector,
                          }
                        )}
                      >
                        <option>Select Sector</option>
                        {eventSectors.map((es) => (
                          <option key={es.name} value={es.name}>
                            {es.name}
                          </option>
                        ))}
                      </select>
                      {formik.touched.sector && formik.errors.sector && (
                        <div className='invalid-feedback'>
                          {formik.errors.sector}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="row px-20">
                  <div className='mt-4 mx-20'>
                    <Link to={{ pathname: `/event/${event?.id}/eventSignup` }}>
                      <button
                        className='btn btn-md btn-primary btn-md mb-3 rounded-pill'
                        id="back"
                      >
                        Back
                      </button>
                    </Link>

                    <button
                      type='submit'
                      id='kt_sign_up_submit'
                      className='btn btn-md btn-primary btn-md mb-3 rounded-pill'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Submit</span>}
                      {loading && (
                        <span className='indicator-label'>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>

                  </div>
                </div>
              </div>

              {formik.status && (
                <div className='mb-lg-4 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}