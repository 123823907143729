import React, { useEffect, useState } from 'react';
import { KTIcon } from '../../helpers';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import CreateOrg from '../../partials/modals/Add Pages/CreateOrg';
import Pagination from '../../../app/modules/auth/components/Pagination';
import Neworganization from '../../partials/modals/Add Pages/Neworganization';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import { useAuth } from '../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function Organizationlist() {
  const [organizationlist, setOrganizationlist] = useState([]);
  const [Details, setDetails] = useState([]);
  const [List, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");

  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const orgid = data?.orgid;
  const rowsPerPage = 15;

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchData(currentPage);
  }, []);


  function fetchData(page) {
    setLoading(true);
    axios
      .get(API_URL + `/api/organizationprofiles/getAll/page=${page}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setOrganizationlist(response.data.pageData);
        setTotalPage(response.data.page);
        setCurrentPage(page);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Orghanizations. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }

  const handlePageChange = page => {
    fetchData(page);
  };

  const [curtainraiserFilter, setCurtainraiserFilter] = useState('all');

  const handleCurtainraiserFilterChange = (filter, page) => {

    if (filter === 'all') {
      axios
        .get(API_URL + `/api/organizationprofiles/getAll/page=${page}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setList(response.data.pageData);
          setTotalPage(response.data.page);
        })
        .catch(error => {
          toast.error('Error fetching curtainraiser events:', error);
        });

    }

    else {

      axios
        .get(API_URL + `/api/organizationprofiles/status=${filter}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then(response => {
          setDetails(response.data);
        })
        .catch(error => {

          toast.error('Error fetching curtainraiser events:', error);
        });
    }

  };


  const SearchedData = organizationlist.filter((accountusers) =>
    accountusers.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = SearchedData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const navigate = useNavigate();
 





  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>

          <Neworganization />

          <h3>
            Organization List

          </h3>

          <div className='row m-2 p-3'>
            <div className='col-6'>

              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 ms-2' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-100'
                  placeholder='Search here..'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '100%' }}
                />
              </div>

            </div>
            <div className='col-12 d-flex align-items-center justify-content-end'>
              <div className='dropdown'>
                <button
                  className='btn btn-sm btn-light-primary dropdown-toggle w-100'
                  type='button'
                  id='curtainraiserFilterDropdown'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  Filter
                </button>
                <ul className='dropdown-menu' aria-labelledby='curtainraiserFilterDropdown'>
                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'all' ? 'active' : ''}`}
                      onClick={() => handleCurtainraiserFilterChange('all')}
                    >
                      All Organization
                    </button>
                  </li>
                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'true' ? 'active' : ''}`}
                      onClick={() => handleCurtainraiserFilterChange('true')}
                    >
                      Active Status
                    </button>
                  </li>
                  <li>
                    <button
                      className={`dropdown-item ${curtainraiserFilter === 'false' ? 'active' : ''}`}
                      onClick={() => handleCurtainraiserFilterChange('false')}
                    >
                      In-Active Status
                    </button>
                  </li>
                </ul>
              </div>
              <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a new Organization'>
                <Link to="/addAdmin" className='btn btn-sm btn-light-primary w-100 mb-2'>
                  <KTIcon iconName='plus' className='fs-3' />
                  Create Organization
                </Link>
              </div>

            </div>
          </div>




          <div className='row g-5 g-xl-8'>
            {paginatedData.slice().reverse().map((organizationlist) => (


              <div className='col-xl-6'>
                <Link to={`/OrgDetailedlist/${organizationlist.id}`}>
                  <div className="card card-raised">
                    <div>
                      <div className='card-toolbar float-end'>
                        {/* begin::Menu */}
                        {<button
                          type='button'
                          className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='float-end'
                        >
                          <Link
                            to={`/updateOrganization/${organizationlist.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-2' />
                          </Link>
                        </button>}
                        {/* <Dropdown1 /> */}
                        {/* end::Menu */}
                      </div>

                      {/* begin::Header */}
                      <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>

                          <span className='card-label fw-bold text-dark'><Link to={`/OrgDetailedlist/${organizationlist.id}`}>{organizationlist.name}</Link></span>
                          <span className='text-muted mt-1 fw-semibold fs-7'><b>Code:</b>{organizationlist.orgcode}</span>
                          <span className='mt-1 fw-semibold fs-7'><b>Admin:</b> {organizationlist.admin}</span>
                        </h3>


                      </div>
                      {/* end::Header */}


                      <div className='row'>
                        <div>

                        </div>
                      </div>

                      {/* begin::Body */}
                      <div className='card-body pt-5'>
                        {/* begin::Item */}

                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Symbol */}
                          <div className='symbol symbol-50px me-5'>
                            <span className='symbol-label bg-light-warning'>
                              <KTIcon iconName='calendar' className='fs-2x text-warning' />
                            </span>
                          </div>
                          {/* end::Symbol */}
                          {/* begin::Text */}
                          <div className='d-flex flex-column'>
                            <span className='text-muted fw-semibold'>Contact</span>
                            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                              {organizationlist.contact}
                            </a>

                          </div>
                          {/* end::Text */}
                        </div>
                        {/* end::Item */}
                        {/* begin::Item */}
                        <div className='d-flex align-items-center mb-7'>
                          {/* begin::Symbol */}
                          <div className='symbol symbol-50px me-5'>
                            <span className='symbol-label bg-light-primary'>
                              <KTIcon iconName='geolocation' className='fs-2x text-primary' />
                            </span>
                          </div>
                          {/* end::Symbol */}
                          {/* begin::Text */}
                          <div className='d-flex flex-column'>
                            <span className='text-muted fw-semibold'>Address</span>
                            <a href='#' className='text-dark text-hover-primary fs-6 fw-bold'>
                              {organizationlist.address}
                            </a>

                          </div>
                          {/* end::Text */}
                        </div>
                        {/* end::Item */}
                      </div>
                      {/* end::Body */}
                    </div>
                  </div>
                </Link>
              </div>

            ))}

            {organizationlist.length > 0 && (
              <div className='pagination-container' style={{ marginRight: '80px' }}>
                <Pagination
                  data={organizationlist} // Pass the full data to the Pagination component
                  itemsPerPage={rowsPerPage}
                  onPageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      )}

    </div>


  )
}
export default Organizationlist;
