import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../../app/modules/auth";
import { useNavigate, useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditOrganization() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { organizationid } = useParams();
  const {auth}= useAuth();
  const [organizationlist, setOrganizationlist] = useState({
    name: "",
    contact: "",
    address: "",
    admin: "",
    description:"",
    pan:"",
    gstin:"",
    tan:"",

  });

  
  useEffect(() => {
    axios
      .get(API_URL + `/api/organizationprofiles/${organizationid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setOrganizationlist(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching Organization. Please try again.';
       toast.error(errorMessage);
      });
  }, [organizationid, token]); // Include taskid as a dependency

  
  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setOrganizationlist((prevOrganization) => ({ ...prevOrganization, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);

    axios
      .post(API_URL + "/api/organizationprofiles/update", organizationlist, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() =>  {
        toast.success("orgnization Updated Succesfully");
        navigate("/organizationlist");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Updating Organization. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Edit Organizations</span>
           </h3>
                     
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name">Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={organizationlist.name}
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contact">Contact</label>
                       <input
                        className="form-control"
                        id="contact"
                        type="text"
                        placeholder="Contact"
                        value={organizationlist.contact}
                        onChange={(e) => handleChange(e, "contact")}
                        />
                    </div>
                  </div>
                 
                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="address">Address</label>
                      <input
                        className="form-control"
                        id="address"
                        type="text"
                        placeholder="Address"
                        value={organizationlist.address}
                        onChange={(e) => handleChange(e, "address")}
                      />
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pan">Pan</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        placeholder="Pan"
                        value={organizationlist.pan}
                        onChange={(e) => handleChange(e, "pan")}
                      />
                    </div>
                  </div>
                
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="gstin">Gstin</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        placeholder="Gstin"
                        value={organizationlist.gstin}
                        onChange={(e) => handleChange(e, "gstin")}
                      />
                    </div>
                  </div>
                 
                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="tan">Tan</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        placeholder="Tan"
                        value={organizationlist.tan}
                        onChange={(e) => handleChange(e, "tan")}
                      />
                    </div>
                  </div>
                  

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <input
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Tan"
                        value={organizationlist.description}
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </div>
                  </div>


                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="admin">Contact Admin</label>
                      <input
                        className="form-control"
                        id="admin"
                        type="text"
                        placeholder="Admin"
                        value={organizationlist.admin}
                        onChange={(e) => handleChange(e, "admin")}
                      />
                    </div>
                  </div>



                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditOrganization;
