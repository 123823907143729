import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import '../../users/sub.css';
import { RxCross2 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa';
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';

const SubPermissions = () => {
  const { auth } = useAuth();
  const [types, setType] = useState([]);
  const [feature, setFeature] = useState([]);
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedType, setSelectedType] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editedFeatures, setEditedFeatures] = useState([]);

  const handleEditClick = () => {
    setEditing(true);
    const copiedFeatures = JSON.parse(JSON.stringify(feature));
    setEditedFeatures(copiedFeatures);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/subscriptionFeatures`)
      .then(response => {
        setFeature(response.data);
         setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching subscription Type:', error);
        setLoading(false);
      });
  }, []);



  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/subscriptiontype`)
      .then(response => {
        setType(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching subscription Type:', error);
        setLoading(false);
      });
  }, []);


  const handleSubmit = async () => {
    setLoading(true);

    const payload = editedFeatures.map((f) => ({
      id: 0,
      size: f.size,
      type: selectedType.type,
      permission: f.permission,
      feature: f.feature,
      description: f.description,
    }));
    

    try {
      const response = await axios.post(
        `${API_URL}/api/subscriptionpermissions/update`,
        payload,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
      
      toast.success('Updated Successfully');
      setLoading(false);
      //setEditing(false);
    } catch (error) {
       toast.error(error.message);
      setLoading(false);
    }
  };


  const handleChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedType = types[selectedIndex];
    setSelectedType(selectedType);
    getPermissions(selectedType.type);
  };

  const getPermissions = (type) => {
    setLoading(true);
    axios
      .get(API_URL + `/api/subscriptionpermissions/type=${type}`)
      .then(response => {
        setEditedFeatures(response.data);
          setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching subscription permissions:', error);
        setLoading(false);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <div className="row mt-10 px-14">
            <div className="form-floating-sm mb-2">
              <h2>Select Subscription Type</h2>
              <select
                id="type"
                className="form-control mt-4"
                value={selectedType ? selectedType.type : 'Please Select Type'}
                onChange={handleChange}
              >
                {types.map((type) => (
                  <option key={type.id} value={type.type}>
                    {type.type}
                  </option>
                ))}
              </select>
              <br />

            </div>
          </div>

          {selectedType ? (
            <div>
              <div className="hero-section">
                <h2 className="mt-5">Details About The Type</h2>
              </div>
              <div className="plans-section px-10">
                <div className="plan row">
                  <div className='col-11'>
                    <h2>
                      {selectedType?.type} - ₹ {selectedType?.cost}
              
                    </h2>
                  </div>

                  <div className='col-1'>

                    <button
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-md me-4'
                      onClick={handleEditClick}
                    >
                      <KTIcon iconName='pencil' className='fs-3'></KTIcon>
                    </button>


                  </div>

                  <div className='table-responsive'>
                  <div className="row">
                    <div className="col-lg-6"></div>
                    <div className="col-lg-6">
                      <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                        <thead>
                          <tr className="fw-bold text-muted">
                            <th className="min-w-200px">Features</th>
                            <th className="min-w-80px">Permission</th>
                            <th className="min-w-80px">Size</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            editedFeatures.map((f, index) => (
                              <tr key={index}>
                                <td>{f.feature}</td>
                                <td className='text-center' onClick={() => {
                                  const updatedFeatures = [...editedFeatures];
                                  updatedFeatures[index].permission = !f.permission;
                                  setEditedFeatures(updatedFeatures);
                                }}>
                                  {f.permission ? <FaCheck size={15}/> : <RxCross2 size={15} />}
                                </td>
                                <td className='text-center'>
                                 <input
                                 className='form-control'
                                    style={{ border: "none" }}
                                    type="number"
                                    placeholder='00'
                                    value={f.size}
                                    onChange={(e) => {
                                      const updatedFeatures = [...editedFeatures];
                                      updatedFeatures[index].size = e.target.value;
                                      setEditedFeatures(updatedFeatures);
                                    }}
                                  />
                                 
                                </td>
                              </tr>
                            ))

                          }
                        </tbody>

                      </table>


                      <button onClick={handleSubmit} className='btn btn-primary float-end' style={{ color: 'white' }}>Submit</button>
                    </div>
                  </div>
                  </div>
                </div>

              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubPermissions;
