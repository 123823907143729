import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CarouselSection from "./Carousel";
import Navbar from "../../Public/PublicHome/Navbar";
import Sponsors from "../../Public/PublicHome/Sponsers";
import Description from "../../Public/PublicHome/Description";
import Venue from "../../Public/PublicHome/Venue";
import EventFooter from "../../Public/PublicHome/EventFooter";
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

function Home() {
  const [event, seteventDetails] = useState({});
  const { eventId } = useParams();
  const [loading, setLoading]= useState();


  useEffect(() => {
    setLoading(true);
    axios.get(API_URL + `/api/EventPlannings/${eventId}`)
      .then(response => {
        seteventDetails(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching eventlist:', error);
        setLoading(false);
      });
  }, [eventId]);

  return (

    <div>
      <Navbar event={event} />

      <br></br>

      <CarouselSection event={event}/>
      <Description event={event} />
      <Sponsors event={event} />
      <Venue event={event} />
      <EventFooter />
    </div>

  );

}




export default Home;