import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import { Link } from 'react-router-dom';
import CreateActivityType from '../partials/modals/Add Pages/CreateActivityType';
import Pagination from '../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function ActivityType() {
  const [activityType, setActivityType] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const { auth } = useAuth();
  const rowsPerPage = 30;


  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disable = checked; // Reverse the checked value to set the 'disabled' property
    setLoadingUserId(id) 

    axios
      .get(API_URL + `/api/ActivityType/id=${id}/value=${disable}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.error('Error updating account:', error);
        setLoadingUserId(null);
      });
  }

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/ActivityType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setActivityType(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching ActivityType:', error);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const handleActivityCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/ActivityType`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setActivityType(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching ActivityType:', error);
        setLoading(false);
      });
  };

  const paginatedData = activityType.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateActivityType onActivityCreated={handleActivityCreated} />
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Activity Type</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_ActivityType'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>
              {loading ? (
                <div className='text-center'>
                  <div className='spinner-border' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bold text-muted'>


                        <th className='min-w-120px'>Activity Type</th>
                        <th className='min-w-120px'>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedData.slice().reverse().map((activityType) => (
                        <tr key={activityType.id}>



                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                              {activityType.type}
                            </a>
                          </td>
                          <td style={{ display: 'flex', alignItems: 'center' }}>

                            <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                              <input
                                className="form-check-input"
                                id="flexSwitchCheckDefault"
                                type="checkbox"
                                defaultChecked={activityType.disable}
                                onClick={(e) => onClickhandler(e, activityType.id)}
                                value={activityType.id}
                              />
                                 {loadingUserId === activityType.id && (
                                <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                              )}
                            </div>
                            <Link
                              to={`/editactivity/${activityType.id}`}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                              <KTIcon iconName='pencil' className='fs-3' />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {activityType.length > 0 && (
                    <div className='pagination-container' style={{ marginRight: '120px' }}>
                      <Pagination
                        data={activityType} // Pass the full data to the Pagination component
                        itemsPerPage={rowsPerPage}
                        onPageChange={handlePageChange}
                        currentPage={page}
                      />
                    </div>
                  )}
                </div>

              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ActivityType;
