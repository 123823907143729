import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import CreateRole from '../../../partials/modals/Add Pages/CreateRole';
const API_URL = process.env.REACT_APP_API_URL;

const OrganizationRole = () => {
  const [roleUsers, setRoleUsers] = useState([]);
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  function onClickHandler(e, id) {
    const checked = e.target.checked;
    setLoading(true);
    axios
      .get(`${API_URL}/api/EventRoleFeatures/id=${id}/value=${checked}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setRoleUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error updating feature:', error);
        setLoading(false);
      });
  }

  const handleRoleCreated = () => {

    setLoading(true);
    axios
      .get(`${API_URL}/api/EventRoleFeatures/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setRoleUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error fetching roles', error);
        setLoading(false);
      }, [auth?.token]);
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/api/EventRoleFeatures/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setRoleUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error fetching roles', error);
        setLoading(false);
      })
  }, [auth?.token]);


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (

        <div className="card card-raised">
          <CreateRole onRoleCreated={handleRoleCreated} />
          <div className='card-header border-0'>
            <span className='card-label fw-bold fs-3 mb-1 mt-4'>Event Role</span>

            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a role'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_Role'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>

          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold text-muted fs-4">
                    <th className="min-w-120px">Event Controller</th>
                    <th className="min-w-120px">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {roleUsers.slice().reverse().map((role) => (
                    <tr key={role.id}>
                      <td style={{ display: 'flex', alignItems: 'center' }}>
                        {role.feature}
                      </td>
                      <td>
                        <div
                          className="form-check form-switch form-check-inline"
                          style={{ fontSize: '12px', marginRight: '10px' }}
                        >
                          <label
                            className={`form-check-label ${role.featureStatus ? 'text-muted' : 'text-success'
                              }`}
                          >
                            {role.featureStatus ? 'Disabled' : 'Enabled'}
                          </label>
                          <input
                            className="form-check-input"
                            id={`flexSwitchCheck_${role.id}`}
                            type="checkbox"
                            defaultChecked={!role.featureStatus}
                            onChange={(e) => onClickHandler(e, role.id, role.featureStatus)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
      )}
    </div>
  );
}


export default OrganizationRole;
