import axios from 'axios'
import {AuthModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/userprofile`
export const GET_USERNOTIFICATION_EMAIL = `${API_URL}/api/user/notifications`
export const LOGIN_URL = `${API_URL}/api/authenticate`
export const VALIDATE_USER_URL = `${API_URL}/validateUser`
export const REGISTER_URL = `${API_URL}/api/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/forgotPassword`
export const PASSWORDRESET_URL= `${API_URL}/api/resetPassword`
export const REQUEST_OTP_VALIDATION_URL = `${API_URL}/api/validatePasswordReset`
export const REQUEST_VERIFY_OTP_URL= `${API_URL}/api/OTPVerification?token=`
export const PUBLIC_LOGIN_URL=`${API_URL}/publicaccounts/authenticate`
export const PUBLIC_REGISTER_URL=`${API_URL}/publicaccounts/register`
export const EVENT_MEMBER_REGISTER_URL = `${API_URL}/eventMembers/register`
export const GET_EXIBITOR_BY_ACCESSTOKEN_URL = `${API_URL}/eventMembers`
export const GET_PUBLICUSER_BY_ACCESSTOKEN_URL = `${API_URL}/api/publicaccount-bio/user`

// Server should return AuthModel
export function login(username: string, role: string, eventid:string, orgid: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    role,
    eventid,
    orgid,
  })
}

export function adminlogin(username: string, role: string, orgid:string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    username,
    role,
    orgid,
  })
}


export function ValidateUser(username: string, password: string) {
  return axios.post(VALIDATE_USER_URL, {
    username,
    password    
  })
}

export function publiclogin(email: string) {
  return axios.post(PUBLIC_LOGIN_URL, {
    email  })
}

export function publicRegister(
  email: string,
  name: string,
  phone: string,
  eventid:String
) {
  return axios.post(PUBLIC_REGISTER_URL, {
    email,
    name,
    phone,
    eventid
  })
}

// Server should return AuthModel
export function register(
  email: string,
  password: string,
  passwordConfirm: string,
  eventrole: string,
  eventid: string,
  sector: string
) {
  return axios.post(REGISTER_URL, {
    email,
    password,
    passwordConfirm,
    eventrole,
    eventid,
    sector
  })
}

export function resetPassword(
  username: string,
  password: string,
  passwordConfirm: string
) {
  return axios.post(PASSWORDRESET_URL, {
    username,
    password,
    passwordConfirm
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(username: string) {
  return axios.post(REQUEST_PASSWORD_URL, {
    username,
  })
}

export function otpValidation(token: string, username: string) {
  return axios.post(REQUEST_OTP_VALIDATION_URL, {
    token,
    username,
   
  })
}

export function verifyOTP(token: string) {
  return axios.post(REQUEST_VERIFY_OTP_URL, {
    token,
  })
}



export function getpublicAccountByToken(token: string) {
  return axios.post(GET_PUBLICUSER_BY_ACCESSTOKEN_URL, {
    token,
  })
}
export function getUserByToken(token: string) {
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL, {
    token,
    
  })
}

export function getUserNotification(token: string) {
  return axios.post(GET_USERNOTIFICATION_EMAIL, {
    token, 
      })
}

export function getExibitorByToken(token: string) {
  return axios.post(GET_EXIBITOR_BY_ACCESSTOKEN_URL, {
    token,
  })
}

export function exhibitorRegister(
  name: string,
  email: string,
  phone: number,
  password: string,
  passwordConfirm: string,
  role: string,
  eventid:string
 
) {
  return axios.post(EVENT_MEMBER_REGISTER_URL, {
    name,
    email,
    phone,
    password,
    passwordConfirm,
    role,
    eventid
  })
}