import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import Loader from '../../../Loader';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useAuth } from '../../../app/modules/auth';


const API_URL = process.env.REACT_APP_API_URL;

export default function EventSession() {
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [speakers, setSpeakers] = useState([]);
  const { auth } = useAuth();
  const uniqueDates = [...new Set(speakers.map(item => item.date))];
  const handleTabClick = async (tab) => {
    setActiveTab(tab);
    await fetchDataForTab(tab);
  };

  const fetchDataForTab = async (date) => {
    try {
      const sessionsResponse = await axios.get(API_URL + `/api/Sessioncalendar/eventid=${eventId}/date=${date}`);
      setSessions(sessionsResponse.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(false);
    try {
      const response = await axios.get(API_URL + `/api/Sessioncalendar/eventid=${eventId}`);
      setSpeakers(response.data);
      setLoading(false);
      const uniqueDates = Array.from(new Set(response.data.map(item => item.date)));

      if (uniqueDates.length > 0) {
        const initialDate = uniqueDates[0];
        setActiveTab(initialDate);
        await fetchDataForTab(initialDate);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };


  const removeClick = (sessionid) => {
    setLoading(true);
    axios
      .post(API_URL + `/api/Sessioncalendar/delete/${sessionid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        fetchData();
        toast.success("Successfully Removed");
        setLoading(false);
      })
      .catch(error => {
        fetchData();
        toast.error('Error deleting Session', error);
        setLoading(false);

      });
  }


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card'>

          <div className='card-header border-0 pt-5 d-flex justify-content-between align-items-end'>
            {/* <h3 className='card-title align-items-start flex-column'>
    <span className='card-label fw-bold fs-3 mb-1'>Event Sessions</span>
  </h3> */}
            <div
              className='card-toolbar ms-auto'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a new session'
            >
              <Link
                to={`/session/${eventId}`}
                className='btn btn-sm btn-light-primary'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </Link>
            </div>
          </div>

          <div className='schedule-header' >
            {/* <h1 style={{ fontSize: '35px', textAlign: 'center' }}>EVENT SCHEDULE</h1> */}
            <br />

            <h1
              style={{
                color: 'grey',
                fontSize: '20px',
                textAlign: 'center',
                position: 'relative',

              }}
            >
              Here is your event schedule  </h1>

            {<div>
              <span
                style={{
                  display: 'block',
                  width: '50px', // Adjust the width of the red line as needed
                  height: '5px',
                  backgroundColor: 'blue',
                  margin: '5px auto', // Adjust the margin as needed
                }}
              ></span>
            </div>}

          </div>
          <br />
          <br />


          <div className='schedule-tabs' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {uniqueDates.map((date, index) => (
              <div
                key={date}
                className={`tab ${activeTab === date ? 'active' : ''} cursor-pointer`}
                onClick={() => handleTabClick(date)}
                style={{
                  backgroundColor: activeTab === date ? 'blue' : 'black',
                  color: 'white',
                  fontSize: '15px',
                  padding: '5px 10px',
                  marginRight: '10px',
                  borderRadius: '30px',
                  width: '150px',
                  margin: '0 5px 5px 0',
                  textAlign: 'center',
                }}
              >
                {`DATE ${date || index + 1}`}
              </div>
            ))}
          </div>

          <br />
          <br />

          <div className='schedule-content' style={{ overflowX: 'auto', textAlign: 'center' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed', margin: '0 auto' }}>
              <thead>
                <tr>
                  <th style={{ fontSize: '17px', padding: '10px', position: 'sticky', left: '0', width: '20%' }}>Time</th>
                  <th style={{ fontSize: '17px', padding: '10px', position: 'sticky', left: '100px', width: '60%' }}>Session Details</th>
                  <th style={{ fontSize: '17px', padding: '10px', position: 'sticky', left: '100px', width: '20%' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {sessions.length > 0 ? (
                  sessions.map((sessionItem) => (
                    <tr key={sessionItem.id}>
                      <td style={{ fontSize: '15px', padding: '5px', position: 'sticky' }}>{`${sessionItem.startTime} - ${sessionItem.endTime}`}</td>
                      <td style={{ fontSize: '15px', padding: '5px', position: 'sticky' }}>
                        <div className='fs-4 mb-8'>
                          <div>{sessionItem.sessionname}</div>
                          <div className='mt-2 mb-2'>Topic: {sessionItem.topic}</div>
                          <div>{sessionItem.userid.join(', ')}</div>
                        </div>
                      </td>
                      <td>
                        <button type='button' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                          <Link to={`/editSession/${eventId}/${sessionItem?.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <KTIcon iconName='pencil' className='fs-2' />
                          </Link>
                        </button>

                        <button type='button' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' onClick={() => removeClick(sessionItem?.id)}>
                          <RiDeleteBin6Line className='fs-3' />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <p className='align-items-center text-center'>No items</p>
                    </td>
                  </tr>
                )}
              </tbody>`                                                                       `
            </table>
          </div>
        </div>
      )}

    </div>
  );
}
