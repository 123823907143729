import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { KTIcon } from "../../../helpers";
import { useAuth } from "../../../../app/modules/auth";

function CreateTask({ onTaskCreated, eventId }) {

  const [events, setEvents] = useState([]);
  const [priority, setPriority] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);


  const [task, setTask] = useState({
    eventid: eventId,
    taskname: "",
    taskpriorityid: "",
    duedate: "",
    taskmaneger: "",
    taskassignedto: "",
    taskstatus: "",
    description: "",
  });
  const [selectedUser, setSelectedUser] = useState('');
  const [matchingNames, setMatchingNames] = useState([]);
  const [showList, setShowList] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("token");
  const [validationError, setValidationError] = useState(null);


  const handleInputChange = async (e) => {
    const { value } = e.target;
    setSelectedUser(value);
    const role = "ROLE_COMMITEE_MEMBER"
    try {
      const response = await axios.get(`${API_URL}/api/authority/${eventId}/${role}/committememberSearch`, {
        params: {
          key: value,
        },
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const usernames = response.data.map((item) => item.username);
        setMatchingNames(usernames);
        setShowList(true);
      } else {
        // No users found
        setMatchingNames([]);
        setShowList(false);
        //toast.info('No users found.');
      }
    } catch (error) {
      toast.error('Error fetching names: ' + error);
    }
  };

  useEffect(() => {
    axios
      .get(API_URL + `/api/TaskPriority`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPriority(response.data);
      })
      .catch((error) => {
        toast.error("Error fetching Task Priorities:", error);
      });
  }, [token]);

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setTask((task) => ({ ...task, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
  
    try {
      if (!task.taskassignedto.trim()) {
        toast.error('Task Assigned To cannot be empty. Please select a user.');
      } else {
        const response = await axios.post(API_URL + "/api/Task/create", task, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        });
        toast.success("Task Added Successfully");
        setLoading(false);
        onTaskCreated();
        closeModal();
      }
    } catch (error) {
      // Handle specific validation errors
      if (error.message === 'Task Assigned To cannot be empty. Please select a user.') {
        toast.error(error.message);
      } else {
        // Handle other errors
        toast.error("An error occurred while adding the task. Please try again.");
      }
  
      setLoading(false);
    }
  };
  



  const handleNameSelection = (selectedName) => {
    // Update the taskassignedto property when a name is selected
    setTask((prevTask) => ({ ...prevTask, taskassignedto: selectedName }));
    setSelectedUser(selectedName);
    // Clear the suggestions and hide the list
    setMatchingNames([]);
    setShowList(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className='modal fade' id='kt_modal_create_task' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Task</h1>


            </div>

            <div>
              <div id="layoutDrawer_content">
                {loading && <div className="loading"></div>}

                <main>
                  <div className="col-12 mx-xl-auto ps-xl-12">
                    <div className="card card-raised">
                      <div className="card-body">
                        <form onSubmit={handleSubmit}>
                          <div className="row" hidden>
                            <div className="form-floating-sm mb-2">
                              <label>Event</label>
                              <select
                                id="eventid"
                                type="text"
                                placeholder="select event"
                                className="form-control"

                              >
                                <option value="">Select event</option>
                                {events.map((event) => (
                                  <option key={event.id} value={event.title}>
                                    {event.title}
                                  </option>
                                ))}
                              </select>{" "}
                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label htmlFor="taskname">Task Name</label>
                              <input
                                className="form-control"
                                id="taskname"
                                type="text"
                                placeholder="Task name"
                                onChange={(e) => handleChange(e, "taskname")}
                              />
                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label>Task Priority</label>
                              <select
                                id="taskpriorityid"
                                type="text"
                                placeholder="Select event"
                                className="form-control"
                                onChange={(e) => handleChange(e, "taskpriorityid")}
                              >
                                <option value="">Select</option>
                                {priority.map((pr) => (
                                  <option key={pr.id} value={pr.prioritytype}>
                                    {pr.prioritytype}
                                  </option>
                                ))}
                              </select>{" "}
                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label>Task Status</label>
                              <select
                                id="taskstatus"
                                type="text"
                                placeholder="Select Status"
                                className="form-control"
                                onChange={(e) => handleChange(e, "taskstatus")}
                              >
                                <option value="">Select</option>
                                <option value="Assigned">ASSIGNED</option>
                                <option value="InProgress">In Progress</option>
                                <option value="Completed">Completed</option>
                                <option value="OnHold">OnHold</option>

                              </select>{" "}
                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label htmlFor="description">Description</label>
                              <textarea
                                className="form-control"
                                id="description"
                                type="text"
                                placeholder="Description"
                                onChange={(e) => handleChange(e, "description")}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label htmlFor="taskassignedto">Task Assigned To</label>
                              <input
                                className="form-control"
                                id="taskassignedto"
                                type="text"
                                placeholder="Task assigned to"
                                onChange={handleInputChange}
                                value={selectedUser}
                              />
                              {showList ? (
                                matchingNames.map((name) => (
                                  <div className="form-control cursor-pointer" key={name} onClick={() => handleNameSelection(name)}>
                                    {name}
                                  </div>
                                ))
                              ) : (
                                <div className="form-control">
                                  {matchingNames.length === 0 ? 'No users found.' : 'Loading...'}
                                </div>
                              )}

                              {validationError && <div style={{ color: 'red' }}>{validationError}</div>}

                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="form-floating-sm mb-2">
                              <label htmlFor="duedate">Due Date</label>
                              <input
                                className="form-control"
                                id="duedate"
                                type="date"
                                placeholder="Task assigned to"
                                onChange={(e) => handleChange(e, "duedate")}
                              />
                            </div>
                          </div>
                          <br></br>

                          <br></br>

                          <div className="text-end">
                            <input
                              className="btn btn-primary"
                              type="submit"
                              disabled={loading}
                              value="Submit"
                              data-bs-dismiss='modal'
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );

}

export default CreateTask;
