import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../../app/modules/auth";
import Loader from "../../../../Loader";
import { FaRegUser } from "react-icons/fa";


function UserDetails() {
    const [loading, setLoading] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;
    const { auth } = useAuth();
    const { userName } = useParams();
    const [profile, setProfile] = useState({
        userid: "",
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        company: "",
        country: "",
        image: null,
        imageMeta: ""
    });

    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/account-bio/username=${userName}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setProfile(response.data);
                setLoading(false);
            })
            .catch(error => {
                const errorMessage = 'Error fetching user Details. Please try again.';
                toast.error(errorMessage);
                setLoading(false);
            });
    }, [auth?.token]);



    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <h2 className="mb-10"> User Details</h2>

                    <div
                        className="form w-100" noValidate id="kt_login_signin_form">

                        <div className="container">
                            <div className="main-body">
                                <div className="row gutters-sm">
                                    <div className="col-md-4 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex flex-column align-items-center text-center">
                                                    {profile?.image ? (
                                                        <img id="image" src={`${profile?.imageMeta},${profile?.image}`} alt={profile?.email} style={{ maxWidth: "200px", maxHeight: "320px" }}
                                                        />
                                                    ) : (
                                                        <FaRegUser size={65} />
                                                    )}

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card mb-3">
                                            <div className="card-body">


                                                <div className="row">

                                                    <div className="col-sm-2">
                                                        <h6 className="mb-2">FirstName</h6>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        {profile?.firstname} {profile?.lastname}

                                                    </div>


                                                </div>
                                                <hr />

                                                <br></br>



                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <h6 className="mb-2">Email</h6>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        {profile?.email}
                                                    </div>
                                                </div>
                                                <hr />
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <h6 className="mb-2">Company</h6>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        {profile?.company}
                                                    </div>

                                                </div>
                                                <hr />

                                                <br></br>


                                                <div className="row">

                                                    <div className="col-sm-3">

                                                        <h6 className="mb-2">Phone Number</h6>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        {profile?.phone}
                                                    </div>

                                                </div>
                                                <hr />

                                                <br></br>



                                                <div className="row">
                                                    <div className="col-sm-2">
                                                        <h6 className="mb-2">Country</h6>
                                                    </div>
                                                    <div className="col-sm-10">
                                                        {profile?.country}
                                                    </div>
                                                </div>
                                                <hr />
                                                <br></br>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br></br>
                </div>


            )}
        </div>
    );
}
export default UserDetails;