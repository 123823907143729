import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../auth';
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const SignInMethod = ({ userId }) => {

  const user = userId;

  const [passwordUpdateData, setPasswordUpdateData] = useState({
    username: "",
    password: "",
    passwordConfirm: ""
  });
  const auth= useAuth();
  const [showPasswordForm, setPasswordForm] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [profile, setProfile] = useState(false);

  const navigate = useNavigate();
  
  const getData = async () => {

    const { data } = await axios.get(API_URL + `/api/accounts/username=${user}`, {
      headers: { Authorization: `Bearer ${auth?.token}` },
    });
    setProfile(data);
  };
  useEffect(() => {
    getData();
  }, []);


  const handleChange = (event, field) => {
    let actualValue = event.target.value;
    if (field === "isTrue") {
      actualValue = event.target.value === "true";
    }
    setPasswordUpdateData({ ...passwordUpdateData, [field]: actualValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(API_URL + "/api/changePassword", passwordUpdateData, {
        headers: { Authorization: `Bearer ${auth?.token}` },

      })

      .then((response) => response.data)
      .then((result) => {
        toast.success("Updated");
        setPasswordForm(false);
        navigate(`/AccSetting/${userId}`);

      });

  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_signin_method'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Sign-in Method</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='d-flex flex-wrap align-items-center'>
            <div id='kt_signin_email'>
              <div className='fs-6 fw-bolder mb-1'>Email Address</div>
              <div className='fw-bold text-gray-600'>{profile.username}</div>
            </div>
          </div>

          <div className='separator separator-dashed my-6'></div>

          <div className='d-flex flex-wrap align-items-center mb-10'>
            <div id='kt_signin_password' className={' ' + (showPasswordForm && 'd-none')}>
              <div className='fs-6 fw-bolder mb-1'>Password</div>
              <div className='fw-bold text-gray-600'>***********</div>
            </div>

            <div id='kt_signin_password_edit' className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}>
              <form onSubmit={handleSubmit} id='kt_signin_change_password' className='form' noValidate>
                <div className='row mb-1'>
                  {/* <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='password' className='form-label fs-6 fw-bolder mb-3'>
                        Current Password
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='password'
                      />
                    </div>
                  </div> */}

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='password' className='form-label fs-6 fw-bolder mb-3'>
                        New Password
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='password'
                        onChange={(e) => handleChange(e, "password")}
                      />
                    </div>
                  </div>

                  <div className='col-lg-4'>
                    <div className='fv-row mb-0'>
                      <label htmlFor='passwordConfirm' className='form-label fs-6 fw-bolder mb-3'>
                        Confirm New Password
                      </label>
                      <input
                        type='password'
                        className='form-control form-control-lg form-control-solid'
                        id='passwordConfirm'
                        onChange={(e) => handleChange(e, "passwordConfirm")}
                      />
                    </div>
                  </div>
                </div>

                <div className='form-text mb-5'>Password must be at least 8 characters and contain symbols</div>

                <div className='d-flex'>
                  <button id='kt_password_submit' type='submit' className='btn btn-primary me-2 px-6'>
                    {!loading2 ? 'Update Password' : (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setPasswordForm(false);
                    }}
                    id='kt_password_cancel'
                    type='button'
                    className='btn btn-color-gray-400 btn-active-light-primary px-6'
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>

            <div id='kt_signin_password_button' className={'ms-auto ' + (showPasswordForm && 'd-none')}>
              <button
                onClick={() => {
                  setPasswordForm(true);
                }}
                className='btn btn-light btn-active-light-primary'
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SignInMethod };
