import React, { FC } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';

interface Connections {
  exibitor: {
    email: '',
    phone: '',
    imageMeta: '',
    country: '',
    description: '',
    company: '',
    orgtype: '',
    pan: '',
    gstin: '',
    tan: '',
    image: ''
  };
  user: {
    email: string;
    id: number;
    designation: '',
    country: '',
    company: '',
    description: '',
    firstname: '',
    lastname: '',
    phone: '',
    image:''
  };
  eventid: string;
  id: number;
}

interface Card3Props {
  connection: Connections;
}

const Card3: FC<Card3Props> = ({ connection }) => {
  const { exibitor, user, eventid } = connection;
  return (
    <div className='card'>
      <div className='card-body d-flex flex-center flex-column p-3'>
        <div className='mb-2'>
          <div className='symbol symbol-75px symbol-circle'>
            <span className={`symbol-label bg-light-primary text-primary fs-5 fw-bolder`}>
              {user.id}
            </span>
          </div>
        </div>

        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>

          {user.firstname} {user.lastname}
        </a>
        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>

          {user.email}
        </a>
        <a href='#' className='fs-4 text-gray-800 text-hover-primary fw-bold mb-0' style={{ color: 'lightgray' }}>

          {user.designation}
        </a>
        <div className='fw-bold text-gray-400 mb-6'>

          {user.country}
        </div>

        <div className='d-flex flex-center flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>
              {user.company}
            </div>
            <div className='fw-bold text-gray-400'></div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder text-gray-700'>{user.phone}</div>
            <div className='fw-bold text-gray-400'></div>
          </div>


        </div>

      
      </div>
    </div>
  );
};

export { Card3 };
