import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import '../../users/sub.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../app/modules/auth';
import { FaAmazonPay } from "react-icons/fa";
import { MdSubscriptions } from "react-icons/md";
const API_URL = process.env.REACT_APP_API_URL;


const Stalls = () => {
    const [types, setType] = useState([]);
    const { eventId } = useParams();
    const auth = useAuth();
    const navigate = useNavigate();
    const [stalls, setStalls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState(null);
   
    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/StallType`)
            .then(response => {
                setType(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching Stall Types', error);
                setLoading(false);
            });
    }, [auth?.token]);

    const handleChange = (type) => {
        setSelectedType(type);
        getStalls(type);
    };

    const getStalls = (type) => {
        setLoading(true);
        axios
            .get(API_URL + `/api/StallChart/${eventId}/stalltype=${type}`)
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error fetching stalls', error);
                setLoading(false);
            });
    };


    function handleStallSubmit(stall) {
        navigate(`/stallPayment/${eventId}`, { state: { stall } });
    }

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className='card mb-5 mb-xl-10'>
                        <div className='card-body border-top p-9 text-center'>

                            <div className="hero-section">
                                <h2>Ready to get started?</h2>
                                <h3 className='mt-4'>Choose one of our stall type</h3>

                            </div>
                            <div className='col-lg-12 mt-12'>
                                <div className='row'>
                                    {types.slice().map((type) => (
                                        <div className='col-lg-4' >
                                            <div style={{
                                                borderLeft: '1px solid black',
                                                height: '100px',
                                            }}>
                                                <div className='fs-6 mb-4'>{type.stallType}</div>
                                                <div> <MdSubscriptions bounce style={{ color: "#2f75ee", }} size={40} /></div>
                                                <div className='fs-6 mt-4 text-hover-primary' style={{
                                                    cursor: 'pointer'
                                                }}

                                                    onClick={() => handleChange(type.stallType)}
                                                > Click to know more </div>

                                            </div>
                                        </div>

                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>



                    {selectedType ? (
                        <div className='card mb-5 mb-xl-10'>
                            <div className="hero-section">
                                <h2 className="mt-5">Stalls</h2>
                            </div>
                            <div className="plans-section px-10">
                                <div className="plan">

                                    <div className="row">
                                        <div className="col-lg-11">
                                            <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                                                <thead>
                                                    <tr className="fw-bold fs-4">
                                                        <th className="min-w-60px">StallId</th>
                                                        <th className="min-w-60px">Dimension</th>
                                                        <th className="min-w-60px">Cost</th>
                                                        <th className="min-w-60px"><FaAmazonPay size={30} /></th>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-bold fs-5">
                                                    {stalls.slice().map((f, index) => (
                                                        <tr key={index}>
                                                            <td>{f.stallid}</td>
                                                            <td>{f.dimension}</td>
                                                            <td>{f.cost}</td>
                                                            <td onClick={() => handleStallSubmit(f)}> <Link>Buy Stall </Link></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            )}
        </div>

    );
};

export default Stalls;
