/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useEffect,useState} from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');



type Props = {
  className: string
  items?: number
}
const ListsWidget4: React.FC<Props> = ({className, items = 6}) => {
const [Widget4,SetWidget4] = useState([]);

const storedData = localStorage.getItem('kt-auth-react-v');
    const data = storedData ? JSON.parse(storedData) : null;
    const orgid = data?.orgid;

useEffect(() => {

  const role= "ROLE_EXHIBITOR";
  axios
    .get(API_URL +`/api/authority/orgid=${orgid}/role=${role}` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      SetWidget4(response.data);
    })
    .catch(error => {
      console.error('Error fetching EventRole:', error);
    });
}, [token]);


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card card-xl-stretch mb-xl-8  bg-primary'>
      <div className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '200px' }}>
      <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{Widget4.length}</span>
      <h3 className='card-title text-gray-800 fw-bold text-center '>Number of Unique Exhibitor</h3>
        </div>
      
      </div>
 
    </div>
  )
}

export {ListsWidget4}
