import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from "react";
import './session.css';
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader";
import { useAuth } from "../../../app/modules/auth";
import { RxCross2 } from "react-icons/rx";


const API_URL = process.env.REACT_APP_API_URL;

export default function AddSession() {
    const [date, setdate] = useState(new Date());
    const { eventId, sessionId } = useParams();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { auth } = useAuth();
    const [selectedStartTime, setSelectedStartTime] = useState("");
    const [selectedEndTime, setSelectedEndTime] = useState("");
    const [session, setSession] = useState({
        id: 0,
        eventid: eventId,
        sessionname: "",
        topic: "",
        location: "",
        userid: [],
        startTime: "",
        endTime: "",
        description: "",
        date: "",
    });
    const [showList, setShowList] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [matchingNames, setMatchingNames] = useState([]);
    const [users, setUsers] = useState([]);

    const handleInputChange = async (e) => {
        const { value } = e.target;
        setSelectedUser(value);
            try {
            const response = await axios.get(`${API_URL}/api/EventSpeakers/${eventId}/find`, {
                params: {
                    key: value,
                },
            });

            if (Array.isArray(response.data)) {
                const usernames = response.data.map((item) => item.name);
                setMatchingNames(usernames);
                setShowList(response.data.length !== 0);
            } else {
                toast.error('Invalid response data format:', response.data);
            }
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    // Autocomplete handle user selection
    const handleUserSelection = (user) => {
        if (users.includes(user)) {
            toast.info('User Already Exist');
            return;
        }
        setUsers([...users, user]);
        setSelectedUser("");

        setMatchingNames([]);
        setSession((s) => ({ ...s, userid: [...s.userid, user] }));
    };

    // Handle date change
    const handleDateChange = (date) => {
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        setSession((s) => ({ ...s, date: formattedDate }));
        setdate(date);
    };

    const handleChange = (e, field) => {
        const actualValue = e.target.value;
        setSession((s) => ({ ...s, [field]: actualValue }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const updatedSession = { ...session, startTime: selectedStartTime, endTime: selectedEndTime };
        if (sessionId) {
            axios
                .post(API_URL + "/api/Sessioncalendar/update", updatedSession, {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                })
                .then(() => {
                    toast.success("Session Updated Successfully");
                    setLoading(false);
                    navigate(`/eventSession/${session.eventid}`);
                })

                .catch((error) => {
                    toast.error(error.response.data.message);
                    setLoading(false);

                });
        }
        else {
            axios
                .post(API_URL + "/api/Sessioncalendar/create", updatedSession, {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                })
                .then(() => {
                    toast.success("Session Created Successfully");
                    setLoading(false);
                    navigate(`/eventSession/${session.eventid}`);
                })

                .catch((error) => {
                    
                    toast.error(error.response.data.message);
                    setLoading(false);

                });
        }

    };

    useEffect(() => {
        if (sessionId) {
            setLoading(true);
            axios
                .get(API_URL + `/api/Sessioncalendar/${sessionId}`, {
                    headers: { Authorization: `Bearer ${auth?.token}` },
                })
                .then((response) => {
                    setSession(response.data);
                    setSelectedStartTime(response.data.startTime);
                    setSelectedEndTime(response.data.endTime);
                    setUsers(response.data.userid);
                    setLoading(false);
                })
                .catch((error) => {
                    const errorMessage = 'Error Fetching session. Please try again.';
                    toast.error(errorMessage);
                    setLoading(false);
                });
        }

        if (date.value) {
            setdate(date.value.toISOString());
        }
    }, [date]);

    function handleRemove(index){
        //const updatedUsers = users.filter((_, i) => i !== index);
        const updatedUsers = [...users.slice(0, index), ...users.slice(index + 1)];
    
        setUsers(updatedUsers);
    }
    

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className='card'>
                    <h2 className="mt-6 px-15"> Add Session for this Event </h2>
                    <div>
                        <div className="row px-15 mt-6 py-4">
                            <div className="col-7">
                                <div className="calendar">
                                    <Calendar onChange={handleDateChange} value={date} />
                                </div>

                                <h2 className="mt-8">Please Select start and end time</h2>
                                <div className="btns mt-4">
                                    <div>
                                        <label>Start Time:</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            value={selectedStartTime}
                                            onChange={(e) => setSelectedStartTime(e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <label>End Time:</label>
                                        <input
                                            type="time"
                                            className="form-control"
                                            value={selectedEndTime}
                                            onChange={(e) => setSelectedEndTime(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-5">
                                
                              

                                <input
                                    className="form-control"
                                    type="text"
                                    id="topic"
                                    value={session?.topic}
                                    onChange={(e) => handleChange(e, "topic")}
                                    required
                                    placeholder="Enter Topic"
                                />
                                <br />

                                <input
                                    type="text"
                                    id="location"
                                    value={session?.location}
                                    onChange={(e) => handleChange(e, "location")}
                                    required
                                    placeholder="Enter Location"
                                    className="form-control"
                                />
                                <br />

                                <input
                                    className="form-control"
                                    id="exhibitorId"
                                    type="text"
                                    placeholder="Speakers Name"
                                    onChange={handleInputChange}
                                    value={selectedUser}
                                />
                                {showList && (
                                    <div>
                                        {matchingNames.map((name) => (
                                            <div className="form-control cursor-pointer" key={name} onClick={() => handleUserSelection(name)}>
                                                {name}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                <div className='card-toolbar'>
                                    <label className='form-label'>Speakers:</label>
                                    <ol style={{ fontSize: '14px' }}>
                                        {users.map((admin, index) => (
                                            <div key={index}>
                                                <li> {admin} <RxCross2 className='mx-20 fs-4 cursor-pointer' onClick={()=>{handleRemove(index)}}/></li>
                                            </div>
                                        ))}

                                    </ol>
                                </div>
                                <br />
                                <textarea
                                    id="description"
                                    value={session?.description}
                                    onChange={(e) => handleChange(e, "description")}
                                    required
                                    placeholder="Enter Description"
                                    className="form-control"
                                />
                            </div>


                            {sessionId ? (
                                <button className="btn btn-md btn-primary col-3 float-end" onClick={handleSubmit}>
                                    Update
                                </button>
                            ) : (
                                <button className="btn btn-md btn-primary col-3 float-end" onClick={handleSubmit}>
                                    Book Session
                                </button>
                            )}
                        </div>
                        <br />
                    </div>
                </div>
            )}
        </>
    );
}
