import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;

function EditEventrole() {
  const [eventrole, setEventrole] = useState({
    id:"",
    role:"",
    eventid: "",
    feature:"",
    userid:"",
  });
  const [permissions, setPermissions] = useState([]); // Changed from Permissions to permissions
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { eventId,id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/eventrolepermission/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setEventrole(response.data); // Assuming response.data is an object
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error Fetching EventRole. Please try again.');
        setLoading(false);
      });
  }, [id, auth?.token]); // Include eventId and auth?.token as dependencies

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${API_URL}/api/subscriptionpermissions`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setPermissions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Permissions:", error);
        setLoading(false);
      });
  }, [auth?.token]);

  const handleChange = (field, value) => { // Changed to accept field and value directly
    setEventrole((prevEventrole) => ({ ...prevEventrole, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${API_URL}/api/eventrolepermission/update`, eventrole, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Updated Successfully");
        navigate(-1);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Error Updating EventRole. Please try again.');
        setLoading(false);
      });
  };

  return (
    <div>
      {loading && <div className="loading"></div>}
      <main>
        <div className="col-8 mx-xl-auto ps-xl-8">
          <div className="card card-raised">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-3 mb-1'> Event Role</span>
                    </h3>
                  </div>
                </div>

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="role">Role</label>
                    <input
                      className="form-control"
                      id="role"
                      type="text"
                      placeholder="Role"
                      value={eventrole.role}
                      onChange={(e) => handleChange("role", e.target.value)}
                    />
                  </div>
                </div>
                <br />

                <div className="row">
                  <div className="form-floating-sm mb-2">
                    <label htmlFor="userid">User Name</label>
                    <input
                      className="form-control"
                      id="userid"
                      type="text"
                      placeholder="User Name"
                      value={eventrole.userid}
                      onChange={(e) => handleChange("userid", e.target.value)}
                    />
                  </div>
                </div>
                <br />

                <div className='row'>
                  <div className='form-floating-sm mb-2'>
                    <label>Permissions</label>
                    <select
                      id='feature'
                      className='form-control'
                      onChange={(e) => handleChange("feature", e.target.value)} // Changed to pass field and value directly
                      value={eventrole.feature} // Assuming eventrole.feature exists
                    >
                      <option>Select...</option>
                      {permissions.map((permission,index) => (
                        <option key={index} value={permission.feature}>
                          {permission.feature}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <br />

                <div className="text-end">
                  <input
                    className="btn btn-primary"
                    type="submit"
                    disabled={loading}
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default EditEventrole;
