import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import CreateCommittee from '../../../partials/modals/Add Pages/CreateCommittee.js';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

function EventCommittees() {
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [RoleUsers, setRoleUsers] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [updateFlag, setUpdateFlag] = useState(false);

  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/authority/eventid=${eventId}/role=ROLE_COMMITEE_MEMBER`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setRoleUsers(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching EventRole', error);
        setLoading(false);
      });
  }

  const handleCommitteeCreated = () => {
  setLoading(true);
  axios
    .get(API_URL + `/api/authority/eventid=${eventId}/role=ROLE_COMMITEE_MEMBER`, {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
    .then(response => {
      setRoleUsers(response.data);
      setLoading(false);
    })
    .catch(error => {
      toast.error('Error fetching EventRole', error);
      setLoading(false);
    });
  }

  function onClickhandler(e, exibitorid) {
    const checked = e.target.checked;
    const status = checked;
    setLoadingUserId(exibitorid);

    axios
      .get(API_URL + `/api/authority/id=${exibitorid}/value=${status}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Successfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        const errorMessage = 'Failed to update. Please try again.';
        toast.error(errorMessage);
        setUpdateFlag((prevFlag) => !prevFlag);
        setLoadingUserId(null);
      });
  }

  useEffect(() => {
    fetchData();
  }, [updateFlag]);

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const filteredData = RoleUsers.filter((RoleUsers) =>
    RoleUsers.username.toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateCommittee onCommitteeCreated={handleCommitteeCreated} />
          <div className='card-header border-0 pt-5'>

            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-450px ps-14'
                  placeholder='Search Username'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

            </div>

          </div>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>

              <span className='card-label fw-bold fs-3 mb-1'> Committee Members</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add commitee member'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_organizationtype'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'>
          </div>
          <div className='card-toolbar'>


            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>

                        </div>
                      </th>

                      {/* <th className='min-w-100px'> Id</th> */}
                      <th className='min-w-200px'>Commitee Members</th>
                      <th className='min-w-80px '>Actions</th>

                    </tr>
                  </thead>

                  <tbody>
                    {paginatedData.slice().reverse().map((RoleUsers) => (
                      <tr key={RoleUsers.id}>
                        <td>

                        </td>
                        {/* <td>
                          {RoleUsers.id}

                        </td> */}

                        <td>
                          <Link
                          to={`/Exibitor/${RoleUsers.username}`} className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {RoleUsers.username}
                          </Link>
                        </td>

                        <td style={{ display: 'flex', alignItems: 'center' }}>

                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                            <input
                              className="form-check-input"
                              id={`flexSwitchCheck-${RoleUsers.id}`}
                              type="checkbox"
                              checked={RoleUsers.status}
                              onChange={(e) => onClickhandler(e, RoleUsers.id)}
                              value={RoleUsers.id}
                            />
                            {loadingUserId === RoleUsers.id && (
                              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                            )}
                          </div>

                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
                {RoleUsers.length > 0 && (
                  <Pagination
                    data={RoleUsers} // Pass the full data to the Pagination component
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={page}
                  />
                )}

              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default EventCommittees;