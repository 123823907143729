/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { KTIcon } from '../../../helpers';



const CreateAdvertisement = ({eventid}) => {
    
    const [loading, setLoading] = useState(false);
    
    const [modalOpen, setModalOpen] = useState(false);
    const { orgid } = useParams();
    const token = localStorage.getItem('token');
  
    const API_URL = process.env.REACT_APP_API_URL;
    const [orgAdmin, setOrgAdmin] = useState({
      id: "",
      eventid: "",
      orgid: "",
      ticketPrice: "",
      registrationDeadline: "",
      registrationLink: "",
      contactInformation: "",
      image: null,
      imageMeta:"",
      videolink:"",
      description:""
  
    });
   
    const [advertisement, setAdvertisement] = useState(false);

    useEffect(() => {
      axios
        .get(API_URL + `/api/EventPlannings/orgid=${orgid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(response => {
          setAdvertisement(response.data);
        })
        .catch(error => {
          console.error('Error fetching EventRole:', error);
        });
    }, [token]);

 

     
    const handleChange = (event, field) => {
      let actualValue = event.target.value;
      if (field === "isTrue") {
        actualValue = event.target.value === "true";
      }
      setOrgAdmin({ ...orgAdmin, [field]: actualValue });
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      axios
        .post(API_URL + "/api/advertisement/create", orgAdmin, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => response.data)
        .then((result) => {
            toast.success("Advertisement Created Successfully");
  
          setLoading(false);
        
           closeModal();
        });
    }; 

    const closeModal = () => {
      setModalOpen(false);
    };
  return (
    <div className='modal fade' id='kt_modal_Advertisement' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Advertisement</h1>
              

            </div>


      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-10 mx-xl-auto ps-xl-10">
             
                <form onSubmit={handleSubmit}>
                <div className='mb-4'>
              <label htmlFor='eventid' className='form-label'>
                Event ID
              </label>
              <select
                id='eventid'
                className='form-select'
                value={orgAdmin.eventid}
                onChange={(e) => handleChange(e, 'advertisement')}
              >
                <option value=''>Select an Event ID</option>
                {advertisement.map((eventId) => (
                  <option key={eventId} value={eventId} >
                    {eventId}
                  </option>
                ))}
              </select>
            </div>
                    <br></br>

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="orgid">Org Id</label>
                      <input
                        className="form-control"
                        id="orgid"
                        type="text"
                        placeholder="Org Id"
                        onChange={(e) => handleChange(e, "orgid")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="ticketPrice">Ticket Price</label>
                      <input
                        className="form-control"
                        id="ticketPrice"
                        type="text"
                        placeholder="Ticket Price"
                        onChange={(e) => handleChange(e, "ticketPrice")}
                      />
                    </div>
                    </div>
                    <br></br>

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="registrationDeadline">Registration Deadline</label>
                      <input
                        className="form-control"
                        id="registrationDeadline"
                        type="text"
                        placeholder="Registration Deadline"
                        onChange={(e) => handleChange(e, "registrationDeadline")}
                      />
                    </div>
                    </div>
                    <br></br>

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="naregistrationLinkme">Registration Link</label>
                      <input
                        className="form-control"
                        id="registrationLink"
                        type="text"
                        placeholder="Registration Link"
                        onChange={(e) => handleChange(e, "registrationLink")}
                      />
                    </div>
                    </div>
                    <br></br>

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contactInformation">Contact Information</label>
                      <input
                        className="form-control"
                        id="contactInformation"
                        type="text"
                        placeholder="Contact Information"
                        onChange={(e) => handleChange(e, "contactInformation")}
                      />
                    </div>
                    </div>
                    <br></br>
                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="videolink">Video Link</label>
                      <input
                        className="form-control"
                        id="videolink"
                        type="text"
                        placeholder="Video Link"
                        onChange={(e) => handleChange(e, "videolink")}
                      />
                    </div>
                    </div>
                    <br></br>

                    <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </div>
                    </div>
                    <br></br>

               
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      
    </div>
  )
}

export default CreateAdvertisement;
