/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

type Props = {
  className: string
}
const ListsWidget6: React.FC<Props> = ({className}) => {
const [RoleUsers,setRoleUsers] = useState([]);
const { eventId } = useParams(); 
const [currentpage, setCurrentPage]= useState(1);
const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const orgid = data?.orgid;


useEffect(() => {
  axios
    .get(API_URL +`/api/EventPlannings/orgid=${orgid}/archiveEvents/page=${currentpage}` , {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setRoleUsers(response.data.pageData);
     
    })
    .catch(error => {
      console.error('Error fetching EventRole:', error);
    });
}, [token]);



  return (
    <>
   
    <div className={`card ${className}`}>
   
      <div className='card card-xl-stretch mb-xl-8  bg-warning '>
        <div className='card-title d-flex flex-column align-items-center justify-content-center ' style={{ height: '200px' }} >
       
     
  <div className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>
    {RoleUsers.length}
  </div>


         
       
          <h3 className='card-title text-gray-800 fw-bold  text-center'>Number of Archived Events</h3>
        </div>
      </div>



      {/* end::Header */}
      {/* begin::Body */}

      {/* end::Body */}
     
    </div>
     
    </>
  )
}

export {ListsWidget6}
