import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { useParams } from 'react-router-dom';
import Loader from '../../../../Loader';
import { toast } from "react-toastify";
import { KTIcon } from '../../../helpers';
import { useAuth } from '../../../../app/modules/auth';
import AddEXEvent from './AddEventRoles/AddEXEvent';
const API_URL = process.env.REACT_APP_API_URL;


function EventExhibitors() {
  const [exhibitor, setExhibitor] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const { eventId } = useParams();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");


  function onClickhandler(e, exibitorid) {
    const checked = e.target.checked;

    const status = checked;
    setLoadingUserId(exibitorid)

    axios
      .get(API_URL + `/api/authority/id=${exibitorid}/value=${status}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);

      })
      .catch((error) => {
        const errorMessage = 'Failed to update Exhibitor Actions . Please try again.';
        toast.error(errorMessage);
        setLoadingUserId(null);
      });
  }

  const handleExCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/authority/eventid=${eventId}/role=ROLE_EXHIBITOR`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setExhibitor(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Exhibitors', error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/authority/eventid=${eventId}/role=ROLE_EXHIBITOR`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setExhibitor(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching Exhibitors', error);
        setLoading(false);
      });
  }


  

  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const filteredData = exhibitor.filter((exhibitor) =>
  exhibitor.username.toLowerCase().includes((searchTerm || '').toLowerCase())
);


  const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <AddEXEvent event={eventId} onexCreated={handleExCreated}/>
       <div className='card-header border-0 pt-5'>

<div className='card-title'>
  <div className='d-flex align-items-center position-relative my-1'>
    <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
    <input
      type='text'
      data-kt-user-table-filter='search'
      className='form-control form-control w-450px ps-14'
      placeholder='Search Users'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
    />
  </div>

</div>

</div>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              
              <span className='card-label fw-bold fs-3 mb-1'> Exhibitors </span>
            </h3>
            <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_Event_Exhibitor'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </a>
        </div>

          </div>
          <div className='card-toolbar'>
          </div>
          <div className='card-toolbar'>


            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='w-25px'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>

                        </div>
                      </th>

                      {/* <th className='min-w-100px'> Id</th> */}
                      <th className='min-w-200px'>Exibitors</th>
                      <th className='min-w-80px '>Actions</th>

                    </tr>
                  </thead>

                  <tbody>
                    {paginatedData.slice().reverse().map((exhibitor) => (
                      <tr key={exhibitor.id}>
                        <td>

                        </td>
                        {/* <td>
                          {exhibitor.id}

                        </td> */}

                        <td>
                          
                          <Link to={`/Exibitor/${exhibitor.username}`} className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {exhibitor.username}
                          </Link>
                        </td>


                        <td style={{ display: 'flex', alignItems: 'center' }}>

                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                            <input
                              className="form-check-input"
                              id={`flexSwitchCheck-${exhibitor.id}`}
                              type="checkbox"
                              checked={exhibitor.status}
                              onChange={(e) => onClickhandler(e, exhibitor.id)}
                              value={exhibitor.id}
                            />
                            {loadingUserId === exhibitor.id && (
                              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                            )}
                          </div>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {exhibitor.length > 0 && (
                  <Pagination
                    data={exhibitor} // Pass the full data to the Pagination component
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={page}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventExhibitors;