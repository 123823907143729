import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../auth';
import { toast } from "react-toastify";
import { FaRegUser } from "react-icons/fa";
import { useLocation } from 'react-router';

const API_URL = process.env.REACT_APP_API_URL;


const ProfileDocumentation = () => {
  const { setCurrentUser, currentUser, auth } = useAuth()
  const location = useLocation()
  const [profile, setProfile] = useState({
    userid: "",
    aadharcard: "",
    pan: "",
    image: null,
    imageMeta: "",
    description: "",
    orgtype: "",
    pan: "",
    gstin: "",
    tan: ""
  });

  const storedData = localStorage.getItem('kt-auth-react-v');
  const data = storedData ? JSON.parse(storedData) : null;
  const role = data?.role;


  const validatePhoneNumber = (phone) => {
    const phoneNumberPattern = /^[0-9]{10}$/;
    return phoneNumberPattern.test(phone);
  };

  const validatePAN = (pan) => {
    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panPattern.test(pan);
  };
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const validateAadharCard = (aadharCard) => {
    // Aadhar Card should be a 12-digit number
    const aadharCardPattern = /^\d{12}$/;
    return aadharCardPattern.test(aadharCard);
  };

  const validateUdyamNumber = (udyamNumber) => {
    // Add your validation logic for Udyam Number here
    // For example, let's assume Udyam Number should be a 12-character alphanumeric identifier
    const udyamNumberPattern = /^[A-Za-z0-9]{12}$/;
    return udyamNumberPattern.test(udyamNumber);
  };

  const validateSINNumber = (sinNumber) => {
    // Add your validation logic for SIN Number here
    // For example, let's assume SIN Number should be a 9-digit numeric identifier
    const sinNumberPattern = /^[0-9]{9}$/;
    return sinNumberPattern.test(sinNumber);
  };


  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }

  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...profile,

      imageMeta: convertedImage.metadata,
      image: convertedImage.content,

    };
    setProfile(imageObject);
  }



  useEffect(() => {
    // getData();
    setProfile(currentUser);
  }, []);

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (role == "ROLE_EXHIBITOR") {
      axios
        .post(API_URL + "/api/exhibitorProfile/update", profile, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        })
        .then((response) => response.data)
        .then((result) => {
          toast.success("Profile Updated");
          setProfile(profile);
          setCurrentUser(profile);
          navigate("/profiletabs");
          setLoading(false);

        });
    }
    else {
      axios
        .post(API_URL + "/api/user/profile/update", profile, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        })
        .then((response) => response.data)
        .then((result) => {
          toast.success("Profile Updated");
          setProfile(profile);
          setCurrentUser(profile);
          navigate("/profiletabs");
          setLoading(false);

        });
    }
  };



  return (
   
    <div>
      <br></br>
      <br></br>
      <div className='card mb-5 mb-xl-10'>

        <br></br>
        <div className='d-flex justify-content-between align-items-center w-100'></div>
        <div className='card-title m-0'>
          <h3 className='fw-bolder'>Documentation </h3>
        </div>


        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
           
              {currentUser?.orgtype === "Individual"&&(
              <div className='card-body border-top p-9'>
              <h3 className='fw-bolder m-5'> Individual </h3>
              <br></br>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'> Organization Photo</label>
                <div className='col-lg-8'>


                  <div className='me-7 mb-4'>
                    <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>


                      {profile?.image ? (
                        <img id="image" src={`${profile?.imageMeta},${profile?.image}`} alt={profile?.email} style={{ maxWidth: "150px", maxHeight: "150px" }}

                        />
                      ) : (
                        <FaRegUser size={45} />
                      )}

                    </div></div>
                  <input className="col-7" type="file" id="image" onChange={(event) => {
                    handleImageChange(event)
                  }} accept="image/*"></input>
                </div>

              </div>

              <div className='row mb-6' hidden>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>User Id</span>
                </label>


                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone number'
                    value={profile?.userid}
                    onChange={(e) => handleChange(e, "userid")}
                    readOnly
                  />
                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Aadhar Card</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateAadharCard(profile.aadharcard) ? '' : 'is-invalid'
                      }`}
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>



              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>PAN</label>

                <div className='col-lg-8 fv-row'>

                  <input
                    type='text'
                    id="pan"
                    placeholder='Pan'
                    value={profile?.pan}
                    onChange={(e) => handleChange(e, "pan")}
                    className={`form-control form-control-lg form-control-solid ${validatePAN(profile.pan) ? '' : 'is-invalid'
                      }`}
                    rows={1}
                  />
                  <div className='invalid-feedback'>PAN should be 10-character alphanumeric identifier, example: AAAAA1111A.</div>

                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Gstin</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    id='gstin'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Gstin'
                    value={profile?.gstin}
                    onChange={(e) => handleChange(e, "gstin")}
                  />

                </div>
              </div>

            </div>
              )}

            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </form>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
           
              {currentUser.orgtype == "Partnership"&&(
                 <div className='card-body border-top p-9'>
              <h3 className='fw-bolder m-5'> Partnership </h3>
              <br></br>


              <div className='row mb-6' hidden>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>User Id</span>
                </label>


                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Phone number'
                    value={profile?.userid}
                    onChange={(e) => handleChange(e, "userid")}
                    readOnly
                  />
                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name of the partners</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Partnership Deed</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>



              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>PAN</label>

                <div className='col-lg-8 fv-row'>

                  <input
                    type='text'
                    id="pan"

                    placeholder='Pan'
                    value={profile?.pan}
                    onChange={(e) => handleChange(e, "pan")}
                    className={`form-control form-control-lg form-control-solid ${validatePAN(profile.pan) ? '' : 'is-invalid'
                      }`}
                    rows={1}
                  />
                  <div className='invalid-feedback'>PAN should be 10-character alphanumeric identifier, example: AAAAA1111A.</div>

                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Gstin</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    id='gstin'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Gstin'
                    value={profile?.gstin}
                    onChange={(e) => handleChange(e, "gstin")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'> TAN </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Udyam Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateUdyamNumber(profile.udyamnumber) ? '' : 'is-invalid'
                      }`}
                    placeholder='Udyam Number'
                    id='udyamnumber'
                    value={profile?.udyamnumber}
                    onChange={(e) => handleChange(e, 'udyamnumber')}
                  />
                  <div className='invalid-feedback'>Udyam Number should be a 12-character alphanumeric identifier.</div>
                </div>
              </div>

            </div>
              )}
            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </form>
        </div>


        

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            
              {currentUser.orgtype === "Public Corporate"&&(

              <div className='card-body border-top p-9'>
              <h3 className='fw-bolder m-5'> Public corporate </h3>
              <br></br>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>SIN Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateSINNumber(profile.sinnumber) ? '' : 'is-invalid'
                      }`}
                    placeholder='SIN Number'
                    id='sinnumber'
                    value={profile?.sinnumber}
                    onChange={(e) => handleChange(e, 'sinnumber')}
                  />
                  <div className='invalid-feedback'>SIN Number should be a 9-digit numeric identifier.</div>
                </div>
              </div>



              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>PAN</label>

                <div className='col-lg-8 fv-row'>

                  <input
                    type='text'
                    id="pan"

                    placeholder='Pan'
                    value={profile?.pan}
                    onChange={(e) => handleChange(e, "pan")}
                    className={`form-control form-control-lg form-control-solid ${validatePAN(profile.pan) ? '' : 'is-invalid'
                      }`}
                    rows={1}
                  />
                  <div className='invalid-feedback'>PAN should be 10-character alphanumeric identifier, example: AAAAA1111A.</div>

                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Gstin</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    id='gstin'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Gstin'
                    value={profile?.gstin}
                    onChange={(e) => handleChange(e, "gstin")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'> TAN </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Udyam Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateUdyamNumber(profile.udyamnumber) ? '' : 'is-invalid'
                      }`}
                    placeholder='Udyam Number'
                    id='udyamnumber'
                    value={profile?.udyamnumber}
                    onChange={(e) => handleChange(e, 'udyamnumber')}
                  />
                  <div className='invalid-feedback'>Udyam Number should be a 12-character alphanumeric identifier.</div>
                </div>
              </div>

            </div>
              )}
            

            {/* <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </form>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            
              {currentUser.orgtype === "Private limited" &&(

              <div className='card-body border-top p-9'>
              <h3 className='fw-bolder m-5'>Private limited </h3>
              <br></br>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>SIN Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateSINNumber(profile.sinnumber) ? '' : 'is-invalid'
                      }`}
                    placeholder='SIN Number'
                    id='sinnumber'
                    value={profile?.sinnumber}
                    onChange={(e) => handleChange(e, 'sinnumber')}
                  />
                  <div className='invalid-feedback'>SIN Number should be a 9-digit numeric identifier.</div>
                </div>
              </div>



              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>PAN</label>

                <div className='col-lg-8 fv-row'>

                  <input
                    type='text'
                    id="pan"

                    placeholder='Pan'
                    value={profile?.pan}
                    onChange={(e) => handleChange(e, "pan")}
                    className={`form-control form-control-lg form-control-solid ${validatePAN(profile.pan) ? '' : 'is-invalid'
                      }`}
                    rows={1}
                  />
                  <div className='invalid-feedback'>PAN should be 10-character alphanumeric identifier, example: AAAAA1111A.</div>

                </div>
              </div>


              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Gstin</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    id='gstin'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Gstin'
                    value={profile?.gstin}
                    onChange={(e) => handleChange(e, "gstin")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'> TAN </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Aadhar Card'
                    id='aadharcard'
                    value={profile?.aadharcard}
                    onChange={(e) => handleChange(e, "aadharcard")}
                  />

                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Udyam Number</label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className={`form-control form-control-lg form-control-solid ${validateUdyamNumber(profile.udyamnumber) ? '' : 'is-invalid'
                      }`}
                    placeholder='Udyam Number'
                    id='udyamnumber'
                    value={profile?.udyamnumber}
                    onChange={(e) => handleChange(e, 'udyamnumber')}
                  />
                  <div className='invalid-feedback'>Udyam Number should be a 12-character alphanumeric identifier.</div>
                </div>
              </div>

            </div>
              )}
            
{/* 
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div> */}
          </form>
        </div>

        < div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={handleSubmit} noValidate className='form'>
            {currentUser.orgtype === "International" && (

              <div className='card-body border-top p-9'>


                <h3 className='fw-bolder m-5'> International </h3>
                <br></br>


                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'> Tax Number </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder=' Tax Number '
                      id='taxnumber'
                      value={profile?.taxnumber}
                      onChange={(e) => handleChange(e, "taxnumber")}
                    />

                  </div>
                </div>


                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company Identification Number</label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Aadhar Card'
                      id='aadharcard'
                      value={profile?.aadharcard}
                      onChange={(e) => handleChange(e, "aadharcard")}
                    />

                  </div>
                </div>

              </div>


            )}


            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>



  )


}

export default ProfileDocumentation
