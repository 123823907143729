import React, { useState, useEffect } from 'react'
import AddStall from './AddStalls';
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../../../app/modules/auth/components/Pagination';
import Loader from '../../../Loader';


const API_URL = process.env.REACT_APP_API_URL;

export default function ExhibitorStalldetails() {
    const [stalls, setStalls] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const [loading, setLoading] = useState(false);
    const { eventId } = useParams();
    const { auth , currentUser} = useAuth();



    useEffect(() => {
        setLoading(true);
        axios
            .get(API_URL + `/api/stallexhibitormapping/${eventId}/exhibitor=${currentUser?.email}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setStalls(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.errorMessage);
                setLoading(false);
            });
    }, [auth?.token, eventId]);

    const handlePageChange = selectedPage => {
        setPage(selectedPage);
    };

    const paginatedData = stalls.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
          {loading ? (
            <Loader />
          ) : (
            <div className='card card-raised'>
            
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-2 mb-1'>Stall Details</span>
                        </h3>
                        <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a stall'
                        >
                    
                           <Link to={`/eventStalls/${eventId}`} className='btn fs-4 btn-sm btn-light-primary float-end'>
                <KTIcon iconName='plus' className='fs-1' />
               Buy Stall
              </Link>
                       
                        </div>
                    </div>
    
              <div className='card-toolbar'>
                <div className='card-body py-3'>
                  <div className='row'>
                    {paginatedData.slice().reverse().map((stalluser) => (
                      <div key={stalluser.id} className='col-md-4 mb-4'>
                        <div className='card'>
                          <div className='card-body'>
                            <h5 className='card-title'>{stalluser.stalltype}</h5>
                            <p className='card-text'>Stall ID: {stalluser.stallid}</p>
                            <p className='card-text'>Dimension: {stalluser.dimension}</p>
                            <p className='card-text'>Cost: {stalluser.cost}</p>
    
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              {/* <div onClick={(e) => removeStall(stalluser)} className='btn btn-danger'>
                                <MdDeleteForever style={{ fontSize: '20px' }} /> Delete
                              </div> */}
                              <Link to={`/stalldetails/${eventId}/${stalluser.id}`} className='btn btn-primary'>
                                <KTIcon iconName='pencil' className='fs-1' /> Edit
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
    
                {stalls.length > 0 && (
                  <Pagination
                    data={stalls}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={page}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      );
}
