import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card3 } from '../../../../_metronic/partials/content/cards/Card3';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function Connections() {
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const { currentUser, auth } = useAuth();
  const { eventId } = useParams();

  const [exibitor, setExibitor] = useState({
    
  });



  useEffect(() => {
    setLoading(true);

  
    if (currentUser && currentUser.id) {
      axios
        .get(`${API_URL}/api/exibitorConnections/eventid=${eventId}/exibitor=${currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        })
        .then((response) => {
          setConnections(response.data);
          setLoading(false);
         })
        .catch((error) => {
          toast.error('Error fetching connection:', error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      
    }
  }, [auth?.token, currentUser, eventId]);

  return (
    <>
      {loading ? (
        <div className='text-center'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h3 className='fw-bolder fs-1 my-2'>Connections</h3>
          </div>

          <div className='row g-6 g-xl-9'>
            {connections.map((connection, index) => (
              <div className='col-md-4 col-xxl-4' key={index}>
                <Card3 connection={connection} />
                {(index + 1) % 4 === 0 && <div className='w-100'></div>}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

export default Connections;
