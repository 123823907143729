import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function AdminAdvertisement() {
  const { addId } = useParams();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("");
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();
  const [Advetype, setAdvetype] = useState([]);
  const [selectedTypeTimeAllowed, setSelectedTypeTimeAllowed] = useState("");
  const [selectedTypePrice, setSelectedTypePrice] = useState("");
  const [listType, setListType] = useState([]);
  const [Adv, setAdv] = useState([]);
  const [content, setContent] = useState({
    id: 0,
    name: "",
    contactInformation: "",
    image: null,
    imageMeta: "",
    videolink: "",
    text: "",
    description: "",
    username: ""
  });
  const [adds, setAdds] = useState({
    id: 0,
    eventid: "",
    addId: addId || content?.id,
    paymentStatus: "",
    startdate: "",
    enddate: "",
    paymentType: "",
    text: "",
    cost: "",
    transaction: {
      id: 0,
      username: "",
      transactionType: "ADVERTISEMENT",
      paymentLink: "",
      transactionStatus: true,
    }
  })

  const [showTransactionDetails, setShowTransactionDetails] = useState(false);



  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${API_URL}/api/advertisement/${addId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setContent(response.data);
        // const addresponse = await axios.get(`${API_URL}/api/advertisement/${addId}`, {
        //   headers: {
        //     Authorization: `Bearer ${auth?.token}`,
        //   },
        // });
        // setAdds(addresponse.data);
      } catch (error) {
        toast.error(`Error fetching Advertisement: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (addId) {
      fetchData();
    }
  }, [addId]);

  useEffect(() => {
    axios
      .get(API_URL + '/api/advertisementtype', {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setAdvetype(response.data);
      })
      .catch((error) => {
        toast.error('Error Fetching :', error);
      });
  }, [auth?.token]);

  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(API_URL + `/api/advertisementsettings`, {
  //       headers: {
  //         Authorization: `Bearer ${auth?.token}`,
  //       },
  //     })
  //     .then(response => {
  //       setListType(response.data);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       toast.error(error);
  //       setLoading(false);
  //     });

  // }, [auth?.token]);



  const handleTransactionSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_URL}/api/addTransaction/create`,
        adds,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      const successMessage = 'Transaction created successfully';
      toast.success(successMessage);
      navigate(`/advertisements`);
    } catch (error) {
      const errorMessage = 'Error creating Transaction';
      toast.error(`${errorMessage}: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleContentTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);

    // Find the selected type in the listType and update the timeAllowed and price
    const selectedTypeDetails = listType.find(type => type.type === selectedType);
    if (selectedTypeDetails) {
      setSelectedTypeTimeAllowed(selectedTypeDetails.timeallowed);
      setSelectedTypePrice(selectedTypeDetails.price);
    }
  };

  const handleSubmitAndTransactionSubmit = async () => {
    setLoading(true);



    try {
      const advertisementResponse = await axios.post(
        `${API_URL}/api/advertisement/${addId ? 'update' : 'create'}`,
        content,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );
      // Call handleTransactionSubmit
      const transactionResponse = await axios.post(
        `${API_URL}/api/addTransaction/create`,
        adds,
        {
          headers: { Authorization: `Bearer ${auth?.token}` },
        }
      );

      const successMessage = addId ? 'Advertisement updated successfully' : 'Advertisement created successfully';
      toast.success(successMessage);
      navigate(-1);
    } catch (error) {
      const errorMessage = addId ? 'Error updating Advertisement' : 'Error creating Advertisement';
      toast.error(`${errorMessage}: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };


  const handleChangeAdv = async (fieldName, value, typeId) => {
    if (fieldName === 'advertisementtypeid') {
      value = typeId;

      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/advertisementsettings/advertisementtypeid=${typeId}`, {
          headers: {
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        setAdv(response.data);
        setLoading(false);
      } catch (error) {
        toast.error(`Error fetching advertisement settings: ${error.message}`);
      }
    }

    setContent((prevContent) => ({
      ...prevContent,
      [fieldName]: value,
    }));
  };

  const handleChange = (e, fieldName) => {
    const { value } = e.target;

    setContent((prevContent) => ({
      ...prevContent,
      [fieldName]: value,
    }));
  };



  const handleTransactionChange = (e, field) => {
    setAdds((prevContent) => ({
      ...prevContent,
      transaction: {
        ...prevContent.transaction,
        [field]: e.target.value,
      },
    }));

  };


  const handleAddsChange = (e, field) => { // Accept event and field
    const { value } = e.target; // Extract value from event
    setAdds((prevContent) => ({
      ...prevContent,
      [field]: value, 
      transaction: {
        ...prevContent.transaction,
        paymentType: value, 
      }
    }));
  };


  function getBase64FromFile(file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const [metadata, content] = reader.result.split(",");
        resolve({ metadata, content });
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }


  const handleImageChange = async (event) => {
    const convertedImage = await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...content,

      imageMeta: convertedImage.metadata,
      image: convertedImage.content,

    };
    setContent(imageObject);
  }

  // function handlePayment(value) {
  //   setPaymenttype(value);
  //     if (value === "Offline") {
  //     setOffline(true);
  //   }
  //   else {
  //     setOffline(false);
  //   }
  // }

  const handleNextButtonClick = () => {
    setShowTransactionDetails(true); // Show transaction details when "Next" button is clicked
  };

  return (
    <div style={{ paddingLeft: '15px', paddingRight: '15px', marginLeft: 'auto', marginRight: 'auto' }}>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="col-md-12">

            <div className="card mb-5 mb-xl-10">

              <h1 className='mt-8 px-8'>Advertisement</h1>
              <div className="px-20">

                <div className="row mt-4">
                  <div className="col-10 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="contactInformation">Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Advertisement Name"
                        onChange={(e) => handleChange(e, "name")}
                        value={content.name}
                      />

                    </div>
                  </div>
                  {/* <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="contactInformation">Mobile</label>
                      <input
                        className="form-control"
                        id="contactInformation"
                        type="number"
                        placeholder="Contact Information"
                        onChange={(e) => handleChange(e, "contactInformation")}
                        value={content?.contactInformation}
                      />
                    </div>
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-10 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "description")}
                        value={content?.description}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                      Select Content type
                    </label>

                    <select
                      name="contentType"
                      className="form-control"
                      onChange={handleContentTypeChange}
                      style={{ fontSize: "15px" }}
                      value={selectedType}
                    >
                      <br></br>
                      <option value="">Select</option>
                      <option value="UPLOAD IMAGE">Upload Image</option>
                      <option value="UPLOAD VIDEO LINK">Upload Video Link</option>
                      <option value="TEXT">Text</option>
                    </select>
                  </div>
                </div>

                {/* Render input fields based on selected content type */}
                {selectedType === "UPLOAD IMAGE" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                        Upload Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => handleImageChange(e)}
                        readOnly={!!content?.image}
                        accept="image/*"
                        style={{ fontSize: "15px" }}
                      />
                      <div className='me-7'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                          {content?.image ? (
                            <img id="image" src={`${content?.imageMeta},${content?.image}`} style={{ maxWidth: "150px", maxHeight: "150px" }}
                            />
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {selectedType === "UPLOAD VIDEO LINK" && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                        Upload Video Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Video Link"

                        onChange={(e) => handleChange(e, "videolink")}
                        value={content?.videolink}

                        style={{ fontSize: "15px" }}
                      />
                    </div>
                  </div>
                )}

                {selectedType === "TEXT" && (
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                        Text
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Text"
                        onChange={(e) => handleChange(e, "text")}
                        value={content.text}
                        style={{ fontSize: "15px" }}
                      />
                    </div>
                  </div>
                )}

                <br></br>

                <div className="row">
                  <div className="col-md-6 ">
                    <div className="form-group">
                      <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                        Select Advertisement type
                      </label>
                      <select
                        id='advertisementtypeid'
                        className='form-control'
                        onChange={(e) => handleChangeAdv('advertisementtypeid', e.target.value, e.target.selectedOptions[0].getAttribute('data-type'))}
                        value={content?.advertisementtypeid}
                      >
                        <option value="">Select...</option>
                        {Advetype.map((type) => (
                          <option key={type.id} value={type.type} data-type={type.type}>
                            {type.type}
                          </option>
                        ))}
                      </select>


                    </div>
                  </div>

                  <br />

                  {Adv.map((Adv) => (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                            Time ={Adv.timeallowed}
                          </label>
                          <div>{selectedTypeTimeAllowed}</div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="form-label fw-semibold" style={{ fontSize: "15px" }}>
                          Price={Adv.cost}
                        </label>
                        <div>{selectedTypePrice}</div>
                      </div>

                    </div>
                  ))}
                </div>


              </div>
              <br />


              <div className="card-footer d-flex justify-content-end py-6 px-9">
                {!showTransactionDetails && (
                  <button type="button" onClick={handleNextButtonClick} className="btn btn-primary">
                    Next
                  </button>
                )}
              </div>

            </div>
          </div>


          {showTransactionDetails && (
            <div className='card mb-xl-10'>
              <h1 className='pt-5 px-8'> Transaction Details</h1>

              <div className="row px-20 mt-2">
                <div className='row'>
                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="username">Username</label>
                      <input
                        className="form-control"
                        id="username"
                        type="text"
                        placeholder="Username"
                        onChange={(e) => handleTransactionChange(e, "username")}
                        value={adds?.transaction.username}
                      />
                    </div>
                  </div>
                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">

                    <div className="form-floating-sm">
                      <label>
                        Payment Type
                      </label>
                      <select
                        className="form-select"
                        name="payment type"
                        onChange={(e) => handleAddsChange(e, "paymentType")} // Pass event and field
                        value={adds?.paymentType}
                      >
                        <option value="">Select</option>
                        <option value="Online">Online</option>
                        <option value="Offline">Offline</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="registrationDeadline">From Date</label>
                      <input
                        className="form-control"
                        id="startdate"
                        type="date"
                        placeholder="From Date"
                        onChange={(e) => handleAddsChange(e, "startdate")}
                        value={adds?.startdate}
                      // readOnly={adds?.startdate ? true : false}
                      />
                    </div>
                  </div>

                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="ticketPrice">To Date</label>
                      <input
                        className="form-control"
                        id="enddate"
                        type="date"
                        placeholder="End Date"
                        onChange={(e) => handleAddsChange(e, "enddate")}
                        value={adds?.enddate}
                      // readOnly={adds?.enddate ? true : false}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="cost">Cost</label>
                      <input
                        className="form-control"
                        id="cost"
                        type="text"
                        placeholder="Cost"
                        onChange={(e) => handleAddsChange(e, "cost")}
                        value={adds?.cost}
                      // readOnly={adds?.cost ? true : false}
                      />
                    </div>
                  </div>

                  <div className="col-6 col-form-label fw-bold mx-0 mb-2">
                    <div className="form-floating-sm">
                      <label htmlFor="paymentLink">Payment Reference Code</label>
                      <input
                        className="form-control"
                        id="paymentLink"
                        type="text"
                        placeholder="Payment Ref. Code"
                        onChange={(e) => handleTransactionChange(e, "paymentLink")}
                        value={adds?.transaction.paymentLink}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-footer d-flex justify-content-end py-6 px-9">

                  <button type="submit" onClick={handleSubmitAndTransactionSubmit} className="btn btn-primary">
                    Save
                  </button>

                </div>

              </div>

            </div>

          )}
        </div>

      )
      }
    </div >
  )
}

export default AdminAdvertisement;
