import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { KTIcon } from '../helpers';
import Pagination from '../../app/modules/auth/components/Pagination';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CreateAdvertisement from '../partials/modals/Add Pages/CreateAdvertisement';


const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

function Advertisement() {
  const [accountuser, setAccountUser] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 30; // Specify the number of rows per page
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");
  const [eventid, setEventId] = useState([]);
  const [orgid,SetOrgId] = useState([]);
 
 


 
 

  useEffect(() => {
    axios
      .get(API_URL + `/api/EventPlannings/orgid=${orgid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setEventId(response.data);
      })
      .catch((error) => {
        toast.error('Error fetching :', error);
      });
  }, []);



  function onClickhandler(e, userid) {
    const checked = e.target.checked;

    const disabled= !checked; // Reverse the checked value to set the 'disabled' property
    setLoading(true);
    setLoadingUserId(userid);

    axios
      .get(API_URL + `/api/accounts/accountUpdate/id=${userid}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setLoadingUserId(null);
        toast.success("User updated successfully");
      })
      .catch((error) => {
        console.error('Error updating account:', error);
      });
  }


  

  const handlePageChange = (selectedPage) => {
      setPage(selectedPage);
  };

  const filteredData = accountuser.filter((accountusers) =>
  accountusers.username.toLowerCase().includes(searchTerm.toLowerCase())
);

const paginatedData = filteredData.slice(
  (page - 1) * rowsPerPage,
  page * rowsPerPage
);


  return (
    
    <div className='card card-raised'>
<CreateAdvertisement eventid={eventid} />
      <div className='card-header border-0 pt-5'>

      <div className='card-title'>
       <div className='d-flex align-items-center position-relative my-1'>
        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control w-450px ps-14'
          placeholder='Search Users'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
     
      <a
            href='#'
            className='btn btn-sm btn-light-primary position-absolute end-0  mb-2 me-12'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_Advertisement'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </a>
     
 
  
    </div>
    
</div>
<div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Advertisement</span>
        </h3>
      </div>
      

      <div className='card-toolbar'>
        <div className='card-body py-3'>
          <div className='table-responsive'>

         

            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              <thead>
                <tr className='fw-bold text-muted'>

              
                  
                  
                  <th className='min-w-80px'>Event Id</th>
                  <th className='min-w-40px'>Registration Deadline</th>
                  <th className='min-w-80px'>Registration Link</th>
                  <th className='min-w-80px'>Video Link</th>
                  <th className='min-w-80px'>Action</th>
                 
                </tr>
              </thead>
              <tbody>
                {paginatedData.slice().reverse().map((accountusers) => (
                  <tr key={accountusers.id}>
                    
                    <td>
                      <div className='text-dark fw-bold text-hover-primary fs-6'>
                        {accountusers.ticketPrice}
                      </div>
                    </td>

                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.registrationDeadline}
                      </div>
                    </td>
                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.registrationLink}
                      </div>
                    </td>

                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.contactInformation}
                      </div>
                    </td>

                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.image}
                      </div>
                    </td>

                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.imageMeta}
                      </div>
                    </td>

                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.videolink}
                      </div>
                    </td>

                    <td>
                      <div href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {accountusers.description}
                      </div>
                    </td>

                  

                    <td>
                    <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px' }}>
    {loading && loadingUserId === accountusers.id && (
      <span className='spinner-border spinner-border-sm align-middle me-2'></span>
    )}

    <input
      className="form-check-input"
      id="flexSwitchCheckDefault"
      type="checkbox" 
      defaultChecked={!accountusers.disabled}
      onClick={(e) => onClickhandler(e, accountusers.id)}
      value={accountusers.id}
    />
  </div>
                      <Link
                        to={`/edittaccountuser/${accountusers.id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
           
            <Pagination
              data={accountuser} // Pass the full data to the Pagination component
              itemsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              currentPage={page}
            />
         
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertisement;
