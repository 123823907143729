import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { useAuth } from '../../../app/modules/auth';
import AddType from '../../partials/modals/Add Pages/AddType';


const API_URL = process.env.REACT_APP_API_URL;

function SubscriptionType() {
    const [sub, setSub] = useState([]);
    const [page, setPage] = useState(1);
    const rowsPerPage = 30;
    const [loading, setLoading] = useState(false);
    const { auth } = useAuth();
    const [loadingUserId, setLoadingUserId] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(false);

    function onClickhandler(e, id) {
        const checked = e.target.checked;

        const disabled = !checked;
        setLoadingUserId(id)


        axios
            .get(API_URL + `/api/subscriptiontype/id=${id}/value=${disabled}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setLoadingUserId(null);
                toast.success("Updated Succesfully");
                setUpdateFlag((prevFlag) => !prevFlag);
            })
            .catch((error) => {
                toast.error('Error updating account:', error);
                setLoadingUserId(null);
            });
    }
    const handleTypeCreated = () => {
        setLoading(true);
        axios
          .get(API_URL + `/api/subscriptiontype`, {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          })
          .then(response => {
            setSub(response.data);
            setLoading(false);
          })
          .catch(error => {
            toast.error('Error Fetching OrganizationType:', error);
            setLoading(false);
          });
      };



    useEffect(() => {
        fetchData();
    }, [updateFlag]);


    function fetchData() {
        setLoading(true);
        axios
            .get(API_URL + `/api/subscriptiontype`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then(response => {
                setSub(response.data);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error Fetching TaskStatus:', error);
                setLoading(false);
            });
    };

      
    const handlePageChange = (selectedPage) => {
        // Update the current page when the pagination component triggers a page change
        setPage(selectedPage);
    };
    const paginatedData = sub.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className='card card-raised'>
                    <AddType handleTypeCreated={handleTypeCreated}/>
                    <div className='card-header border-0 pt-5'>
                        <h3 className='card-title align-items-start flex-column'>

                            <span className='card-label fw-bold fs-3 mb-1'>Subscription Type</span>

                        </h3>
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Click to add new'
                        >
                            <a
                                href='#'
                                className='btn btn-sm btn-light-primary float-end'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_Type'
                            >
                                <KTIcon iconName='plus' className='fs-3' />
                                Add
                            </a>
                        </div>
                    </div>


                    <div className='card-toolbar'>



                        <div className='card-body py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>



                                            <th className='min-w-120px'>Subscription Type</th>
                                            <th className='min-w-120px'>Cost</th>
                                            <th className='min-w-120px'>Action</th>



                                        </tr>
                                    </thead>

                                    <tbody>
                                        {paginatedData.slice().reverse().map((sub) => (
                                            <tr key={sub.id}>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {sub.type}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                        {sub.cost}
                                                    </div>
                                                </td>



                                                <td style={{ display: 'flex', alignItems: 'center' }}>

                                                    <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                                                        <input
                                                            className="form-check-input"
                                                            id="flexSwitchCheckDefault"
                                                            type="checkbox"
                                                            defaultChecked={!sub.disabled}
                                                            onClick={(e) => onClickhandler(e, sub.id)}
                                                            value={sub.id}
                                                        />
                                                        {loadingUserId === sub.id && (
                                                            <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                                                        )}
                                                    </div>
                                                    <Link
                                                        to={`/editSubscription/${sub.id}`}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-3' />
                                                    </Link>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {sub.length > 0 && (
                                    <div className='pagination-container' style={{ marginRight: '120px' }}>
                                        <Pagination
                                            data={sub} // Pass the full data to the Pagination component
                                            itemsPerPage={rowsPerPage}
                                            onPageChange={handlePageChange}
                                            currentPage={page}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
}

export default SubscriptionType;
