import React, { FC, useEffect, useState, useContext } from 'react'
import { ErrorMessage } from 'formik'
import axios from 'axios';
import { useAuth } from '../../../auth';
import { toast } from 'react-toastify';
import { useNavigate, useLocation} from 'react-router-dom';
import WizardContext from '../WizardContext';
interface SubscriptionType {
  id: number;
  type: string;
  cost: string;
}
const API_URL = process.env.REACT_APP_API_URL;
 
const Step4: FC = () => {
  const { auth } = useAuth();
  const [types, setType] = useState<SubscriptionType[]>([]);
  const [selectedType, setSelectedType] = useState<string>(''); 
  const [loading, setLoading]= useState(false);
  const navigate= useNavigate();
  const location = useLocation();
  const { organization } = useContext(WizardContext);
  


  useEffect(() => {
    axios.get(API_URL + "/api/subscriptiontype", {
      headers: {
        Authorization: `Bearer ${auth?.token}`,
      },
    })
      .then((response) => {
        setType(response.data);
            })
      .catch((error) => {
        toast.error('Error fetching types:', error);
      });

  }, [])


  

  const handleSubmit = (selectedType: string) => {
    setLoading(true);
    const username = organization?.admin;
     if (selectedType =="FreeTrail") {
      
      axios
        .post(API_URL + `/api/user/subscription/freetrial?username=${username}`)
        .then((res) => {
          toast.success('Free Trial account create successfully!');
          setLoading(false);
        })
        .catch((error) => {
          toast.error('Error creating Free Trial account:', error);
          setLoading(false);
        });
    } else {
      localStorage.setItem('currentPage', location.pathname);
      navigate(`/paymentDetails/${selectedType}/${username}`, { state: { feature: { type: selectedType } } });
      setLoading(false);
    }
  };

  const handleChange = (type:any, field:any) => {
    let actualValue = type.target.value;

    setSelectedType({ ...type, [field]: actualValue });
    handleSubmit(actualValue);
  };


  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Subscription Details</h2>

        <div className='text-gray-400 fw-bold fs-6'>

        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
          <span className='required'>Please select subscription type</span>
         
        </label>
        <select placeholder="select..." className="form-control form-control-solid"
                        onChange={(e) => handleChange(e, "type")} >
                        <option> Select..... </option>
                        {types.map(etask => {
                          return (
                             <option value={etask.type}>
                              {etask.type}
                            </option>

                          );
                        })}

                      </select>{" "}
       
        <div className='text-danger mt-2'>
          <ErrorMessage name='nameOnCard' />
        </div>
      </div>

      
      

     
    </div>
  )
}

export { Step4 }
