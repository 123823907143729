import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";
import Loader from "../../../Loader";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditOragnizationType() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { orgtypeid } = useParams();
  const { auth } = useAuth();
  const [accountuser, setaccountuser] = useState({
    type: "",

  });


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/OrganizationType/${orgtypeid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setaccountuser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching OrganizationType. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [orgtypeid, token]); // Include taskid as a dependency

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setaccountuser((accountuser) => ({ ...accountuser, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + `/api/OrganizationType/update`, accountuser, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Account User Updated");

        navigate("/orgtype");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to update Organization Type. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div>
        {loading ? (
          <Loader />
        ) : (
          <div id="layoutDrawer_content">

            <main>
              <div className="col-8 mx-xl-auto ps-xl-8">
                <div className="card card-raised">

                  <div className="card-body">
                    <h2 className='mb-3'>Edit Organization Type</h2>
                    <form onSubmit={handleSubmit}>
                      <br></br>
                      <div className="row">
                        <div className="form-floating-sm mb-2">
                          <label htmlFor="type"> Oganization Type</label>
                          <input
                            className="form-control"
                            id="type"
                            type="text"
                            value={accountuser.type}
                            //  placeholder="Type"
                            onChange={(e) => handleChange(e, "type")}
                          />
                        </div>
                      </div>
                      <br></br>




                      <div className="text-end">
                        <input
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                          value="Submit"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
      </div>
    </div>

  );
}

export default EditOragnizationType;
