/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import { ProfileDetails } from './settings/cards/ProfileDetails'
import { SignInMethod } from './settings/cards/SignInMethod'

import { useAuth } from '../../auth'
import { UpdatePassword } from './settings/cards/UpdatePassword'
export function Overview() {


  const {currentUser} = useAuth()

  return (
    <>
     <ProfileDetails />
      <UpdatePassword />

    </>
  )
}
