import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import { Link, useParams } from 'react-router-dom';
import CreateSocialMedia from '../partials/modals/Add Pages/CreateSocialMedia';
import Pagination from '../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

const storedData = localStorage.getItem('kt-auth-react-v');
const data = storedData ? JSON.parse(storedData) : null;
const userid = data?.userid;

function SocialMedia() {
  const [socialmedias, setSocialmedias] = useState([]);
  const [loading, setLoading] = useState(true);
 const [page, setPage] = useState(1);
  const rowsPerPage = 30;
  const { auth } = useAuth();
  const event = useParams();
  const eventId = event.eventId;




  useEffect(() => {
    axios
      .get(API_URL + `/api/socialmedias/eventid=${eventId}/userid=${userid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setSocialmedias(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching SocialMedias:', error);
        setLoading(false);
      });
  }, []);


  const handlePageChange = selectedPage => {
    setPage(selectedPage);
  };

  const handleSocailMediaCreated = () => {
    axios
      .get(API_URL + `/api/socialmedias/eventid=${eventId}/userid=${userid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setSocialmedias(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching Socialmedias:', error);
        setLoading(false);
      });
  };

  const paginatedData = socialmedias.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
            <CreateSocialMedia event={eventId} onSocailMediaCreated={handleSocailMediaCreated} />
           <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>SocialMedias</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add social Media'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_socialMedia'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>

              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>


                      <th className='min-w-80'>SocialMedia</th>
                      <th className='min-w-100px'>AppId</th>
                      <th className='min-w-100px'>Secret Code</th>

                      <th className='min-w-50px'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.slice().reverse().map((media) => (
                      <tr key={media.id}>


                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {media.socialmedia}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {media.appId}
                          </div>
                        </td>
                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {media.secretId}
                          </div>
                        </td>
                        {/* <td>
                        <div className='text-dark fw-bold d-block mb-1 fs-6'>
                          {media.type}
                        </div>
                      </td> */}
                        <td style={{ display: 'flex', alignItems: 'center' }}>
                          <Link
                            to={`/editsocialmedia/${media.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {socialmedias.length > 0 && (
                  <Pagination
                    data={socialmedias} // Pass the full data to the Pagination component
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    currentPage={page}
                  />
                )}
              </div>
            
         
            </div>
          </div>
        </div>
         )}
         </div>
      );
}

      export default SocialMedia;
