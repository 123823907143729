import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { KTIcon } from '../../../helpers';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import CreateSponsors from '../../../partials/modals/Add Pages/CreateSponsors';
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from '../../../../Loader';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function Sponsors() {
  const [sponsers, setsponsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { eventId } = useParams();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const rowsPerPage = 16;
  const { auth } = useAuth();



  const websiteClick = (url) => {
    if (url) {
      window.open(`${url}`, '_blank');
    }
  };



  const handleSponsorCreated = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/sponsers/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setsponsers(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }


  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/sponsers/eventid=${eventId}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setsponsers(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }, []);

  const removeClick = (sponserid) => {
    setLoading(true);
    axios
      .post(API_URL + `/api/sponsers/delete/${sponserid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        toast.success("Successfully Removed");
        handleSponsorCreated();
        setLoading(false);
      })
      .catch(error => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  }


  const handlePageChange = (selectedPage) => {
    setPage(selectedPage);
  };

  const filteredData = sponsers.filter((sponsers) =>
    sponsers.name.toLowerCase().includes((searchTerm || '').toLowerCase())
  );

  const paginatedData = filteredData.slice((page - 1) * rowsPerPage, page * rowsPerPage);



  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='row'>

          <CreateSponsors onSponsorCreated={handleSponsorCreated} eventId={eventId} />
          <h1>Sponsors</h1>

          <div className='card-header border-0 pt-5'>

            <div className='card-title'>
              <div className='d-flex align-items-center position-relative my-1'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control w-450px ps-14'
                  placeholder='Search Users'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

            </div>

          </div>

          <div className='m-2'>
            <a
              href='#'
              className='btn btn-sm btn-light-primary float-end mt-2'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_sponsors'
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add
            </a>


          </div>


          {paginatedData.map((sponsers) => (
            <div className='col-lg-4 col-md-2'>


              <div className='card card-xl-stretch mb-xl-8'
              >
                <div className='mx-4 mt-3'>
                  <div className='card-toolbar float-end'>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='float-end'
                    >
                      <Link
                        to={`/editsponsors/${eventId}/${sponsers.id}`}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-2' />
                      </Link>
                    </button>

                    <button
                      type='button'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={() => removeClick(sponsers?.id)}
                    >
                      <RiDeleteBin6Line className='fs-3' />
                    </button>

                  </div>
                </div>
                <div key={sponsers.id} className='card-title d-flex flex-column align-items-center justify-content-center' style={{ height: '250px' }}>
                  <div className='image-container d-flex align-items-center justify-content-center'>

                    <img
                      src={`${sponsers.imageMeta},${sponsers.logo}`}
                      style={{
                        width: '100%',
                        height: '100px',
                        borderRadius: '20%',
                      }}
                    />
                  </div>
                  <div className='text-dark fw-bold d-block mt-1 fs-4'>
                    {sponsers.name}
                  </div>
                  <div className='text-dark fw-bold d-block mt-1 fs-4'>
                    {sponsers.email}
                  </div>
                  <div className='text-dark fw-bold d-block mt-1 fs-4'>
                    {sponsers?.contact}
                  </div>
                  <div onClick={() => websiteClick(sponsers?.website)} className='text-primary mt-2 fs-5' style={{ cursor: 'pointer' }}>
                    {sponsers?.website}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {sponsers.length > 0 && (
            <Pagination
              data={sponsers}
              itemsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              currentPage={page}
            />
          )}

        </div>
      )
      }
    </div >
  );
}

export default Sponsors;
