import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from '../../app/modules/auth';
import "jspdf-autotable";
import { toAbsoluteUrl } from '../helpers';
import { IoArrowBackOutline } from "react-icons/io5";
const API_URL = process.env.REACT_APP_API_URL;

function withRouter(Component) {
    function ComponentWithRouterProp(props) {

        let params = useParams();

        return <Component {...props} params={params} />;
    }
    return ComponentWithRouterProp;
}


function SubPayment() {
    const { currentUser } = useAuth();
    const username = currentUser !== undefined ? currentUser?.email :localStorage.getItem('user') ;                     
    const { featureId } = useParams();
    const [feature, setFeature] = useState({});
    const [amount, setAmount] = useState([]);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState({});
    const navigate = useNavigate();

    const [RazorpayApiKey, setRazorpayApiKey] = useState("");  //Declare and initialize the username state

    useEffect(() => {

        const fetchRazorpayApiKey = async () => {
            try {
                const response = await axios.get(API_URL + '/api/payment/getKey');
                const apiKey = response.data.apiKey;
                setRazorpayApiKey(apiKey);
            } catch (error) {
                toast.error('Error retrieving Razorpay API key: ' + error.message);
            }
        };

        fetchRazorpayApiKey();
    }, []);


    const handlePayment = async (e, totalamount) => {
        e.preventDefault();

        const ta = Number(totalamount.toFixed(2));
        const orderId = order.orderId;

        try {
            const response = await axios.post(API_URL + '/api/payment/createOrder', {
                amount: ta,
                currency: 'INR',
                orderId: orderId,
            });

            const orderData = response.data;
            setOrder(orderData);
            if (window.Razorpay) {
                var options = {
                    key: "rzp_test_gZezGE8BQpR73R",
                    key_secret: "ciqzuuE2DJoUj9uE4xA2dABR",
                    amount: (totalamount * 100).toFixed(0),
                    currency: "INR",
                    name: "cynefian",
                    orderId: response.data.orderId,
                    description: "for testing purpose",

                    handler: function (response) {

                        var values = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            amount: ta,
                            paymentStatus: response.paymentStatus,
                            currency: "INR",
                            razorpay_order_id: response.razorpay_order_id,
                        };
                        if (username) {
                            var sub = {
                                username: username,
                                 payment: {
                                    username: username,
                                    id: response.id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    amount: ta,
                                    paymentStatus: response.paymentStatus,
                                    currency: "INR",
                                    razorpay_order_id: response.razorpay_order_id,
                                },
                            };
                        }
                        else {
                            var sub = {
                                username: currentUser.email,
                                userid: currentUser.id,


                                payment: {
                                    username: currentUser.email,
                                    id: response.id,
                                    razorpay_payment_id: response.razorpay_payment_id,
                                    amount: ta,
                                    paymentStatus: response.paymentStatus,
                                    currency: "INR",
                                    razorpay_order_id: response.razorpay_order_id,
                                },
                            };
                        }

                        axios.post(API_URL + `/api/user/subscription/create`, sub)
                        .then((res) => {
                            const path = localStorage.getItem("currentPage");
                            if (path) {
                                navigate(`/organizationlist`);
                                localStorage.removeItem(path);
                                //  toast.info("Payment done! Subscription created succesfully");
                            } else {
                                navigate(`/invoice/${featureId}`, { state: { sub } });
                                toast.info("Invoice bill Generated !");
                            }


                        });

                        axios.post(API_URL + `/api/payment/processPayment`, values)
                            .then((res) => {
                                toast.success("Payment Successful and amount Verified!");

                            });

                       
                    }
                };

                const pay = new window.Razorpay(options);
                pay.open();
            } else {
                toast.error('Razorpay script not loaded');
            }
        } catch (error) {
            toast.error('Error processing payment: ' + error.message);
        }
    };

    const getData = async () => {
        setLoading(true);
        try {
            const amountResponse = await axios.get(API_URL + '/api/paymentgatewaytax');
            setAmount(amountResponse.data);

            const featureResponse = await axios.get(API_URL + `/api/subscriptiontype/type=${featureId}`);
            setFeature(featureResponse.data);
        } catch (error) {
            toast.error('Error fetching data: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const goBack = () => {
        navigate(-1); // This will take you back to the previous page
      };

    return (

        <div className='px-20'>

            <div onClick={goBack} className='mt-10 mb-10 cursor-pointer'> <IoArrowBackOutline size={30}/></div>
            <div className='card'>
                <div id="layoutDrawer_content">
                    {loading && <div className="loading"></div>}

                    <main>
                        <div className="a4-sheet px-6 mt-3">
                            <header className="bg-primary">
                                <div className="container-xl px-5">
                                    <h1 className="text-white mt-2 py-3 mb-0 display-6"> Payment Details</h1>
                                </div>
                            </header>
                            <br />
                            <br /> <br />
                            <form>
                                {amount.map((amount) => {

                                    const totalcost = +`${feature.cost}`;
                                    const gsttax = +`${amount.gsttax}`;
                                    const gatewaytax = +`${amount.gatewaytax}`;
                                    const Taxamount1 = (gsttax / 100) * totalcost + totalcost;
                                    const Taxamount3 = (gsttax / 100) * totalcost;
                                    const Taxamount2 = (gatewaytax / 100) * Taxamount1;
                                    const Tax1 = (gsttax / 100) * Taxamount2;
                                    const TotalTax = Taxamount2 + Tax1;
                                    const cgst = (gsttax / 2 / 100) * totalcost;
                                    const sgst = (gsttax / 2 / 100) * totalcost;

                                    const totalamount = Taxamount1 + TotalTax;
                                    const totaltransactioncost = Tax1 + Taxamount2;
                                    return (
                                        <div>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-8'>
                                                        <h4>
                                                            <b>Cynefian</b>{" "}
                                                        </h4>{" "}
                                                        <h6> 1010/27 Mysore Road</h6>{" "}
                                                        <h6>Gundlupet , Karnataka</h6>
                                                        <h6>In 571111</h6>
                                                    </div>
                                                    <div className='col-4'>

                                                        <img
                                                            src={toAbsoluteUrl('/media/cynefian.logo.png')} width="125px" />
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <div>
                                                {" "}
                                                <h4>
                                                    <b>To</b>
                                                </h4>
                                                {username ? (
                                                    <h6>{username}</h6>
                                                ) : (
                                                    <h6> {`${currentUser?.email}`} </h6>)}
                                            </div>
                                            <hr style={{ height: "8px", color: "black" }} />

                                            <div className='col-12'>
                                                <div className='row'>

                                                    <div className='col-8'>
                                                        <h2>Total Amount</h2></div>
                                                    <div className="col-4 d-flex">
                                                        {" "}
                                                        <h2>{totalamount.toFixed(2)}</h2>
                                                    </div>
                                                </div>

                                                <hr style={{ height: "8px", color: "black" }} />
                                                <div className='row mt-10'>

                                                    <div className='col-6'></div>


                                                    <div className="col-6 px-5">
                                                        <div>
                                                            <div>
                                                                <h6>Amount: {totalcost}</h6>
                                                                <h6>CGST: {cgst.toFixed(2)}</h6>
                                                                <h6>SGST: {sgst.toFixed(2)}</h6>
                                                                <h6>Transaction Cost: {totaltransactioncost.toFixed(2)}</h6>
                                                            </div>
                                                            <br></br>
                                                            <h2 style={{ color: "black" }}>
                                                                Total Amount: {totalamount.toFixed(2)}
                                                            </h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="text-end float-end d-flex mb-6">

                                                <button onClick={(e) => handlePayment(e, totalamount)} className="btn btn-primary">
                                                    Proceed
                                                </button>

                                            </div>
                                        </div>
                                    );

                                })}
                            </form>
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SubPayment);