import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { toast } from "react-toastify";
import Navbar from '../Navbar';
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;

const Schedule = () => {
  const [event, seteventDetails] = useState({});
  const { eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [speakers, setSpeakers] = useState([]);
  const [sessions, setSessions] = useState([]);
  const uniqueDates = [...new Set(speakers.map(item => item.date))];

  const handleTabClick = async (tab) => {
    setActiveTab(tab);
    await fetchDataForTab(tab);
  };


  useEffect(() => {
    setLoading(true);
    axios.get(API_URL + `/api/EventPlannings/${eventId}`)
      .then(response => {
        seteventDetails(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error fetching event', error);
        setLoading(false);
      });
  }, [eventId]);


  const fetchDataForTab = async (date) => {
    try {
      const sessionsResponse = await axios.get(API_URL + `/api/Sessioncalendar/eventid=${eventId}/date=${date}`);
      setSessions(sessionsResponse.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(false);
    try {
      const response = await axios.get(API_URL + `/api/Sessioncalendar/eventid=${eventId}`);
      setSpeakers(response.data);
      setLoading(false);
      const uniqueDates = Array.from(new Set(response.data.map(item => item.date)));

      if (uniqueDates.length > 0) {
        const initialDate = uniqueDates[0];
        setActiveTab(initialDate);
        await fetchDataForTab(initialDate);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Navbar event={event} />
          <div className='schedule-header mt-20' >
            <h1 style={{ fontSize: '35px', textAlign: 'center' }}>EVENT SCHEDULE</h1>
            <br />

            <h1
              style={{
                color: 'grey',
                fontSize: '20px',
                textAlign: 'center',
                position: 'relative',

              }}
            >
              Here is our event schedule  </h1>

            {<div>
              <span
                style={{
                  display: 'block',
                  width: '50px', // Adjust the width of the red line as needed
                  height: '5px',
                  backgroundColor: 'blue',
                  margin: '10px auto', // Adjust the margin as needed
                }}
              ></span>
            </div>}

          </div>
          <br />
          <br />

          <div className='schedule-tabs' style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {uniqueDates.map((date, index) => (
              <div
                key={date}
                className={`tab ${activeTab === date ? 'active' : ''} cursor-pointer`}
                onClick={() => handleTabClick(date)}
                style={{
                  backgroundColor: activeTab === date ? 'blue' : 'black',
                  color: 'white',
                  fontSize: '15px',
                  padding: '5px 10px',
                  marginRight: '10px',
                  borderRadius: '30px',
                  width: '150px',
                  margin: '0 5px 5px 0',
                  textAlign: 'center',
                }}
              >
                {`DATE ${date || index + 1}`}
              </div>
            ))}
          </div>
          <br />
          <br />

          <div className='schedule-content' style={{ overflowX: 'auto' }}>
            {sessions.map((sessionItem) => (
              <div key={sessionItem.id}>
                <div key={sessionItem.id} style={{ margin: '-10px 0' }}>
                  <table style={{ width: '60%', borderCollapse: 'collapse', tableLayout: 'fixed', margin: '0 auto' }}>
                    <thead>

                      <tr>
                        <th style={{ fontSize: '17px', textAlign: 'center', padding: '10px', position: 'sticky', left: '0', color: 'white' }}>Time</th>
                        <th style={{ fontSize: '17px', textAlign: 'center', padding: '10px', position: 'sticky', left: '200px', color: 'white' }}>Session</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ fontSize: '15px', textAlign: 'center', padding: '5px', position: 'sticky', background: '#eee' }}>
                          {sessionItem.startTime} - {sessionItem.endTime}
                        </td>
                        <td style={{ fontSize: '15px', padding: '5px', position: 'sticky', background: '#eee' }}>
                          <div>
                            <i><h6>{`${sessionItem.sessionname} - ${sessionItem.userid}`}</h6></i>
                          </div>
                          <div style={{ fontSize: '15px', padding: '10px' }}>
                            <i>{sessionItem.topic}</i>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Schedule;
