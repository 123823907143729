import axios from 'axios';
import React, { useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { toast } from "react-toastify";
import Loader from '../../../../Loader';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

class FetchData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      agents: [],
      response: {},
      page: 1,
      rowsPerPage: 30,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.setState({
      loading: true,
    });

    axios.get(API_URL + '/api/features', { headers: { Authorization: `Bearer ${token}` } })
      .then(response => response.data)
      .then(
        (result) => {
          this.setState({
            agents: result,
            isLoading: false,
          });
         },
        (error) => {
          this.setState({ error });
        }
      );
  }

  selectPageHandle = (selectedPage) => {
    if (
      selectedPage >= 1 &&
      selectedPage <= Math.ceil(this.state.agents.length / this.state.rowsPerPage) &&
      selectedPage !== this.state.page
    ) {
      this.setState({ page: selectedPage });
    }
  };

  onClickhandler = (featureid) => {
    this.setState({
      loading: true,
    });

    const agent = this.state.agents.find(agent => agent.id === featureid);

    if (agent) {
      const disable = !agent.featureStatus;

      axios
        .get(API_URL + `/api/features/id=${featureid}/value=${disable}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.fetchData(); // Refetch data after successful update
        })
        .catch((error) => {
          toast.error('Error updating account:', error);
          this.setState({
            loading: false,
          });
        });
    }
  }

  render() {
    const { isLoading, error, agents } = this.state;

    if (error) {
      return (
        <div>Error:{error.message}</div>
      );
    } else {
      return (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className='card card-raised'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>Feature Details</span>
                </h3>
                <div className='card-toolbar'></div>
              </div>
              <div className='card-toolbar'></div>
              <div className='card-toolbar'>
                <div className='card-body py-3'>
                  <div className='table-responsive'>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bold text-muted'>
                          <th className='min-w-40px'>ID</th>
                          <th className='min-w-80px'>Feature</th>
                          <th className='min-w-130px'>Description </th>
                          <th className='min-w-50px'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agents &&
                          this.state.agents
                            .slice(
                              (this.state.page - 1) * this.state.rowsPerPage,
                              this.state.page * this.state.rowsPerPage
                            )
                            .map((agent) => (
                              <tr key={agent.id}>
                                <td>
                                  <div className='text-dark fw-bold fs-6'>
                                    {agent.id}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {agent.feature}
                                  </div>
                                </td>
                                <td>
                                  <div className='text-dark fw-bold d-block mb-1 fs-6'>
                                    {agent.description}
                                  </div>
                                </td>
                                <td style={{ display: 'flex', alignItems: 'center' }}>
                                  <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                                    <input
                                      className="form-check-input"
                                      id={`flexSwitchCheckDefault-${agent.id}`}
                                      type="checkbox"
                                      defaultChecked={agent.featureStatus}
                                      onClick={() => this.onClickhandler(agent.id)}
                                      value={agent.id}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {!isLoading && agents.length > 0 && (
                <div className='pagination' style={{ marginRight: '120px' }}>
                  <div
                    className='arrows'
                    onClick={() => this.selectPageHandle(this.state.page - 1)}
                  >
                    <MdKeyboardArrowLeft size={35} />
                  </div>
                  <div className='pageNumbers'>
                    {Array.from({ length: Math.ceil(this.state.agents.length / this.state.rowsPerPage) }, (_, i) => i + 1)
                      .slice(Math.max(this.state.page - 3, 0), this.state.page + 2)
                      .map((pageNumber) => (
                        <div
                          className={`num ${this.state.page === pageNumber ? 'numActive' : ''}`}
                          key={pageNumber}
                          onClick={() => this.selectPageHandle(pageNumber)}
                        >
                          {pageNumber}
                        </div>
                      ))}
                  </div>
                  <div
                    className='arrows'
                    onClick={() => this.selectPageHandle(this.state.page + 1)}
                  >
                    <MdKeyboardArrowRight size={35} />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      );
    }
  }
}

export default FetchData;
