import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../../_metronic/helpers';
import { Link, Navigate } from 'react-router-dom';
import CreateRoleUser from '../../../partials/modals/Add Pages/CreateRoleUser';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');



function RoleUsers() {
  const [RoleUsers,setRoleUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPage] = useState(0);
 
  const { eventId } = useParams(); 

  useEffect(() => {
    fetchData(currentPage);
}, []);

  function onClickhandler(e, id) {
    const checked = e.target.checked;
    const disabled= !checked; // Reverse the checked value to set the 'disabled' property
    axios
      .get(API_URL + `/api/eventroleuser/disabled/id=${id}/value=${disabled}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setRoleUsers(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Failed to update EventRoleUser Status. Please try again.';
          toast.error(errorMessage);
      });
  }

  function handleEventRoleUser() {
  axios
  .get(API_URL + `/publicaccounts/eventid=${eventId}/page=${currentPage}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(response => {
    setRoleUsers(response.data.pageData);
    setTotalPage(response.data.page);
    setCurrentPage(currentPage);
  })
  .catch(error => {
    const errorMessage = 'Error Fetching EventRoleUser. Please try again.';
    toast.error(errorMessage);
  });
}


const handlePageChange = page => {
  fetchData(page);
};

 function fetchData(currentPage) {
    axios
      .get(API_URL + `/publicaccounts/eventid=${eventId}/page=${currentPage}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setRoleUsers(response.data.pageData);
        setTotalPage(response.data.page);
        setCurrentPage(currentPage);
      })
      .catch(error => {
        const errorMessage = 'Error Fetching EventRoleUser. Please try again.';
        toast.error(errorMessage);
      });
  };

  return (
    <div className='card card-raised'>
     <CreateRoleUser onRoleUserCreate={handleEventRoleUser}/>
     <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>

          <span className='card-label fw-bold fs-3 mb-1'> Role Users</span>
           </h3>
           <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary float-end'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_event_role_user'
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add
          </a>
        </div>
       </div>
    <div className='card-toolbar'>
           </div>
        <div className='card-toolbar'>


          <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
            <tr className='fw-bold text-muted'>
                
                
                <th className='min-w-150px'>Role</th>
                <th className='min-w-200px'>User</th>
                <th className='min-w-80px '>Actions</th>
                
              </tr>
            </thead>
           
           <tbody>
            {RoleUsers.slice().reverse().map((RoleUsers) => (
                  <tr key={RoleUsers.id}>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-13-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                  {RoleUsers.event.title}
                  </a>
                </td>
             
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  {RoleUsers.role.rolename}
                  </a>
                 </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  {RoleUsers.user.email}
                  </a>
                  </td>
            
                  
             
               
                
                <td style={{ display: 'flex', alignItems: 'center' }}>

  <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
    <input
      className="form-check-input"
      id="flexSwitchCheckDefault"
      type="checkbox"
      defaultChecked={!RoleUsers.disabled}
      onClick={(e) => onClickhandler(e, RoleUsers.id)}
      value={RoleUsers.id}
    />
  </div>

  <Link to={`/editroleuser/${RoleUsers.id}`} className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
    <KTIcon iconName='pencil' className='fs-3' />
  </Link>
</td>
              </tr>
           ))}
           </tbody>
           </table>
           {RoleUsers.length > 0 && (
           <Pagination
           data={RoleUsers} // Pass the full data to the Pagination component
           onPageChange={handlePageChange}
           currentPage={currentPage}
       />
           )}
</div>
</div>
</div>
</div>
     
  );
}

export default RoleUsers;
