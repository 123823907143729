import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pagination from '../../../../app/modules/auth/components/Pagination';
import { toast } from 'react-toastify';
import Loader from '../../../../Loader';
import ViewArticle from './ViewArticle';
import Advertisement from '../../org-management/Advertisement/Advertisement';
import HalfPageAdd from '../../org-management/Advertisement/HalfPageAdd';
import LeaderBoardAdd from '../../org-management/Advertisement/LeaderBoardAdd';
import VerticleRectangleAdd from '../../org-management/Advertisement/VerticleRectangleAdd';
import SkyscraperAdd from '../../org-management/Advertisement/SkyscraperAdd';
import BannerAdd from '../../org-management/Advertisement/BannerAdd';
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;


export default function Articles() {
    const [articleList, setarticleList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPage] = useState(0);
    const rowsPerPage = 30;
    const [selectedArticle, setSelectedArticle] = useState(null);
    const { eventId } = useParams();
    const [hoveredEventIndex, setHoveredEventIndex] = useState(-1);
    const {auth}= useAuth();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchData(currentPage);

    }, []);


    function fetchData(currentPage) {
        setLoading(true);
        axios
            .get(API_URL + `/api/eventarticles/eventid=${eventId}/page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setarticleList(response.data.pageData);
                setTotalPage(response.data.page);
                setCurrentPage(currentPage);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Error fetching Articles:', error);
                setLoading(false);
            });
    }

    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    };


    const handlePublish = async (value) => {
        if (articleList.find(article => article.id === value && article.published)) {
            toast.warning('This article is already published.');
        } else {

            try {
                setLoading(true);
                const response = await axios.post(
                    API_URL + `/api/socialmedia/share?articleid=${value}`,
                    {
                        headers: {
                            Authorization: `Bearer ${auth?.token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    toast.success(response.data);
                    setLoading(false);
                } else {
                    toast.error('Failed to share the post.');
                    setLoading(false);
                }
            } catch (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                     toast.error('Failed to share the post. Server error.');
                    setLoading(false);
                } else if (error.request) {
                    // The request was made but no response was received
                     toast.error('Failed to share the post. No response received.');
                    setLoading(false);
                } else {
                    // Something happened in setting up the request
                    console.error('Error:', error.message);
                    toast.error('Failed to share the post. An error occurred.');
                    setLoading(false);
                }
            }
        }
    };

    const handleViewArticle = (article) => {
        setSelectedArticle(article);
      };

    const handlePageChange = page => {
        fetchData(page);
    };

    return (
        <div>

            {loading ? (
                <Loader />
            ) : (
                <div>


                    <div className="row m-4">
                        <div className="col-md-12">
                            <h2>Event Articles</h2>
                            <div
                                className='card-toolbar'
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-trigger='hover'
                                title='Click to add new article'
                            >
                                <Link
                                    to={`/createArticle/${eventId}`}
                                    className='btn btn-sm btn-light-primary float-end'>
                                    <KTIcon iconName='plus' className='fs-3' />
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>


                    <div className='col-xl-12'>
                        <div className='row g-5 g-xl-10'>

                            {articleList.slice().reverse().map((article, index) => (
                                <div
                                    className='col-xl-11'
                                    key={article.id}
                                    onMouseEnter={() => setHoveredEventIndex(index)}
                                    onMouseLeave={() => setHoveredEventIndex(-1)}
                                >

                                    <ViewArticle selectedArticle={selectedArticle} />
                                    <div
                                        className='card card-raised'
                                        style={{
                                            transform: hoveredEventIndex === index ? 'scale(1.05)' : 'scale(1)',
                                            transition: 'transform 0.2s ease-in-out',
                                        }}
                                    >


                                        <div className='mx-4 mt-3'>
                                            <div className='card-toolbar float-end'>

                                                <a
                                                    href='#'
                                                    className='btn btn-sm btn-light-primary'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#kt_modal_view_article'
                                                    onClick={() => handleViewArticle(article)}
                                                >
                                                    <i className="fa-solid fa-eye"></i>

                                                </a>

                                                <button
                                                    type='button'
                                                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                                    data-kt-menu-trigger='click'
                                                    data-kt-menu-placement='bottom-end'
                                                    data-kt-menu-flip='float-end'
                                                >
                                                    <Link
                                                        to={`/editArticle/${eventId}/${article.id}`}
                                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                    >
                                                        <KTIcon iconName='pencil' className='fs-2' />
                                                    </Link>
                                                </button>

                                                <button
                                                    type='button'
                                                    className={`btn btn-md btn-icon ${article.published ? 'btn-color-success' : 'btn-color-warning'
                                                        } `}
                                                    onClick={() => handlePublish(article.id)}
                                                >
                                                    {article.published ? 'Published' : 'Publish'}
                                                </button>

                                            </div>
                                            <div className='border-0 pt-5'>
                                                <h5 className='card-title align-items-start flex-column'>

                                                    {article.subject}

                                                    <br />
                                                </h5>
                                            </div>
                                            {/* end::Header */}


                                            <div className='text-muted d-flex justify-content'>
                                                <b>{article.userid}</b>

                                            </div>

                                            <div className='text-muted d-flex justify-content-end'>
                                                <b>{article.dateTime}</b>

                                            </div>



                                            <div className='pt-5'>

                                                {stripHtmlTags(article.description).length > 500
                                                    ? `${stripHtmlTags(article.description).slice(0, 500)}...`
                                                    : stripHtmlTags(article.description)}

                                            </div>

                                            <hr />

                                        </div>


                                    </div>


                                </div>



                            ))}
                 

                        </div>



                        {articleList && articleList.length > 0 && (

                            <Pagination
                                data={articleList}
                                itemsPerPage={rowsPerPage}
                                onPageChange={handlePageChange}
                                currentPage={currentPage}
                            />

                        )}

                    </div>

                </div>
            )}
        </div>



    );
}
