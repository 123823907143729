import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../app/modules/auth';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

export default function PublicAdver() {
    const [mediaList, setMediaList] = useState([]);
    const { eventId } = useParams();
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    useEffect(() => {
        fetchData();
    }, [eventId, auth?.token]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentVideoIndex(prevIndex => (prevIndex + 1) % mediaList.length);
        }, 10000); // Change video every 10 seconds

        return () => clearInterval(intervalId);
    }, [mediaList]);

    function fetchData() {
        setLoading(true);
        const url =  '/api/advertisement';
        axios
            .get(API_URL + url)
            .then((response) => {
                setMediaList(response.data);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                toast.error('Error fetching advertisements:', error);
                setLoading(false);
            });
    }

    function getYouTubeVideoId(url) {
        // Regular expression to extract the YouTube video ID from the URL
        const regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        if (match && match[1]) {
            return match[1];
        } else {
            return null;
        }
    }

    return (
        <div>
            {mediaList.map((media, index) => (
                <div key={index} style={{ display: index === currentVideoIndex ? 'block' : 'none' }}>
                    {media.type === 'image' ? (
                        <img src={media.url} alt="advertisement" style={{ maxWidth: '100%', height: 'auto' }} />
                    ) : (
                        <div className="video-container" style={{ display: 'flex', justifyContent: 'center' }}>
                            <iframe
                                title={`Video ${index}`}
                                width="1000"
                                height="400"
                                src={`https://www.youtube.com/embed/${getYouTubeVideoId(media.videolink)}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                style={{ alignSelf: 'center', border: '2px solid black', borderRadius: '5px' }}
                            ></iframe>
                        </div>
                    )}
                </div>
            ))}
            <br></br>
        </div>
    );
}
