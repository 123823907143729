import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");

function EditUspSeek() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { uspseekid } = useParams();
  const {auth} = useAuth();
  const [uspSeek, setUspspseek] = useState({
    name: "", 
  });



  useEffect(() => {
    axios
      .get(API_URL + `/api/UspSeek/${uspseekid}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setUspspseek(response.data);
      })
      .catch((error) => {
        const errorMessage = 'Error Fetching USPSeeks. Please try again.';
        toast.error(errorMessage);
      });
  }, [uspseekid, token]); // Include taskid as a dependency

  const handleChange = (e, field) => {
    const actualValue = e.target.value;
    setUspspseek((uspSeek) => ({ ...uspSeek, [field]: actualValue }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(API_URL + `/api/UspSeek/update`, uspSeek, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then(() => {
        toast.success("Uspseek Updated");
        navigate("/uspSeek");
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage = 'Failed to Update USPSeek. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  return (
    <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-8 mx-xl-auto ps-xl-8">
            <div className="card card-raised">
              <div className="card-body">
                <form onSubmit={handleSubmit}>

                <div className="row">
                <div className="form-floating-sm mb-2">
                    <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Edit USPSeek </span>
          <br></br>
          <span className='card-label fs-5 mb-2'style={{color:'orange'}}>
            ( What Are You Looking For )</span>



           </h3>
                      <br></br>
                    </div>
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name">USPSeek Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        value={uspSeek.name}
                        placeholder=" Name"
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

                 
                 

                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditUspSeek;
