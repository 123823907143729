import React from 'react'
import { useParams } from 'react-router-dom'
import RTE from '../../../users/RTE';

export default function CreateArticle() {

    const {eventId}= useParams();
    const {articleId}= useParams();
    
  return (
    <div>
       <div className='card mb-5 mb-xl-10'>
          <div className='card-body border-top p-9'>
            <h3 className='mb-7'> Your Article</h3>
            <RTE eventId={eventId} article={articleId}/>    
      </div>
    </div>
    </div>
  )
}
