/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../../../helpers';
import { toast } from "react-toastify";
import { useAuth } from '../../../../app/modules/auth';
const API_URL = process.env.REACT_APP_API_URL;

function CreateSpeaker({onSpeakerCreated, eventId}) {
    const[loading ,setLoading]=useState(false);
    const {auth} = useAuth();
    const [speaker, setspeaker] = useState({
        name: "",
        eventid:eventId,
        company:"",
        designation: "",
        linkedinId:"",
        image:"",
        imageMeta:""
      
    })
   
    function getBase64FromFile(file) {

      return new Promise((resolve, reject) => {
  
        var reader = new FileReader();
  
        reader.readAsDataURL(file);
  
        reader.onload = function () {
  
          const [metadata, content] = reader.result.split(",");
  
          resolve({ metadata, content });
  
        };
  
        reader.onerror = function (error) {
  
          reject(error);
  
        };
  
      });
  
    }


    const handleImageChange = async (event) => {
      const convertedImage = await getBase64FromFile(event.target.files[0]);
      const imageObject = {
  
        ...speaker,
  
        imageMeta: convertedImage.metadata,
        image: convertedImage.content,
  
      };
      setspeaker(imageObject);
    }
    const [modalOpen, setModalOpen] = useState(false);
  
    const handleChange = (inten, field) => {
      let actualValue = inten.target.value;
  
      setspeaker({ ...speaker, [field]: actualValue });
    };

    
    const handleSubmit = async(task) => {
      task.preventDefault();
      setLoading(true); 
      axios
        .post(API_URL+"/api/EventSpeakers/create", speaker, {
          headers: { Authorization: `Bearer ${auth?.token}` },
        })
        .then((response) => response.data)
        .then((result) => {
         // toast.success("Stalltype created");
          toast.success("Speaker created successfully");
          setLoading(false);
          onSpeakerCreated(); 
           closeModal();
        });
    };

    const closeModal = () => {
      setModalOpen(false);
    };

    


  return (
    <div className='modal fade' id='kt_modal_speaker' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Speaker Management</h1>

             
            </div>


            <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-10 mx-xl-auto ps-xl-10">
             <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row"></div>
                 

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name"> Name</label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
  <div className="form-floating-sm mb-2">
    <div className='me-7 mb-4'>
      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
        {speaker?.image ? (
          <img
            id="image"
            src={`${speaker?.imageMeta},${speaker?.image}`}
            alt={speaker?.name}
            style={{ maxWidth: "150px", maxHeight: "150px" }}
          />
        ) : (
          <img alt={speaker?.firstname} />
        )}
      </div>
    </div>
  </div>
  <input
    className="col-7"
    type="file"
    id="image"
    onChange={(event) => {
      handleImageChange(event)
    }}
    accept="image/*"
  />
</div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="company"> Company Name</label>
                      <input 
                        className="form-control"
                        id="company"
                        type="text"
                        placeholder="Company Name"
                        onChange={(e) => handleChange(e, "company")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="designation"> Designation </label>
                      <input 
                        className="form-control"
                        id="designation"
                        type="text"
                        placeholder="Designation"
                        onChange={(e) => handleChange(e, "designation")}
                      />
                    </div>
                  </div><br></br>

            

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="linkedinId"> LinkedIn ID</label>
                      <input 
                        className="form-control"
                        id="linkedinId"
                        type="text"
                        placeholder="LinkedIn ID"
                        onChange={(e) => handleChange(e, "linkedinId")}
                      />
                    </div>
                  </div>
                  <br></br>

                 

                  
                
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                      data-bs-dismiss='modal'
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateSpeaker;
