import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Organizationlist from '../../_metronic/organization/org-management/organizationlist'
import TaskList from '../../_metronic/organization/Tasks/TaskList';
import TaskPriority from '../../_metronic/organization/Task Priority/TaskPriority';
import StallExhibitor from '../../_metronic/organization/Stalls/StallExhibitor';
import StallExhibitorList from '../../_metronic/organization/Stalls/StallExhibitorList';
import StallType from '../../_metronic/organization/Stalls/StallType';

import RoleUsers from '../../_metronic/organization/events/event-role-users/RoleUsers';
import EventRoles from '../../_metronic/organization/events/event-role-users/EventRoles';


import Feature from '../../app/modules/auth/components/Feature'
import Permission from '../../app/modules/auth/components/Permission'
import AuditLog from '../../app/modules/auth/components/Auditlog'
import AdminOrganization from '../../_metronic/organization/organizationAdmin/OrganizationAdminList'
import CreateNewAdmin from '../../_metronic/organization/addAdmin/CreateNewAdmin'
import TaskStatus from '../../_metronic/organization/Tasks/TaskStatus'
import { AddAdmin } from '../../_metronic/organization/addAdmin/AddAdmin'
import ListEvents from '../../_metronic/organization/events/ListEvents'
import SystemSettingPage from '../modules/accounts/components/settings/SystemSettings/SettingPage'

import AccountUser from '../../_metronic/users/Accountuser'
import EditTask from '../../_metronic/organization/EditPages/EditTask'
import EditEventrole from '../../_metronic/organization/EditPages/EditEventrole'
import EditOrganization from '../../_metronic/organization/EditPages/EditOrganization'
import EditRoleUsers from '../../_metronic/organization/EditPages/EditRoleUsers'
import EditStallType from '../../_metronic/organization/EditPages/EditStallType'
import EditStallEXhibitor from '../../_metronic/organization/EditPages/EditStallExhibitor'
import EditTaskPriority from '../../_metronic/organization/EditPages/EditTaskPriority'
import EditTaskStatus from '../../_metronic/organization/EditPages/EditTaskStatus'
import UserNotification from '../../_metronic/users/Notification'
import ActivityType from '../../_metronic/Super/ActivityType'
import OrganizationType from '../../_metronic/Super/OrganizationType'
import EditEvent from '../../_metronic/organization/EditPages/EditEvent'
import EditActivity from '../../_metronic/organization/EditPages/EditActivity'
import EditOragnizationType from '../../_metronic/organization/EditPages/EditOrganizationType'

import { Overview } from '../modules/accounts/components/Overview'
import UspSeek from '../../_metronic/Super/UspSeek'
import EditUspSeek from '../../_metronic/organization/EditPages/EditUspSeek'
import EventDetails from '../../_metronic/organization/events/EventDetails'
import USPIntentions from '../../_metronic/Super/USPIntentions'
import EventSectors from '../../_metronic/Super/EventSectors'
import EditUSPIntention from '../../_metronic/organization/EditPages/EditUSPIntention'
import EditEventSector from '../../_metronic/organization/EditPages/EditEventSector'
import EventPermission from '../../_metronic/organization/events/event-role-users/EventPermission'
import EventCommittees from '../../_metronic/organization/events/event-role-users/EventCommittees'
import EventExhibitors from '../../_metronic/organization/events/event-role-users/EventExhibitors'

import { Settings } from '../modules/accounts/components/settings/Settings'
//import ExhibitorProfile from '../modules/accounts/components/settings/cards/ExhibitorProfile'
import { EventmembersProfile } from '../modules/accounts/components/settings/cards/EventmembersProfile'
import Advertisement from '../../_metronic/Super/Advertisement'
import EventAttendee from '../modules/events/EventAttendee'
import SocialMedia from '../../_metronic/users/SocialMedias'
import EditSocialMedia from '../../_metronic/organization/EditPages/EditSocialMedia'
import EventDashboard from '../../_metronic/organization/events/EventDashboard'
import SuperDashboard from '../../_metronic/Super/SuperDashboard'
import EditEventArticle from '../../_metronic/organization/EditPages/EditEventArticle'
import OrganizationDetails from '../../_metronic/organization/org-management/OrganizationDetails'
import Articles from '../../_metronic/organization/events/Articles/Articles'
import CreateArticle from '../../_metronic/organization/events/Articles/CreateArticle'
import SpeakerMngnt from '../../_metronic/organization/events/Speakers/SpeakerMngnt'
import Sponsors from '../../_metronic/organization/events/Sponsors/Sponsors'
import Subscription from '../../_metronic/users/Subscription'
import Payment from '../../_metronic/users/Payment'
import OrganizationRole from '../../_metronic/organization/events/event-role-users/OrganizationRole'
import Live from '../modules/events/Live'
import UserDetails from '../../_metronic/partials/layout/search/userDetails'

import AddSession from '../../_metronic/organization/events/AddSessions'
import EventSession from '../../_metronic/organization/events/EventSession'
import ExhibitorDashboard from '../../_metronic/organization/events/Exhibitor/ExhibitorDashboard'
import ExhibitorContent from '../../_metronic/organization/events/Exhibitor/ExhibitorContent'
import EditSubscription from '../../_metronic/Super/Subscriptions/EditSubscription'
import ExhiobitorEvents from '../../_metronic/organization/events/Exhibitor/ExhibitorEvents'
import SubscriptionType from '../../_metronic/Super/Subscriptions/SubscriptionType'
import SubscriptionFeature from '../../_metronic/Super/Subscriptions/SubscriptionFeature'
import EditSubFeature from '../../_metronic/Super/Subscriptions/editSubFeature'
import SubPermissions from '../../_metronic/Super/Subscriptions/SubPermissions'
import ExhibitorEventDashboard from '../../_metronic/organization/events/Exhibitor/ExhibitorEventDashboard'
import ExibitorSeeks from '../../_metronic/organization/events/Exhibitor/ExibitorSeeks'
import ExibitorIntentions from '../../_metronic/organization/events/Exhibitor/ExibitorIntenstions'
import AdminAdvertisement from '../../_metronic/organization/events/EventAdvertisement.js/AdminAdvertisement'
import ExhibitorDetails from '../../_metronic/organization/events/Exhibitor/ExhibitorDetails'
import Connections from '../../_metronic/organization/events/Exhibitor/Connections'



import AddList from '../../_metronic/organization/events/EventAdvertisement.js/AddList'
import EditSpeaker from '../../_metronic/organization/events/Speakers/EditSpeaker'
import EditSponsors from '../../_metronic/organization/events/Sponsors/EditSponsors'
import Exibitor from '../../_metronic/partials/layout/search/Exibitor'
import Whitelabeling from '../../_metronic/organization/events/Whitelabeling/Whitelabeling'
import StallChart from '../../_metronic/organization/Stalls/StallChart'
import Stalls from '../../_metronic/organization/Stalls/Stalls'
import StallPayment from '../../_metronic/users/StallPayment/StallPayment'
import EditStallChart from '../../_metronic/organization/Stalls/EditStallChart'
import TransactionHistory from '../../_metronic/users/Transaction/TransactionHistory'
import ExhibitorStalldetails from '../../_metronic/organization/Stalls/ExhibitorStalldetails'
import ProfileTabnav from '../../_metronic/users/ProfileTabnav'
import CommitteeDashboard from '../../_metronic/organization/events/Committe/CommitteeDashboard'
import AddsDetails from '../../_metronic/organization/events/EventAdvertisement.js/AddsDetails'
import EditAddsDetails from '../../_metronic/organization/events/EventAdvertisement.js/EditAddsDetails'
import { ProfileCommittee } from '../modules/accounts/components/settings/cards/ProfileCommittee'
import Committee from '../../_metronic/organization/events/Committe/Committee'
import Attendees from '../modules/events/Attendees'
import SponsorTypeList from '../../_metronic/Public/PublicHome/Event/SponsorTypeList'
import EditSponsorType from '../../_metronic/organization/events/Sponsors/EditSponsorType'
import PlatformFee from '../../_metronic/Super/SuperAdvertisment/PlatformFee'
import AdminPlatformfee from '../../_metronic/Public/PublicHome/Event/AdminPlatformfee'
import EditAdminPlatform from '../../_metronic/Public/PublicHome/Event/EditAdminPlatform'
import EditPlatform from '../../_metronic/Super/SuperAdvertisment/EditPlatform'
import AdvertisementSettings from '../../_metronic/Super/AdvertisementSettings'
import EditAdvsettings from '../../_metronic/organization/EditPages/EditAdvsettings'
import AdminAdver from '../../_metronic/organization/AdminAdvertisement/AdminAdver'
import AddAdminAdver from '../../_metronic/organization/AdminAdvertisement/AddAdminAdver'
import AdminListAdver from '../../_metronic/Super/SuperAdvertisment/AdminListAdver'
import Popuprequest from '../../_metronic/organization/addAdmin/Popuprequest'
import AdminAddsettings from '../../_metronic/organization/AminAdvertisementsettings/AdminAddsettings'
import AddExhibitorcontent from '../../_metronic/organization/events/Exhibitor/AddExhibitorcontent'
import GetAdminProfile from '../modules/accounts/components/settings/cards/GetAdminProfile'



const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))




  return (
    <Routes>

      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path="/listEvents/:eventId" element={<ListEvents />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='features' element={<Feature />} />
        <Route path='permissions' element={<Permission />} />

        <Route path='organizationlist' element={<Organizationlist />} />
        <Route path='adminOrgMap' element={<AdminOrganization />} />
        <Route path='addAdmin' element={<AddAdmin />} />
        <Route path='createadmin' element={<CreateNewAdmin />} />

        {/* <Route path='events' element={<Events />} /> */}
        {/* <Route path='/events/:eventid' element={<Events />} /> */}


        <Route path='eventDashboard/:eventId' element={<EventDashboard />} />
        <Route path='eventDetails/:eventId' element={<EventDetails />} />
        <Route path='tasklist/:eventId' element={<TaskList />} />
        <Route path='taskstatus' element={<TaskStatus />} />
        <Route path='attendee/:AttendeesEmail' element={<Attendees />} />
        <Route path='attendees/:eventId' element={<EventAttendee />} />

        <Route path='socialmedias/:eventId' element={<SocialMedia />} />

        <Route path='updateOrganization/:orgid' element={<AddAdmin />} />

        <Route path='taskpriorities' element={<TaskPriority />} />
        <Route path='stallexhibitorList/:eventId' element={<StallExhibitorList />} />
        <Route path='stallexhibitor/:eventId' element={<StallExhibitor />} />
        <Route path='stalltype/:eventId' element={<StallType />} />

        <Route path='events/roleusers/:eventId' element={<RoleUsers />} />
        <Route path='events/role/:eventId' element={<EventRoles />} />
        <Route path='auditlog' element={<AuditLog />} />
        <Route path='auditlog/:eventId' element={<AuditLog />} />
        <Route path='commiteemembers/:eventId' element={<EventCommittees />} />
        <Route path='eventpermission/:eventid' element={<EventPermission />} />
        <Route path='exhibitors/:eventId' element={<EventExhibitors />} />
        <Route path='eventArticles/:eventId' element={<Articles />} />
        <Route path='createArticle/:eventId' element={<CreateArticle />} />
        <Route path='editArticle/:eventId/:articleId' element={<CreateArticle />} />

        <Route path='systemSettings' element={<SystemSettingPage />} />
        <Route path='userAcc' element={<AccountUser />} />
        <Route path='listEvents' element={<ListEvents />} />
        <Route path='edittask/:eventId/:taskid' element={<EditTask />} />
        <Route path='editeventrole/:eventId/:id' element={<EditEventrole />} />
        <Route path='editorg' element={<EditOrganization />} />
        <Route path='editroleuser/:roleuserid' element={<EditRoleUsers />} />
        {/* <Route path='editstalltype' element={<EditStallType />} /> */}
        <Route path='editstallexhibitor' element={<EditStallEXhibitor />} />
        <Route path='edittaskpriority' element={<EditTaskPriority />} />
        <Route path='usernotifications' element={<UserNotification />} />
        <Route path='editstalltype/:eventId/:stalltypeid' element={<EditStallType />} />
        <Route path='editstallexhibitor/:eventId/:stallexhibitorid' element={<EditStallEXhibitor />} />
        <Route path='edittaskpriority/:taskpriorityid' element={<EditTaskPriority />} />
        <Route path='edittaskstatus/:taskstatusid' element={<EditTaskStatus />} />
        <Route path='editevent/:eventid' element={<EditEvent />} />
        <Route path='editsocialmedia/:eventId' element={<EditSocialMedia />} />
        <Route path='editorganization/:organizationid' element={<EditOrganization />} />
        <Route path='activitytype' element={<ActivityType />} />
        <Route path='orgtype' element={<OrganizationType />} />
        <Route path='editactivity/:activityid' element={<EditActivity />} />
        <Route path='editorgtype/:orgtypeid' element={<EditOragnizationType />} />
        <Route path='Profile' element={<Overview />} />
        <Route path='AccSetting/:userid' element={<Settings />} />
        <Route path='roles/:eventId' element={<OrganizationRole />} />

        <Route path='uspseek' element={<UspSeek />} />
        <Route path='edituspseek/:uspseekid' element={<EditUspSeek />} />
        <Route path='USPIntentions' element={<USPIntentions />} />
        <Route path='EditUSPIntentions/:intentionid' element={<EditUSPIntention />} />
        <Route path='EventSectors' element={<EventSectors />} />
        <Route path='EventSectors/:sectorid' element={<EditEventSector />} />
        <Route path='EventSectors/:sectorid' element={<EditEventSector />} />


        <Route path='eventmemberprofile' element={<EventmembersProfile />} />
        <Route path='advertisement/:orgid' element={<Advertisement />} />
        <Route path='SuperDashboard' element={<SuperDashboard />} />
        <Route path='EditEventArticle' element={<EditEventArticle />} />

        <Route path='eventRoles/:eventId' element={<EventRoles />} />

        <Route path='OrgDetailedlist/:id' element={<OrganizationDetails />} />
        <Route path='Speakers/:eventId' element={<SpeakerMngnt />} />
        <Route path='Sponsonrs/:eventId' element={<Sponsors />} />
        <Route path='SponsonrsType/:eventId' element={<SponsorTypeList />} />
        <Route path='editspeaker/:eventId/:id' element={<EditSpeaker />} />
        <Route path='editsponsors/:eventId/:sponsersid' element={<EditSponsors />} />


        {/* Exhibitors */}
        <Route path='Exhibitordashboard' element={<ExhibitorDashboard />} />
        <Route path='ExhibitorEventdashboard/:eventId' element={<ExhibitorEventDashboard />} />
        <Route path='Exhibitorcontent/:eventId' element={<ExhibitorContent />} />
        <Route path='Addcontent/:eventId' element={<AddExhibitorcontent />} />
        <Route path='Addcontent/:eventId/:addId' element={<AddExhibitorcontent />} />
        <Route path='events' element={<ExhiobitorEvents />} />
        <Route path='uspSeeks' element={<ExibitorSeeks />} />
        <Route path='uspIntens' element={<ExibitorIntentions />} />
        <Route path='stallChart/:eventId' element={<StallChart />} />
        <Route path='eventStalls/:eventId' element={<Stalls />} />
        <Route path='stalldetails/:eventId' element={<ExhibitorStalldetails />} />
        <Route path='transactionhistory' element={<TransactionHistory />} />

        <Route path='Editstallchart/:eventId/:id' element={<EditStallChart />} />

        {/* Subscription */}
        <Route path='subscriptionTypes' element={<SubscriptionType />} />
        <Route path='editSubscription/:typeId' element={<EditSubscription />} />
        <Route path='subscriptionFeatures' element={<SubscriptionFeature />} />
        <Route path='editSubscriptionFeature/:featureId' element={<EditSubFeature />} />

        <Route path='subscriptionPermissions' element={<SubPermissions />} />
        <Route path='whitelabeling/:eventId' element={<Whitelabeling />} />

        <Route path='subscription' element={<Subscription />} />
        <Route path='paymentDetails/:featureId' element={<Payment />} />
        <Route path='live' element={<Live />} />
        <Route path='paymentDetails/:featureId/:username' element={<Payment />} />
        <Route path='stallPayment/:eventId' element={<StallPayment />} />




        <Route path='OrgDetailedlist/:orgId' element={<OrganizationDetails />} />
        <Route path='Speakers/:eventId' element={<SpeakerMngnt />} />
        <Route path='Sponsonrs/:eventId' element={<Sponsors />} />
        <Route path='EditSponsorType/:eventId/:sponsertypeid' element={<EditSponsorType />} />

        {/* Session */}
        <Route path='session/:eventId' element={<AddSession />} />
        <Route path='eventSession/:eventId' element={<EventSession />} />
        <Route path='adminAdvertisement/:eventId' element={<AdminAdvertisement />} />
        <Route path='AddsDetails/:addId' element={<AddsDetails />} />
        <Route path='ExhibitorDetails/:eventId' element={<ExhibitorDetails />} />
        <Route path='connections/:eventId' element={<Connections />} />
        <Route path='editSession/:eventId/:sessionId' element={<AddSession />} />
       

        <Route path='createAdds' element={<AdminAdvertisement />} />
        <Route path='eventAdds/:eventId' element={<AddList />} />
        <Route path='editAdds/:addId' element={<AdminAdvertisement />} />
        <Route path='advertisements' element={<AddList />} />
        <Route path='editdetails/:addId' element={<EditAddsDetails />} />
        <Route path='addsDetails' element={<AddsDetails />} />
        {/* SearchList */}
        <Route path='Admin/:userName' element={<UserDetails />} />
        <Route path='Organization/:orgName' element={<OrganizationDetails />} />
        <Route path='Event/:eventName' element={<EventDetails />} />
        <Route path='Exibitor/:exibitorName' element={<Exibitor />} />
        <Route path='profiletabs' element={<ProfileTabnav />} />




        {/* committee member */}
        <Route path='profilecommittee' element={<ProfileCommittee />} />
        <Route path='committeedash' element={<CommitteeDashboard />} />
        <Route path='Exibitor/:exibitorName' element={<Committee />} />


        {/* Super Advertisments */}

        <Route path='platformfee' element={<PlatformFee />} />
        <Route path='Editplatform/:platformid' element={<EditPlatform />} />
        <Route path='AdvSetting' element={<AdvertisementSettings />} />
        <Route path='/editadv/:Advseid' element={<EditAdvsettings />} />
        <Route path='/ListAdver' element={<AdminListAdver />} />
        <Route path='/GetAdmin/:AdminName' element={<GetAdminProfile />} />


        {/* Admin Advertisments */}
        <Route path='/createadminadv/:eventId' element={<AddAdminAdver />} />
        <Route path='/platformfee/:eventId' element={<AdminPlatformfee />} />
        <Route path='/editeventadver/:eventId/:addId' element={<AddAdminAdver />} />
        <Route path='/editplatformfee/:eventId' element={<EditAdminPlatform />} />
        <Route path='/adminadv/:eventId' element={<AdminAdver />} />
        <Route path='/addseventDetails/:eventId' element={<AddsDetails />} />
        <Route path='/addseventDetails/:eventId' element={<AddsDetails />} />
        <Route path='/adminadds/:eventId' element={<AdminAddsettings />} />
       



        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}


const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
