import React, { useState } from 'react';
import { KTIcon } from '../../../../../../_metronic/helpers';
import axios from 'axios';
import { useAuth } from '../../../../auth';

const token = localStorage.getItem('token');
const API_URL = process.env.REACT_APP_API_URL;

const DeactivateAccount = (userId) => {
  const [loading, setLoading] = useState(false);
  const user = userId;

  const onClickhandler = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API_URL}/api/accounts/accountUpdate/id=${user}/value=false`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

    } catch (error) {
      // Handle error, e.g., show an error message
      console.error('Error deactivating account:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='card'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_deactivate'
        aria-expanded='true'
        aria-controls='kt_account_deactivate'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Deactivate Account</h3>
        </div>
      </div>

      <div id='kt_account_deactivate' className='collapse show'>
        <form onSubmit={onClickhandler} className='form'>
          <div className='card-body border-top p-9'>
            <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
              <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />

              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>You Are Deactivating the Account</h4>
                  <div className='fs-6 text-gray-600'>
                    For extra security, this requires you to confirm the email.
                    <br />
                    <a className='fw-bolder' href='#'>
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className='form-check form-check-solid fv-row'>
              <input className='form-check-input' type='checkbox' />
              <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                I confirm account deactivation
              </label>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              // id='kt_account_deactivate_account_submit'
              type='submit'
              className='btn btn-danger fw-bold'
              disabled={loading}
            >
              {!loading ? 'Deactivate Account' : 'Please wait...'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { DeactivateAccount };
