import React, {useState, useEffect} from 'react';
import Map from './location'; // Import the Map component
import {toast} from 'react-toastify';
//import Directions from './DirectionService';

const EventPage = ({event}) => {

  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          toast.error(`Error getting user's location: ${error.message}`);
        }
      );
    } else {
        toast.error(`Geolocation is not available`);
    }
  }, []);

  return (
    <div>


        <Map eventLocation={event} />
         </div>
  );
};

export default EventPage;
