import * as React from 'react';
import { Box, styled, Grid, Typography, Button } from '@mui/material';
import ProductHeroLayout from './ProductHeroLayout';
import { PublicNavbar } from './PublicNavbar';
import PubNavbar from '../Public/PublicHome/Navbar';
const backgroundImage =
  'https://vijaykarnataka.com/thumb/93384619/mysuru-startup-pavillion-93384619.jpg?imgsize=31590&width=700&height=525&resizemode=75';

export default function ProductHero() {
  return (
    <>    <PubNavbar />
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
       //backgroundColor: '#7fc7d9', // Average color of the background image.
       backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
      />
      
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Evenet Organization
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 } }}
      >
      Mysuru Startup Pavilion September 2023
      </Typography>
      <div className='row'>
        <div className='col-6'>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/auth/generalRegistration"
        sx={{ minWidth: 200 }}
        
      >
        Register
      </Button>
      </div>
      <div className='col-6'>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/auth/PublicQRCode"
        sx={{ minWidth: 200 }}
      >
        Genrate USer QR code 
      </Button>
      </div>
      </div>
      {/* <Typography variant="body2" color="inherit" sx={{ mt: 2 }}>
        Discover the experience
      </Typography> */}
    </ProductHeroLayout>
    </>

  );
}