import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../app/modules/auth";
import {PageTitle} from '../../layout/components/toolbar/page-title/PageTitle';

function CreateNewAdmin() {
  
  const [image,setImage]=useState("");
  const[loading ,setLoading]=useState(false);
  const {auth} = useAuth();
 
  const API_URL = process.env.REACT_APP_API_URL;
  
  const token = localStorage.getItem('token');

  
 
  const [profile, setProfile] = useState({
    name:"",
    contact: "",
    description: "",
    pan: "",
    gstin: "",
    tan: "",
    orgcode: "",
    address: "",
    status: "",
    admin: "",
    logo: "",
  })

  function getBase64FromFile(file) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();

      reader.readAsDataURL(file);

       reader.onload = function () {

        const [metadata, content] = reader.result.split(",");

        resolve({ metadata, content });

      };

      reader.onerror = function (error) {

        reject(error);

      };

    });

  }

  const handleImageChange=async(event)=>{
    const convertedImage= await getBase64FromFile(event.target.files[0]);
    const imageObject = {

      ...profile,

      imageMeta: convertedImage.metadata   ,
         image:convertedImage.content,

    };
    setProfile(imageObject);
  }

  const navigate = useNavigate();

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    setProfile({ ...profile, [field]: actualValue });
  };
  const handleSubmit = async(event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(API_URL+"/api/createOrganization/createAdmin", profile, {
        headers: { Authorization: `Bearer ${auth?.token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        toast.success("Admin & organization created");
        
                navigate("/adminOrgMap");
                setLoading(false);

      });
  };



  return (
    <div>
     


    <div id="layoutDrawer_content">

{loading && <div className="loading"></div>}

<main>

<div className="col-12 mx-xl-auto ps-xl-12">

<div className="card card-raised ">
<div className="card-body ">
    <div className="col-xl-10" style={{
                    fontSize: '16px'}}>
   <div className="card-title"><h3>Create Admin</h3></div>
        <br>
        </br>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="name">Organization Name</label>
              <input
                className="form-control"
                id="name"
                type="text"
                placeholder="Name"
                onChange={(e) => handleChange(e, "name")}
                
              />
            </div>
          </div>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="contact">Organization Contact</label>
              <input
                className="form-control"
                id="contact"
                type="text"
                placeholder="Contact"
                onChange={(e) => handleChange(e, "contact")}
              />
            </div>
          </div>

          
          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="address">Organization Address</label>
              <input
                className="form-control"
                id="address"
                type="text"
                placeholder="Address"
                onChange={(e) => handleChange(e, "address")}
              />
            </div>
          </div>


          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="pan">Organization Pan</label>
              <input
                className="form-control"
                id="pan"
                type="text"
                placeholder="Pan"
                onChange={(e) => handleChange(e, "pan")}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="gstin">Organization Gstin</label>
              <input
                className="form-control"
                id="gstin"
                type="text"
                placeholder="Gstin"
                 onChange={(e) => handleChange(e, "gstin")}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="tan">Organization Tan</label>
              <input
                className="form-control"
                id="tan"
                type="text"
                placeholder="Tan"
                onChange={(e) => handleChange(e, "tan")}
              />
            </div>
          </div>
   
      

          <div className="row">
            <div className="form-floating-sm mb-2">
              <label htmlFor="admin">User Name</label>
              <input
                className="form-control"
                id="username"
                type="text"
                placeholder="User Name"
                 onChange={(e) => handleChange(e, "username")}
              />
            </div>
          </div>




          <br>
          </br>

          

          <div className="text-end">
            <input
              className="btn btn-secondary"
              type="submit"
              disabled={loading}

              value="Submit"
            />
          </div>
        </form>
     
  </div> 
   </div>
   </div>
   </div>
   </main>
   </div>
   </div>
  );
}



export default CreateNewAdmin;



      