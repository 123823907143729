import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../helpers';
import { toast } from "react-toastify";
import Loader from '../../../Loader';
import Pagination from '../../../app/modules/auth/components/Pagination';
import { useAuth } from '../../../app/modules/auth';

const API_URL = process.env.REACT_APP_API_URL;

function PlatformFee() {
  const [platform, setPlatform] = useState({});
  const [page, setPage] = useState(1);
  const rowsPerPage = 30; 
  const [loading, setLoading] = useState(false);
  const { auth } = useAuth();



  useEffect(() => {
    setLoading(true);
    axios
      .get(API_URL + `/api/platformFee`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setPlatform(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch(error => {
        toast.error('Error Fetching Platform Fee:', error);
        // If there's an error, set default value
        setPlatform([{ id: 1, platformFee: 0 }]);
        setLoading(false);
      });
  }, []);
  

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
        
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>

              <span className='card-label fw-bold fs-3 mb-1'>Platform Fee</span>

            </h3>

         
          </div>


          <div className='card-toolbar'>



            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>


                      <th className='min-w-120px'>Plateform Fee</th>
                      <th className='min-w-120px'>Action</th>



                    </tr>
                  </thead>

                  <tbody>
                  
                    <tr key={platform.id}>
                   



                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                            {platform.platformFee} %
                          </a>
                        </td>



                        <td style={{ display: 'flex', alignItems: 'center' }}>

                        
                          <Link
                            to={`/Editplatform/${platform.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>

                        </td>
                      </tr>
                  
                  </tbody>
                </table>

          
              </div>
            </div>
          </div>

        </div>
      )}
    </div>
  );
}

export default PlatformFee;
