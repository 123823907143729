import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { KTIcon } from '../helpers';
import Pagination from '../../app/modules/auth/components/Pagination';
import CreateEventSector from '../partials/modals/Add Pages/CreateEventSector';
import { Link } from 'react-router-dom';
import Loader from '../../Loader';
import { useAuth } from '../../app/modules/auth';
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

function EventSectors() {
  const [sectors, setSectors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const { auth } = useAuth();
  const [loadingUserId, setLoadingUserId] = useState(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const rowsPerPage = 30;

  function onClickhandler(e, id) {
    const checked = e.target.checked;

    const disable = !checked;
    setLoadingUserId(id)

    axios
      .get(API_URL + `/api/sectors/id=${id}/value=${disable}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then((response) => {
        setLoadingUserId(null);
        toast.success("Updated Succesfully");
        setUpdateFlag((prevFlag) => !prevFlag);
      })
      .catch((error) => {
        toast.errorMessage('Error updating account:', error);
        setLoadingUserId(null);
      });
  }

  const handleEventSector = () => {
    setLoading(true);
    axios
      .get(API_URL + `/api/sectors`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setSectors(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Event Sector. Please try again';
        toast.error(errorMessage);
        setLoading(false);
      });
  };


  useEffect(() => {
    fetchData();
  }, [updateFlag]);


  function fetchData() {
    setLoading(true);
    axios
      .get(API_URL + `/api/sectors`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      })
      .then(response => {
        setSectors(response.data);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = 'Error fetching Event Sector. Please try again.';
        toast.error(errorMessage);
        setLoading(false);
      });
  };

  const handlePageChange = selectedPage => {
    // Update the current page when the pagination component triggers a page change
    setPage(selectedPage);
  };

  const paginatedData = sectors.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className='card card-raised'>
          <CreateEventSector onSectorCreated={handleEventSector} />
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Event Sectors</span>
            </h3>
            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a sector'
            >
              <a
                href='#'
                className='btn btn-sm btn-light-primary float-end'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_eventSector'
              >
                <KTIcon iconName='plus' className='fs-3' />
                Add
              </a>
            </div>
          </div>
          <div className='card-toolbar'></div>
          <div className='card-toolbar'>
            <div className='card-body py-3'>

              <div className='table-responsive'>
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                  <thead>
                    <tr className='fw-bold text-muted'>


                      <th className='min-w-120px'>Sector Name</th>
                      <th className='min-w-120px'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.slice().reverse().map((sector) => (
                      <tr key={sector.id}>

                        <td>
                          <div className='text-dark fw-bold d-block mb-1 fs-6'>
                            {sector.name}
                          </div>
                        </td>
                        <td>
                          <div className="form-check form-switch form-check-inline" style={{ fontSize: '12px', marginRight: '10px' }}>
                            <input
                              className="form-check-input"
                              id={`flexSwitchCheckDefault-${sector.id}`}
                              type="checkbox"
                              defaultChecked={!sector.disable}
                              onClick={(e) => onClickhandler(e, sector.id)}
                              value={sector.id}
                            />
                            {loadingUserId === sector.id && (
                              <span className='spinner-border spinner-border-sm align-middle me-2'></span>
                            )}
                          </div>
                          <Link
                            to={`/EventSectors/${sector.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {sectors.length > 0 && (
                  <div className='pagination-container' style={{ marginRight: '120px' }}>
                    <Pagination
                      data={sectors} // Pass the full data to the Pagination component
                      itemsPerPage={rowsPerPage}
                      onPageChange={handlePageChange}
                      currentPage={page}
                    />
                  </div>
                )}
              </div>


            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EventSectors;
