import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
     {/* {userRole === 'admin' ? ( */}
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/SuperDashboard' /> */}
      {/* // ) : (
      //   <MenuItem title={intl.formatMessage({ id: 'MENU.USER_DASHBOARD' })} to='/dashboard' />
      // )} */}
      {/* <MenuInnerWithSub title='Administrations' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
      
        <MenuItem
          title='Organization management'
          to='/organizationlist'
          icon='message-text-2'/>
        <MenuItem icon='abstract-28' to='/userAcc' title='User ma nagement' />
      </MenuInnerWithSub> */}

      
    </>
  )
}
