import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { KTIcon } from '../../helpers';
import { useAuth } from '../../../app/modules/auth';
import { useParams } from 'react-router-dom';

const AddStall = ({ onstallCreated }) => {
    const {eventId}= useParams();
    const [modalOpen, setModalOpen]= useState(false);
    const [stall, setStall] = useState({
        stallid: '',
        stalltype: '',
        dimension: '',
        eventid: eventId,
        disabled: false,
    });
    const { auth } = useAuth();
    const [stalltype, setStalltype] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        axios
            .get(API_URL + '/api/StallType', {
                headers: {
                    Authorization: `Bearer ${auth?.token}`,
                },
            })
            .then((response) => {
                setStalltype(response.data);
            })
            .catch((error) => {
                toast.error('Error Fetching Stall types:', error);
            });
    }, [auth?.token]);

    const handleChange = (field, value) => {
        const updatedValue = field === 'dimension' ? parseFloat(value) : value;

        setStall({ ...stall, [field]: updatedValue });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
   
        axios
            .post(API_URL + '/api/StallChart/create', stall, {
                headers: { Authorization: `Bearer ${auth?.token}` },
            })
            .then((response) => {
                toast.success('Created Successfully');
                onstallCreated();
                closeModal();
            })
            .catch((error) => {
                toast.error('Error creating Stall:', error);
            });
    };

    const closeModal = () => {
        setModalOpen(false);
      };

    return (
        <div className='modal fade' id='kt_modal_stall_chart' aria-hidden='true'>
            <div className='modal-dialog mw-650px'>
                <div className='modal-content'>
                      <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                            <KTIcon iconName='cross' className='fs-1' />
                        </div>
                    </div>

                    <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>Create Stall Type</h1>
                        </div>

                        {/* Form */}
                        <form onSubmit={handleSubmit}>
                            
                        <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label>Stall Type</label>
                                    <select
                                        id='stalltype'
                                        className='form-control'
                                        onChange={(e) => handleChange('stalltype', e.target.value)}
                                    >
                                        <option>Select...</option>
                                        {stalltype.map((ex) => (
                                            <option key={ex.id} value={ex.stallType}>
                                                {ex.stallType}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label htmlFor='stallId'>Stall Id</label>
                                    <input
                                        className='form-control'
                                        id='stallid'
                                        type='text'
                                        placeholder='Stall Id'
                                        autoComplete='off'
                                        onChange={(e) => handleChange('stallid', e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Stall Type */}
                            

                            {/* Exhibitor Name */}
                            <div className='row'>
                                <div className='form-floating-sm mb-2'>
                                    <label>Dimension</label>
                                    <input
                                        className='form-control'
                                        id='dimension'
                                        type='text'
                                        placeholder='Dimension'
                                        autoComplete='off'
                                        onChange={(e) => handleChange('dimension', e.target.value)}
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className='text-end'>
                                <button className='btn btn-primary' type='submit'>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddStall;
