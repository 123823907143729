/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";
import { KTIcon } from '../../../helpers';

const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const CreateOrg = () => {
  //const [organizationlist, SetOrganizationlist] = useState([]);
  const [loading, setLoading] = useState(false);
 // const [exhibitor, setExhibitor] = useState([]);
  const [image,setImage]=useState("");
const [organizationlist, SetOrganizationlist] = useState({
    name: "",
    contact: "",
    description: "",
    pan: "",
    address: "",
    tan: "",
    admin:"",
  });

  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = (event, field) => {
    let actualValue = event.target.value;

    SetOrganizationlist({ ...organizationlist, [field]: actualValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post(API_URL+"/api/organizationprofiles/create", organizationlist, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data)
      .then((result) => {
        setLoading(false);
          toast.success("Created Successfully");
          
          closeModal();


        });
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };

  return (
    <div className='modal fade' id='kt_modal_invite_organization' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Organization</h1>

             
            </div>


            <div>
      <div id="layoutDrawer_content">
        {loading && <div className="loading"></div>}

        <main>
          <div className="col-12 mx-xl-auto ps-xl-12">
             <br></br>
                <form onSubmit={handleSubmit}>
                  <div className="row"></div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="name">Name </label>
                      <input
                        className="form-control"
                        id="name"
                        type="text"
                        placeholder="Name"
                        onChange={(e) => handleChange(e, "name")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contact">Contact</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        placeholder="Contact"
                        onChange={(e) => handleChange(e, "contact")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        type="text"
                        placeholder="Description"
                        onChange={(e) => handleChange(e, "description")}
                      />
                    </div>
                  </div>
                  <br></br>



                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="pan">Pan</label>
                      <input
                        className="form-control"
                        id="pan"
                        type="text"
                        placeholder="Pan"
                        onChange={(e) => handleChange(e, "pan")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="gstin">Gstin</label>
                      <input
                        className="form-control"
                        id="gstin"
                        type="text"
                        placeholder="Gstin"
                        onChange={(e) => handleChange(e, "gstin")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="tan">Tan</label>
                      <input
                        className="form-control"
                        id="tan"
                        type="text"
                        placeholder="Tan"
                        onChange={(e) => handleChange(e, "tan")}
                      />
                    </div>
                  </div>
                  <br></br>

                  {/* <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="contact">Contact</label>
                      <input
                        className="form-control"
                        id="contact"
                        type="text"
                        placeholder="Contact"
                        onChange={(e) => handleChange(e, "contact")}
                      />
                    </div>
                  </div> */}
                 

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="address">Address</label>
                      <input
                        className="form-control"
                        id="address"
                        type="text"
                        placeholder="Address"
                        onChange={(e) => handleChange(e, "address")}
                      />
                    </div>
                  </div>
                  <br></br>

                  <div className="row">
                    <div className="form-floating-sm mb-2">
                      <label htmlFor="admin">Admin</label>
                      <input
                        className="form-control"
                        id="admin"
                        type="text"
                        placeholder="Admin"
                        onChange={(e) => handleChange(e, "admin")}
                      />
                    </div>
                  </div>
                  <br></br>

                 
                {/* <div className="card card-raised mb-5">
                  <div className="card-body p-5">
                    <div className="card-title">Image</div>
                  

                    <div className="text-center">
                     
                    
                      <div className="caption fst-italic text-muted mb-4">
                        JPG or PNG no larger than 5 MB
                      </div>
                      <input
                        className="col-7"
                        type="file"
                        id="image"
                        onChange={(event) => {
                          console.log(event.target.files[0]);
                          //setProfile({...profile, image: event.target.files[0] });
                          handleImageChange(event)
                        }}
                        accept="image/*"
                      ></input>
                    </div>
                
                </div> 
              </div>*/}
                  <div className="text-end">
                    <input
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                      value="Submit"
                      data-bs-dismiss='modal'
                    />
                  </div>
                </form>
              </div>
           
        </main>
      </div>
    </div>
              
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOrg;
